import styled from 'styled-components';
// IMPORT IMAGES
import changeLanguageIcon from '@assets/images/header/changeLanguageIcon.svg';
import { COLORS } from '@project/styles';

export const LanguageChangerContainer = styled.div`
    display: flex;
    width: auto;
    height: 45px;
    min-width: 100px;
    margin: 0px 15px;
    align-self: center;
    flex-direction: row;
    align-items: center;
    border-radius: 200px;
    border: solid 1px ${COLORS.DARKER_GREY};
    justify-content: center;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        border: none;
        min-width: auto;
        max-width: 69px;
        margin: 0px 10px;
    }

    @media screen and (max-width: 300px) {
        border: none;
        margin: 0px;
    }
`;
export const ChangLanguageIcon = styled.img.attrs({
    src: `${changeLanguageIcon}`,
})`
    width: 85%;
`;
export const ImageWrapper = styled.div`
    display: block;
    width: 30%;
`;

export const LanguageName = styled.h3<{ active: boolean }>`
    padding: 0 10px;
    opacity: ${props => props.active ? 1 : 0.25};
`;