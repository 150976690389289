import { API_ADDRESS_TYPE, LatLng } from "@api/user/address/types";
import { DIBSY_PAYMENT_OPTION } from "@api/user/cards/types";
import { CUSTOM_CARD_RESPONSE_TYPE } from "@api/user/cart/types";
import { Moment } from "moment";
import {
    API_POT_VARIANT_TYPE,
    API_SOIL_VARIANT_TYPE,
    API_TOOL_VARIANT_TYPE,
    API_PLANT_VARIANT_TYPE,
    API_FLOWER_VARIANT_TYPE,
    API_NORMAL_VARIANT_TYPE,
    API_EQUIPMENT_VARIANT_TYPE,
    API_PRODUCT_VARIANT_TYPE,
} from "./variant";

export interface API_TIPS_TYPE {
    id: string;
    title: string;
    image: string;
    description: string;
    high_res_image: string;
    image_height: number;
    image_width: number;
    related_products: API_PRODUCT_TYPE[];
}


export interface API_LISTINGS_TYPE {
    count: number;
    results: API_PRODUCT_TYPE[];
    next: string | null;
    previous: string | null;
}


export type APP_STATE_PRODUCT_VARIANT_TYPE = API_PRODUCT_VARIANT_TYPE & {
    product_images: API_PRODUCT_IMAGE_TYPE[];
}


export interface API_PRODUCT_IMAGE_TYPE {
    product_skus: string[];
    image: string;
    is_main: boolean;
    image_width: number;
    image_height: number;
}

export type API_PRODUCT_BRAND_TYPE = {
    logo: string | null;
    name: string;
    brand_id: string;
    logo_width: number | null;
    logo_height: number | null;
}

export interface API_EXTERNAL_PLANTS_TYPE {
    scientific_names: string[],
    probability: number,
    images: string[]
}

export interface APP_STATE_EXTERNAL_PLANTS_TYPE {
    scientific_name: string,
    accuracy: number,
    image: string[]
}

export interface API_REVIEW_TYPE {
    plant_id: string;
    total: number;
    average_rating: number;
    data: API_RATING_TYPE[];
}

export interface APP_STATE_DESCRIPTION_TYPE {
    id: number;
    text: string;
    progress: number;
}

export interface API_RATING_TYPE {
    user_id: number;
    user_name: string;
    rating: number;
    description: string;
    variant: string;
    user_image?: string;
    plant_image?: string[];
}

// API RESPONSE INTERFACES

export interface API_BASE_PRODUCT_TYPE {
    name: string;
    price: number;
    product_id: string;
    slug: string,
    total_sold?: number;
    num_reviews: number;
    is_in_sale: boolean;
    non_sale_price: number | null;
    average_rating: number | null;
    shortened_display_name: string | null;
    variant_filter_hierarchy: string[];
    instructions_video_url: string | null;
    instructions_video_title: string | null;
    root_category: API_PRODUCT_CATEGORY_TYPE;
    product_images: API_PRODUCT_IMAGE_TYPE[];
    sub_categories: API_PRODUCT_CATEGORY_TYPE[];
    web_meta_title: string | null;
    web_meta_description: string | null;
}

export interface API_PLANT_TYPE extends API_BASE_PRODUCT_TYPE {
    quantity?: number;
    description?: string;
    product_type: 'Plant';
    scientific_name: string;
    sunlight_percentage: number;
    sunlight_description: string;
    easy_grow_percentage: number;
    water_quantity: string | null | number;
    water_frequency: string | null;
    variants: API_PLANT_VARIANT_TYPE[];
    fertilizer_frequency: string | null;
    watering_environment_percentage: number;
    watering_environment_description: string;
    additional_casual_names: string[] | null;
    additional_scientific_names: string[] | null;
    water_quantity_additional_details: string | null;
    water_frequency_additional_details: string | null;
    fertilizer_frequency_additional_details: string | null;
}

export interface API_FLOWER_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'FlowerBouquet';
    subheader: string;
    description: string;
    bouquet_message: string;
    variants: API_FLOWER_VARIANT_TYPE[];
}

export interface API_TOOL_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Tool';
    subheader: string;
    description: string;
    variants: API_TOOL_VARIANT_TYPE[];
}

export interface API_ACCESSORY_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Accessory';
    description: string;
    variants: API_NORMAL_VARIANT_TYPE[];
}

export interface API_POT_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Pot';
    subheader: string;
    description: string;
    finish: string | null;
    has_saucer: boolean | null;
    brand: API_PRODUCT_BRAND_TYPE;
    variants: API_POT_VARIANT_TYPE[];
    has_drainage_holes: boolean | null;
}

export interface API_VASE_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Vase';
    description: string;
    finish: string | null;
    variants: API_NORMAL_VARIANT_TYPE[];
}

export interface API_SOIL_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Soil';
    description: string;
    usage: string | null;
    dosage: string | null;
    organic: boolean | null;
    subheader: string | null;
    nitrogen_npk: number | null;
    potassium_npk: number | null;
    phosphorus_npk: number | null;
    variants: API_SOIL_VARIANT_TYPE[];
}

export interface API_EQUIPMENT_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Equipment';
    description: string;
    subheader: string | null;
    variants: API_EQUIPMENT_VARIANT_TYPE[];
}

export interface API_SEED_TYPE extends API_BASE_PRODUCT_TYPE {
    product_type: 'Seed';
    description: string;
    scientific_name: string;
    variants: API_NORMAL_VARIANT_TYPE[];
}

export type API_OTHER_PRODUCTS_TYPE =
    | API_POT_TYPE
    | API_TOOL_TYPE
    | API_VASE_TYPE
    | API_SOIL_TYPE
    | API_SEED_TYPE
    | API_FLOWER_TYPE
    | API_EQUIPMENT_TYPE
    | API_ACCESSORY_TYPE;

export interface CATEGORY_LISTINGS_FILTER_TYPE {
    name: string;
    display_name: string;
}

// Subject to change
export interface API_CATEGORY_TYPE {
    slug: string;
    name: string;
    subheader: string;
    children: string[];
    icon: string | null;
    image: string | null;
    color_code: string | null;
    image_width: number | null;
    image_height: number | null;
    icon_size_percentage: number | null;
    listing_filters_info: CATEGORY_LISTINGS_FILTER_TYPE[];
}

export type API_PRODUCT_CATEGORY_TYPE = {
    slug: string;
    name: string;
    subheader: string;
    icon: string | null;
    image: string | null;
    color_code: string | null;
    icon_size_percentage: number | null;
};

export interface APP_STATE_PRODUCT_CATEGORY_TYPE {
    slug: string;
    name: string;
    subheader: string;
    icon: string | null;
    image: string | null;
    redirect_url: string;
    color_code: string | null;
    icon_size_percentage: number | null;
}

export type API_PRODUCT_TYPE =
    | API_PLANT_TYPE
    | API_FLOWER_TYPE
    | API_TOOL_TYPE
    | API_ACCESSORY_TYPE
    | API_POT_TYPE
    | API_VASE_TYPE
    | API_SEED_TYPE
    | API_SOIL_TYPE
    | API_EQUIPMENT_TYPE;


export interface POT_COLOR_SKU_TYPE {
    pot_sku: string;
    is_sku_available: boolean;
}

export interface POT_COLOR_OPTION {
    color_code: string;
    color_sku_info: POT_COLOR_SKU_TYPE[];
    is_available: boolean;
    color_finish: string;
    color_variant_image: string | null;

}

export interface APP_STATE_HOME_PRODUCT_TYPE {
    id: string;
    name: string;
    type: string;
    price: number;
    image: string;
    easy_grow?: number;
    subtext: string | null;
    product_redirect_url: string;
    color_options: POT_COLOR_OPTION[];
    product_details: API_PRODUCT_TYPE;
};

export type API_HOME_RECOMMENDED_TYPE = {
    title: string;
    description: string;
    product: API_PRODUCT_TYPE;
}

export type APP_STATE_HOME_RECOMMENDED_TYPE = {
    title: string;
    description: string;
    product_redirect_url: string;
    product: APP_STATE_HOME_PRODUCT_TYPE;
}

export interface API_PLANT_ACCURACY_TYPE {
    plant: API_PLANT_TYPE;
    probability: number;
}


export interface APP_STATE_CATEGORY_TYPE extends API_CATEGORY_TYPE {
    redirect_url: string;
    icon_size_percentage: number;
}

export const initialReviewState: API_REVIEW_TYPE = {
    plant_id: 'Unavailable',
    total: 0,
    average_rating: 0,
    data: [],
};


export interface API_VOTE_TYPE {
    id: string;
    name: string;
    image: string;
    votes: number;
    subheader: string;
    image_width: number;
    image_height: number;
    marked_by_user: boolean;
    description: string | null;
    root_category: API_PRODUCT_CATEGORY_TYPE;
    sub_categories: API_PRODUCT_CATEGORY_TYPE[];
}

export type APP_STATE_VOTE_TYPE = API_VOTE_TYPE;

export interface API_VOTE_PRODUCT_TYPE {
    next: string | null;
    results: API_VOTE_TYPE[];
}

export interface API_RECOMMENDED_POT_TYPE {
    pot_sku: string;
    product: API_POT_TYPE;
}

export interface APP_RECOMMENDED_POT_TYPE {
    sku: string;
    name: string;
    image: string;
    price: number;
    subheader: string | null;
    existing_quantity: number;
    pot_details: API_POT_TYPE;
    variant_info: API_POT_VARIANT_TYPE;
}


export type PRODUCT_TYPE =
    | 'Plant'
    | 'Tool'
    | 'FlowerBouquet'
    | 'Accessory'
    | 'Pot'
    | 'ProductBundle'
    | 'Vase'
    | 'Soil'
    | 'Seed'
    | 'Equipment'


export interface API_CUSTOM_CARD_TYPE {
    sender: string;
    recipient: string;
    message: string;
    card_design: CUSTOM_CARD_RESPONSE_TYPE | null;
    additional_instructions: string | null;
}

export interface API_CUSTOM_CARD_PARAMS {
    sender: string;
    recipient: string;
    message: string;
    card_design?: string;
    additional_instructions?: string;
}

export interface APP_STATE_CUSTOM_CARD_TYPE {
    to: string;
    from: string;
    message: string;
    card_design?: string;
}


export interface OPTION_SKU_TYPE {
    product_sku: string;
    is_sku_available: boolean;
}

export interface COLOR_OPTION_TYPE {
    color: string;
    color_code: string;
    is_available: boolean;
    color_finish?: string;
    color_sku_info: OPTION_SKU_TYPE[];
}

export interface OPTION_TYPE {
    option: string;
    is_available: boolean;
    option_sku_info: OPTION_SKU_TYPE[];
    other_option_data?: { [k: string]: string };

}


// Import Types
export const ORDER_STATUS = {
    OTHER: 'Other',
    FAILED: 'Failed',
    UNKNOWN: 'Unknown',
    PENDING: "Pending",
    ACCEPTED: 'Accepted',
    DELIVERED: 'Delivered',
    CANCELLED: 'Cancelled',
    DELIVERING: 'Delivering',
    COULD_NOT_FULFILL: 'Could Not Fulfill',
} as const;

export type OrderStatusType = typeof ORDER_STATUS[keyof typeof ORDER_STATUS]


export interface API_GIFT_ADDRESS_TYPE {
    recipient_name: string;
    mobile_number: string;
}

export interface DELIVERY_WINDOW_TYPE {
    date: string;
    start_time: string;
    end_time: string;
}

interface API_PROMO_CODE_INFO {
    type: string
    code: string
    minimum_required_amount?: number
    absolute_discount_amount?: number
    percentage_decrease_amount?: number
    combo_absolute_discount_amount?: number
    combo_percentage_decrease_amount?: number

}

export interface API_ORDER_DETAIL_PRODUCT_TYPE {
    price: number;
    quantity: number;
    product_sku: string;
    is_review_added: boolean;
    product: API_PRODUCT_TYPE;
    custom_card: API_CUSTOM_CARD_TYPE | null;
}

export interface API_ORDER_DETAIL_TYPE {
    date: string;
    status: string;
    ref_id: number;
    order_id: string;
    created_at: string;
    merchant_name: string;
    delivery_cost: number;
    is_retryable: boolean;
    total_cart_price: number;
    is_gift_delivery: boolean;
    payment_completed: boolean;
    failed_time: string | null;
    pickup_time: string | null;
    accepted_time: string | null;
    delivered_time: string | null;
    repotting_cost: number | null;
    user_additional_notes: string | null;
    related_split_order: string | null;
    delivery_address?: API_ADDRESS_TYPE;
    delivery_cost_after_discount: number;
    total_cart_price_after_discount: number;
    custom_card: API_CUSTOM_CARD_TYPE | null;
    plants_in_pots: REPOTTED_PRODUCTS_TYPE[];
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE;
    order_items: API_ORDER_DETAIL_PRODUCT_TYPE[];
    applied_promo_code: API_PROMO_CODE_INFO | null;
    delivery_schedule_window: DELIVERY_WINDOW_TYPE;
    payment_type: PAYMENT_METHODS;
}

export type APP_STATE_ORDER_SUCCESSFUL_TYPE = APP_STATE_ORDER_TYPE

export interface APP_STATE_ORDER_TYPE extends Omit<
    API_ORDER_DETAIL_TYPE,
    'status'
> {
    status: OrderStatusType;
}

export interface APP_STATE_ORDER_DETAIL_PAGINATION_TYPE {
    next: string | null;
    results: APP_STATE_ORDER_DETAIL_TYPE[];
}

export interface APP_STATE_ORDER_DETAIL_TYPE {
    order_id: string;
    created_at: string;
    pickup_time: string;
    failed_time: string;
    accepted_time: string;
    is_retryable: boolean;
    merchant_name: string;
    repotting_cost: number;
    delivered_time: string;
    status: OrderStatusType;
    is_gift_delivery: boolean;
    user_additional_notes: string;
    custom_card?: API_CUSTOM_CARD_TYPE;
    delivery_address?: API_ADDRESS_TYPE;
    order_details: API_ORDER_DETAIL_TYPE;
    delivery_window: DELIVERY_WINDOW_TYPE;
    normal_products: API_ORDER_PRODUCT_TYPE[];
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE;
    repotted_products: APP_REPOTTED_PRODUCTS_TYPE[];
}


export interface API_REPOT_NORMAL_PRODUCT_SPLIT_TYPE {
    repottable_products: {
        pot: API_ORDER_PRODUCT_TYPE;
        plant: API_ORDER_PRODUCT_TYPE;
    }[];
    normal_products: API_ORDER_PRODUCT_TYPE[];
}

export interface API_ORDER_PAGINATION_TYPE {
    count: number;
    next: string | null;
    previous: string | null;
    results: API_ORDER_DETAIL_TYPE[];
}

export interface API_ORDER_PRODUCT_TYPE {
    id: string;
    name: string;
    latin: string;
    variant_name: string;
    price: number;
    image: string;
    quantity: number;
    is_review_added: boolean;
    custom_card: API_CUSTOM_CARD_TYPE | null;
    product: API_PRODUCT_TYPE
}

export interface API_SCHEDULE_TIMING_TYPE {
    [k: string]: API_SCHEDULE_TIME_TYPE[]
}

export interface DELIVERY_OPTION_TYPE {
    cart_items: string[];
    timings: API_SCHEDULE_TIMING_TYPE[];
}

export interface APP_ORDER_SCHEDULE_TYPE {
    date: Moment;
    time: API_SCHEDULE_TIME_TYPE
}

export interface API_SCHEDULE_TIME_TYPE {
    start_time: string;
    end_time: string;
}

export interface API_ORDER_SCHEDULE_TYPE {
    possible_timing_windows: API_SCHEDULE_TIME_TYPE[];
    delivery_options: {
        single_order: DELIVERY_OPTION_TYPE;
    }
}


export interface CUSTOM_CARD_TYPE {
    sender: string,
    recipient: string,
    message: string,
    card_design?: string;
}


export interface REPOTTED_PRODUCTS_TYPE {
    pot_sku: string;
    plant_sku: string;
}


export interface APP_REPOTTED_PRODUCTS_TYPE {
    pot: API_ORDER_PRODUCT_TYPE;
    plant: API_ORDER_PRODUCT_TYPE;
}

export type PAYMENT_METHODS = 'Cash' | 'Credit Card' | 'Debit Card' | 'Apple Pay'

export interface MAKE_ORDER_PARAMS {
    payment_type: PAYMENT_METHODS;
    total_price_expected_by_user: number;
    delivery_cost_expected_by_user: number;
    repotting_cost_expected_by_user: number;
    plants_in_pots: REPOTTED_PRODUCTS_TYPE[];
    main_delivery_window: DELIVERY_WINDOW_TYPE;
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE;

    // Optional Parameters
    delivery_address?: string;
    custom_card?: CUSTOM_CARD_TYPE;
    user_additional_notes?: string;
}

export interface CHECKOUT_STATE {
    total_price_expected_by_user: number;
    delivery_cost_expected_by_user: number;
    repotting_cost_expected_by_user: number;
    plants_in_pots: REPOTTED_PRODUCTS_TYPE[];

    // Optional Parameters
    delivery_address?: string;
    custom_card?: CUSTOM_CARD_TYPE;
    user_additional_notes?: string;
    payment_option?: DIBSY_PAYMENT_OPTION;
    main_delivery_window?: DELIVERY_WINDOW_TYPE;
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE;
}

export type FLAT_REPOT_STRATEGY = 'flat_fee_repotting';
export type SCALING_REPOT_STRATEGY = 'charge_each_repotting';
export type TWO_LEVEL_REPOT_STRATEGY = 'x_for_single_repot_y_for_multiple_repot';

export type REPOT_STRATEGY = TWO_LEVEL_REPOT_STRATEGY | SCALING_REPOT_STRATEGY | FLAT_REPOT_STRATEGY;

export interface API_REPOTTING_CONFIGURATION_TYPE {
    repotting_cost_strategy: REPOT_STRATEGY;
    each_repotting_cost: number;
    first_repotting_cost: number;
    second_repotting_cost: number;
    flat_fee_repotting_cost: number;
}

export interface ADDITIONAL_ORDER_COSTS_TYPE {
    total_additional_cost: number;
    all_cost_information?: { [cost_name: string]: number; };
}

export interface APP_STATE_ORDER_DETAIL_TYPE {
    order_id: string;
    created_at: string;
    pickup_time: string;
    failed_time: string;
    accepted_time: string;
    is_retryable: boolean;
    merchant_name: string;
    repotting_cost: number;
    delivered_time: string;
    status: OrderStatusType;
    is_gift_delivery: boolean;
    user_additional_notes: string;
    custom_card?: API_CUSTOM_CARD_TYPE;
    delivery_address?: API_ADDRESS_TYPE;
    order_details: API_ORDER_DETAIL_TYPE;
    delivery_window: DELIVERY_WINDOW_TYPE;
    normal_products: API_ORDER_PRODUCT_TYPE[];
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE;
    repotted_products: APP_REPOTTED_PRODUCTS_TYPE[];
}

export interface API_ORDER_TYPE {
    count: number;
    next: string | null;
    previous: string | null;
    results: APP_STATE_ORDER_DETAIL_TYPE[];
}
