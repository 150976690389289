
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    getFirstError,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
    RESPONSE_TYPE,
} from '@api/interactions';


export const getWebIdFetch = async () => {

    const HttpRequest = new HTTP_REQUEST('auth/get-web-device-id/', {
        method: HTTP_METHODS.GET,
        options: {},
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


interface CUSTOM_RESPONSE {
    web_device_id: string;
    web_device_id_cookie: string;
}

const api_type = 'GET_WEB_DEVICE_ID';
const getWebDeviceId = async (): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await getWebIdFetch();
        const APIResponse = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>(APIResponse);
        }
        return FAILURE_RESPONSE(
            api_type,
            getFirstError(APIResponse) || 'Invalid Information'
        );
    } catch (error) {
        return FAILURE_RESPONSE(api_type, 'Error setting web-device-id');
    }
};

export default getWebDeviceId;