import React, { useEffect, useState } from 'react';

// IMPORT TYPES
import { RootState } from '@redux/store';

// IMPORT COMPONENTS
import Summary from '@pages/Checkout/components/Summary';
import { CheckoutMain } from './components/CheckoutElements';

// IMPORT HELPERS
import { Loading } from '@custom_components';
import { useDidMount } from '@project/hooks';


import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { getAuthenticatedCartItems } from '@api/helpers';
import { begin_checkout_gtag_report_conversion } from 'lib/common/google_analytics';


const OrderProcessFlow = () => {
    useDidMount(() => {
        getAuthenticatedCartItems();
        begin_checkout_gtag_report_conversion();
    })
    const { t } = useTranslation();

    return (
        <CheckoutMain>
            <Helmet><title>{t('checkout.metaTitle')}</title></Helmet>
            <Summary />
        </CheckoutMain>
    )
}

export default OrderProcessFlow;
