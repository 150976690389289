import { memo, useEffect } from 'react';

// MODULE IMPORTS
import styled from 'styled-components';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

// API HELPERS
import googleSignIn from '@api/auth/signin/google';
import facebookSignIn from '@api/auth/signin/facebook';

// STORE/REDUX IMPORTS
import { signIn } from '@redux/state/auth';
import { useDispatch } from 'react-redux';

import '@project/fonts';
import { FACEBOOK_APP_ID } from 'config';
import { useNavigate } from 'react-router-dom';
import { getPathWithLang } from '@translation';
import { GOOGLE_WEB_CLIENT_ID } from 'config';

interface SocialMediaProps {
    nextPath: string | null;
    setError: (error_msg: string) => void;
}

export const SocialMediaLogin = ({ nextPath, setError }: SocialMediaProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const googleLoginSuccess = async (response: any) => {
        const googleAuthResponse = await googleSignIn(response.accessToken);

        if (googleAuthResponse.status) {
            const { key } = googleAuthResponse.response_body
            setError('');
            dispatch(signIn(key));
            navigate(getPathWithLang(nextPath ? nextPath : '/'), { replace: true });
        } else {
            setError(googleAuthResponse.error?.error_message as string)
        }
    };

    const socialLoginFailure = async (fail: any) => {
        setError('Something went wrong... Please try again later!')
    }

    const facebookLoginSuccess = async (response: any) => {
        const facebookAuthResponse = await facebookSignIn(response.accessToken);

        if (facebookAuthResponse.status) {
            const { key } = facebookAuthResponse.response_body
            setError('');
            dispatch(signIn(key));
            navigate(getPathWithLang(nextPath ? nextPath : '/'), { replace: true });
        } else {
            setError(facebookAuthResponse.error?.error_message as string)
        }
    };

    return (
        <div style={{ width: '70%', display: 'flex', flexDirection: 'column', paddingTop: 20, }}>
            <GoogleLogin {...{
                clientId: GOOGLE_WEB_CLIENT_ID,
                onSuccess: googleLoginSuccess,
                cookiePolicy: 'single_host_origin',
                render: (props) => <GoogleLoginButton {...props} />
            }} />
            <FacebookLogin {...{
                autoLoad: true,
                useRedirect: true,
                initParams: { version: 'v10.0' },
                fields: "name,email,picture",
                appId: FACEBOOK_APP_ID || '',
                onSuccess: facebookLoginSuccess,
                onFail: socialLoginFailure,
                render: (renderProps) => (<FacebookLoginButton {...renderProps} {...{
                    style: { background: '#1877f2' }, activeStyle: { background: '#0d6ae4' }
                }} />)
            }}
            />
        </div>
    );
};
