import { API_FLOWER_TYPE } from '@api/types';
import HighlightedCategory from './HighlightedCategory/HighlightedCategory';
import { useTranslation } from 'react-i18next';
import FlowerBouquetImage from '@assets/images/home/CategoryDisplay/flowerBouquet.jpg';


const FlowerBouquetSection = ({ products }: { products: API_FLOWER_TYPE[] }) => {
    const { t } = useTranslation();

    return (
        <HighlightedCategory
            {...{
                products,
                categoryName: t('home.flowerBouquets'),
                categoryButtonText: t('home.allProducts'),
                categoryImage: FlowerBouquetImage,
                categorySlug: 'flower-bouquets',
            }}
        />
    );
};

export default FlowerBouquetSection;
