// IMPORT TYPES
import { API_USER_TYPE } from '@api/user/types';
import { API_ADDRESS_TYPE } from '@api/user/address/types';
import { API_SAVED_CARDS_TYPE } from '@api/user/cards/types';

// IMPORT STORE HELPERS
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface USER_PROFILE_DETAILS {
    details: API_USER_TYPE;
    addresses: API_ADDRESS_TYPE[];
    cards: API_SAVED_CARDS_TYPE[];
}

export const initialUserState: USER_PROFILE_DETAILS = {
    cards: [],
    addresses: [],
    details: {
        email: '',
        user_id: '',
        username: '',
        last_name: '',
        full_name: '',
        first_name: '',
        data_joined: '',
        otp_phone_number: '',

        avatar: '',
        avatar_path: '',
        avatar_width: 0,
        avatar_height: 0,

        is_otp_verified: false,
        is_fake_temporary_email: true,
        should_prompt_app_review: false,
    },
};

const updateAddress = (
    newAddress: API_ADDRESS_TYPE,
    allAddresses: API_ADDRESS_TYPE[]
) =>
    allAddresses.map((address) => {
        if (address.address_id === newAddress.address_id) {
            return newAddress;
        }
        return address;
    });

const deleteUserAddress = (
    addressId: string,
    allAddresses: API_ADDRESS_TYPE[]
) => allAddresses.filter((address) => addressId !== address.address_id);

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        //
        // Sync state with database
        //
        syncUserDetailsWithDb: (
            state,
            {
                payload,
            }: PayloadAction<Partial<USER_PROFILE_DETAILS['details']>>
        ) => {
            state.details = { ...state.details, ...payload };
        },

        syncUserAddressesWithDb: (
            state,
            {
                payload,
            }: PayloadAction<
                USER_PROFILE_DETAILS['addresses']
            >
        ) => {
            state.addresses = payload;
        },
        syncUserCardsWithDb: (
            state,
            {
                payload,
            }: PayloadAction<
                USER_PROFILE_DETAILS['cards']
            >
        ) => {
            state.cards = payload;
        },
        //
        // Update user details
        //
        updateUserAvatar: (state, { payload }: PayloadAction<string>) => {
            state.details.avatar = payload;
        },
        updateUserFirstName: (state, { payload }: PayloadAction<string>) => {
            state.details.first_name = payload;
        },
        updateUserLastName: (state, { payload }: PayloadAction<string>) => {
            state.details.last_name = payload;
        },
        updateUserName: (state, { payload }: PayloadAction<string>) => {
            state.details.username = payload;
        },
        updateUserEmail: (state, { payload }: PayloadAction<string>) => {
            state.details.email = payload;
            state.details.is_fake_temporary_email = false;
        },
        updateUserPhoneNumber: (state, { payload }: PayloadAction<string>) => {
            state.details.otp_phone_number = payload;
        },

        updateUserPhoneNumberWithOTP: (state, { payload }: PayloadAction<string>) => {
            state.details.otp_phone_number = payload;
            state.details.is_otp_verified = true;
        },
        updateUserAddressState: (
            state,
            { payload }: PayloadAction<API_ADDRESS_TYPE>
        ) => {
            const newAddresses = updateAddress(
                payload,
                state.addresses
            );
            state.addresses = newAddresses;
        },
        deleteUserAddressState: (
            state,
            { payload }: PayloadAction<API_ADDRESS_TYPE['address_id']>
        ) => {
            state.addresses = deleteUserAddress(
                payload,
                state.addresses
            );
        },
        //
        // ADD NEW
        //
        addNewAddress: (
            state,
            { payload }: PayloadAction<API_ADDRESS_TYPE>
        ) => {
            state.addresses = [
                payload,
                ...state.addresses,
            ];
        },
        addNewCard: (
            state,
            { payload }: PayloadAction<API_SAVED_CARDS_TYPE>
        ) => {
            state.cards = [
                payload,
                ...state.cards,
            ];
        },

        clearUser: (state) => {
            state.cards = [];
            state.addresses = [];
            state.details = initialUserState.details;
        }
    },
});

export const {
    clearUser,

    syncUserCardsWithDb,
    syncUserDetailsWithDb,
    syncUserAddressesWithDb,

    updateUserName,
    updateUserEmail,
    updateUserAvatar,
    updateUserLastName,
    updateUserFirstName,
    updateUserPhoneNumber,
    updateUserPhoneNumberWithOTP,

    addNewCard,
    addNewAddress,
    updateUserAddressState,
    deleteUserAddressState,

} = userSlice.actions;

export default userSlice.reducer;
