import React, { useRef, useState, useCallback, useEffect } from 'react';

// IMPORT HOOKS
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

// IMPORT TYPES
import { RootState } from '@redux/store';

// IMPORT STYLED COMPONENTS
import {
    UploadIcon,
    UploadOrText,
    UploadMaxText,
    ImageUploadBox,
    UploadNewImageText,
    UploadImageContainer,

    Main,
    TitleWrapper,
    SliderAndSaveWrapper,
    ImagePreviewAndCrop,

} from './UploadAvatarElements';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Stack, styled } from '@mui/material';
import {
    CommonButton,
    CommonButtonText,
} from '@styled_components';
import { InputSectionTitle } from '@form_components';
import { PrivateButtonState, LoadingButton } from '@custom_components';
import { TitleButtonsWrapper } from '../../AddressAndCardsTab/SavedAddresses/SavedAddressesElements';

//IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT COMPONENTS
import { uploadImage } from '@api/settings/image/uploads';
import { imagePostTypeWithAspectRatio } from '@api/settings/image/types';

// IMPORT HELPERS
import { getUserAvatar, updateUserFetch } from '@api/user';
import { updateUserAvatar } from '@redux/state/user';
import { scrollToTop } from '@project/helpers';


const UploadAvatar = ({
    openImageSelector,
    setOpenImageSelector,
}: {
    openImageSelector: boolean;
    setOpenImageSelector: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [newAvatar, setNewAvatar] = useState('');
    const [isAvatarEditorOpen, setIsAvatarEditorOpen] =
        useState<boolean>(false);

    const [value, setValue] = useState<number>(25);
    const [imageScale, setImageScale] = useState<number>(1);
    const imageEditorRef = useRef<any>(null);

    const onDrop = useCallback((acceptedFiles) => {
        setNewAvatar(acceptedFiles[0]);
        setIsAvatarEditorOpen(true);
    }, []);
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
    });
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const newScale = Math.max(1, 4 * ((125 - (newValue as number)) / 100));
        setImageScale(newScale);
        setValue(125 - (newValue as number))

    };

    const [cropButtonState, setCropButtonState] = useState<PrivateButtonState>({
        loading: false,
        submitted: false,
        success: null,
        error: '',
    });
    const avatarUploadKey = useSelector(
        (store: RootState) => store.upload.user_avatar
    );
    const dispatch = useDispatch();

    const uploadImageKey = async (imageKey: string) => {
        const data = {
            avatar_path: imageKey,
        };
        const response = await updateUserFetch(data);
        if (response.ok) {
            const newUserAvatar = await getUserAvatar();
            if (response) {
                dispatch(updateUserAvatar(newUserAvatar.response_body.url));
            }
            setCropButtonState((state) => ({
                ...state,
                loading: false,
                success: true,
            }));
        } else {
            setCropButtonState((state) => ({
                ...state,
                loading: false,
                success: false,
            }));
        }
    };
    const [oldImageKey, setOldImageKey] = useState(avatarUploadKey);

    useEffect(() => {
        if (oldImageKey !== avatarUploadKey && avatarUploadKey) {
            uploadImageKey(avatarUploadKey.image_key);
        }
    }, [avatarUploadKey]);
    const onClickSave = async () => {
        if (imageEditorRef) {
            const canvas = imageEditorRef.current.getImageScaledToCanvas();
            const { width, height } = canvas;
            const imageURL = canvas.toDataURL();
            const imagePost: imagePostTypeWithAspectRatio = {
                uri: imageURL,
                type: 'image/jpeg',
                name: 'user-avatar',
                height,
                width,
                aspectRatio: 1,
            };
            setCropButtonState((state) => ({
                ...state,
                loading: true,
                submitted: true,
            }));
            const response = await uploadImage(imagePost, 'profile');
            if (!response) {
                setCropButtonState((state) => ({
                    ...state,
                    loading: false,
                    success: false,
                }));
            } else {
                if (avatarUploadKey) {
                    scrollToTop();
                    setOpenImageSelector(false);
                    uploadImageKey(avatarUploadKey.image_key);
                }
            }
        }
    };
    return (
        <Main>
            <UploadImageContainer
                {...{ isOpen: openImageSelector, isAvatarEditorOpen }}
            >
                <TitleWrapper>
                    <InputSectionTitle>New Profile Image</InputSectionTitle>
                    <TitleButtonsWrapper>
                        <CommonButton
                            {...{
                                style: {
                                    margin: '0 5px',
                                    padding: '0 10px',
                                    width: 'auto',
                                },
                                color: COLORS.RED,
                                outlined: false,
                                onClick: () => {
                                    scrollToTop();
                                    setOpenImageSelector(false)
                                },
                            }}
                        >
                            <CommonButtonText
                                {...{
                                    color: COLORS.RED,
                                    outlined: false,
                                    style: {
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                    }
                                }}
                            >
                                Cancel
                            </CommonButtonText>
                        </CommonButton>
                    </TitleButtonsWrapper>
                </TitleWrapper>

                <ImagePreviewAndCrop
                    {...{ areImagesUploaded: isAvatarEditorOpen }}
                >
                    <AvatarEditor
                        {...{
                            ref: imageEditorRef,
                            style: { border: '1px', borderRadius: 0 },
                            width: 250,
                            height: 250,
                            scale: imageScale,
                            borderRadius: 220,
                            image: newAvatar,
                        }}
                    />

                    <SliderAndSaveWrapper>
                        <Slider
                            {...{
                                value,
                                min: 25,
                                max: 100,
                                size: 'medium',
                                onChange: handleSliderChange,
                                sx: {
                                    direction: 'ltr',
                                    marginBottom: '20px',
                                    color: 'darkgreen',
                                    transform: 'scale(-1, -1)',
                                    "& .MuiSlider-thumb": {
                                        transform: 'translate(10px, -10px)',
                                    }
                                },
                            }}
                        />
                        <LoadingButton
                            {...{
                                buttonState: cropButtonState,
                                disabled: false,
                                buttonTitle: 'crop',
                                onClick: onClickSave,
                                style: { width: 200 },
                                colorScheme: COLORS.BASIC_LOADING_COLOR_SCHEME,
                            }}
                        />
                    </SliderAndSaveWrapper>
                </ImagePreviewAndCrop>
                <ImageUploadBox {...getRootProps()}>
                    <UploadIcon />
                    {isDragActive ? (
                        <p>Drop your image here...</p>
                    ) : (
                        <>
                            <UploadNewImageText style={{ marginTop: '2%' }}>
                                Upload an image
                            </UploadNewImageText>
                            <UploadOrText>or</UploadOrText>
                            <UploadNewImageText>
                                Drag and Drop a PNG or JPEG into this box.
                            </UploadNewImageText>
                            <UploadMaxText>( max. 800 x 400px )</UploadMaxText>

                        </>
                    )}
                </ImageUploadBox>
            </UploadImageContainer>
        </Main>
    );
};

export default UploadAvatar;
