import { useEffect, useRef, useState } from 'react';

// MODULE IMPORTS
import { makeStyles } from '@mui/styles';
import { Slide, TextField, Typography } from '@mui/material';

// IMPORT API HELPERS
import SignUp, { SIGN_UP_RESPONSE } from '@api/auth/signup';

// IMPORT HOOKS
import {
    useEmailValidator,
    useFormSubmission,
    useNewPasswordValidator,
} from '@project/hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// IMPORT TYPES

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT STORE HELPERS
import { signIn } from '@redux/state/auth';


// CUSTOM COMPONENTS IMPORTS
import { OrDivider } from '../components/OrDivider';
import { RememberMe } from '../components/RememberMe';
import { SocialMediaLogin } from '../components/SocialMediaLogin';
import { LoadingButton, ResponsiveText } from '@custom_components';
import { useNavigate } from 'react-router-dom';


const styles = {
    divider: {
        width: '70%',
        marginTop: '4%',
        marginBottom: '4%',
    },
    textInput: {
        width: '70%',
    },

    loginButtonsAndTextInput: {
        display: 'flex',
        height: '90%',
        alignItems: 'center',
    },
};
const useHelperTextStyles = makeStyles(() => ({
    root: {
        color: COLORS.RED,
    },
}));
interface LoginProps {
    nextPath: string | null;
}
export const SignUpForm = ({ nextPath }: LoginProps) => {
    // STYLES =====
    const { divider, textInput, loginButtonsAndTextInput } = styles;
    const helperTextStyles = useHelperTextStyles();
    const containerRef = useRef(null);

    // HOOKS =====
    const { t } = useTranslation();
    const { emailerror, email, setEmail } = useEmailValidator();
    const {
        passwordError,
        confirmPasswordError,
        password,
        confirmPassword,
        setPassword,
    } = useNewPasswordValidator();

    const [rememberMe, setRememberMe] = useState(true);

    const [
        { showEmailError, showPasswordError, showConfirmPasswordError },
        setShowErrors,
    ] = useState({
        showEmailError: false,
        showPasswordError: false,
        showConfirmPasswordError: false,
    });
    const [disableButton, setDisableButton] = useState(false);
    useEffect(() => {
        if (passwordError || confirmPasswordError || emailerror) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [passwordError, confirmPasswordError, emailerror]);

    // HELPERS
    const dispatch = useDispatch();
    const [signUpState, setSignUpState] = useFormSubmission<SIGN_UP_RESPONSE>(() =>
        SignUp(email, password, confirmPassword)
    );

    const { response, success, error } = signUpState;
    const navigate = useNavigate();

    const storeKey = () => {
        const key = response.response_body ? response.response_body.key : '';
        dispatch(signIn(key));
    };

    const getResponseFromSignUp = () => {
        response.status
            ? storeKey()
            : setSignUpState((state) => ({
                ...state,
                error: t('auth.unableToSignUp'),
            }));
    };

    const onSubmit = async () => {
        setSignUpState((state) => ({
            ...state,
            submitted: true,
        }));
    };

    const onPressEnter = (e: any) => {
        if (e.keyCode == 13 && Boolean(email)
            && Boolean(password) && Boolean(confirmPassword)) {
            onSubmit();
        }
    }

    const setError = (error_msg: string) => {
        setSignUpState(prev => ({
            ...prev, error: error_msg
        }))
    }

    useEffect(() => {
        if (response.status) {
            getResponseFromSignUp();
            navigate(nextPath ? nextPath : '/');
        }
    }, [response])

    return (
        <Slide
            {...{
                direction: 'right',
                in: true,
                enter: true,
                exit: true,
                container: containerRef.current,
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 40,
                }}
            >
                <ResponsiveText
                    props={{
                        variant: 'h3',
                        marginLeft: '17%',
                        marginRight: '17%',
                        marginTop: '5%',
                        marginBottom: '1%',
                        fontWeight: '900',
                    }}
                >
                    {t('auth.signUp')}
                </ResponsiveText>
                <ResponsiveText
                    props={{
                        variant: 'h5',
                        marginLeft: '17%',
                        marginRight: '17%',
                    }}
                >
                    {t('auth.createNewAccount')}
                </ResponsiveText>

                <div
                    style={{
                        ...loginButtonsAndTextInput,
                        flexDirection: 'column',
                    }}
                >
                    <SocialMediaLogin {...{ nextPath, setError }} />
                    <OrDivider />
                    <TextField
                        {...{
                            id: 'emailInput',
                            label: t('common.email'),
                            type: 'email',
                            onBlur: () =>
                                Boolean(email) && setShowErrors((state) => ({
                                    ...state,
                                    showEmailError: true,
                                })),
                            style: textInput,
                            variant: 'outlined',
                            helperText: showEmailError && emailerror,
                            FormHelperTextProps: {
                                classes: {
                                    root: helperTextStyles.root,
                                },
                            },
                            onChange: (e) => setEmail(e.target.value),
                            onKeyDown: onPressEnter,
                        }}
                    />

                    <TextField
                        {...{
                            label: t('common.password'),
                            type: 'password',
                            variant: 'outlined',
                            onBlur: () =>
                                Boolean(password) && setShowErrors((state) => ({
                                    ...state,
                                    showPasswordError: true,
                                })),
                            helperText: showPasswordError && passwordError,
                            style: { width: '70%', marginTop: '3%' },
                            FormHelperTextProps: {
                                classes: {
                                    root: helperTextStyles.root,
                                },
                            },
                            onChange: (e) =>
                                setPassword((state) => ({
                                    ...state,
                                    password: e.target.value,
                                })),
                            onKeyDown: onPressEnter,
                        }}
                    />
                    <TextField
                        {...{
                            id: 'confirmPasswordInput',
                            label: t('common.confirmPassword'),
                            type: 'password',
                            variant: 'outlined',

                            onBlur: () =>
                                setShowErrors((state) => ({
                                    ...state,
                                    showConfirmPasswordError: true,
                                })),
                            helperText:
                                showConfirmPasswordError &&
                                confirmPasswordError,
                            style: { width: '70%', marginTop: '3%' },
                            FormHelperTextProps: {
                                classes: {
                                    root: helperTextStyles.root,
                                },
                            },
                            onChange: (e) =>
                                setPassword((state) => ({
                                    ...state,
                                    confirmPassword: e.target.value,
                                })),
                            onKeyDown: onPressEnter,
                        }}
                    />

                    <RememberMe {...{ setRememberMe, color: COLORS.AUTH.SIGNUP.PRIMARY }} />
                    {!signUpState.success && (
                        <Typography style={{
                            width: '70%',
                            margin: error && '10px',
                            color: COLORS.RED,
                            textAlign: 'center',
                        }}>
                            {error}
                        </Typography>
                    )}

                    <LoadingButton
                        {...{
                            disabled: false,
                            style: { backgroundColor: COLORS.AUTH.SIGNUP.TERTIARY },
                            buttonTitle: t('auth.signUp'),
                            onClick: onSubmit,
                            buttonState: signUpState,
                            colorScheme: COLORS.AUTH.SIGNUP,
                        }}
                    />
                </div>
            </div>
        </Slide>
    );
};
