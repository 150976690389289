// IMPORT TYPES
import {
    API_ADDRESS_ERROR_TYPE,
    PRIVATE_API_ADDRESS_TYPE,
} from '@api/user/address/types';

// IMPORT STYLED COMPONENTS
import {
    TextInput,
    TextInputOneField,
    TextInputTwoFields,
} from '@form_components';

export const RenderFields = ({
    newAddressForm,
    formValidation: { status, errors },
    setAddressForm,
}: {
    newAddressForm: PRIVATE_API_ADDRESS_TYPE;
    formValidation: {
        status: boolean;
        errors: Partial<API_ADDRESS_ERROR_TYPE>;
    };
    setAddressForm: React.Dispatch<
        React.SetStateAction<PRIVATE_API_ADDRESS_TYPE>
    >;
}) => {
    const { building, apartment_number, compound_name, floor, office } =
        newAddressForm;
    switch (newAddressForm.address_type) {
        case 'House':
            return (
                <>
                    <TextInputTwoFields>
                        <TextInput
                            {...{
                                value: building || '',
                                label: 'Building Number',
                                required: true,
                                error: errors.building,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputTwoFields>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                value: building || '',
                                label: 'Building Number',
                                required: true,
                                error: errors.building,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                </>
            );
        case 'Apartment':
            return (
                <>
                    <TextInputTwoFields>
                        <TextInput
                            {...{
                                required: true,
                                error: errors.apartment_number,
                                value: apartment_number || '',
                                label: 'Apartment Number',
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        apartment_number: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: apartment_number ? true : false,
                                },
                            }}
                        />
                        <TextInput
                            {...{
                                required: true,
                                error: errors.floor,
                                label: 'Floor Number',
                                value: floor || '',
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        floor: e.target.value,
                                    })),
                                InputLabelProps: { shrink: floor ? true : false },
                            }}
                        />
                    </TextInputTwoFields>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                required: true,
                                error: errors.apartment_number,
                                value: apartment_number || '',
                                label: 'Apartment Number',
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        apartment_number: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: apartment_number ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                required: true,
                                error: errors.floor,
                                label: 'Floor Number',
                                value: floor || '',
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        floor: e.target.value,
                                    })),
                                InputLabelProps: { shrink: floor ? true : false },
                            }}
                        />
                    </TextInputOneField>
                </>
            );
        case 'Compound':
            return (
                <>
                    <TextInputTwoFields>
                        <TextInput
                            {...{
                                required: true,
                                value: compound_name || '',
                                label: 'Compound Name',
                                variant: 'outlined',
                                error: errors.compound_name,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        compound_name: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: compound_name ? true : false,
                                },
                            }}
                        />
                        <TextInput
                            {...{
                                required: true,
                                value: building || '',
                                label: 'Building Number',
                                error: errors.building,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputTwoFields>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                required: true,
                                value: compound_name || '',
                                label: 'Compound Name',
                                variant: 'outlined',
                                error: errors.compound_name,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        compound_name: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: compound_name ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                required: true,
                                value: building || '',
                                label: 'Building Number',
                                error: errors.building,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                </>
            );
        case 'Office':
            return (
                <>
                    <TextInputTwoFields>
                        <TextInput
                            {...{
                                value: office || '',
                                label: 'Office Name',
                                required: true,
                                variant: 'outlined',
                                error: errors.office,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        office_name: e.target.value,
                                        office: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: office ? true : false,
                                },
                            }}
                        />
                        <TextInput
                            {...{
                                value: floor || '',
                                required: true,
                                label: 'Floor Number',
                                error: errors.floor,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        floor: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: floor ? true : false,
                                },
                            }}
                        />
                    </TextInputTwoFields>
                    <TextInputTwoFields>
                        <TextInput
                            {...{
                                value: building || '',
                                required: true,
                                label: 'Building Number',
                                variant: 'outlined',
                                error: errors.building,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputTwoFields>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                value: office || '',
                                label: 'Office Name',
                                variant: 'outlined',
                                required: true,
                                error: errors.office,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        office_name: e.target.value,
                                        office: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: office ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                value: floor || '',
                                label: 'Floor Number',
                                required: true,
                                error: errors.floor,
                                variant: 'outlined',
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        floor: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: floor ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                    <TextInputOneField>
                        <TextInput
                            {...{
                                value: building || '',
                                required: true,
                                label: 'Building Number',
                                variant: 'outlined',
                                error: errors.building,
                                onChange: (e) =>
                                    setAddressForm((state) => ({
                                        ...state,
                                        building: e.target.value,
                                    })),
                                InputLabelProps: {
                                    shrink: building ? true : false,
                                },
                            }}
                        />
                    </TextInputOneField>
                </>
            );
        default:
            return <></>;
    }
};
