import React, { useEffect, useState } from 'react';

import { COLORS } from '@project/styles';

// IMPORT STYLED COMPONENTS
import {
    RightIcons,
    RightIconsWrapper,

    Nav,
    NavLogo,
    NavLogoMobile,
    NavbarContainer,

    UserIcon,

    CartItemNumber,
    CartIconNumbers,
    CartIconWrapper,

    Text,
    MiddleTabs,
    TextContainer,
    LeftIconsWrapper,
    UserIconLinkWrapper,
    UserIconSelectWrapper,

    CartIconFill,
    CartIconNumbersInner,
    CartIconNumbers_Flipped,

    UserImageContainer,
    UserAvatarImage,
    UserFacelessIcon,
    TextLinkContainer,
    LogoutFill,
    ReceiptFill,
    DeleteFill,
    DeleteLinkContainer,
    NavLogoArabic,
    ItemRowWrapper,
    ItemIconContainer,
    PersonFill,
    ItemRowWrapperLink,
    ItemRowWrapperA,

} from './HeaderElements';

//IMPORT COMPONENTS
import Cart from '@overlays/cart';

import { ENVIRONMENT, HOSTNAME } from 'config';
import { RootState } from '@redux/store';

import i18next from 'i18next';

import { RouterPages } from '@project/routes';
import { availableLanguages } from '@translation';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import LanguageChanger from './components/LanguageChanger';


import Cookies from 'js-cookie';
import signUserOut from '@api/auth/signout';
import { signOut } from '@redux/state/auth';
import { clearCart } from '@redux/state/cart';
import { clearUser } from '@redux/state/user';
import { useTranslation } from 'react-i18next';
import { clearCookies } from '@redux/state/cookie';
import { clearTracking } from '@redux/state/settings';
import { clearCheckout } from '@redux/state/checkout';


import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { delay } from 'underscore';


const UserImage = () => {
    const { avatar } = useSelector(
        (store: RootState) => store.user.details
    );

    return (
        <UserImageContainer>
            {
                Boolean(avatar && !avatar.includes(HOSTNAME))
                    ? <UserAvatarImage {...{ src: avatar as string }} />
                    : <UserFacelessIcon {...{ color: '#157c13' }} />
            }
        </UserImageContainer>
    )
}


export const Header = () => {
    const direction = i18next.dir();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const isSelectOpen = Boolean(anchorEl);


    const numberOfItemsInCart = useSelector(
        (state: RootState) => state.cart.total_quantity
    );
    const isUserSignedIn = useSelector(
        (state: RootState) => state.auth.signed_in
    );

    const openSelect = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeSelect = () => {
        setAnchorEl(null);
    }

    const toggleCart = () => {
        setIsCartOpen((prev_state) => !prev_state);
    };

    const closeCart = () => {
        setIsCartOpen(false);
    };

    const deleteUserCachedMemory = () => {
        dispatch(signOut())
        dispatch(clearUser())
        dispatch(clearCart())
        dispatch(clearCookies())
        dispatch(clearTracking())
        dispatch(clearCheckout())
        Cookies.remove('authtoken')
        Cookies.remove('webdeviceid')
    }

    const dispatch = useDispatch();

    const matchesLoginLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/login`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesSignupLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/signup`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesCheckoutLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/checkout`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesForgotPasswordLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/passowrd/forgot`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesForgotPassword = useMatch({
        path: 'auth/password/forgot',
        caseSensitive: true, end: true,
    }) !== null || matchesForgotPasswordLanguages;

    const matchesLogin = useMatch({
        path: 'auth/login',
        caseSensitive: true, end: true,
    }) !== null || matchesLoginLanguages;

    const matchesSignup = useMatch({
        path: 'auth/signup',
        caseSensitive: true, end: true,
    }) !== null || matchesSignupLanguages;

    const matchesCheckout = useMatch({
        path: 'checkout',
        caseSensitive: true, end: true,
    }) !== null || matchesCheckoutLanguages;

    const deactivateDisplay = matchesLogin || matchesSignup || matchesForgotPassword;

    const handleSignOut = async () => {
        const response = await signUserOut();
        if (response.status) {
            dispatch(signOut());
            dispatch(clearUser());
            dispatch(clearCart());
        }

        window.location.href = '/'
    };

    useEffect(() => {
        closeCart();
    }, [matchesCheckout])
    return (
        <>
            <Cart {...{ isCartOpen, toggleCart }} />
            <Nav {...{ deactivate: deactivateDisplay }}>
                <NavbarContainer>
                    <LeftIconsWrapper>
                        <LanguageChanger />
                    </LeftIconsWrapper>

                    <MiddleTabs>
                        {
                            direction === 'ltr' ? < NavLogo  {...{ page: RouterPages.Home }} />
                                : <NavLogoArabic  {...{ page: RouterPages.Home }} />
                        }
                        <NavLogoMobile {...{ page: RouterPages.Home }} />
                    </MiddleTabs>

                    <RightIconsWrapper>
                        <RightIcons>
                            <CartIconWrapper {...{ style: { display: matchesCheckout ? 'none' : 'flex' } }} onClick={toggleCart}>
                                <CartIconFill />
                                {
                                    Boolean(numberOfItemsInCart >= 1) &&
                                    <>
                                        {
                                            direction === 'ltr' ?
                                                <CartIconNumbers>
                                                    <CartIconNumbersInner>
                                                        <CartItemNumber>
                                                            {numberOfItemsInCart}
                                                        </CartItemNumber>
                                                    </CartIconNumbersInner>
                                                </CartIconNumbers>
                                                :
                                                <CartIconNumbers_Flipped>
                                                    <CartIconNumbersInner>
                                                        <CartItemNumber>
                                                            {numberOfItemsInCart}
                                                        </CartItemNumber>
                                                    </CartIconNumbersInner>
                                                </CartIconNumbers_Flipped>
                                        }
                                    </>
                                }

                            </CartIconWrapper>

                            {
                                Boolean(!isUserSignedIn) &&
                                <UserIconLinkWrapper page={RouterPages.Login}>
                                    <TextContainer>
                                        <Text>
                                            {t("common.signIn")}
                                        </Text>
                                    </TextContainer>
                                </UserIconLinkWrapper>
                            }

                            {
                                Boolean(isUserSignedIn) &&
                                <div>
                                    <Tooltip {...{ title: 'Account Settings' }} arrow >
                                        <UserIconSelectWrapper {...{ onClick: openSelect }}>
                                            <UserImage />
                                        </UserIconSelectWrapper>
                                    </Tooltip>
                                    <Menu
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        // style={{ width: 250, right: '10%' }}
                                        anchorEl={anchorEl}
                                        open={isSelectOpen}
                                        onClose={closeSelect}
                                        TransitionComponent={Fade}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                width: 250,
                                                right: i18next.dir() === 'rtl' ? undefined : '8%',
                                                left: i18next.dir() === 'ltr' ? undefined : '8%',
                                                position: 'absolute',
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    top: 0,
                                                    zIndex: 0,
                                                    width: 10,
                                                    height: 10,
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    left: i18next.dir() === 'ltr' ? undefined : 21,
                                                    right: i18next.dir() === 'rtl' ? undefined : 21,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: i18next.dir() === 'rtl' ? 'left' : 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: i18next.dir() === 'rtl' ? 'left' : 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem {...{ onClick: closeSelect }}>
                                            <ItemRowWrapperLink {...{ page: RouterPages.UserProfilePage }}>
                                                <ItemIconContainer>
                                                    <PersonFill />
                                                </ItemIconContainer>
                                                <div>User Profile</div>
                                            </ItemRowWrapperLink>
                                        </MenuItem>
                                        <MenuItem {...{ onClick: closeSelect }}>
                                            <ItemRowWrapperLink {...{ page: RouterPages.OrdersPage }}>
                                                <ItemIconContainer>
                                                    <ReceiptFill />
                                                </ItemIconContainer>
                                                <div>My Orders</div>
                                            </ItemRowWrapperLink>
                                        </MenuItem>
                                        {
                                            ENVIRONMENT !== 'PRODUCTION' &&
                                            <MenuItem {...{ onClick: closeSelect }}>
                                                <ItemRowWrapper {...{ onClick: deleteUserCachedMemory }}>
                                                    <ItemIconContainer>
                                                        <DeleteFill />
                                                    </ItemIconContainer>
                                                    <div>Delete Storage</div>
                                                </ItemRowWrapper>
                                            </MenuItem>
                                        }

                                        <MenuItem {...{ onClick: closeSelect }}>
                                            <ItemRowWrapper {...{ onClick: handleSignOut }}>
                                                <ItemIconContainer>
                                                    <LogoutFill />
                                                </ItemIconContainer>
                                                <div>Logout</div>
                                            </ItemRowWrapper>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            }
                        </RightIcons>

                    </RightIconsWrapper>
                </NavbarContainer>
            </Nav>
        </>

    );
};