import styled from 'styled-components';

// IMPORT IMAGES
import { COLORS } from '@project/styles';

import { Link } from '@project/routes/components';
import leftArrow from '@assets/images/home/CategoryPicker/leftArrow.png';
import rightArrow from '@assets/images/home/CategoryPicker/rightArrow.png';



export const CategoryContainer = styled.div`
    display: flex;
    bottom: 100px;
    width: 100%;
    z-index: 0;
    height: 170px;
    position: relative;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 350px;
    }

    @media screen and (max-width: 500px) {
        bottom: 50px;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    width: 85%;
    padding: 20px 0;
    overflow: hidden;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${COLORS.DARK_GREEN};

    
    @media screen and (max-width: 768px) {
        width: 80%;
        margin-top: 10px;
    }
`;

export const CategoryItemWrapper = styled(Link)`
    width: 140px;
    padding: 0% 2%;
    display: flex;
    color: inherit;
    height: 140px;
    align-items: center;
    text-decoration: none;
    flex-direction: column;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const CategoryName = styled.h5`
    color: #8d958c;
    font-family: roboto, sans-serif;
    margin: 4px 0 4px;
    text-align: center;
`;

export const CategoryImage = styled.div`
    margin-top: 12px;
    border-radius: 60px;
    width: 90px;
    height: 90px;
    /* background-color: black; */
    text-decoration: none;
    text-decoration: none;
    @media screen and(max-width: 768px) {
        width: 90px;
        height: 90px;
    }
`;

export const LeftArrow = styled.img.attrs({
    src: `${leftArrow} `,
})`
    display: block;
    width: 60%;
`;

export const RightArrow = styled.img.attrs({
    src: `${rightArrow} `,
})`
    display: block;
    width: 60%;
`;
