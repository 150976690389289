import styled from 'styled-components';
import { Link } from 'routes/components/Link';
import { COLORS } from '@project/styles';



const RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE = 'images/header_greenit_logo.svg';
const RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE_ARABIC = 'images/header_greenit_logo_arabic.svg';


export const AuthContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: row;
    background-color: white;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const AuthLeft = styled.div<{ color: string }>`
    display: flex;
    width: 700px;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    background-color: ${({ color }) => color};

    @media screen and (max-width: 900px) {
        display: none;
    }
`

export const AuthNavLogo = styled(Link)`
    display: flex;
    width: 130px;
    height: 35px;
    padding-left: 15%;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE}) no-repeat center;
    background-size: 100% auto;

    @media screen and (max-width: 500px) {
        width: 100px;
        height: 30px;
    }
`;

export const AuthNavLogoArabic = styled(Link)`
    display: flex;
    width: 130px;
    height: 35px;
    padding-left: 15%;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE_ARABIC}) no-repeat center;
    background-size: 100% auto;

    @media screen and (max-width: 500px) {
        width: 100px;
        height: 30px;
    }
`;


export const AuthMainCatchPhrase = styled.div<{ color: string }>`
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: 2rem;
    text-align: left;
    padding-right: 20px;
    margin-top: 20px;
`

export const AuthTop = styled.div`
    display: flex;
    position: absolute;
    top: 5%;
    width: 100%;
    height: 30%;
    padding: 8%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const AuthBottom = styled.div`
    display: flex;
    position: absolute;
    bottom: 5%;
    width: 100%;
    height: 50%;
`

export const AuthMain = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
`

export const GreenitNavContainer = styled(Link)`
    display: flex;
    top: 1%;
    right: 20px;
    position: absolute;
    text-decoration: none;
    cursor: pointer;
`

export const GreenitNavLogoContainer = styled.div`
    display: flex;
    bottom: 2%;
    right: 24px;
    position: absolute;
    text-decoration: none;
    cursor: pointer;
`

export const AuthFormsContainer = styled.div`
    display: flex;
    width: 500px;
    height: 700px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`

export const TutorialContainer = styled.div`
    display: flex;
    width: 650px;
    height: 100%;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
`

export const TutorialSVG = styled.div`
    display: flex;
    width: 250px;
    height: 250px;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const TutorialSVGCircle = styled.div<{ color: string }>`
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-color: ${({ color }) => color};
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const SVGImage = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;    
`

export const TutorialTitle = styled.div<{ color: string }>`
    display: flex;
    color: ${({ color }) => color};
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const TutorialSubtext = styled.h4<{ color: string }>`
    display: flex;
    width: 80%;
    margin-top: 10px;
    flex-wrap: wrap;
    padding: 15px 10%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: normal;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;
    color: ${({ color }) => color};
`

export const ChangePasswordContainer = styled(Link)`
    display: flex;
    text-decoration: none;
    cursor: pointer;
` 