import { store } from '@redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

interface TOKEN_COOKIE_PAIR {
    cookie: string;
    cookie_refresh_date: string;
}

export interface COOKIE_STATE {
    auth_token: TOKEN_COOKIE_PAIR;
    web_device_id: TOKEN_COOKIE_PAIR;
}

interface SET_TOKEN_COOKIE_INFO_TYPE extends TOKEN_COOKIE_PAIR {
    type: keyof COOKIE_STATE;
}

export const getCookieValue = (type: keyof COOKIE_STATE) => {
    return store.getState().cookie[type].cookie;
}

export const cookieExpiring = (type: keyof COOKIE_STATE) => {
    const { cookie_refresh_date } = store.getState().cookie[type];
    return moment(new Date(cookie_refresh_date)).diff(moment(new Date()), 'months') < 6;
}


export const getRefreshDateFromDaysDelta = (days: number) => {
    const todayDate = moment(new Date());
    const refreshDate = todayDate.add(days, 'd');

    return refreshDate.toLocaleString();
}

const refreshedCookieState: TOKEN_COOKIE_PAIR = {
    cookie: '',
    cookie_refresh_date: getRefreshDateFromDaysDelta(0),
}


export const cookieSlice = createSlice({
    name: 'cookie',
    initialState: {
        auth_token: refreshedCookieState,
        web_device_id: refreshedCookieState,
    } as COOKIE_STATE,
    reducers: {
        setTokenCookiePair: (state, { payload: {
            type,
            ...restPayload
        } }: PayloadAction<SET_TOKEN_COOKIE_INFO_TYPE>) => {
            state[type] = restPayload;
        },

        deleteTokenCookiePair: (state, { payload }: PayloadAction<keyof COOKIE_STATE>) => {
            state[payload] = refreshedCookieState;
        },

        refreshCookieExpiry: (state, { payload }: PayloadAction<keyof COOKIE_STATE>) => {
            state[payload].cookie_refresh_date = getRefreshDateFromDaysDelta(2 * 365);
        },

        clearCookies: (state) => {
            state.auth_token = refreshedCookieState;
            state.web_device_id = refreshedCookieState;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    clearCookies,
    setTokenCookiePair,
    refreshCookieExpiry,
    deleteTokenCookiePair,
} = cookieSlice.actions;

export default cookieSlice.reducer;
