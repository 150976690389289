import styled from 'styled-components';

// IMPORT STYLES
import {
    COLORS,
    BIG_CONTENT_CONTAINER_HEIGHT,
} from '@project/styles';
import { Link } from 'routes/components/Link';

export const NoCartItems = styled.div`
    margin: 0;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARKER_GREY};
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`

export const CartPageContainer = styled.div`
    width: 90%;
    display: flex;
    position: absolute;
    flex-direction: column;
    transition: all 0.2s ease;
    @media screen and (max-width: 1000px) {
        margin: 0;
        height: auto;
    }
`;
export const PageTitle = styled.h1`
    font-size: 35px;
    @media screen and (max-width: 1000px) {
        font-size: 30px;
        text-align: center;
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ProductHeaders = styled.div``;


export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: space-between;
    border-bottom: 1px solid ${COLORS.LIGHTER_GREY};
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

export const SectionHeading = styled.h3`
    opacity: 0.5;
`;
export const NameAndImageContainer = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
`;

export const ProductImage = styled.img`
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
`;

export const ProductTitle = styled.h1``;
export const ProductSubTitle = styled.h3``;

export const Quantity = styled.div`
    width: 150px;
    height: 450px;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const AdditionalInformation = styled.div`
    display: flex;
    width: auto;
    height: 50px;
    position: absolute;
    bottom: 40px;
    right: 5px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 1200px) {
        right: -10px;
    }
`

export const CategoryInformation = styled.div`
    height: 100%;
    display: flex;
    padding: 10px 15px 0px 0px;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const Price = styled.div`
    width: 20%;
    height: 50%;
    display: flex;
    align-items: center;
`;

export const Total = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
`;

export const CartMain = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        height: auto;
    }
`;

export const CartSection = styled.div`
    width: 100%;
    padding: 0% 6%;
    margin-right: 2px;
    padding-top: 20px;
    overflow-y: auto;
    align-items: center;
    display: flex;
    position: relative;
    flex-direction: column;
    /* justify-content: center; */
    transition: all 0.75s ease;
    background-color: ${COLORS.WHITE};

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar{
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        margin: 0px 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(0,0,0,.3);
    }   

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: auto;
    }
`;

export const CheckoutButton = styled(Link)`
    background-color: ${COLORS.DARK_GREEN};
    height: 60px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 20px;
`;

export const EmptyCartButton = styled.div`
    background-color: ${COLORS.GREY};
    height: 70px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
`;


export const CheckoutText = styled.h3`
    font-size: 1.7rem;
    padding: 0;
    color: white;
`;

export const CheckoutSection = styled.div`
    height: 100%;
    width: 900px;
    position: sticky;
    padding: 0% 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease;
    background-color: ${COLORS.LIGHT_GREEN};
    @media screen and (max-width: 1000px) {
       width: 100%;
       position: relative;
       padding: 20px 10%;
       background-color: ${COLORS.WHITE};
    }
`;

export const MarginWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 800px) {
        justify-content: flex-start;
    }
`;

export const TitleWrapper = styled.div`
    border-bottom: 1px solid ${COLORS.LIGHTER_GREY};
`;

export const DetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const DetailTitle = styled.div`
    display: flex;
    padding: 10px 0px;
    font-size: 1rem;
    font-weight: bold;
`;

export const PromoDiscountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;
export const PromoCodeSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

export const SectionTitle = styled.h2``;

export const TotalCostWrapper = styled.div`
    border-top: 1px solid #0000007d;
    margin: 20px 0;
    padding: 20px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const TopSection = styled.div`
    /* border: 1px solid black; */
`;
