import styled from 'styled-components';
import { COLORS } from '@project/styles';
import { Link } from '@project/routes/components';
import { DialogContent } from '@mui/material';

import { BsCartPlus, BsCartPlusFill } from 'react-icons/bs';

export const PRODUCT_CARD_MOBILE_WIDTH = 700;

interface ProductWrapperProps {
    isexpanded?: string;
}

export const OutOfStockBand = styled(Link)`
    display: flex;
    width: 100%;
    opacity: 0.75;
    color: #FFF;
    font-weight: bold;
    font-size: 2rem;
    height: 100%;
    text-align: center;
    align-items: center;
    position: absolute;
    align-self: center;
    text-decoration: none;
    justify-content: center;
    background-color: #333;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
`

export const QuickAdd = styled.div`
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 20%;
    opacity: 0;
    display: flex;
    z-index: 1000000;
    align-self: center;
    align-items: flex-end;
    justify-content: center;
    transition: all .25s;

    @media screen and (max-width: ${PRODUCT_CARD_MOBILE_WIDTH}px) {
        bottom: 40%;
    };
    &:hover {
        opacity: 1;
    }
`

export const QuickAddInner = styled.div`
    margin-bottom: 20px;
    width: 70%;
    height: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15rem;
    font-weight: bold;
    border-radius: 5px;
    color: ${COLORS.WHITE_SMOKE};
    background-color: ${COLORS.DARK_GREEN};
`

export const EasyGrowBanner = styled.div`
    top: 7%;
    left: 0%;
    width: 150px;
    height: 40px;
    display: flex;
    opacity: 0.75;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 0% 2%;
    background-color: green;
    justify-content: center;
`

export const SaleBanner = styled.div`
    top: calc(7% + 50px);
    left: 0%;
    width: 125px;
    height: 40px;
    display: flex;
    opacity: 0.75;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 0% 2%;
    background-color: red;
    justify-content: center;
`

export const BannerText = styled.div`
    margin: 0;
    font-size: 1rem;
    color: white;
    margin-left: 10px;
    font-weight: bolder;
    text-decoration: none;
    text-align: center;
`

export const ProductWrapper = styled.div<ProductWrapperProps>`
    width: 320px;
    height: 440px;
    display: flex;
    color: #444444;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    flex-direction: column;
    box-sizing: border-box;
    background: ${COLORS.WHITE};
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all .5s;
    transform: scale(${props => Boolean(props.isexpanded) ? 1 : 0.95});
    border: ${props => Boolean(props.isexpanded) ? `2px solid ${COLORS.DARK_GREEN}` : `1px solid ${COLORS.WHITE}`};
    
    @media screen and (max-width: 768px) {
        margin: 0 0px;
        width: 260px;
        height: 350px;
    }

    @media screen and (max-width: 500px) {
        margin: 0 0px;
        width: 200px;
        height: 300px;
        border-radius: 2px;
        border: solid .5px green;
    }

    @media screen and (max-width: 400px) {
        margin: 0 0px;
        width: 180px;
        height: 290px;
    }
`;


export const ProductActionSheet = styled.div`
    top: 5%;
    right: 5%;
    width: 50px;
    display: flex;
    position: absolute;
    border-radius: 5px;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
`

export const ActionCircle = styled.div`
    display: flex;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    padding: 5px 0px;
    margin-top: 15px;
    opacity: 0.5;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    background-color: grey;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    transition: all 0.5s ease;
    &:hover {
        opacity: 1;
        background-color: black;
    }
`

export const ActionCircleQuickAdd = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 5px 0px;
    margin-top: 15px;
    opacity: 0.5;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    background-color: grey;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    transition: all 0.5s ease;
    &:hover {
        opacity: 1;
        background-color: black;
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
`


export const Top = styled.img`
    max-height: 90%;
    width: 100%;
    align-self: center;
    object-fit: contain;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${COLORS.WHITE};
    transition: all .25s;

    /* @media screen and (max-width: 768px) {
        height: 65%;
        max-height: auto;
    } */
`;

export const Bottom = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0% 2%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        height: 28%;
        /* align-items: flex-end; */
    }
`;

export const CartAdd = styled.div`
    max-width: 70px;
    min-width: 70px;
    height: 70px;
    right: 10px;
    display: flex;
    align-self: center;
    position: absolute;
    align-items: center;
    border-radius: 15px;
    margin-right: 12px;
    cursor: pointer;
    justify-content: center;
    /* border: solid 0px white; */
    transition: all .25s ease;

    /* &:hover {
        border: solid 2px ${COLORS.DARK_GREEN};
    } */

    @media screen and (max-width: 768px) {
        max-width: 40px;
        min-width: 40px;
        margin-right: 0px;
    }
`

export const Cart = styled(BsCartPlus)`
    display: flex;
    position: absolute;
    align-self: center;
    opacity: 1;
    transition: all .25s ease;

    ${CartAdd}:hover & {
        opacity: 0;
        transform-origin: center;
        transform: scale(1.25);
    }    
`

export const CartFill = styled(BsCartPlusFill)`
    display: flex;
    opacity: 0;
    position: absolute;
    align-self: center;
    transition: all .25s ease;

    ${CartAdd}:hover & {
        display: flex;
        opacity: 1;
        transform-origin: center;
        transform: scale(1.25);
    }    
`

export const Name = styled.div`
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 1.1rem;
    }

    @media screen and (max-width: 500px) {
        font-size: .9rem;
    }
`;

export const Subheader = styled.div`
    font-size: .9rem;
    font-weight: normal;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: .8rem;
    }

    @media screen and (max-width: 500px) {
        font-size: .7rem;
    }
`;

export const PriceContainer = styled.div`
    z-index: 10;
    display: none;
    width: 100%;
    color: white;
    margin-top: 10px;
    border-radius: 5px;
    padding: 4%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${COLORS.DARK_GREEN};
    transition: all .2s ease;
    &:hover {
        transform: scale(1.05)
    }
    
    /* @media screen and (max-width: 768px) {
        display: flex;
        padding: 4% 10%;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        padding: 4%;
    } */
`;

export const PriceTextNormal = styled.div`
    font-size: 1.4rem;
    font-weight: bolder;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`;

export const PriceTextSmall = styled.div`
    font-size: 1.28rem;
    font-weight: bolder;
    text-align: center;
`;

export const SaleText = styled.span`
    font-size: 1rem;
    color: lightgray;
    font-weight: bold;
    text-decoration: line-through;
`

export const SaleTextSmall = styled.span`
    color: lightgray;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: strike;
`

// LEFT
export const Details = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 5px 20px 0px 20px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 5px 3px 5px 3px;
    }
`;
export const Buy = styled.div`
    float: right;
    width: calc(25% - 2px);
    height: 100%;
    background: #fff;
    transition: background 0.5s;
    border-left: solid thin rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${PRODUCT_CARD_MOBILE_WIDTH}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

const MaterialCartIcon = (props: any) => (
    <i className={`material-icons ${props.className}`}>add_shopping_cart</i>
);
interface CartIconProps {
    isLoading: boolean;
    isClicked: boolean;
}
export const CartIcon = styled(MaterialCartIcon)`
    font-size: 30px;
    padding: 30px;
    /* background-color: ${COLORS.LIGHT_GREEN}; */
    color: #254053;
    transition: transform 0.5s;
    &:hover {
        opacity: 0.2;
        cursor: pointer;
    }
    &:hover i {
        transform: translateY(5px);
        color: #5cbb6c;
    }
    @media screen and (max-width: ${PRODUCT_CARD_MOBILE_WIDTH}px) {
        display: none;
    }
`;

export const InfoContents = styled.div`
    padding: 5%;
    width: 400px;
    height: 300px;
    overflow-y: auto;
    scrollbar-width: none;
    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;


    @media screen and (max-width: 768px) {
        width: 400px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar{
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        margin: 0px 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(0,0,0,.3);
    }   
`;

export const ProductDescriptionContent = styled(DialogContent)`
    width: 500px;
    height: 500px;
    padding: 5%;
    background: ${COLORS.WHITE};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.7s, border-radius 2s, top 1s;

    @media screen and (max-width: 768px) {
        width: 300px;
        height: 400px;
        padding: 2%;
    }
`;

export const TypeIcon = styled.div<{ url: string }>`
    background-image: url(${props => props.url});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
`

export const DescriptionTitle = styled.h1`
    font-size: 25px;
    margin: 0;
    padding: 0;
    color: #000;
    margin-top: 5%;
`;
export const DescriptionSubTitle = styled.p`
    font-size: 15px;
    margin: 0;
    padding: 0;
    color: #000;
    font-style: italic;
`;

export const DescriptionMainDescription = styled.div`
    width: 100%;
    font-size: 15px;
    margin: 0;
    padding: 0;
    color: #000;
    text-align: justify;
    margin-top: 20px;
    overflow-y: auto;
`;

export const AddToCartMobile = styled.button`
    display: none;
    @media screen and (max-width: ${PRODUCT_CARD_MOBILE_WIDTH}px) {
        display: flex;
        border: 0;
        padding: 12px;
        color: #000;
        background-color: ${COLORS.GREEN};
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
`;

export const ButtonText = styled.h1`
    color: #fff;
    font-size: 16px;
    margin: 0;
`;

export const ProductCardLink = styled(Link)`
    text-decoration: inherit;
    display: flex;
    position: relative;
    height: 100%;
    max-height: calc(100% - 150px);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        max-height: 100%;
        height: 72%;
    }
`;
