import styled from 'styled-components';

// IMPORT COMPONENTS
import { DesktopDatePicker } from '@mui/x-date-pickers';

// IMPORT STYLES
import { COLORS } from '@project/styles';

import '@project/fonts';
import { animated } from '@react-spring/web';

export const ScheduleInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 10px;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const ScheduleDate = styled.div`
    width: 100%;
    display: flex;
    padding: 0px 0px 20px 0px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const MUIDatePickerDesktop = styled(DesktopDatePicker)`
    width: 100%;
`

export const ScheduleTimeSlot = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const ScheduleDateInformation = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const AddressInformation = styled(animated.div)`
    position: relative;
    width: 100%;
    display: flex;
    margin: 20px;
    overflow: hidden;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        margin: 15px 5px;
    }
`

export const AddressScrollContainer = styled(animated.div)`
    width: 200%;
    height: 100%;
    display: flex;
    flex-direction: row;

    
`

export const NormalDeliveryAddressOption = styled(animated.div)`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    position: relative;
    flex-direction: row;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const GiftDeliveryAddressOption = styled(animated.div)`
    width: 100%;
    display: flex;
    height: 100%;
    padding: 0px 10px;
    
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`

export const AddressSelectionUnit = styled.div`
    display: flex;
    height: 80px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    @media screen and (max-width: 600px) {
        height: 150px;
        flex-direction: column;
        justify-content: center;
    }
`

export const AddressSelectionQuestionOption = styled.h3`
    width: 40%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
        
    @media screen and (max-width: 600px) {
        width: 100%;
        margin: 0;
        margin-top: 20px;
        text-align: center;
    }    
`

export const AddressActions = styled.div`
    margin: 10px;
    flex-direction: row;

    @media screen and (max-width: 600px) {

    }
`


export const AddressSelectionContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.01);
`

export const AddressMapContainer = styled.div`
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: solid 2px #223232; */
    top: 0; bottom: 0; left: 0; right: 0;
    
`

export const MainContainer = styled.div`
    display: flex;
    width: 90%;
    height: 90%;
    border-radius: 5px;
    position: relative;
    align-self: center;
    align-items: center;
    font-weight: normal;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const AddAddressTitle = styled.div`
    display: flex;
    font-weight: bolder;
    font-size: 1.3rem;
    align-items: center;
    justify-content: center;
`

export const NoAddressTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`


export const ScheduleSummary = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`


export const RepotPlantIcon = styled.div<{ selected: boolean }>`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin: 2px 10px 2px 0px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px ${({ selected }) => selected ? COLORS.GREEN : COLORS.RED};
    transition: all 0.5s ease;    
`

export const RepotRow = styled.div`
    display: flex;
    width: 100%;
    max-height: 60px;
    min-height: 60px;
    padding: 5px 10px;
    font-weight: bold;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;   
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between; 
    background-color: ${COLORS.WHITE};

    @media screen and (max-width: 768px) {
        flex-direction: column;
        min-height: 135px;
        max-height: 135px;
    }
`

export const RepotPlantInformation = styled.div`
    width: 50%;
    display: flex;
    padding: 2px 10px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 60px;
        align-self: flex-start;
    }
`

export const RepotPotInformation = styled.div`
    width: 30%;
    display: flex;
    padding: 2px 10px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
        width: 100%;
        align-self: flex-end;
    }
`

export const SectionTitle = styled.div`
    display: flex;
    font-size: 1.6rem;
    font-weight: bolder;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 15px 0px;
    color: ${COLORS.DARK_GREEN};
`

export const SectionSubTitleEdit = styled.div`
    display: flex;
    height: 50px;
    font-size: 1.2rem;
    font-weight: bolder;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    justify-content: center;
    color: ${COLORS.ORANGE};
    border: solid 1px ${COLORS.ORANGE};
`

export const SectionSubTitleRemove = styled.div`
    display: flex;
    font-size: 1.2rem;
    font-weight: bolder;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    color: ${COLORS.RED};
    justify-content: center;
    border: solid 1px ${COLORS.RED};
`

export const SectionSubInformation = styled.div`
    display: flex;
    font-size: 1.1rem;
    font-weight: normal;
    font-style: normal;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 10px 0px;
    color: ${COLORS.DARK_GREEN};
`


export const PersonalDetailsContainer = styled.div`
    display: flex;
    margin: 5px;
    padding: 20px 2px;
    flex-direction: column;
`

export const AdditionalNotesContainer = styled.div`
    display: flex;
    margin: 5px;
    padding: 0px 2px 30px 2px;
    flex-direction: column;
`

export const GiftCardOptionContainer = styled.div`
    display: flex;
    margin: 5px;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    padding: 20px 2px 30px 2px;
    flex-direction: row;
`

export const GiftCardIconContainer = styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const GiftCardContainer = styled.div`
    display: flex;
    margin: 5px;
    padding: 0px 2px 30px 2px;
    flex-direction: column;
`


export const AdditionalOptionsContainer = styled.div<{ componentLoading: boolean }>`
    display: flex;
    margin: 10px 5px;
    height: auto;
    min-height: ${({ componentLoading }) => componentLoading ? '350px' : 'auto'};
    border-radius: 10px;
    position: relative;
    flex-direction: column;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        margin: 10px 0px;
    }
`

export const SummaryContainer = styled.div`
    display: flex;
    margin: 10px 5px;
    height: auto;
    border-radius: 10px;
    position: relative;
    flex-direction: column;
    overflow: hidden;
`

export const RepottingContainer = styled.div<{ number_of_repottable_plants: number }>`
    display: flex;
    margin: 5px 5px 30px 5px;
    height: ${({ number_of_repottable_plants }) =>
        number_of_repottable_plants > 3
            ? 'auto'
            : number_of_repottable_plants * 65}px;
    padding: 10px 2px 0px 2px;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        margin: 5px 0px;
        padding: 10px 0px 0px 0px;
    }
`

export const ScheduleContainer = styled.div<{ componentLoading: boolean }>`
    height: auto;
    min-height: ${({ componentLoading }) => componentLoading ? '350px' : 'auto'};
    display: flex;
    margin: 10px 5px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        margin: 10px 0px;
    }
`

export const PaymentDetailsContainer = styled.div`
    display: flex;
    position: relative;
    padding: 5px 0px 0px 0px;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    `

export const OTPContainer = styled.div`
    display: flex;
    position: relative;
    margin: 20px 10px;
    height: 250px;
    padding: 5px 0px 10px 0px;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: solid 1px ${COLORS.DARK_GREY};


    @media screen and (max-width: 500px) {
        height: 350px;
    }
`

export const OTPInformationContainer = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
    padding: 0% 10px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    @media screen and (max-width: 500px) {
        padding: 0px;
        flex-direction: column;
    }
`

export const OTPButton = styled.div`
    height: auto;
    display: flex;
    padding: 15px 20px;
    font-size: large;
    min-width: 130px;
    border-radius: 5px;
    border-width: 0.5px;
    font-weight: 800;
    cursor: pointer;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color:${COLORS.GREEN_BRIGHT};
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    transition: all 0.3s ease;
    background-color: ${COLORS.WHITE};
    border: solid 1px ${COLORS.GREEN_BRIGHT};
    text-decoration: none;
    &:hover {
        opacity: .85;
        border: solid 1px ${COLORS.DARK_GREEN};
        color: ${COLORS.DARK_GREEN};
    }

    @media screen and (max-width: 800px) {
        margin: 2% 0 0 30px;
    }
    @media screen and (max-width: 500px) {
        margin-left: 0%;
        margin-top: 20px;
        padding: 15px 10px;
    }
`

export const PaymentOptions = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`


export const OptionRow = styled.div<{ disabled: boolean }>`
    width: 100%;
    height: 50px;
    display: flex;
    padding: 1px 0px;
    align-items: center;
    box-sizing: border-box;
    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
    justify-content: space-between;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`


export const OptionInformation = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    padding: 1px 0px;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const PaymentIcon = styled.div`
    display: flex;
    height: 60%;
    max-width: 50px;
    min-width: 50px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: solid 1.5px ${COLORS.NOTIFICATION_LIGHT_GREEN};
`

export const OptionActions = styled.div`
    display: flex;
    height: 100%;
    width: 30%;
    padding: 0px 10px;
    border-radius: 5px;
    align-items: center;
    align-self: center;
    justify-self: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const OptionActionIcon = styled.div`
    height: 70%;
    display: flex;
    aspect-ratio: 1;
    margin-left: 5px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: solid 1px ${COLORS.DARK_RED};
`

export const OptionAdditionalInfo = styled.div`
    display: flex;
    height: 100%;
    width: 30%;
    padding: 0px 10px;
    border-radius: 5px;
    align-items: flex-end;
    align-self: center;
    flex-direction: column;
    justify-self: flex-end;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`


export const CardExpiry = styled.div`
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    font-size: 0.9rem;
`

export const CardExpiryTitle = styled.div`
    display: flex;
    align-items: flex-end;
    font-weight: normal;
    font-size: 0.8rem;
`

export const PaymentLabel = styled.div<{ disabled?: boolean }>`
    display: flex;
    font-size: 1rem;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`

export const AddCardWrapper = styled.div<{ contentHeight: number }>`
    display: flex;
    width: 100%;
    padding: 20px 6% 40px 6%;
    border-radius: 5px;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: ${({ contentHeight }) => contentHeight};
    border: solid 1px ${COLORS.NOTIFICATION_GREEN};
`