import { API_PRODUCT_TYPE } from '@api/types';
import React, { Fragment, useMemo, useState } from 'react';

import {
    InformationItem,
    InformationItemText,
    InformationSection,
    ProductDetailsOption,
    ProductDetailsOptionName,
    ProductDetailsOptionsWrapper,
    ProductDetailsWrapper,
} from './ProductDescriptionElements';
import { COLORS } from '@project/styles';
import { Book, Thermometer, Droplet, Icon } from 'react-feather';
import { useTranslation } from 'react-i18next';

interface DetailsProps {
    product: API_PRODUCT_TYPE
}

interface DESCRIPTION_TYPE {
    DescriptionIcon: Icon;
    description_title: string;
}

const ProductDetails = ({ product }: DetailsProps) => {
    const { t } = useTranslation();

    const [selectedItem, setSelectedItem] = useState<number>(0);
    const productDescriptionTitles = useMemo((): DESCRIPTION_TYPE[] => {
        switch (product.product_type) {
            case 'Plant':
                return (
                    product.root_category.slug === 'indoor-plants'
                        ? [
                            {
                                DescriptionIcon: Book,
                                description_title: t("common.summary")
                            }, {
                                DescriptionIcon: Thermometer,
                                description_title: t("productDetail.light")
                            }, {
                                DescriptionIcon: Droplet,
                                description_title: t("productDetail.plantCare")
                            }
                        ]
                        : [
                            {
                                DescriptionIcon: Book,
                                description_title: t("common.summary")
                            }, {
                                DescriptionIcon: Droplet,
                                description_title: t("productDetail.plantCare")
                            }
                        ])
            default:
                return ([
                    {
                        DescriptionIcon: Book,
                        description_title: t("common.summary")
                    }
                ])
        }
    }, [product])

    const productDescriptionInformation = useMemo((): string[] => {
        switch (product.product_type) {
            case 'Plant':
                return (
                    product.root_category.slug === 'indoor-plants'
                        ? [
                            product.description || '',
                            product.sunlight_description || '',
                            product.watering_environment_description || ''
                        ]
                        : [
                            product.description || '',
                            product.watering_environment_description || ''
                        ])
            default:
                return ([
                    product.description || ''
                ])
        }
    }, [product])

    const showDescriptionTitles = productDescriptionInformation.length > 1;

    return (
        <ProductDetailsWrapper>
            <InformationSection {...{ selectedItem }}>
                {
                    productDescriptionInformation.map((description, key) => {
                        const { description_title, DescriptionIcon } = productDescriptionTitles[key];
                        return (
                            <div {...{ key, style: { flexDirection: 'column' } }}>
                                {
                                    showDescriptionTitles &&
                                    <ProductDetailsOption {...{ onClick: () => setSelectedItem(key) }} >
                                        <DescriptionIcon {...{ color: COLORS.DARK_GREEN }} />
                                        <ProductDetailsOptionName>
                                            {description_title}
                                        </ProductDetailsOptionName>
                                    </ProductDetailsOption>
                                }


                                <InformationItem {...{ key }}>
                                    <InformationItemText>
                                        {description}
                                    </InformationItemText>
                                </InformationItem>
                            </div>
                        )
                    })
                }
            </InformationSection>
        </ProductDetailsWrapper>
    );
};

export default ProductDetails;
