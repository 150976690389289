//IMPORT COMPONENTS
import { useTranslation } from 'react-i18next';


//IMPORT STYLED COMPONENTS
import {
    Button,

    HeroImage,
    HeroContainer,
    HeroImageMobile,
    HeroTitleContainer,
    HeroTextInnerContainer,
    HeroSectionDescription,
    HeroTitleText,
    HeroDescriptionText,
} from './HeroSectionElements';

import { getSiteLanguage } from '@translation';
import { RouterPages } from '@project/routes';

const HeroSection = () => {
    const currentLanguage = getSiteLanguage();

    const { t } = useTranslation();
    return (
        <HeroContainer>
            <HeroImage {...{
                style: {
                    transform: currentLanguage === 'ar'
                        ? 'scaleX(-1)' : 'none',
                }
            }} />

            <HeroImageMobile {...{
                style: {
                    transform: currentLanguage === 'ar'
                        ? 'scaleX(-1)' : 'none',
                }
            }} />

            <HeroTitleContainer>
                <HeroTextInnerContainer>
                    <HeroTitleText>
                        {t('home.allYour')}{' '}
                        <span style={{
                            color: '#118B19',
                            fontWeight: 'bolder',
                            fontFamily: 'ManropeBold'
                        }} >
                            {t('home.gardening')}
                        </span>{' '}
                        {t('home.needs')}
                    </HeroTitleText>
                    <HeroSectionDescription>
                        <HeroDescriptionText>
                            {t('home.heroSubheader')}
                        </HeroDescriptionText>
                    </HeroSectionDescription>
                    <Button page={RouterPages.ListingCategoryPage} pagePathArgs={{ 'category': 'indoor-plants' }}>
                        {t('home.shopNow')}
                    </Button>
                </HeroTextInnerContainer>
            </HeroTitleContainer>
        </HeroContainer >
    );
};

export default HeroSection;
