// IMPORT STYLED COMPONENTS
import {
    CardText,
    CardTitle,
    TestimonialWrapper,
    TestimonialCardContainer,
} from '../TestimonialsElements';
import { CardContent, Rating } from '@mui/material';

const TestimonialCard = (props: { title: string, text: string }) => {
    return (
        <TestimonialWrapper>
            <TestimonialCardContainer>
                <CardTitle>{props.title}</CardTitle>
                <Rating name="read-only" value={5} readOnly />
                <CardText>
                    {props.text}
                </CardText>
            </TestimonialCardContainer>
        </TestimonialWrapper>
    );
};

export default TestimonialCard;
