import React from 'react';

// IMPORT COMPONENTS
import ProfileDetails from './ProfileDetails/ProfileDetails';
import AddressesAndCards from './AddressAndCardsTab/AddressesAndCards';
import { ResetPasswordForm } from './ChangePasswordTab/ChangePasswordForm';

// IMPORT STYLED COMPONENTS
import {
    TabsContainer,
    CategoryTab,
    MainSectionContainer,
    ProfileDetailsWrapper,
    CardsAndAddressesWrapper,
    ResetPasswordWrapper,
    Main,
} from './TabsElements';
import { useTranslation } from 'react-i18next';

const Tabs = ({
    selectedTab,
    setSelectedTab,
    openImageSelector,
    setOpenImageSelector,
}: {
    openImageSelector: boolean;
    selectedTab: number;
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
    setOpenImageSelector: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Main>
            <TabsContainer {...{ value: selectedTab, onChange: handleChange }}>
                <CategoryTab {...{ label: t("userProfile.profileDetails"), value: 0 }} />
                <CategoryTab {...{ label: t("userProfile.addressesAndCards"), value: 1 }} />
                <CategoryTab {...{ label: t("userProfile.changePassword"), value: 2 }} />
            </TabsContainer>
            <MainSectionContainer {...{ selectedTab }}>
                {/* PROFILE DETAILS */}
                <ProfileDetailsWrapper>
                    <ProfileDetails {...{ openImageSelector, setOpenImageSelector }} />
                </ProfileDetailsWrapper>
                {/* ADDRESSES & CARDS */}
                <CardsAndAddressesWrapper>
                    <AddressesAndCards />
                </CardsAndAddressesWrapper>
                {/* RESET PASSWORD */}
                <ResetPasswordWrapper>
                    <ResetPasswordForm />
                </ResetPasswordWrapper>
            </MainSectionContainer>
        </Main>
    );
};

export default Tabs;
