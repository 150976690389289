
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';


const resolvePaymentPollFetch = async (payment_process_identifier: string) => {
    const HttpRequest = new HTTP_REQUEST(`payment/check-payment-status/`, {
        method: HTTP_METHODS.POST,
        options: { data: { dibsy_payment_process: payment_process_identifier } },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


interface PAYMENT_STATUS_TYPE {
    status: 'Pending' | 'Success' | 'Failed' | 'Timeout';
    checkout_link?: string;
    failure_message?: string;
}

const resolvePaymentPoll = async (payment_process_identifier: string):
    Promise<RESPONSE_TYPE<PAYMENT_STATUS_TYPE>> => {
    try {
        const response = await resolvePaymentPollFetch(payment_process_identifier)
        const body = await response.json();

        if (response.ok) {
            return SUCCESS_RESPONSE<PAYMENT_STATUS_TYPE>(body)
        }
        return FAILURE_RESPONSE('GET_PAYMENT_STATUS', body)
    } catch (error) {
        return FAILURE_RESPONSE('GET_PAYMENT_STATUS', error)
    }
}

export type PollFunction = (payment_process_identifier: string) => Promise<RESPONSE_TYPE<PAYMENT_STATUS_TYPE>>

export default resolvePaymentPoll;