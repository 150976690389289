import styled from 'styled-components';

//IMPORT ICONS
import plusIcon from '@assets/images/common/plus.png';
import cartIconWhite from '@assets/images/common/cartIconWhite.png';

// IMPORT STYLES
import { COLORS } from '@project/styles';
import { Link } from 'routes/components/Link';


import '@project/fonts';

// ADD A GIFT CARD
export const GiftCardDesignContainer = styled.div`
    width: 100%;
    height: 300px;
    margin: 0px 30px;
    padding: 10px 20px;
    flex-direction: column;
    border-radius: 10px;
    
    @media screen and (max-width: 768px) {
        height: 240px;
        padding: 5px 10px;
    }
`

export const GiftCardTopContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const GiftCardTitleContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

export const GiftCardCheckBoxContainer = styled.div`
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const GiftCardImageSection = styled.div`
    width: 100%;
    height: 225px;
    display: flex;
    margin: 10px 0px 30px 0px;
    object-fit: contain;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (max-width: 1400px) {
        width: 100%;
        max-height: 600px;
        min-height: 600px;
    }

    @media screen and (max-width: 768px) {
        max-height: 400px;
        min-height: 400px;
    }
`;

export const GiftCardTextInputContainer = styled.div`
    width: 100%;
    height: 235px;
    padding: 15px;
    margin-bottom: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 800px) {
        height: auto;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
`

export const GiftCardAddButton = styled.button`
    border: 0;
    width: 35%;
    padding: 12px;
    font-size: 1.1rem;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${COLORS.DARK_GREEN};
    @media screen and (max-width: 868px) {
        width: 100%;
    }
`;
export const NonGiftCardAddButton = styled.button`
    border: 0;
    width: 25%;
    padding: 12px;
    font-size: 1.1rem;
    margin-right: 10px;
    cursor: pointer;
    color: ${COLORS.DARK_GREEN};
    font-weight: bold;
    border-radius: 5px;
    background-color: white;
    @media screen and (max-width: 868px) {
        width: 80%;
    }
`;
