// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

// IMPORT HOOKS
import { useTranslation } from 'react-i18next';

// IMPORT COMPONENTS
import { GiFlowerPot } from 'react-icons/gi';
import HighlightedSection from './HighlightedSection/HighlightedSection';


const NewArrivals = ({ data, }: { data: API_PRODUCT_TYPE[] }) => {
    const { t } = useTranslation();

    return (
        <HighlightedSection
            {...{
                data,
                title: t('home.new'),
                sectionIcon: GiFlowerPot,
                buttonText: t('common.viewMore'),
                boldedTitle: t('home.arrivals'),
                buttonCategorySlug: 'indoor-plants',
            }}
        />
    );
};

export default NewArrivals;
