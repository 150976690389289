import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';
import styled from 'styled-components';

export const CheckoutButtonContainer = styled.div`
    bottom: 5%;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    background-color: ${COLORS.WHITE};
`
export const Main = styled.div`
    width: 50%;
    display: flex;
    margin-left: 50px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1000px) {
        width: 90%;
    }
`;

export const PromoCodeErrorMsg = styled.p`
    color: ${COLORS.RED};
    margin: 0;
`;

export const PaymentSummary = styled.div`
    width: 100%;
    padding: 20px 5%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    ::-webkit-scrollbar { display: none; };
    transition: opacity 0.2s ease z-index 0s;
    /* border: solid 2px ${COLORS.NOTIFICATION_GREEN}; */

    @media screen and (max-width: 1000px) {
        padding: 20px 2%;
        /* border: solid 0px ${COLORS.NOTIFICATION_GREEN}; */
    }

    @media screen and (max-width: 500px) {
        padding: 20px 0;
        /* border: solid 0px ${COLORS.NOTIFICATION_GREEN}; */
    }
`

