import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';



// IMPORT HELPERS
import {
    getVariant,
    getFirstVariant,
    getProductSubheading,
} from '@project/helpers';
import { AddToCart as addToCart } from '@api/helpers';
import { notifyMessage, promiseMessage } from '@overlays/notifications';


// IMPORT COMPONENTS
import {
    QuickAddTitle,
    QuickAddTitles,
    QuickAddSubtitle,

    QuickAddContainer,
    QuickAddTopContainer,
    QuickAddInfoContainer,
    QuickAddDescriptionContainer,

    QuickAddReadMoreButton,
    CarouselContainer,
} from '@listing_components/QuickAddElements';

import {
    Counter,
    QuantityBox,
    PlusMinusBox,

    AddToCartText,
    AddToCartButton,
    AddToCartInterface,
} from '@detail_components/Top/TopElements';
import { SubCategoryCircles } from '@custom_components';
import VariantPicker from '@detail_components/VariantSelection';
import QuickAddCarousel from '@detail_components/ProductCarousel';
import { useTranslation } from 'react-i18next';

interface DescriptionProps {
    text: string;
    readMore: boolean;
    fullText?: boolean;
    toggleReadMore: () => void;
}

const shrinkText = (text: string) => {
    return text.slice(0, 110) + '...'
}
const Description = ({ text, readMore, fullText, toggleReadMore }: DescriptionProps) => {
    return fullText ? (
        <QuickAddDescriptionContainer {...{ readMore: true }}>
            {text}
        </QuickAddDescriptionContainer>
    ) : (
        <QuickAddDescriptionContainer {...{ readMore }}>
            {readMore ? text : shrinkText(text)}
            <QuickAddReadMoreButton {...{ onClick: toggleReadMore }}>
                {readMore ? 'Read Less' : 'Read More'}
            </QuickAddReadMoreButton>
        </QuickAddDescriptionContainer>
    )
}

interface QuickAddProps {
    product: API_PRODUCT_TYPE
}


const QuickAddProduct = ({ product }: QuickAddProps) => {
    const { name } = product;
    const { t } = useTranslation();
    const subheader = getProductSubheading(product);
    const [readMore, setReadMore] = useState<boolean>(false);
    const [active_variant, set_active_variant] = useState<string>(getFirstVariant(product));

    const variantInfo = useMemo(
        () => getVariant(product, active_variant),
        [product, active_variant])

    const displayableImages = useMemo(
        () => variantInfo?.product_images || product.product_images,
        [product, variantInfo])

    const [requested_quantity, set_requested_quantity] = useState<number>(1);

    const incrementQuantity = useCallback(() => {
        const maxQuantity = product.variants.find(variant => variant.sku === active_variant)?.quantity || 0;

        if (requested_quantity >= maxQuantity) {
            notifyMessage({
                notification_type: 'ERROR',
                message: 'Maximum Quantity Reached for this Product!',
            })
        } else {
            set_requested_quantity(prev => prev + 1)
        }
    }, [product, requested_quantity, active_variant])

    const decrementQuantity = useCallback(() => {
        if (requested_quantity === 1) {
            notifyMessage({
                message: 'Minimum Quantity Reached for this Product!',
                notification_type: 'ERROR',
            })
        } else {
            set_requested_quantity(prev => prev - 1)
        }
    }, [product, requested_quantity])


    const addProductToCart = () => {
        if (variantInfo) {
            const { sku, price } = variantInfo;

            promiseMessage({
                promise: addToCart(sku, requested_quantity, price),
                success: `Added ${product.shortened_display_name || product.name} to Cart!`,
                loading: `Adding ${product.shortened_display_name || product.name} to Cart...`,
                error: (error: string | null) => `Something went wrong, ${error || 'Please try again!'}.`,
            })
        }
    }

    const toggleReadMore = () => {
        setReadMore(prev => !prev);
    }

    useEffect(() => {
        set_active_variant(getFirstVariant(product));
        set_requested_quantity(1);
    }, [product])

    return (
        <QuickAddContainer>
            <CarouselContainer>
                <QuickAddCarousel {...{ displayableImages }} quickAdd />
            </CarouselContainer>
            <QuickAddInfoContainer>
                <QuickAddTopContainer>
                    <QuickAddTitles>
                        <QuickAddTitle>
                            {name}
                        </QuickAddTitle>
                        {
                            Boolean(subheader) &&
                            <QuickAddSubtitle>
                                {subheader}
                            </QuickAddSubtitle>
                        }
                    </QuickAddTitles>

                    {
                        Boolean(product.sub_categories.length) &&
                        <SubCategoryCircles {...{
                            product_id: product.product_id,
                            sub_categories: product.sub_categories
                        }} />
                    }

                </QuickAddTopContainer>

                <Description {...{
                    readMore,
                    toggleReadMore,
                    fullText: product.variants.length <= 1,
                    text: product.description || 'No Description'
                }} />
                <VariantPicker {...{
                    product,
                    active_variant,
                    set_active_variant,
                }} />
            </QuickAddInfoContainer>
            {

            }
            <AddToCartInterface quick_add>
                <AddToCartButton {...{ onClick: addProductToCart }}>
                    <AddToCartText {...{ style: { fontWeight: 'bold' } }}>
                        {t("productDetail.addToCart")}
                    </AddToCartText>
                    <AddToCartText>
                        QAR {(variantInfo?.price || 0) * requested_quantity}
                    </AddToCartText>
                </AddToCartButton>

                <Counter>
                    <PlusMinusBox {...{ onClick: decrementQuantity }}>
                        -
                    </PlusMinusBox>
                    <QuantityBox>{requested_quantity}</QuantityBox>
                    <PlusMinusBox {...{ onClick: incrementQuantity }}>
                        +
                    </PlusMinusBox>
                </Counter>
            </AddToCartInterface>
        </QuickAddContainer >
    );
}

export default QuickAddProduct