import styled from 'styled-components';
import { Link } from '@project/routes/components';
import getOnAppStore from '@assets/images/footer/getOnAppStore.png';
import getOnPlayStore from '@assets/images/footer/getOnPlayStore.png';
import footerFacebookIcon from '@assets/images/footer/footerFacebookIcon.png';
import footerInstagramIcon from '@assets/images/footer/footerInstagramIcon.png';

export const FooterContainer = styled.footer<{ deactivateDisplay: boolean }>`
    background-color: #28442c;
    display: ${({ deactivateDisplay }) => deactivateDisplay ? 'none' : undefined}
`;

export const FooterWrap = styled.div`
    display: flex;
    margin: 0px auto;
    padding: 20px 24px;
    align-items: center;    
    flex-direction: column;
    justify-content: center;
`;


export const FooterLinksContainer = styled.div`
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`;


export const FooterLinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        justify-content: space-evenly;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    height: 160px;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 140px;
    box-sizing: border-box;
    color: #fff;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        width: 40%;
        padding-left: 50px;
        height: 160px;
        align-items: center;
        justify-content: flex-start;
    }

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 5px 2px;
        padding-left: 10px;
        height: 180px;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 18px;
    font-weight: 900;
`;

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: normal;

    &:hover {
        color: #01bf71;
        transition: 0.3 ease-out;
    }
`;

export const SocialMedia = styled.section`
    width: 100%;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto 0 auto;
    flex-direction: column;
    @media screen and (max-width: 820) {
    }
`;

export const SocialLogo = styled.a`
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    font-weight: bold;
    width: 50%;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    font-size: 16px;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240;
`;

export const SocialIconsLink = styled.a`
    color: #fff;
    font-size: 24px;
`;

export const WebsiteDocsContainer = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
`
export const PrivacyPolicy = styled.a`
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
    &:hover {
        color: #01bf71;
        transition: 0.3 ease-out;
    }
`;

export const TermsOfService = styled.a`
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
    &:hover {
        color: #01bf71;
        transition: 0.3 ease-out;
    }
`

export const DownloadOnLogos = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 125px;
`;

export const AppStoreLink = styled.a.attrs({
    href: "https://apps.apple.com/qa/app/greenit/id1586204009",
    target: "_blank"
})`
`

export const AppStoreLinkImage = styled.img.attrs({
    src: `${getOnAppStore}`
})`
    width: 100px;
    transition: all .5s ease;
    &:hover {
        opacity: .85;
    }
`

export const PlayStoreLink = styled.a.attrs({
    href: "https://play.google.com/store/apps/details?id=com.greenitapp",
    target: "_blank"
})`
`

export const PlayStoreLinkImage = styled.img.attrs({
    src: `${getOnPlayStore}`
})`
    width: 100px;
    transition: all .5s ease;
    &:hover {
        opacity: .85;
    }
`

export const FollowUsOnLogos = styled.div`
    display: flex;
    flex-direction: row;
`;


export const FollowUsOnFacebookLink = styled.a.attrs({
    href: "https://facebook.com/Greenit-105408695224073/",
    target: "_blank"
})`
`

export const FollowUsOnFacebookImage = styled.img.attrs({
    src: `${footerFacebookIcon}`
})`
    margin-right: 8px;
    width: 30px;
    &:hover {
        opacity: .85;
    }
`;


export const FollowUsOnInstagramLink = styled.a.attrs({
    href: "https://www.instagram.com/greenit.qa/",
    target: "_blank"
})`
`
export const FollowUsOnInstagramImage = styled.img.attrs({
    src: `${footerInstagramIcon}`
})`
    width: 30px;
    &:hover {
        opacity: .85;
    }
`;