import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import {
    mapPromocode,
    mapCartProducts,
    addIndividualQuantities,
} from '../helpers';
import { APP_STATE_CUSTOM_CARD_TYPE } from '@api/types';
import { API_CART_TYPE, cartItemIdType, CART_TYPE } from '../types';

export const addToCartFetch = async (
    id: cartItemIdType,
    quantity: number,
    price: number,
    customMessage?: APP_STATE_CUSTOM_CARD_TYPE
) => {
    var data = {
        product_sku: id,
        requested_quantity: quantity,
        price_entered_at: price,
        custom_card: customMessage
            ? {
                sender: customMessage.from,
                recipient: customMessage.to,
                message: customMessage.message,
                ...(customMessage.card_design ? { card_design: customMessage.card_design } : undefined),
            }
            : undefined,
    };
    const HttpRequest = new HTTP_REQUEST('user/cart/', {
        method: HTTP_METHODS.POST,
        options: {
            data,
            json: true,
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Add To Cart');
    }
};


type CUSTOM_RESPONSE = CART_TYPE;

const addToCart = async (
    product_sku: cartItemIdType,
    quantity: number,
    price: number,
    customMessage?: APP_STATE_CUSTOM_CARD_TYPE
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await addToCartFetch(
            product_sku,
            quantity,
            price,
            customMessage
        );
        let body: API_CART_TYPE = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>({
                ...body,
                cart_items: mapCartProducts(body.cart_items),
                promo_code: mapPromocode(body.promo_code),
                num_items: addIndividualQuantities(body.cart_items),
            })
            : FAILURE_RESPONSE('ADD_CART', body);
    } catch (error) {
        return FAILURE_RESPONSE('ADD_CART');
    }
};

export default addToCart;
