//IMPORT COMPONENTS
import { isValidPhoneNumber } from 'react-phone-number-input';

//IMPORT TYPES
import {
    PRIVATE_API_ADDRESS_TYPE,
    API_ADDRESS_TYPE,
    API_ADDRESS_ERROR_TYPE,
} from '@api/user/address/types';

export interface LatLng {
    latitude: number;
    longitude: number;
}

export const DEFAULT_DELTA = {
    latitudeDelta: 0.015,
    longitudeDelta: 0.0121,
};

export const DEFAULT_LATLNG = {
    latitude: 0,
    longitude: 0,
};

export const getAddressUpdates = (
    edited: PRIVATE_API_ADDRESS_TYPE,
    original: API_ADDRESS_TYPE
): Partial<PRIVATE_API_ADDRESS_TYPE> => {
    let updates: Partial<PRIVATE_API_ADDRESS_TYPE> = {};

    if (edited.address_nickname !== original.address_nickname) {
        updates['address_nickname'] = edited.address_nickname;
    }
    if (edited.area !== original.area) {
        updates['area'] = edited.area;
    }
    if (edited.address_type !== original.address_type) {
        updates['address_type'] = edited.address_type;
        switch (edited.address_type) {
            case 'House':
                updates['building'] = edited.building;
                break;
            case 'Compound':
                updates['building'] = edited.building;
                updates['compound_name'] = edited.compound_name;
                break;
            case 'Office':
                updates['office'] = edited.office;
                updates['floor'] = edited.floor;
                updates['building'] = edited.building;
                break;
            case 'Apartment':
                updates['apartment_number'] = edited.apartment_number;
                updates['floor'] = edited.floor;
        }
        updates['address_type'] = edited.address_type;
    }
    if (edited.street_name !== original.street_name) {
        updates['street_name'] = edited.street_name;
    }
    if (edited.street_number !== original.street_number) {
        updates['street_number'] = edited.street_number;
    }
    if (edited.additional_directions !== original.additional_directions) {
        updates['additional_directions'] = edited.additional_directions;
    }
    if (edited.mobile_number !== original.mobile_number) {
        updates['mobile_number'] = edited.mobile_number;
    }
    if (edited.latitude !== original.latitude) {
        updates['latitude'] = edited.latitude;
    }
    if (edited.longitude !== original.longitude) {
        updates['longitude'] = edited.longitude;
    }
    if (edited.delta_latitude !== original.delta_latitude) {
        updates['delta_latitude'] = edited.delta_latitude;
    }
    if (edited.delta_longitude !== original.delta_longitude) {
        updates['delta_longitude'] = edited.delta_longitude;
    }

    return updates;
};

export const validAddress = (
    address: PRIVATE_API_ADDRESS_TYPE
    // serviceArea: LatLng[]
) => {
    var errors: Partial<API_ADDRESS_ERROR_TYPE> = {};

    let status = true;
    // if (
    //     !isPointInPolygon(
    //         {
    //             latitude: address.latitude,
    //             longitude: address.longitude,
    //         },
    //         serviceArea
    //     )
    // ) {
    //     errors['map'] = true;
    //     status = false;
    // }

    if (!address.area) {
        errors['area'] = true;
        status = false;
    }
    if (!isValidPhoneNumber(address.mobile_number)) {
        errors['mobile_number'] = true;
        status = false;
    }
    if (!address.street_number) {
        errors['street_number'] = true;
        status = false;
    }
    if (!address.address_type) {
        errors['address_type'] = true;
        status = false;
    } else {
        switch (address.address_type) {
            case 'House':
                if (!address.building) {
                    errors['building'] = true;
                    status = false;
                }
                break;
            case 'Office':
                if (!address.building) {
                    errors['building'] = true;
                    status = false;
                }
                if (!address.floor) {
                    errors['floor'] = true;
                    status = false;
                }
                if (!address.office) {
                    errors['office'] = true;
                    status = false;
                }
                break;
            case 'Compound':
                if (!address.compound_name) {
                    errors['compound_name'] = true;
                    status = false;
                }
                if (!address.building) {
                    errors['building'] = true;
                    status = false;
                }
                break;
            case 'Apartment':
                if (!address.apartment_number) {
                    errors['apartment_number'] = true;
                    status = false;
                }
                if (!address.floor) {
                    errors['floor'] = true;
                    status = false;
                }
                break;
        }
    }

    if (
        address.latitude === -1 ||
        address.longitude === -1
    ) {
        errors['coordinates'] = true;
        status = false;
    }

    return {
        status,
        errors,
    };
};
