import styled from 'styled-components';
import { COLORS } from '@project/styles';

export const Main = styled.div`
    display: flex;
    flex-direction: column;
`;
export const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${COLORS.DARK_GREY};
    width: 45%;
    border-radius: 10px;
    height: 200px;
    margin-top: 20px;
    /* @media screen and (max-width: 800px) {
        width: 100%;
    } */
`;

export const CardDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    width: 50%;
    justify-content: space-around;
`;
export const CardLogo = styled.img.attrs({})`
    width: 45px;
    padding: 10px;
    border-radius: 160px;
    background-color: ${COLORS.WHITE_SMOKE};
`;
export const LogoAndTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const Title = styled.h2`
    font-weight: normal;
    margin: 0;
    margin-left: 10px;
`;
export const AddressWrapper = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;
export const AddressDetailText = styled.p`
    margin: 0;
`;
export const MapWrapper = styled.div`
    height: 100%;
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`;
export const MapElement = styled.div`
    border-radius: 10px;
    width: 95%;
    height: 95%;
    display: flex;
    /* border: 1px solid black; */
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-left: 10px;
    justify-content: space-between;
    width: 60%;
`;
interface ButtonProps {
    isDelete: boolean;
}
export const Button = styled.div<ButtonProps>`
    background-color: white;
    border: ${(props) =>
        props.isDelete
            ? `1px solid ${COLORS.RED}`
            : `1px solid ${COLORS.ORANGE}`};
    height: 30px;
    width: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const ButtonText = styled.h5<ButtonProps>`
    margin: 0;
    font-weight: normal;
    color: ${(props) =>
        props.isDelete ? `${COLORS.RED}` : `${COLORS.ORANGE}`};
`;
interface AllAddressesProps {
    expandAddresses: boolean;
}
export const AllAddresses = styled.div<AllAddressesProps>`
    transition: all 0.5s;
    display: flex;
    justify-content: space-around;
    height: ${(props) => (props.expandAddresses ? `460px` : `240px`)};
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    overflow: ${(props) => (props.expandAddresses ? `auto` : `hidden`)};
`;

export const TitleButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    /* border: 1px solid black; */
    flex-direction: row;
`;
interface AllCardsProps {
    expand: boolean;
}
export const AllCards = styled.div<AllCardsProps>`
    position: relative;
    transition: all 0.5s;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${(props) => (props.expand ? '0 0 15px 0' : '0 0 10px 0')};
    height: ${(props) => (props.expand ? `430px` : `220px`)};
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    overflow-y: ${(props) => (props.expand ? `auto` : `hidden`)};
    ::-webkit-scrollbar {display:none; };

    @media screen and (max-width: 800px) {
        flex-direction: column3;
        align-content: center;
        justify-content: center;
    }
`;
