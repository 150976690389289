
import styled from 'styled-components';

import { COLORS } from '@project/styles';

import GreenitMarker from '@assets/images/user/Marker.png';

export const GreenButton = styled.button`
    border: 0;
    margin-top: 5px;
    width: 30%;
    padding: 12px;
    font-size: 1.1rem;
    color: ${COLORS.WHITE};
    font-weight: bold;
    min-width: 100px;
    max-width: 300px;
    border-radius: 5px;
    background-color: ${COLORS.DARK_GREEN};
    @media screen and (max-width: 868px) {
        width: 80%;
    }
`;

export const LtrDiv = styled.div`
    direction: ltr;
    height: max-content;
`;

export const BigLtrDiv = styled.div`
    direction: ltr;
`;

export const GreenitMapMarker = styled.img.attrs({
    src: GreenitMarker,
})`
    width: 40px;
    height: 80px;
    overflow: hidden;
`;

interface ButtonProps {
    color: string;
    outlined?: boolean;
    height?: number;
    width?: number;
    textColor?: string;
    borderRadius?: number;
    text?: string;
}
export const CommonButton = styled.div<ButtonProps>`
    background-color: ${(props) =>
        props.outlined ? 'white' : `${props.color}`};
    border: 1px solid ${(props) => props.color};
    height: ${(props) => (props.height ? `${props.height}px` : `30px`)};
    width: ${(props) => (props.width ? `${props.width}px` : `60px`)};
    border-radius: ${(props) =>
        props.borderRadius ? `${props.borderRadius}px` : `5px`};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const CommonButtonText = styled.h5<ButtonProps>`
    margin: 0;
    font-weight: normal;
    color: ${(props) =>
        props.textColor
            ? `${props.textColor}`
            : props.outlined
                ? `${props.color}`
                : `white`};
`;

// export const CommonButton = (props: ButtonProps) => (
//     <Button onClick={props.onClick} {...{ ...props }}>
//         <ButtonText {...props}>{props.text}</ButtonText>
//     </Button>
// );

interface LowOpacityH1 {
    opacity?: number;
    size?: number;
    color?: string;
    fontWeight?: 'normal' | 'bold';
}
export const LowOpacityH1 = styled.h1<LowOpacityH1>`
    width: 100%;
    margin-top: 50px;
    text-align: center;
    opacity: ${(props) => (props.opacity ? `opacity: ${props.opacity}` : 0.3)};
    size: ${(props) => (props.size ? `size: ${props.size}` : 'normal')};
    color: ${(props) => (props.color ? `color: ${props.color}` : 'black')};
    font-weight: ${(props) =>
        props.fontWeight ? `font-weight: ${props.fontWeight}` : 'bold'};
`;