import styled from 'styled-components';

//IMPORT STYLES
import {
    COLORS,
    BIG_HEADER_HEIGHT,
    BASE_TRANSITION_TIME,
    BIG_CONTENT_CONTAINER_HEIGHT,
} from '@project/styles';

//IMPORT ICONS
import { FaChevronRight } from 'react-icons/fa';
import { FaChevronLeft } from 'react-icons/fa';
import FilterIcon from '@assets/images/listings/filter.png';

import '@project/fonts';
import { Divider } from '@mui/material';
import { Link } from '@project/routes/components';


interface ContainerProps {
    is_open: boolean;
}

export const ListingContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
export const ListingWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    min-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`;

export const LeftContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
    ::-webkit-scrollbar {display:none;}
`

export const ProductsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-evenly;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`

export const ComingSoon = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 2.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARKER_GREY};

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`

export const FilterContainer = styled.div<ContainerProps>`
    display: flex;
    position: sticky;
    overflow-y: auto;
    flex-direction: column;
    top: ${BIG_HEADER_HEIGHT}vh;
    ::-webkit-scrollbar { display:none; };
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
    min-width: ${(props) => props.is_open ? '350px' : '0%'};
    max-width: ${(props) => props.is_open ? '350px' : '0%'};
    background-color: ${COLORS.WHITE};
    transition: all .1s ease;
`
export const FilterDivider = styled.div<ContainerProps>`
    position: absolute;
    height: 100%;   
    right: 0;
    opacity: ${(props) => props.is_open ? 1 : 0}; 
`

export const RightContainer = styled.div<ContainerProps>`
    display: flex;
    position: sticky;
    overflow-y: auto;
    flex-direction: column;
    top: ${BIG_HEADER_HEIGHT}vh;
    background-color: ${COLORS.WHITE};
    ::-webkit-scrollbar {display:none;};
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
    width: ${(props) => props.is_open ? '50%' : '0%'};
    transition: all 0.1s ease;
    @media screen and (max-width: 900px) {
        display: none;
    }
`

export const RightDivider = styled.div<ContainerProps>`
    position: absolute;
    height: 100%;
    left: 0;
    opacity: ${(props) => props.is_open ? 1 : 0};
`

export const Cancel = styled.div<ContainerProps>`
    width: 35px;
    height: 35px;
    opacity: 0.3;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    font-weight: bolder;
    background-color: #000;
    justify-content: center;
    transition: all 0.2s ease;
    color: ${COLORS.DARK_GREEN};
    display: ${props => props.is_open ? 'flex' : 'none'};

    &:hover {
        opacity: 1;
    }
`

export const CancelAbsolute = styled.div<ContainerProps>`
    top: 3%;
    right: 3%;
    width: 35px;
    height: 35px;
    opacity: 0.3;
    cursor: pointer;
    position: absolute;
    border-radius: 10px;
    font-weight: bolder;
    align-items: center;
    background-color: #000;
    justify-content: center;
    transition: all 0.2s ease;
    color: ${COLORS.DARK_GREEN};
    display: ${props => props.is_open ? 'flex' : 'none'};

    &:hover {
        opacity: 1;
    }
`

// LISTING TITLE
export const ListingTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    max-height: 350px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    flex-direction: row;
    position: relative;
    overflow: hidden;
    color: ${COLORS.DARK_GREEN};
    @media screen and (max-width: 800px) {
        margin: 2%;
        flex-direction: column;
    }
`;


export const BlackTint = styled.div`
    display: flex;
    position: absolute;
    opacity: 0.35;
    width: 100%;
    height: 100%;
    background-color: #000000;
`

export const ImageBackground = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    object-position: center;
`

export const ListingTitleIcon = styled.img`
    width: 40px;
    height: 40px;
    margin: 15px 5px;
    @media screen and (max-width: 800px) {
        width: 20px;
        height: 20px;
        margin: 10px 0;
    }
`;

export const ListingTitleText = styled.h1`
    font-size: 2.3rem;
    margin: 15px 5px;
    text-align: center;
    @media screen and (max-width: 900px) {
        font-size: 1.7rem;
        font-weight: bold;
        margin: 0px 0px 0px 0px;
    }
`;

export const ListingPhraseText = styled.div`
    font-size: 1.5rem;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    @media screen and (max-width: 900px) {
        font-size: 1.2rem;
        margin: 10px 0px 10px 10px;
    }
`;

export const NameAndIcon = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: ${COLORS.WHITE};
    width: 100%;
    /* padding-bottom: 25px; */
    background-color: rgba(92, 187, 108, 0.8);

    @media screen and (max-width: 900px) {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }
`;

export const NumberOfProductsAndFilter = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 120px);
    bottom: 0px;
    padding: 20px 60px;
    @media screen and (max-width: 800px) {
        width: calc(100% - 10px);
        padding: 10px 5px;
    }
`;

export const NumberOfProductsContainer = styled.div`
    background-color: rgba(255, 255, 255);
    border-radius: 10px;
    padding: 0px 10px;
    height: 50px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 800px) {
        min-width: 40%;
        font-size: 0.8rem;
    }
`;

export const NumberOfProductsText = styled.h3`
    font-weight: normal;
    text-align: center;
    margin: 0 0;
    padding: 20px 0;
`;
export const SortingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    min-width: 200px;
    height: 50px;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    background-color: rgba(255, 255, 255);

    &:hover {
        background-color: lightgray;
    }
`;
export const SortingButtonTitle = styled.h3`
    font-weight: normal;
    margin: 10px 0;
`;
export const SortingIcon = styled.img.attrs({
    src: `${FilterIcon}`,
})`
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 10px;
`;
// SORTING
export const SortingItem = styled.div`
    display: flex;
    flex-direction: row;
    /* width: 30px; */
    border-radius: 60px;
    border: 2px solid ${COLORS.GREY};
    justify-content: space-around;
    padding: 10px 10px;
    align-items: center;
`;
export const SortingItemText = styled.h3`
    font-weight: normal;
    margin: 0 5px;
`;

//Products
export const ListingProductsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 800px) {
        margin: 0;
    }
`;

export const ProductWrapper = styled.div`
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

//Page selector
export const MainFullWidthContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
    direction: ltr;
    padding-bottom: 50px;
`;

export const PageSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
export const PageNumber = styled.div<{ active: boolean }>`
    display: flex;
    margin: 0 5px;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border-radius: 60px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${({ active }) => active ? COLORS.WHITE : '#222'};
    transition: all .2s ease;
    background-color: ${({ active }) => active ? COLORS.DARK_GREEN : COLORS.WHITE};
    &:hover {
        color: white;
        background-color: ${COLORS.DARK_GREEN};
    }
`;
export const PageNumberText = styled.h3`
    font-weight: normal;
    background: transparent;
`;
export const LeftArrow = styled(FaChevronLeft)`
    color: #000000;
`;
export const RightArrow = styled(FaChevronRight)`
    color: #000000;
`;
export const Arrow = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 5px;
    align-items: center;
    text-decoration: none;
    border-radius: 60px;
    border: 1px solid ${COLORS.GREY};
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .2s ease;
    &:hover {
        transform-origin: center;
        transform: scale(1.2);
    }
`;

//SIDE MENU
export const FilterMenuWrapper = styled.div`
    background-color: #d4d4d4;
    width: 100%;
    border-radius: 5px;
    transition: transform 1s;
`;
export const FilterMenuContainer = styled.div`
    display: flex;
    width: 50%;
    &:hover ${FilterMenuWrapper} {
        transform: transformX(100%);
    }
`;
export const FilterMenuTitle = styled.h1`
    text-align: center;
    font-weight: normal;
`;
export const FilterWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
export const FilterName = styled.h3``;