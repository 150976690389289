
import { useEffect, useMemo, useState } from 'react';

//IMPORT STYLED COMPONENTS
import {
    OrderDetailName,
    OrderDetailsPicker,
    OrderDetailsPickerWrapper,
} from '@detail_components/Top/TopElements';

import {
    ColorBall,
    ColorOptions,
    ColorBallOuter,

    HighlightedText,
    NonHighlightedText,

    OutOfStockBox,
    InformationBox,
    PotDimensionOptions,
    InformationText,
} from '@detail_components/VariantSelection/VariantPickerElements';


// IMPORT TYPES
import { API_PRODUCT_TYPE, OPTION_SKU_TYPE } from '@api/types';
import { API_VARIANT_HIERARCHY_KEY } from '@api/types/variant';


// IMPORT HELPERS
import {
    cleanKeyName,
    getNewSKU,
    getVariantOptions,
    getVariantUnitOnType
} from '@project/helpers';
import _ from 'underscore';


// IMPORT STYLES
import { COLORS } from '@project/styles';
import { useTranslation } from 'react-i18next';



const PickerFilter = ({ filter, product, active_variant, get_updated_sku }: {
    filter: API_VARIANT_HIERARCHY_KEY,
    product: API_PRODUCT_TYPE,
    active_variant: string,
    get_updated_sku: (key: string, val: string) => void;
}) => {

    const { t } = useTranslation();
    const includes = (item_list: OPTION_SKU_TYPE[]) => item_list.find(item => item.product_sku === active_variant)

    switch (filter as API_VARIANT_HIERARCHY_KEY) {
        case 'is_hanging':
            const hangingOptions = getVariantOptions(product, filter);
            return (
                <OrderDetailsPicker {...{ style: { borderColor: COLORS.WHITE } }}>
                    <HighlightedText>
                        {t(`productDetail.${filter}` as any)}
                    </HighlightedText>
                    <PotDimensionOptions>
                        {
                            hangingOptions.map(({ option, option_sku_info, is_available }, key) => (
                                <InformationBox {...{
                                    key,
                                    numberOfOptions: hangingOptions.length,
                                    onClick: () => is_available && get_updated_sku(filter, option),
                                    style: {
                                        fontWeight: includes(option_sku_info) ? 'bolder' : 'normal',
                                        borderColor: includes(option_sku_info) ? '#222222' : '#EEEEEE',
                                    }
                                }}>
                                    {
                                        !is_available &&
                                        <OutOfStockBox {...{
                                            number_of_options: hangingOptions.length
                                        }}>
                                            {t('productDetail.outOfStock')}
                                        </OutOfStockBox>
                                    }
                                    <InformationText {...{ is_available }}>
                                        {option}
                                    </InformationText>
                                </InformationBox>
                            ))
                        }
                    </PotDimensionOptions>
                </OrderDetailsPicker>
            )
        case "height":
        case "volume":
        case "weight":
        case "pieces":
        case "layers":
        case 'stick_flowers':
            const metricOptions = useMemo(() => getVariantOptions(product, filter, true), [product, filter])
            return (
                <OrderDetailsPicker {...{ style: { borderColor: COLORS.WHITE } }}>
                    <HighlightedText>
                        {t(`productDetail.${filter}` as any)}
                    </HighlightedText>
                    <PotDimensionOptions>
                        {
                            metricOptions.map(({ option, option_sku_info, other_option_data, is_available }, key) => (
                                <InformationBox {...{
                                    key,
                                    numberOfOptions: metricOptions.length,
                                    onClick: () => is_available && get_updated_sku(filter, option),
                                    style: {
                                        fontWeight: includes(option_sku_info) ? 'bolder' : 'normal',
                                        borderColor: includes(option_sku_info) ? '#222222' : '#EEEEEE',
                                    }
                                }}>
                                    {!is_available && <OutOfStockBox {...{
                                        number_of_options: metricOptions.length
                                    }}>{t('productDetail.outOfStock')}</OutOfStockBox>}
                                    <InformationText {...{ is_available }}>
                                        {option} {other_option_data?.option_unit || getVariantUnitOnType(option, filter)}
                                    </InformationText>
                                </InformationBox>
                            ))
                        }
                    </PotDimensionOptions>
                </OrderDetailsPicker>
            );


        case "pot_dimensions":
            const dimOptions = useMemo(() => getVariantOptions(product, 'pot_dimensions'), [product])

            return (
                <OrderDetailsPicker {...{ style: { borderColor: COLORS.WHITE } }}>
                    <HighlightedText>
                        {t(`productDetail.${filter}` as any)}
                    </HighlightedText>
                    <PotDimensionOptions>
                        {
                            dimOptions.map(({ option, option_sku_info, is_available }, key) => (
                                <InformationBox {...{
                                    key,
                                    numberOfOptions: dimOptions.length,
                                    onClick: () => is_available && get_updated_sku(filter, option),
                                    style: {
                                        opacity: !is_available ? 0.3 : 1,
                                        fontWeight: includes(option_sku_info) ? 'bolder' : 'normal',
                                        borderColor: includes(option_sku_info) ? '#222222' : '#EEEEEE',
                                    }
                                }}>
                                    {!is_available && <OutOfStockBox {...{
                                        number_of_options: dimOptions.length
                                    }}>{t('productDetail.outOfStock')}</OutOfStockBox>}
                                    <InformationText {...{ is_available }}>
                                        {option}
                                    </InformationText>
                                </InformationBox>
                            ))
                        }
                    </PotDimensionOptions>
                </OrderDetailsPicker>
            );

        case "color":
            const activeVariant = product.variants.find(variant => active_variant === variant.sku);
            const activeColor = activeVariant ? ((activeVariant as any).color || 'Jordan Peele') : 'Jordan Peele';
            const colorOptions = getVariantOptions(product, 'color');

            return activeVariant ? (
                <OrderDetailsPicker {...{ style: { borderColor: COLORS.WHITE } }}>
                    <HighlightedText>
                        {t(`productDetail.${filter}` as any) + '\xA0'}{<NonHighlightedText>{activeColor}</NonHighlightedText>}
                    </HighlightedText>
                    <ColorOptions>
                        {
                            colorOptions.map(({ option, other_option_data, option_sku_info, is_available }, key) => (
                                <ColorBallOuter {...{
                                    key,
                                    "aria-label": option,
                                    onClick: () => is_available && get_updated_sku(filter, option),
                                    style: {
                                        opacity: !is_available ? 0.2 : 1,
                                        borderColor: !is_available ? '#FFF' : includes(option_sku_info)
                                            ? '#444'
                                            : '#DDD',
                                    }
                                }}>
                                    <ColorBall style={{ backgroundColor: (other_option_data as any).color_code }} />
                                </ColorBallOuter>
                            ))
                        }
                    </ColorOptions>
                </OrderDetailsPicker>
            ) : null;

        default: return (
            <OrderDetailsPicker>
                <OrderDetailName>
                    Choose {cleanKeyName(filter)}:
                </OrderDetailName>
            </OrderDetailsPicker>
        )
    }
}

interface VariantPickerProps {
    active_variant: string;
    product: API_PRODUCT_TYPE;
    set_active_variant: (sku: string) => void;

}

interface VariantPickerState {
    active_variant: string;
}

const VariantPicker = ({ product, active_variant, set_active_variant }: VariantPickerProps) => {

    const [state, setState] = useState<VariantPickerState>({
        active_variant,
    });

    useEffect(() => {
        let cancelled = false;
        if (!cancelled) { setState({ active_variant }) }
        return () => { cancelled = true; }
    }, [active_variant])

    const getNewSkuChoices = (changed_key: string, changed_value: string) => {
        set_active_variant(
            getNewSKU(
                state.active_variant,
                product,
                changed_key,
                changed_value,
                product.variant_filter_hierarchy.length <= 1
            )
        )
    }

    const activeVariant = product.variants.find(variant => active_variant === variant.sku)

    return Boolean(product.variants.length > 1 && activeVariant) ? (
        <OrderDetailsPickerWrapper>
            {
                product
                    .variant_filter_hierarchy
                    .map(
                        (filter) => <PickerFilter {...{
                            product,
                            key: filter,
                            get_updated_sku: getNewSkuChoices,
                            active_variant: state.active_variant,
                            filter: filter as API_VARIANT_HIERARCHY_KEY,
                        }} />
                    )
            }
        </OrderDetailsPickerWrapper>
    ) : null;
}


export default VariantPicker;
