// IMPORT COMPONENTS
import { COLORS } from '@project/styles';
import { BsGiftFill } from 'react-icons/bs';
import {
    InfoHeader,
    InfoSubheader,

    GiftWrapperTop,
    GiftWrapperBottom,
    GiftAddressWrapper,
    GiftWrapperIcon,
    GiftWrapperVerticalLine,
    GiftWrapperHorizontalLine,
} from "./TicketElements"


export const GiftAddress = ({ name, phone }: { name: string, phone: string }) => {
    return (
        <GiftAddressWrapper>
            <GiftWrapperTop>
                <GiftWrapperIcon>
                    <BsGiftFill {...{ style: { color: COLORS.PINK, fontSize: '1.4rem' } }} />
                </GiftWrapperIcon>
                <GiftWrapperVerticalLine />
                <GiftWrapperHorizontalLine />
            </GiftWrapperTop>
            <GiftWrapperBottom>
                <InfoHeader>Gift Delivery</InfoHeader>
                <InfoSubheader>{'Recipient Name: ' + name}</InfoSubheader>
                <InfoSubheader>{'Recipient Phone Number: ' + phone}</InfoSubheader>
            </GiftWrapperBottom>
        </GiftAddressWrapper>
    )
}