import {
    TutorialSVG,
    TutorialTitle,
    TutorialSubtext,
    TutorialContainer,
    TutorialSVGCircle,
} from "../AuthElements";
import { COLORS } from "@project/styles";

import { ReactComponent as Book } from '../assets/book.svg';
import { ReactComponent as Gift } from '../assets/gift_card.svg';
import { ReactComponent as Camera } from '../assets/search.svg';
import { ReactComponent as Bouquet } from '../assets/custom_bouquet.svg';
import { Swipeable } from "@custom_components";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface TutorialCardInfo {
    title: string;
    subtext: string;
    icon: LocalIconType;
}

interface TutorialCardProps extends TutorialCardInfo {
    colors: { PRIMARY: string, SECONDARY: string, TERTIARY: string };
}

type LocalIconType = 'book' | 'camera' | 'bouquet' | 'gift'


const Icon = ({ type, color }: { type: LocalIconType, color: string }) => {
    switch (type) {
        case 'book':
            return (<Book {...{ height: '75%', fill: color }} />)
        case 'bouquet':
            return (<Bouquet {...{ height: '85%', fill: color }} />)
        case 'gift':
            return (<Gift {...{ height: '65%', fill: color }} />)
        case 'camera':
            return (<Camera {...{ height: '60%', fill: color }} />)
    }
}

const TutorialCard = ({ title, subtext, icon, colors }: TutorialCardProps) => {
    return (
        <TutorialContainer>
            <TutorialSVG>
                <TutorialSVGCircle {...{ color: colors.TERTIARY }}>
                    <Icon {...{ type: icon, color: colors.SECONDARY }} />
                </TutorialSVGCircle>
            </TutorialSVG>
            <TutorialTitle {...{ color: colors.TERTIARY }}>
                {title}
            </TutorialTitle>
            <TutorialSubtext {...{ color: colors.TERTIARY }}>
                {subtext}
            </TutorialSubtext>
        </TutorialContainer>
    )
}

interface TutorialCardsProps {
    colors: { PRIMARY: string, SECONDARY: string, TERTIARY: string };
}

const TutorialCards = ({ colors }: TutorialCardsProps) => {
    const { t } = useTranslation();

    const tutorials: TutorialCardInfo[] = [{
        icon: 'book',
        title: t("auth.tutorialCardTitle1"),
        subtext: t("auth.tutorialCardBody1"),
    }, {
        icon: 'camera',
        title: t("auth.tutorialCardTitle2"),
        subtext: t("auth.tutorialCardBody2"),
    }, {
        icon: 'bouquet',
        title: t("auth.tutorialCardTitle3"),
        subtext: t("auth.tutorialCardBody3"),
    }, {
        icon: 'gift',
        title: t("auth.tutorialCardTitle4"),
        subtext: t("auth.tutorialCardBody4"),
    }]

    const render_item = ({ item }: { item: any }) => (<TutorialCard {...item} {...{ colors }} />);

    return (
        <Swipeable {...{
            data: tutorials,
            render_item,
            additional_swiper_props: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                navigation: false,
                autoplay: {
                    delay: 2500,
                },
                loop: true,
                pagination: false,
                mousewheel: false,
                grabCursor: false,
            },
            container_styles: { width: '100%', height: '100%' },
        }} />
    )
}

export default memo(TutorialCards);