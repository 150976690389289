import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';
import { PRIVATE_API_ADDRESS_TYPE } from '../types';


export const addAddressFetch = async (data: PRIVATE_API_ADDRESS_TYPE) => {
    const HttpRequest = new HTTP_REQUEST('user/address/', {
        method: HTTP_METHODS.POST,
        options: {
            data,
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Add User Address');
    }
};


const addUserAddress = async (
    data: PRIVATE_API_ADDRESS_TYPE
): Promise<RESPONSE_TYPE<any>> => {
    try {
        const response = await addAddressFetch(data);
        const body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<any>(body)
            : FAILURE_RESPONSE('ADD_ADDRESS', body);
    } catch (error) {
        return FAILURE_RESPONSE('ADD_ADDRESS');
    }
};

export default addUserAddress;