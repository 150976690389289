import React from 'react';


import { RouterPages, routesInfo } from '.';
import { GetDataFromDOM } from '@custom_components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthProtected, useSessionProtected } from '@project/hooks';

interface InjectedProtectedProps {
    auth_token_cookie: string | null;
    web_device_id_cookie: string | null;
}

const Protected = ({ auth_token_cookie, web_device_id_cookie }: InjectedProtectedProps) => {
    const location = useLocation();
    const authValid = useAuthProtected();
    const sessionValid = useSessionProtected()

    return !(sessionValid && authValid)
        ? <Navigate {...{ to: `${routesInfo[RouterPages.Login].path}/?next=${location.pathname}`, replace: true }} />
        : <Outlet />
}


const GuestProtected = GetDataFromDOM<null>(({ auth_token_cookie, web_device_id_cookie }: InjectedProtectedProps) => {
    const authValid = useAuthProtected();
    const sessionValid = useSessionProtected()

    return (sessionValid && authValid)
        ? <Navigate {...{ to: routesInfo[RouterPages.Home].path, replace: true }} />
        : <Outlet />
})

export { GuestProtected }
export default GetDataFromDOM<null>(Protected);