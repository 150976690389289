
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import {
    MAKE_ORDER_PARAMS,
    APP_STATE_ORDER_TYPE,
    API_ORDER_DETAIL_TYPE,
} from '@api/types';
import { getOrderStatus } from '@api/checkout/helpers';


const failOrderFetch = async (order_id: string) => {
    const HttpRequest = new HTTP_REQUEST(`order/${order_id}/fail-online-payment/`, {
        method: HTTP_METHODS.POST,
        options: {},
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


const failOrder = async (order_id: string): Promise<RESPONSE_TYPE<null>> => {
    try {
        const response = await failOrderFetch(order_id);

        if (response.ok) {
            return SUCCESS_RESPONSE<null>(null)
        } else {
            return FAILURE_RESPONSE('FAIL_ORDER')
        }
    } catch (error) {
        return FAILURE_RESPONSE('FAIL_ORDER', error)
    }
}


export default failOrder;