import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import { ADDRESS_UPDATE_TYPE } from '../types';


export const updateAddressFetch = async (address_id: string, data: ADDRESS_UPDATE_TYPE) => {
    try {
        const HttpRequest = new HTTP_REQUEST(`user/address/${address_id}/`, {
            method: HTTP_METHODS.PATCH,
            options: {
                data, validateCookies: true,
            },
        });

        const Response = await HttpRequest.callFetch();

        return Response;
    } catch (error) {
        throw new RequestError(
            `Failed To Update User Address with pk = ${address_id}`
        );
    }
};



const updateUserAddress = async (
    address_id: string,
    data: ADDRESS_UPDATE_TYPE
) => {
    try {
        const update_params = { ...data, is_main: true };
        const response = await updateAddressFetch(address_id, update_params);
        const body = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<any>(body)
            : FAILURE_RESPONSE('UPDATE_ADDRESS', body);
    } catch (error) {
        return FAILURE_RESPONSE('UPDATE_ADDRESS', error);
    }
};

export default updateUserAddress;