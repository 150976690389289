import styled from 'styled-components';
import { COLORS } from '@project/styles';


import AppStoreImage from '@assets/images/home/app_store_download_white_bg.svg';
import PlayStoreImage from '@assets/images/home/play_store_download_white_bg.svg';

const RELATIVE_PATH_TO_BACKGROUND_IMAGE = 'images/download_app_background_image_2.png';
const RELATIVE_PATH_TO_PHONE_APP_PAIRED_IMAGE_ENGLISH = 'images/download_app_phone_image_english.png';
const RELATIVE_PATH_TO_PHONE_APP_PAIRED_IMAGE_ARABIC = 'images/download_app_phone_image_arabic.png';
const RELATIVE_PATH_TO_SECTION_DESCRIPTION_IMAGE = 'images/download_app_description.png';
const RELATIVE_PATH_TO_LADY_HOLDING_PLANT_IMAGE = 'images/download_app_generic_image_2.png';
const RELATIVE_PATH_TO_BACKGROUND_IMAGE_FLIPPED = 'images/download_app_background_image_flipped_2.png';

const SCREEN_SIZE_BIG = 1100;
const SCREEN_SIZE_MEDIUM = 768;
const SCREEN_SIZE_SMALL = 500;

export const DownloadAppContainer = styled.div`
    width: 100%;
    height: 1000px;
    display: flex;
    margin: 2% 0%;
    /* direction: ltr; */
    position: relative;
    flex-direction: row;
    align-items: center;
    transition: all 0.5s;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${COLORS.CREAM};
    
    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        font-size: 85%;
        height: 700px;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        flex-direction: column;
        font-size: 70%;
    }
`;


export const BackgroundImage_1 = styled.div`
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_BACKGROUND_IMAGE}) no-repeat top right transparent;
    background-size: 50%;

    @media screen and (max-width: 1200px) {
        background-size: 75%;
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const BackgroundImage_1_Flipped = styled.div`
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    position: absolute;
    background-size: contain;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_BACKGROUND_IMAGE_FLIPPED}) no-repeat top right transparent;
    background-size: 50%;

    @media screen and (max-width: 1200px) {
        background-size: 75%;
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const BackgroundImage_2 = styled.div`
    left: 0%;
    z-index: 10;
    height: 100%;
    display: flex;
    min-width: 100%;
    max-width: 100%;
    position: absolute;
    box-sizing: border-box;
    transform: rotate(180deg);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_BACKGROUND_IMAGE}) no-repeat top right;
    background-size: 50%;

    @media screen and (max-width: 1200px) {
        background-size: 75%;
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const BackgroundImage_2_Flipped = styled.div`
    left: 0%;
    z-index: 10;
    height: 100%;
    display: flex;
    min-width: 100%;
    max-width: 100%;
    position: absolute;
    box-sizing: border-box;
    transform: rotate(180deg);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_BACKGROUND_IMAGE_FLIPPED}) no-repeat top right;
    background-size: 75%;

    @media screen and (max-width: 1200px) {
        opacity: 0.5;
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
`;

export const DownloadAppInformationContainer = styled.div`
    width: 84%;
    height: 80%;
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${COLORS.CREAM};

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        flex-direction: column;
    }

`
export const PhoneAppImage = styled.div`
    width: 35%;
    z-index: 12;
    display: block;
    position: absolute;
    aspect-ratio: 0.82;
    align-self: center;
    transition: all 0.5s;
    overflow: visible;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_PHONE_APP_PAIRED_IMAGE_ENGLISH}) no-repeat center;
    background-size: contain;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        display: none;
    }
`;

export const PhoneAppImage_Flipped = styled.div`
    width: 35%;
    z-index: 12;
    display: block;
    position: absolute;
    aspect-ratio: 0.82;
    align-self: center;
    transition: all 0.5s;
    overflow: visible;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_PHONE_APP_PAIRED_IMAGE_ARABIC}) no-repeat center;
    background-size: contain;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        display: none;
    }
`;

export const SectionDescriptionWrapper = styled.div`
    width: 130%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        width: 100%;
        height: auto;
        align-items: center;
    }
`;

export const PictureContainer = styled.div`
    width: 55%;
    aspect-ratio: 1.2;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 10px 0;

    position: absolute;
    transition: all 0.5s;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_LADY_HOLDING_PLANT_IMAGE});
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        width: 50%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        position: relative;
        width: 100%;
        aspect-ratio: 1.2;
        margin-bottom: 10px;
        background-size: cover;
    }
`

export const SectionDescription = styled.div`
    right: 0;
    bottom: 0;
    width: 53%;
    height: 65%;
    z-index: 11;
    padding: 10px 0px;

    position: absolute;
    transition: all 0.5s;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_SECTION_DESCRIPTION_IMAGE}) repeat center;
    background-size: 100% 100%;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        width: 50%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        position: relative;
        width: 100%;
        aspect-ratio: 0.86;
    }
`;

export const SectionDescriptionRelativeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        align-items: center;
        justify-content: center;
    }
`

export const SectionDescriptionContainer = styled.div`
    display: flex;
    width: 60%;
    height: 70%;
    top: 15%;
    left: 35%;
    right: 35%;
    margin: 2% 0%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        width: 85%;
        height: 90%;
        top: auto;
        left: auto;
        right: auto;
        position: relative;
    }
`;

export const SectionDescriptionHeading = styled.h1`
    color: #fff;
    margin: 0;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        text-align: center;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 300%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 200%;
    }
`;

export const SectionDescriptionText = styled.h2`
    color: #fff;
    margin: 0;
    text-align: left;
    font-weight: normal;
    padding: 0% 10% 20px 0%;


    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        font-size: 125%;
        text-align: center;
        padding: 0% 0% 10px 0%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 150%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 120%;
    }
`;

export const DownloadAppButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 5% 0;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        justify-content: center;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        flex-direction: column;
    }
`;

export const AppStoreDownloadButtonContainer = styled.a.attrs({
    href: "https://apps.apple.com/qa/app/greenit/id1586204009",
    target: "_blank"
})`
    width: 40%;
    margin-right: 25px;
    transition: all .5s ease;
    &:hover {
        opacity: .85;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        width: 45%;
        margin-right: 25px;
        margin-bottom: 0px;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        width: 60%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
`
export const PlayStoreDownloadButtonContainer = styled.a.attrs({
    href: "https://play.google.com/store/apps/details?id=com.greenitapp",
    target: "_blank"
})`
    transition: all 0.5s ease;
    width: 40%;
    &:hover {
        opacity: .85;
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        width: 45%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        width: 60%;
    }
`

export const AppStoreDownloadButton = styled.img.attrs({
    src: AppStoreImage
})`
    width: 100%;
    object-fit: contain;
`;

export const PlayStoreDownloadButton = styled.img.attrs({
    src: PlayStoreImage,
})`
    width: 100%;
    object-fit: contain;
`;