import { API_CATEGORY_TYPE } from '@api/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CATEGORY_STATE = API_CATEGORY_TYPE[];

export const categorySlice = createSlice({
    name: 'category',
    initialState: [] as CATEGORY_STATE,
    reducers: {
        setCategories: (state, { payload }: PayloadAction<API_CATEGORY_TYPE[]>) => {
            state = payload;
        },
        updateCategory: (state, { payload }: PayloadAction<API_CATEGORY_TYPE>) => {
            const idx = state.findIndex(cat => cat.slug === payload.slug);
            state[idx] = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCategories, updateCategory } = categorySlice.actions;

export default categorySlice.reducer;
