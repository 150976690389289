import React from 'react'


export enum APPLE_PAY_BUTTON_TYPES {
    TIP = 'tip',
    BUY = 'buy',
    BOOK = 'book',
    RENT = 'rent',
    PLAIN = 'plain',
    ORDER = 'order',
    SETUP = 'set-up',
    TOPUP = 'top-up',
    RELOAD = 'reload',
    DONATE = 'donate',
    SUPPORT = 'support',
    CONTINUE = 'continue',
    ADDMONEY = 'add-money',
    CHECKOUT = 'check-out',
    SUBSCRIBE = 'subscribe',
    CONTRIBUTE = 'contribute',
}


interface ButtonProps {
    type: APPLE_PAY_BUTTON_TYPES;
    locale: string;
    buttonstyle: string;

    // Optional 
    borderRadius?: number;
}

// Button Naturally fits container dimensions
// Need to specify border_radius and button meta information


const ApplePayButton = (props: ButtonProps) => {
    const { borderRadius, ...styleProps } = { borderRadius: 10, ...props }
    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: `\napple-pay-button {\n  margin-top: 10px;\n  padding: 12px 0px;\n  --apple-pay-button-width: 100%;\n  --apple-pay-button-height: 100%;\n  --apple-pay-button-border-radius: ${borderRadius}px;\n  --apple-pay-button-padding: 5px 0px;\n}\n` }} />                {/* @ts-ignore */}
            <apple-pay-button {...{ styleProps, type: 'check-out' }} />
        </>
    )
}

export default ApplePayButton