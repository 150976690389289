import styled from 'styled-components';
import { BASE_TRANSITION_TIME } from '@project/styles';

export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 3% 2% 5% 2%;
    flex-direction: column;
    transition: all ${BASE_TRANSITION_TIME / 2}s;
    @media screen and (max-width: 1000px) {
        padding: 0% 0%;
    }
`;

export const VideoTitle = styled.h2`
    display: none;
    margin: 10px;
    @media screen and (max-width: 800px) {
        display: block;
    }
`;


export const VideoContainer = styled.div`
    width: 100%;
    aspect-ratio: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    transition: all ${BASE_TRANSITION_TIME / 2}s;
    @media screen and (max-width: 1000px) {
        align-items: center;
        width: 100%;
    }
    @media screen and (max-width: 800px) {
        padding: 10px 0 0 0;
    }
`;
export const YoutubeVideo = styled.iframe`
    width: 100%;
    height: 100%;
    padding: 2% 0;
    border-radius: 5px;
    transition: all ${BASE_TRANSITION_TIME / 2}s;
    @media screen and (max-width: 1000px) {
        width: 90%;
    }
    @media screen and (max-width: 800px) {
        width: 90%;
        height: 300px;
        padding: 20px 0;
        margin-bottom: 30px;
    }
`;

export const TitleAndDescriptionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all ${BASE_TRANSITION_TIME / 2}s;
    @media screen and (max-width: 1000px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;
export const DetailsItem = styled.div`
    display: flex;
    flex-direction: row;
`;
export const Title = styled.h2``;
export const DetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 800px) {
        justify-content: center;
    }
`;
export const DetailsIcon = styled.img`
    width: 50px;
    height: 50px;
    @media screen and (max-width: 800px) {
        margin-left: 20px;
    }
`;
export const DetailsTextWrapper = styled.div`
    margin: 0 30px;
    @media screen and (max-width: 800px) {
    }
`;
export const DetailsText = styled.h3`
    font-weight: normal;
`;
