import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_ORDER_SCHEDULE_TYPE, API_REPOTTING_CONFIGURATION_TYPE } from '@api/types';


interface PAYMENT_IDENTIFIER_TYPE {
    [order_id: string]: string
}

interface ADD_PAYMENT_IDENTIFIER_TYPE {
    order_id: string;
    payment_process_identifier: string;
}

interface CHECKOUT_STATE {
    payment_identifiers: PAYMENT_IDENTIFIER_TYPE
    delivery_availability: API_ORDER_SCHEDULE_TYPE | null;
    repotting_configuration: API_REPOTTING_CONFIGURATION_TYPE | null;
}

const initialRepottingConfiguration: API_REPOTTING_CONFIGURATION_TYPE = {
    repotting_cost_strategy: 'flat_fee_repotting',
    first_repotting_cost: 5,
    second_repotting_cost: 10,
    flat_fee_repotting_cost: 10,
    each_repotting_cost: 5,
}

const initialDeliveryAvailability: API_ORDER_SCHEDULE_TYPE = {
    possible_timing_windows: [
        { start_time: '12:00', end_time: '15:00' },
        { start_time: '15:00', end_time: '19:00' }
    ],
    delivery_options: {
        single_order: {
            cart_items: [],
            timings: [],
        }
    }
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        repotting_configuration: null,
        delivery_availability: null,
        payment_identifiers: {},
    } as CHECKOUT_STATE,
    reducers: {
        syncRepotConfigWithDB: (state, { payload }: PayloadAction<API_REPOTTING_CONFIGURATION_TYPE>) => {
            state.repotting_configuration = payload;
        },
        syncScheduleWithDB: (state, { payload }: PayloadAction<API_ORDER_SCHEDULE_TYPE>) => {
            state.delivery_availability = payload;
        },
        addPaymentProcessIdentifier: (state, { payload: { order_id, payment_process_identifier } }: PayloadAction<ADD_PAYMENT_IDENTIFIER_TYPE>) => {
            state.payment_identifiers = {
                ...state.payment_identifiers,
                [order_id]: payment_process_identifier
            }
        },
        clearCheckout: (state) => {
            Object.keys(state.payment_identifiers).forEach(key => {
                delete state.payment_identifiers[key];
            })
            state.payment_identifiers = {};
            state.delivery_availability = initialDeliveryAvailability;
            state.repotting_configuration = initialRepottingConfiguration;
        }
    },
});

export const { clearCheckout, syncScheduleWithDB,
    syncRepotConfigWithDB, addPaymentProcessIdentifier } = checkoutSlice.actions;

export default checkoutSlice.reducer;
