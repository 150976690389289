import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';
import styled from 'styled-components';

export const ProductDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 0% 5% 5% 5%;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const ProductMainContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    min-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`

export const HighlightedItemsWrapper = styled.div`
    width: 100%;
`;

export const BundleSectionTitleText = styled.h2``;

export const NoProductDetails = styled.div`
    display: flex;
    margin: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: ${COLORS.DARKER_GREY};
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`