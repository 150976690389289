import React from 'react';

// IMPORT STYLES
import styled from 'styled-components';
import { COLORS } from '@project/styles';

const Button = styled.button`
    height: auto;
    padding: 10px 20px;
    font-size: large;
    border-radius: 5px;
    border-width: 0.5px;
    font-weight: bolder;
    color:${COLORS.WHITE};
    cursor: pointer;
    background-color: ${COLORS.DARK_GREEN};
`;

const GreenButton = ({ text }: { text: string }) => {
    return <Button>{text}</Button>;
};

export default GreenButton;
