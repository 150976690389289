import React, { useEffect, useState } from 'react';


// IMPORT COMPONENTS
import {
    SectionTitle,
    SectionSubTitleEdit,
    SectionSubTitleRemove,
    GiftCardIconContainer,
    GiftCardOptionContainer,
} from './AdditionalElements';

import { Dialog } from '@mui/material';
import { HiGift } from 'react-icons/hi';
import { GiftCard } from '@custom_components';

import { useTranslation } from 'react-i18next';
import { useIsScreenBiggerThan } from '@project/hooks';
import { APP_STATE_CUSTOM_CARD_TYPE, CUSTOM_CARD_TYPE } from '@api/types';

interface GiftCardOptionProps {
    customCard?: CUSTOM_CARD_TYPE;
    removeGiftCardOption: () => void;
    setGiftCardOption: (customMessage: APP_STATE_CUSTOM_CARD_TYPE) => void;
}

const GiftCardOption = ({ customCard, removeGiftCardOption, setGiftCardOption }: GiftCardOptionProps) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const _768 = useIsScreenBiggerThan(768);
    const _500 = useIsScreenBiggerThan(500);
    const _400 = useIsScreenBiggerThan(400);

    const openAlert = () => setDialogOpen(true);
    const closeAlert = () => setDialogOpen(false);


    const handleGiftCardAdd = (customMessage?: APP_STATE_CUSTOM_CARD_TYPE) => {
        closeAlert();
        customMessage && setGiftCardOption(customMessage);
    }

    return (
        <GiftCardOptionContainer>
            {
                customCard ?
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            <SectionTitle onClick={openAlert}>
                                {t('gift.giftCardAddded')}
                            </SectionTitle>
                            <GiftCardIconContainer>
                                <HiGift {...{
                                    style: {
                                        fontSize: '2rem',
                                        fill: 'palevioletred'
                                    }
                                }} />
                            </GiftCardIconContainer>
                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                        }}>
                            <SectionSubTitleEdit {...{ onClick: openAlert }}>
                                {_768 ? t('gift.edit1') : (_500 ? t('gift.edit2') : (_400 ? t('gift.edit3') : t('gift.edit4')))}
                            </SectionSubTitleEdit>
                            <SectionSubTitleRemove {...{ onClick: removeGiftCardOption }}>
                                {_768 ? t('gift.remove1') : (_500 ? t('gift.remove2') : (_400 ? t('gift.remove3') : t('gift.remove4')))}
                            </SectionSubTitleRemove>
                        </div>
                    </div> :
                    <div onClick={openAlert} style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <SectionTitle>
                            {t('gift.addGiftCard')}
                        </SectionTitle>
                        <GiftCardIconContainer>
                            <HiGift {...{
                                style: {
                                    fontSize: '2rem',
                                    fill: 'gray'
                                }
                            }} />
                        </GiftCardIconContainer>
                    </div>
            }
            <Dialog {...{
                open: dialogOpen,
                onClose: closeAlert,
                style: { padding: '10px' },
            }}>
                <GiftCard {...{
                    name: 'order',
                    onCancel: closeAlert,
                    preExistingCard: customCard,
                    onSuccess: handleGiftCardAdd,
                    buttonTitle: t('gift.addYourCardButton'),
                }} />
            </Dialog>
        </GiftCardOptionContainer>
    )
}

export default GiftCardOption;