import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
    DIBSY_PAYMENT_OPTION,
    DIBSY_SAVED_PAYMENT_OPTION_TYPE,
    DIBSY_ADDED_PAYMENT_OPTION_TYPE,
    DIBSY_DEFAULT_PAYMENT_OPTION_TYPE,
    DIBSY_PRIORITY_PAYMENT_OPTION_TYPE,
} from '@api/user/cards/types';
import { API_SAVED_CARDS_TYPE } from '@api/user/types';


import {

    OptionRow,
    OptionActions,
    OptionInformation,

    SectionTitle,
    SectionSubInformation,

    PaymentIcon,
    PaymentLabel,
    PaymentOptions,
    PaymentDetailsContainer,
    OptionAdditionalInfo,
    CardExpiryTitle,
    CardExpiry,
    OptionActionIcon,
} from './AdditionalElements';
import { BiEditAlt } from 'react-icons/bi';
import Checkbox from '@mui/material/Checkbox';
import { MdDeleteOutline } from 'react-icons/md';
import AddACreditCard from './AddCreditCardSection';



// IMPORT STORE HELPERS
import {
    getPaymentTitle,
    renderPaymentIcon,
    getActivePaymentOption,
    generateDefaultPayments,
    generateAlphaNumericId,
    setApplePayAsActiveIfNecessary,
} from '@api/checkout/helpers';


// IMPORT HELPERS
import { useImmer } from 'use-immer';
import { COLORS } from '@project/styles';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';


interface PaymentDetailsProps {
    disableCashOrders: boolean;
    setActivePayment: (payment?: DIBSY_PAYMENT_OPTION) => void;
}

interface PAYMENT_STATE {
    added_cards: string[];
    selected_payment_ids: string[];
    disabled_payment_ids: (string | number)[];
    added_payments: DIBSY_ADDED_PAYMENT_OPTION_TYPE[];
    saved_payments: DIBSY_SAVED_PAYMENT_OPTION_TYPE[];
    edit_payment_state?: DIBSY_ADDED_PAYMENT_OPTION_TYPE;
    default_payments: DIBSY_DEFAULT_PAYMENT_OPTION_TYPE[];
    priority_payments: DIBSY_PRIORITY_PAYMENT_OPTION_TYPE[];
}

const PaymentDetails = ({ disableCashOrders, setActivePayment }: PaymentDetailsProps) => {
    const { t } = useTranslation();

    const cards: API_SAVED_CARDS_TYPE[] = useSelector((state: RootState) => state.user.cards)

    const [state, setState] = useImmer<PAYMENT_STATE>({
        added_cards: [],
        added_payments: [],
        disabled_payment_ids: [],
        saved_payments: cards.sort((
            { is_primary_card: pa },
            { is_primary_card: pb }
        ) => - (Number(pa) - Number(pb))).map((card) => ({
            ...card,
            payment_id: card.id,
            payment_type: 'saved',
        })),
        selected_payment_ids: [],
        ...generateDefaultPayments(),
    })


    const all_payments: DIBSY_PAYMENT_OPTION[] = useMemo(() => ([
        ...state.priority_payments,
        ...state.saved_payments,
        ...state.added_payments,
        ...state.default_payments
    ]), [state.priority_payments, state.added_payments, state.saved_payments])

    const [active, setActive] = useState<string>('');
    const handleChecked = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        setActive(e.target.checked ? id : '');
    }

    const addCreditCardOption = (
        option: Omit<DIBSY_ADDED_PAYMENT_OPTION_TYPE, 'payment_id'>,
        edit_payment_id: string = ''): void => {
        const payment_id = edit_payment_id || generateAlphaNumericId(
            state.added_payments.map(({ payment_id }) => payment_id),
            1, 4)[0];

        setState(draft => {
            const filtered_payments = draft.added_payments.filter(
                ({ payment_id: defined_id }) => defined_id !== payment_id);

            draft.added_payments = [{ payment_id, ...option }, ...filtered_payments];
        })

        setActive(payment_id);
    }

    const handleClick = (id: string) => {
        setActive(active === id ? '' : id);
    }

    const editCreditCardOption = (option: DIBSY_ADDED_PAYMENT_OPTION_TYPE) => {
        setActive('add_a_credit_card');

        setState(draft => {
            draft.edit_payment_state = option
        })
    }

    const deleteCreditCardOption = (filter_id: string) => {
        setActive('')
        setState(draft => {
            active !== 'add_a_credit_card' && (draft.added_payments = draft.added_payments.filter(
                ({ payment_id }) => payment_id !== filter_id
            ));
        })
    }

    useEffect(() => {
        let cancelled = false;

        if (!cancelled && active !== 'add_a_credit_card') {
            setState(draft => { draft.edit_payment_state = undefined });
            setActivePayment(all_payments.find(({ payment_id }) => payment_id === active))
        }

        if (!cancelled && active === 'add_a_credit_card') {
            setActivePayment(undefined)
        }

        return () => { cancelled = true; }
    }, [active])

    useEffect(() => {
        let cancelled = false;
        setApplePayAsActiveIfNecessary(() => {
            !cancelled && setActive(all_payments.find(option => option.payment_type === 'apple_pay')?.payment_id || getActivePaymentOption(all_payments))
        })

        return () => { cancelled = true }
    }, [])


    return (
        <PaymentDetailsContainer>
            <SectionTitle>{t("checkout.verifyYourPaymentDetails")}</SectionTitle>
            <SectionSubInformation>
                {t("checkout.verifyPaymentSubheader")}
            </SectionSubInformation>
            <PaymentOptions>
                {
                    all_payments.map((payment_option) => {
                        const disabled = payment_option.payment_type === 'cash' && disableCashOrders;
                        const maxLabelWidth = payment_option.payment_type === 'cash'
                            || payment_option.payment_type === 'credit'
                            || payment_option.payment_type === 'debit';
                        return (
                            <Tooltip {...{
                                key: payment_option.payment_id,
                                title: !disabled ? '' : t('checkout.cashGiftOrders'),
                                placement: 'top-end'
                            }} arrow>
                                <OptionRow {...{
                                    key: payment_option.payment_id,
                                    disabled,
                                }}>

                                    <OptionInformation {...{
                                        onClick: () => { !disabled && handleClick(payment_option.payment_id) },
                                        style: { width: maxLabelWidth ? '100%' : '60%' }
                                    }}>
                                        <Checkbox {...{
                                            color: 'success',
                                            sx: { fontWeight: 'bold' },
                                            checked: active === payment_option.payment_id,
                                            onChange: (e) => { !disabled && handleChecked(e, payment_option.payment_id) }
                                        }} />
                                        <PaymentIcon>
                                            {renderPaymentIcon(payment_option)}
                                        </PaymentIcon>
                                        <PaymentLabel>{getPaymentTitle(payment_option)}</PaymentLabel>
                                    </OptionInformation>

                                    {
                                        Boolean(payment_option.payment_type === 'added') &&
                                        <OptionActions>
                                            <OptionActionIcon
                                                onClick={() => editCreditCardOption(payment_option as DIBSY_ADDED_PAYMENT_OPTION_TYPE)}
                                                style={{ border: `solid 1px ${COLORS.ORANGE}` }}>
                                                <BiEditAlt {...{ fontSize: '1.3rem', fill: COLORS.ORANGE }} />
                                            </OptionActionIcon>
                                            <OptionActionIcon
                                                onClick={() => deleteCreditCardOption(payment_option.payment_id)}
                                                style={{ border: `solid 1px ${COLORS.RED}` }}>
                                                <MdDeleteOutline {...{ fontSize: '1.3rem', fill: COLORS.RED }} />
                                            </OptionActionIcon>
                                        </OptionActions>
                                    }
                                    {
                                        Boolean(payment_option.payment_type === 'saved') &&
                                        <OptionAdditionalInfo>
                                            <CardExpiryTitle>{t('checkout.expiresOn')}</CardExpiryTitle>
                                            <CardExpiry>{(payment_option as DIBSY_SAVED_PAYMENT_OPTION_TYPE).card_expiry}</CardExpiry>
                                        </OptionAdditionalInfo>
                                    }
                                </OptionRow>
                            </Tooltip>
                        )
                    })
                }
                <AddACreditCard {...{
                    active,
                    handleClick,
                    handleChecked,
                    addCreditCardOption,
                    option: {
                        payment_type: 'credit',
                        payment_id: 'add_a_credit_card',
                    },
                    editPaymentInfo: state.edit_payment_state,
                }} />
            </PaymentOptions>
        </PaymentDetailsContainer>
    )
}

export default PaymentDetails;