import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CART_TYPE,
    APP_STATE_PROMO_CODE_INFO,
    APP_STATE_CART_PRODUCT_TYPE,
} from '@api/user/cart/types';
import { PROMO_CODE_RESPONSE } from '@api/user/cart/promo/add';

interface CART_STATE {
    cart_items: APP_STATE_CART_PRODUCT_TYPE[];
    total_quantity: number;
    total_payable: number;
    delivery_cost: number;
    total_cart_price: number;
    delivery_cost_after_discount: number;
    total_payable_after_discount: number;
    total_cart_price_after_discount: number;


    promo_code_info?: APP_STATE_PROMO_CODE_INFO;
    loading: boolean;
}

const updateCartItemQty = (
    cartItems: APP_STATE_CART_PRODUCT_TYPE[],
    cart_item_id: string,
    updatedQuantity: number
) => {
    return cartItems.map((item) => {
        if (item.cart_item_id === cart_item_id) {
            const cartItemCopy = { ...item };
            cartItemCopy.requested_quantity = updatedQuantity;
            return cartItemCopy;
        }
        return item;
    });
};

const updateNumberOfItemsInCart = (cartItems: APP_STATE_CART_PRODUCT_TYPE[]) =>
    cartItems.reduce((prev, cartItem) => prev + cartItem.requested_quantity, 0);

const updateCartTotal = (cartItems: APP_STATE_CART_PRODUCT_TYPE[]) =>
    cartItems.reduce(
        (prev, cartItem) =>
            prev + cartItem.actual_price * cartItem.requested_quantity,
        0
    );

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart_items: [],
        total_payable: 0,
        delivery_cost: 0,
        total_quantity: 0,
        total_cart_price: 0,
        delivery_cost_after_discount: 0,
        total_payable_after_discount: 0,
        total_cart_price_after_discount: 0,
        promo_code_info: undefined,
        loading: false,
    } as CART_STATE,
    reducers: {
        syncCartWithDB: (state, { payload }: PayloadAction<CART_TYPE>) => {
            state.cart_items = payload.cart_items;
            state.promo_code_info = payload.promo_code;
            state.total_cart_price = payload.total_cart_price;
            state.delivery_cost = payload.delivery_cost_for_single_order;
            state.total_quantity = updateNumberOfItemsInCart(payload.cart_items);
            state.total_cart_price_after_discount = payload.total_cart_price_after_discount;
            state.total_payable_after_discount = payload.total_payable_due_for_single_order;
            state.total_payable = payload.total_cart_price + payload.delivery_cost_for_single_order;
            state.delivery_cost_after_discount = payload.delivery_cost_for_single_order_after_discount;
        },

        updateCartOnPromoCodeAdd: (state, { payload: { promoInfo, newPrices } }: PayloadAction<PROMO_CODE_RESPONSE>) => {
            state.promo_code_info = promoInfo;
            state.total_cart_price = newPrices.total_cart_price as number;
            state.delivery_cost = newPrices.delivery_cost_for_single_order as number;
            state.total_cart_price_after_discount = newPrices.total_cart_price_after_discount as number;
            state.delivery_cost_after_discount = newPrices.delivery_cost_for_single_order_after_discount as number;

        },
        updateCartOnPromoCodeRemove: (state, { payload: { newPrices } }: PayloadAction<Pick<PROMO_CODE_RESPONSE, 'newPrices'>>) => {
            state.promo_code_info = undefined;
            state.total_cart_price = newPrices.total_cart_price as number;
            state.delivery_cost = newPrices.delivery_cost_for_single_order as number;
            state.total_cart_price_after_discount = newPrices.total_cart_price_after_discount as number;
            state.delivery_cost_after_discount = newPrices.delivery_cost_for_single_order_after_discount as number;

        },
        setLoadingCart: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        clearCart: (state) => {
            state.cart_items = [];
            state.total_payable = 0;
            state.delivery_cost = 0;
            state.total_quantity = 0;
            state.total_cart_price = 0;
            state.delivery_cost_after_discount = 0;
            state.total_payable_after_discount = 0;
            state.total_cart_price_after_discount = 0;
            state.promo_code_info = undefined;
            state.loading = false;
        }
    },
});

export const {
    clearCart,
    syncCartWithDB,
    setLoadingCart,
    updateCartOnPromoCodeAdd,
    updateCartOnPromoCodeRemove,
} = cartSlice.actions;

export default cartSlice.reducer;
