import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    getPromocodeType,
    FAILURE_RESPONSE,
} from '@api/interactions';


import {
    CART_TYPE,
    API_CART_TYPE,
    APP_STATE_PROMO_CODE_INFO,
} from '@api/user/cart/types';
import { TFunction } from 'i18next';

export const addPromoCodeFetch = async (promo_code: string) => {
    const HttpRequest = new HTTP_REQUEST('user/cart/add-promo-code/', {
        method: HTTP_METHODS.POST,
        options: {
            data: { promo_code },
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Apply Promocode');
    }
};

export type PROMO_CODE_RESPONSE = {
    promoInfo: APP_STATE_PROMO_CODE_INFO;
    newPrices: Partial<CART_TYPE>;
};

const addPromoCode = async (
    promoCode: string, t: TFunction
): Promise<RESPONSE_TYPE<PROMO_CODE_RESPONSE>> => {
    try {
        const response = await addPromoCodeFetch(promoCode);
        let body: API_CART_TYPE = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<PROMO_CODE_RESPONSE>({
                promoInfo: {
                    code: body.promo_code?.code || '',
                    type: getPromocodeType(body.promo_code?.type),
                    minimum_required_amount: body.promo_code?.minimum_required_amount,
                    absolute_discount_amount: body.promo_code?.absolute_discount_amount,
                    percentage_decrease_amount: body.promo_code?.percentage_decrease_amount,
                    combo_absolute_discount_amount: body.promo_code?.combo_absolute_discount_amount,
                    combo_percentage_decrease_amount: body.promo_code?.combo_percentage_decrease_amount,
                },
                newPrices: {
                    total_cart_price: body.total_cart_price,
                    delivery_cost_for_single_order: body.delivery_cost_for_single_order,
                    total_cart_price_after_discount: body.total_cart_price_after_discount,
                    total_payable_due_for_single_order: body.total_payable_due_for_single_order,
                    delivery_cost_for_single_order_after_discount: body.delivery_cost_for_single_order_after_discount,
                },
            });
        } else {
            const error_body: any = body;
            const error_body_alert =
                (error_body.show_error[0] || '') === 'True'
                    ? error_body.display_error_msg[0] || t('checkout.promoCodeIsInvalid')
                    : t('checkout.promoCodeIsInvalid');
            return FAILURE_RESPONSE('PROMO_CODE', error_body_alert);
        }
    } catch (error) {
        return FAILURE_RESPONSE('PROMO_CODE', t("checkout.promoCodeIsInvalid"));
    }
};

export default addPromoCode;
