import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions'

import { getAuthToken } from '@project/helpers';
import { deleteTokenCookiePair } from '@redux/state/cookie';
import { storeDispatch } from '@redux/store';
import Cookies from 'js-cookie';

export const signOutFetch = async () => {
    const HttpRequest = new HTTP_REQUEST('auth/logout/', {
        method: HTTP_METHODS.POST,
        options: {}
    })

    const Response = await HttpRequest.callFetch()
    return Response
}

type CUSTOM_RESPONSE = null;
const api_type = 'SIGN_OUT';

const signOut = async (): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await signOutFetch()

        if (response.ok) {
            storeDispatch(deleteTokenCookiePair('auth_token'));
            Cookies.remove('authtoken');
            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>(null)
        }
        return FAILURE_RESPONSE(api_type)
    } catch (err) {
        return FAILURE_RESPONSE(api_type)
    }

}

export default signOut;
