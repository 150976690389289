import { COLORS } from '@project/styles';
import styled from 'styled-components';


export const QuickAddContainer = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;
`

export const QuickAddInfoContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0px 0 90px 0;
    overflow-y: auto;
    ::-webkit-scrollbar { display: none };
    padding: 5px 20px 30px 20px;
`

export const QuickAddTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 20px 0;
    justify-content: space-between;
`

export const QuickAddTitles = styled.div`
    display: flex;
    width: auto;
    height: 100%;
    padding-right: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`


export const QuickAddTitle = styled.div`
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0;
`

export const QuickAddSubtitle = styled.div`
    opacity: 1;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: bold;
    color: ${COLORS.DARK_GREY};
`

export const QuickAddDescriptionContainer = styled.div<{ readMore: boolean }>` 
    width: 100%;
    font-size: 18px;
    position: relative;
    text-align: justify;
    margin-bottom: 10px;
    // border: solid 2px ${COLORS.DARK_RED};
    height: auto;
    padding: ${({ readMore }) => readMore ? '0px 0px 26px 0px' : '0px 0px 6px 0px'};
`

export const QuickAddReadMoreButton = styled.div`
    color: ${COLORS.GREEN};
    font-weight: bold;
    font-size: 18px;
    padding: 2px 5px;
    position: absolute;
    bottom: 4px;
    right: -3px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-left: solid 1px green;
    border-top: solid 1px green;
    background-color: rgba(255, 255, 255, 0.95);
`

export const CarouselContainer = styled.div`
    display: flex;
    width: 96%;
    max-height: 45vh;
    min-height: 45vh;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 2%;
`