// IMPORT STYLED COMPONENTS
import {
    Title,
    Button,

    Box,
    MapBox,
    NickName,
    TitleContiainer,

    AddressWrapper,
    ButtonsWrapper,
    AddressDetailText,

    CardWrapper,
    LogoAndTitle,
    CardDetailsSection,
} from './SavedAddressesElements';

// IMPORT COMPONENTS
import { Map } from '@custom_components';

// IMPORT HELPERS
import { useDispatch } from 'react-redux';
import deleteUserAddress from '@api/user/address/delete';
import { deleteUserAddressState } from '@redux/state/user';

// IMPORT TYPES
import { API_ADDRESS_TYPE } from '@api/user/address/types';
import { COLORS } from '@project/styles';

const AddressCard = ({
    address,
    showForm,
    disableButtons,
}: {
    address: API_ADDRESS_TYPE;
    disableButtons?: boolean;
    showForm?: (isOpen: boolean, editDetails?: API_ADDRESS_TYPE) => void;
}) => {
    const dispatch = useDispatch();
    const deleteAddress = async (id: string) => {
        const response = await deleteUserAddress(id);
        if (response.status) {
            dispatch(deleteUserAddressState(id));
        }
    };

    // ADDRESS FORMATTING
    const shrinkText = (text?: string) => {
        const currentText = text || '';
        const shrinkedText = currentText.substring(0, 100);
        return !(currentText.length - shrinkedText.length)
            ? text
            : shrinkedText + '...';
    };

    const { latitude, longitude } = address;
    const GetDetailsOnType = (address: API_ADDRESS_TYPE) => {
        switch (address.address_type) {
            case 'House':
                return `Building No. ` + shrinkText(address.building);
            case 'Apartment':
                return (
                    `Apartment No. ` +
                    shrinkText(address.apartment_number) +
                    `,  Floor No. ` +
                    shrinkText(address.floor)
                );
            case 'Compound':
                return (
                    shrinkText(address.compound_name) +
                    ', Building No. ' +
                    shrinkText(address.building)
                );
            case 'Office':
                return (
                    shrinkText(address.office) +
                    `, Building No. ` +
                    shrinkText(address.building) +
                    `,  Floor No. ` +
                    shrinkText(address.floor)
                );
            default:
                return '';
        }
    };

    return (
        <CardWrapper {...{ key: address.address_id, fixed: Boolean(disableButtons) }}>
            <CardDetailsSection>
                <LogoAndTitle>
                    <TitleContiainer>
                        <Title>
                            {address.address_type}, {address.area}
                        </Title>
                        <NickName>
                            {address.address_nickname ? `${address.address_nickname}, ` : ''}<span>
                                {address.street_name ? `${address.street_name}` : `Street No. ${address.street_number}`}
                            </span>
                        </NickName>
                    </TitleContiainer>

                    <MapBox>
                        <Map {...{
                            location: { latitude, longitude },
                            dimensions: { width: '90%', height: '90%' },
                        }} />
                        <Box />
                    </MapBox>
                </LogoAndTitle>
                <AddressWrapper>
                    <AddressDetailText>
                        {`Mobile No. ${address.mobile_number}`}
                    </AddressDetailText>
                    <AddressDetailText>
                        {GetDetailsOnType(address)}
                    </AddressDetailText>
                    {
                        Boolean(address.additional_directions) && <AddressDetailText>
                            {shrinkText(address.additional_directions)}
                        </AddressDetailText>
                    }
                </AddressWrapper>
                {
                    Boolean(!disableButtons) &&
                    <ButtonsWrapper>
                        <Button
                            {...{
                                color: COLORS.RED,
                                onClick: () => deleteAddress(address.address_id),
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            {...{
                                color: COLORS.ORANGE,
                                onClick: () => {
                                    showForm ? showForm(true, address) : {}
                                },
                            }}
                        >
                            Edit
                        </Button>
                    </ButtonsWrapper>
                }
            </CardDetailsSection>
        </CardWrapper >
    );
};
export default AddressCard;
