// IMPORT TYPES
import {
    OPTION_SKU_TYPE,
    API_PRODUCT_TYPE,
    API_PRODUCT_IMAGE_TYPE,
    APP_STATE_PRODUCT_VARIANT_TYPE,

    OPTION_TYPE,
    ORDER_STATUS,
    OrderStatusType,
    API_ORDER_DETAIL_TYPE,
    API_ORDER_PRODUCT_TYPE,
    REPOTTED_PRODUCTS_TYPE,
    APP_STATE_ORDER_DETAIL_TYPE,
    API_REPOT_NORMAL_PRODUCT_SPLIT_TYPE,
    PRODUCT_TYPE,
} from "@api/types";
import {
    API_POT_INFORMATION_KEYS,
    API_PRODUCT_VARIANT_TYPE,
    API_SOIL_INFORMATION_KEYS,
    API_TOOL_INFORAMTION_KEYS,
    API_VARIANT_HIERARCHY_KEY,
    API_PLANT_INFORMATION_KEYS,
    API_FLOWER_BOUQUET_INFORMATION_KEYS,
} from "@api/types/variant";
import { APP_STATE_CART_PRODUCT_TYPE, LISTED_CART_PRODUCT_TYPE } from "@api/user/cart/types";

// IMPORT HELPERS
import {
    getGuestData,
    getAuthenticatedData,
    handleServerUnauthorized,
} from "@api/helpers";

import _ from 'underscore';
import { getVariantImage } from "@api/user/cart/helpers";
import { store, RootState, storeDispatch } from "@redux/store";


// IMPORT STYLES
import { COLORS } from "./styles";

// IMPORT HELPERS
import Cookies from 'js-cookie';
import getWebDeviceId from "@api/auth/web";
import { setToken } from "@redux/state/settings";
import {
    cookieExpiring,
    COOKIE_STATE,
    getCookieValue,
    getRefreshDateFromDaysDelta,
    refreshCookieExpiry,
    setTokenCookiePair,
} from "@redux/state/cookie";



export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const getFirstVariant = (product: API_PRODUCT_TYPE) => (
    product.variants.find(({ quantity }) => quantity > 0)?.sku || product.variants[0].sku
)

export const getFirstVariantInfo = (product: API_PRODUCT_TYPE) => (
    product.variants.find(({ quantity }) => quantity > 0) || product.variants[0]
)

export const isProductAvailable = (product: API_PRODUCT_TYPE) => (
    product.variants.find(variant => variant.quantity > 0) !== undefined
)

export const getProductSubheading = (product: API_PRODUCT_TYPE) => {
    switch (product.product_type) {
        case "Plant":
            return product.scientific_name;
        case "Pot":
        case "Soil":
        case "Tool":
        case "Equipment":
        case "FlowerBouquet":
            return product.subheader;
        default: return null
    }
}

export const getVariantImages = (
    images: API_PRODUCT_IMAGE_TYPE[],
    sku: string
): API_PRODUCT_IMAGE_TYPE[] => {
    return images.filter(
        (elem =>
            elem.product_skus.indexOf(sku) >= 0
            || !elem.product_skus.length));
}

export const cleanKeyName = (key: string) => {
    return key
        .split('_')
        .map(key =>
            key[0].toUpperCase()
            + key.slice(1).toLowerCase())
        .join(' ')
}

export const getVariant = (
    product: API_PRODUCT_TYPE,
    variant_sku: string
): APP_STATE_PRODUCT_VARIANT_TYPE | null => {
    // INVALID PRODUCT SKU FOR THIS PRODUCT
    let variantInfo = product.variants.find(
        variant => variant.sku === variant_sku
    );
    if (!variantInfo) return null;

    return {
        ...variantInfo,
        product_images: getVariantImages(product.product_images, variant_sku)
    }
}

const getSkuInfo = (
    option_type: string,
    option_value: string,
    variants: API_PRODUCT_VARIANT_TYPE[]
): OPTION_SKU_TYPE[] => {
    return variants
        .filter((variant: any) => variant[option_type] === option_value)
        .map(variant => ({ product_sku: variant.sku, is_sku_available: variant.quantity > 0 }))
}

const getOptionOutOfStockStatus = (
    option_type: string,
    option_value: string,
    variants: API_PRODUCT_VARIANT_TYPE[]): boolean => {
    return variants
        .filter((variant: any) => variant[option_type] === option_value)
        .map(elem => elem.quantity > 0)
        .includes(true)
}

export function getVariantUnitOnType<S>(value: any, type: API_VARIANT_HIERARCHY_KEY, variant?: S) {
    switch (type) {
        case 'pieces':
            return +value !== 1 ? 'Pieces' : 'Piece';
        case 'stick_flowers':
            return +value !== 1 ? 'Sticks' : 'Stick';
        case 'layers':
            return +value !== 1 ? 'Layers' : 'Layer';

        case 'color':
        case 'model':
        case 'finish':
        case 'pot_shape':
        case 'color_code':
        case 'is_hanging':
        case 'pot_dimensions':
            return ''

        case 'width':
        case 'volume':
        case 'length':
        case 'weight':
        case 'height':
        case 'diameter':
            return variant ? variant[`${type}_unit` as keyof S] : '';
    }
}

export function serializeVariantKeyValue<T>(key: string, val: T[keyof T], variant: T): string {
    if (key === 'is_hanging') { return Boolean(val) ? 'Hanging' : 'Not Hanging' }
    return `${val} ${getVariantUnitOnType<T>(val, key as API_VARIANT_HIERARCHY_KEY, variant)}`
}

export const serializeKey = (key: string) => {
    if (key === 'is_hanging') { return 'Hanging' }
    return key.toLocaleLowerCase().split('_').map(elem => elem.charAt(0).toUpperCase() + elem.slice(1)).join(' ')
}


export const getInformationKeysOnType = (type: PRODUCT_TYPE) => {
    switch (type) {
        case 'Plant':
            return API_PLANT_INFORMATION_KEYS;
        case 'Pot':
            return API_POT_INFORMATION_KEYS;
        case 'FlowerBouquet':
            return API_FLOWER_BOUQUET_INFORMATION_KEYS;
        case 'Soil':
            return API_SOIL_INFORMATION_KEYS;
        case 'Tool':
            return API_TOOL_INFORAMTION_KEYS;
        default:
            return [];
    }
}

export function getVariantInformation<R extends API_PRODUCT_VARIANT_TYPE>(variant_sku: string, product: API_PRODUCT_TYPE) {
    let retObject: {
        var_key: string;
        var_val: string;
    }[] = [];
    const variant = product.variants.find(item => item.sku === variant_sku) as R;
    const variant_keys = getInformationKeysOnType(product.product_type) as (keyof R)[]
    variant_keys.forEach((key) => {
        const val = variant[key];
        if (val !== null && val !== undefined) {
            retObject.push({
                var_key: serializeKey(key as string),
                var_val: serializeVariantKeyValue<R>(key as string, val as R[keyof R], variant)
            })
        }
    })
    return retObject
}

export const getFirstDigits = (value: string) => {
    let digitString = '';
    const charsList = value.split('')
    for (let index = 0; index < charsList.length; index++) {
        const character = charsList[index];
        if (Number.isInteger(+character) || ['-', '.', '+'].includes(character)) {
            digitString += character;
            continue;
        } else {
            return digitString;
        }
    }
    return digitString
}

export const optionSortFunction = (option1: OPTION_TYPE, option2: OPTION_TYPE) => {
    const item1DisplayValue = getFirstDigits(option1.option.toString() || '');
    const item2DisplayValue = getFirstDigits(option2.option.toString() || '');

    const item1SortedString = item1DisplayValue || option1.option.toString() || '';
    const item2SortedString = item2DisplayValue || option2.option.toString() || '';


    if (Number.isInteger(+item1SortedString) && Number.isInteger(+item2SortedString)) {
        return +item1SortedString < +item2SortedString
            ? -1
            : (
                +item1SortedString === +item2SortedString
                    ? 0
                    : 1
            )
    } else return item1SortedString < item2SortedString
        ? -1
        : (
            item1SortedString === item2SortedString
                ? 0
                : 1
        )
}

export const getAlterableKeys = (product: API_PRODUCT_TYPE) => {
    switch (product.product_type) {
        case "Plant":
            return _.intersection([
                'height',
                'color',
                'layers',
                'pieces',
                'volume',
                'weight',
                'diameter',
                'is_hanging',
                'stick_flowers'
            ], product.variant_filter_hierarchy)
        case "Pot":
            return _.intersection([
                'color',
                'is_hanging',
                'pot_dimensions'
            ], product.variant_filter_hierarchy);

        case "FlowerBouquet":
            return ['arrangement'];
        case "Tool":
            return ['model'];
        case "Soil":
            return _.intersection([
                'volume',
                'weight'
            ], product.variant_filter_hierarchy);
        default:
            return [];
    }
}

interface KEYVAL_TYPE {
    key: string;
    value: string;
}
export const findSKUForData = (
    data: KEYVAL_TYPE[],
    product: API_PRODUCT_TYPE
) => {
    const correctVariant = (variant: API_PRODUCT_VARIANT_TYPE) => !(
        data.map(
            ({ key, value }) => ((variant as any)[key] === null
                || (variant as any)[key] === undefined
                || (variant as any)[key].toString() === value.toString())
        ).includes(false)
    )

    return product.variants.find(correctVariant) as API_PRODUCT_VARIANT_TYPE;
}


export const getNewSKU = (
    prev_sku: string,
    product: API_PRODUCT_TYPE,
    changed_key: string,
    changed_value: string,
    single_level: boolean = false
): string => {
    if (single_level) {
        return (
            product
                .variants
                .find(
                    variant =>
                        Object.keys(variant)
                            .map(
                                key =>
                                    key === changed_key
                                        ? (variant as any)[key].toString() === changed_value.toString()
                                        : false
                            )
                            .includes(true)
                ) as API_PRODUCT_VARIANT_TYPE
        ).sku
    }

    const prevVariantInfo = product.variants.find(variant => variant.sku === prev_sku) as any;
    const getAlteredVariantData = (key: string) => ({
        key,
        value: key === changed_key
            ? changed_value
            : prevVariantInfo[key]
    })

    const alterableVariantKeys: string[] = getAlterableKeys(product)
    const variantData = alterableVariantKeys.map(getAlteredVariantData)
    return findSKUForData(variantData, product).sku;
}

export const getVariantOptions = (
    product: API_PRODUCT_TYPE,
    type: API_VARIANT_HIERARCHY_KEY,
    getUnits: boolean = false
): OPTION_TYPE[] => {
    let options: OPTION_TYPE[] = [];

    for (let index = 0; index < product.variants.length; index++) {
        const variant_info: any = product.variants[index];
        let optionMetaData = {};
        if (getUnits) {
            optionMetaData = {
                ...optionMetaData,
                option_unit: variant_info[`${type}_unit`]
            }
        }
        if (type === 'color') {
            optionMetaData = {
                ...optionMetaData,
                color_code: variant_info.color_code
            }
        }
        if (type === 'is_hanging') {
            options = [...options, {
                option: variant_info[type] ? 'Hanging' : 'Not Hanging',
                option_sku_info: getSkuInfo(type, variant_info[type], product.variants),
                other_option_data: Object.keys(optionMetaData).length ? optionMetaData : undefined,
                is_available: getOptionOutOfStockStatus(type, variant_info[type], product.variants),
            }]
        }

        else if (variant_info[type] !== null && variant_info !== undefined) {
            options = [...options, {
                option: variant_info[type],
                option_sku_info: getSkuInfo(type, variant_info[type], product.variants),
                other_option_data: Object.keys(optionMetaData).length ? optionMetaData : undefined,
                is_available: getOptionOutOfStockStatus(type, variant_info[type], product.variants),
            }]
        }
    }

    if (!options.length) return [];

    const uniqueOptionList = _.uniq(options, false, (elem) => elem.option);
    const sortedOptionList = uniqueOptionList.sort(optionSortFunction);

    return sortedOptionList;
}


export const testPasswordForWeakness = (password: string) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = password.length;
    const uppercasePassword = uppercaseRegExp.test(password);
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);

    let weakness = '';

    if (passwordLength === 0) {
        weakness = 'Password is empty';
    } else if (!uppercasePassword) {
        weakness = 'At least one Uppercase';
    } else if (!lowercasePassword) {
        weakness = 'At least one Lowercase';
    } else if (!digitsPassword) {
        weakness = 'At least one digit';
    } else if (!specialCharPassword) {
        weakness = 'At least one Special Characters';
    } else if (!minLengthPassword) {
        weakness = 'At least minumum 8 characters';
    } else {
        weakness = '';
    }
    return weakness;
};


export const transformDataToResponsive = (
    data: any[],
    maxLength: number,
    isBig: boolean = false,
) => {
    const newArr = [];
    let currIdx = 0;
    while (currIdx < data.length) {
        if (currIdx + 3 < data.length && !isBig && 3 < maxLength) {
            let items = [
                data[currIdx],
                data[currIdx + 1],
                data[currIdx + 2],
                data[currIdx + 3],
            ];
            currIdx += 4;
            newArr.push(items);
        } else if (currIdx + 2 < data.length && !isBig && 2 < maxLength) {
            let items = [data[currIdx], data[currIdx + 1], data[currIdx + 2]];
            currIdx += 3;
            newArr.push(items);
        } else if (currIdx + 1 < data.length && !isBig && 1 < maxLength) {
            let items = [data[currIdx], data[currIdx + 1]];
            currIdx += 2;
            newArr.push(items);
        } else {
            let items = [data[currIdx]];
            currIdx += 1;
            newArr.push(items);
        }
    }
    return newArr;
};

export const isCancelledOrder = (status: OrderStatusType) => {
    switch (status) {
        case ORDER_STATUS.OTHER:
        case ORDER_STATUS.FAILED:
        case ORDER_STATUS.UNKNOWN:
        case ORDER_STATUS.CANCELLED:
        case ORDER_STATUS.COULD_NOT_FULFILL:
            return true;
        default:
            return false;
    }
}

const getOrderStatus = (api_status: string): OrderStatusType => {
    var key: keyof typeof ORDER_STATUS;
    for (key in ORDER_STATUS) {
        var currVal = ORDER_STATUS[key];
        if (api_status === currVal) {
            return currVal;
        }
    }
    return ORDER_STATUS.UNKNOWN;
};


export const getProductSplitInformation = (
    products: API_ORDER_PRODUCT_TYPE[],
    plants_in_pots: REPOTTED_PRODUCTS_TYPE[]
): API_REPOT_NORMAL_PRODUCT_SPLIT_TYPE => {

    if (!plants_in_pots.length) return {
        repottable_products: [],
        normal_products: products,
    };

    let skuQuantityDict: { [k: string]: number } = {};

    let plantPotDuos: {
        pot: API_ORDER_PRODUCT_TYPE;
        plant: API_ORDER_PRODUCT_TYPE;
    }[] = [];

    let normalProducts: API_ORDER_PRODUCT_TYPE[] = [];

    for (let index = 0; index < plants_in_pots.length; index++) {
        const { pot_sku, plant_sku } = plants_in_pots[index];
        const pot = products.find(product => product.id === pot_sku) as API_ORDER_PRODUCT_TYPE;
        const plant = products.find(product => product.id === plant_sku) as API_ORDER_PRODUCT_TYPE;

        if (!skuQuantityDict[plant_sku]) {
            skuQuantityDict[plant_sku] = 1
        } else {
            skuQuantityDict[plant_sku] += 1
        }

        if (!skuQuantityDict[pot_sku]) {
            skuQuantityDict[pot_sku] = 1
        } else {
            skuQuantityDict[pot_sku] += 1
        }

        // Add Plant Pot to Repottable Products
        plantPotDuos.push({ pot, plant })
    }

    // Make Normal Products List
    for (let index = 0; index < products.length; index++) {
        const product = products[index];
        const repotted_quantity = skuQuantityDict[product.id] || null;

        if (repotted_quantity && repotted_quantity < product.quantity) {
            normalProducts.push({
                ...product,
                quantity: product.quantity - repotted_quantity
            })
        } else if (repotted_quantity === null) {
            normalProducts.push(product)
        }
    }

    return {
        repottable_products: plantPotDuos,
        normal_products: normalProducts
    }
}


export const getOrderMainColor = (order: OrderStatusType) => {
    switch (order) {
        case ORDER_STATUS.CANCELLED: return COLORS.ORDER_STATUS.CANCELLED;
        case ORDER_STATUS.DELIVERED: return COLORS.ORDER_STATUS.DELIVERED;
        case ORDER_STATUS.DELIVERING: return COLORS.ORDER_STATUS.DELIVERING;
        case ORDER_STATUS.ACCEPTED: return COLORS.ORDER_STATUS.ACCEPTED;
        case ORDER_STATUS.PENDING: return COLORS.ORDER_STATUS.PENDING;
        case ORDER_STATUS.UNKNOWN: return COLORS.ORDER_STATUS.OTHER;
        case ORDER_STATUS.COULD_NOT_FULFILL: return COLORS.ORDER_STATUS.COULD_NOT_FULFILL;
        case ORDER_STATUS.FAILED: return COLORS.ORDER_STATUS.FAILED;
        default: return COLORS.ORDER_STATUS.OTHER;
    }
}

export const getOrderLighterColor = (order: OrderStatusType) => {
    return colorLightPercentage(getOrderMainColor(order), 15);
}

export const colorLightPercentage =
    (color: string, percentage: number) =>
        Add_Colors(color, '#FFFFFF', percentage)


export const Add_Colors = (col1: string, col2: string, weight?: number) => {
    function d2h(d: number) { return d.toString(16); }
    function h2d(h: string) { return parseInt(h, 16); }
    function h2s(h: string) { return h.substr(1, 6) }

    var color_1 = h2s(col1)
    var color_2 = h2s(col2)
    weight = (typeof (weight) !== 'undefined') ? weight : 50;

    var color = '#';

    for (var i = 0; i <= 5; i += 2) {
        var v1 = h2d(color_1.substr(i, 2)),
            v2 = h2d(color_2.substr(i, 2)),

            val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

        while (val.length < 2) { val = '0' + val; }

        color += val;
    }

    return color;
};


export const mapOrders = (results: API_ORDER_DETAIL_TYPE[]): APP_STATE_ORDER_DETAIL_TYPE[] => {
    return results.map((order) => {
        const mapped_products = order.order_items.map((product) => ({
            price: product.price,
            id: product.product_sku,
            name: product.product.name,
            quantity: product.quantity,
            custom_card: product.custom_card,
            is_review_added: product.is_review_added,
            latin: getProductSubheading(product.product) || '',
            variant_name: product.product.variants.filter(variant =>
                variant.sku === product.product_sku)[0].display_name,
            image: getVariantImage(product.product.product_images, product.product_sku),
            product: product.product
        }));

        const product_information = getProductSplitInformation(mapped_products, order.plants_in_pots);

        return {
            order_id: order.order_id,
            merchant_name: 'Unavailable',
            created_at: order.created_at,
            status: getOrderStatus(order.status),
            failed_time: order.failed_time || '',
            pickup_time: order.pickup_time || '',
            is_gift_delivery: order.is_gift_delivery,
            repotting_cost: order.repotting_cost || 0,
            delivery_address: order.delivery_address,
            accepted_time: order.accepted_time || '',
            is_retryable: order.is_retryable || false,
            custom_card: order.custom_card || undefined,
            delivered_time: order.delivered_time || '',
            delivery_window: order.delivery_schedule_window,
            gift_delivery_address: order.gift_delivery_address,
            normal_products: product_information.normal_products,
            user_additional_notes: order.user_additional_notes || '',
            repotted_products: product_information.repottable_products,
            order_details: order,
        }
    })
}

export const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

export const getWebId = (): string | null => {
    return store.getState().settings.tracking.web_id
}

export const getAuthToken = () => {
    return store.getState().auth.auth_token;
}

export interface AuthCheckerOptions {
    token_override: boolean;
}

export interface AuthCheckerInputFunction<S = any, T = void> {
    (): Promise<T> | T;
    (data: S): Promise<T> | T;
}

export function authChecker<S = any, T = void>(
    fn: AuthCheckerInputFunction<S, T>,
    options?: AuthCheckerOptions,
): (data?: S) => Promise<T | null> {
    return async (data?: S) => {
        const { signed_in } = store.getState().auth;
        if (Boolean(signed_in) || Boolean(options?.token_override)) {
            return await (data ? fn(data) : fn());
        } else {
            // HANDLE 401 AUTH SESSION HERE!
            handleServerUnauthorized();
            return null
        }
    }
}

export function webDeviceIDChecker<T>(
    fn: (id?: string) => Promise<T>,
    _?: string
): (id?: string) => Promise<T | null> {
    return async (id?: string) => {
        const wed_device_id = store.getState().settings.tracking.web_id;

        if (Boolean(wed_device_id)) {
            return await fn(id);
        } else {
            return null;
        }
    }
}

export const configureAuthCookie = authChecker<any, void>(
    async () => {
        Cookies.set('authtoken', getCookieValue('auth_token'), { expires: 2 * 365 })
        refreshCookieExpiry('auth_token')
        return;
    })


export const configureWebDeviceCookie = webDeviceIDChecker<void>(
    async (_) => {
        Cookies.set('webdeviceid', getCookieValue('web_device_id'), { expires: 2 * 365 })
        refreshCookieExpiry('web_device_id')
        return;
    })


export const getAndSetWebDeviceId = async () => {
    const webDeviceIdResponse = await getWebDeviceId();
    if (webDeviceIdResponse.status) {
        const web_device_id = webDeviceIdResponse.response_body.web_device_id;
        const web_device_id_cookie = webDeviceIdResponse.response_body.web_device_id_cookie;

        storeDispatch(
            setToken({
                token_key: 'web_id',
                token_value: web_device_id
            })
        );
        storeDispatch(
            setTokenCookiePair({
                type: 'web_device_id',
                cookie: web_device_id_cookie,
                cookie_refresh_date: getRefreshDateFromDaysDelta(2 * 365)
            })
        )
    }
}

export const setWebDeviceId = async () => {
    !getWebId() && (await getAndSetWebDeviceId());
}

export const appMountFunctions = () => {
    sessionStartCookieFunctions();
    populateStateData();
}


export const populateStateData = async () => {
    await setWebDeviceId();
    if (getAuthToken()) { return getAuthenticatedData(); }
    else { return getGuestData() }
}


export const getExpandedProductList = (
    cart_items: APP_STATE_CART_PRODUCT_TYPE[],
    type: PRODUCT_TYPE,
    size: 'long' | 'short') => {
    let plantList: LISTED_CART_PRODUCT_TYPE[] = [];
    for (let index = 0; index < cart_items.length; index++) {
        const product = cart_items[index];
        if (product.product.product_type === type) {
            for (let quantity_index = 0; quantity_index <
                product.requested_quantity; quantity_index++) {
                const ordinalPrefix = product.requested_quantity > 1
                    ? numberToOrdinal(quantity_index + 1) + ' ' : '';
                const title = ordinalPrefix + ((size === 'short' && product.product.shortened_display_name) || product.product.name);
                plantList = plantList.concat([{
                    product_longform_name: title,
                    product_information: product,
                    random_associated_number: randomInt(0, 3),
                }])
            }
        }
    }
    return plantList;
}

export const numberToOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const randomInt = (min: number, max: number): number => {
    return Math.round(Math.random() * (max - min) + min);
}

export const sessionStartCookieFunctions = () => {
    const cookies: {
        cookie_key: string;
        storage_key: keyof COOKIE_STATE
    }[] = [{
        cookie_key: 'authtoken',
        storage_key: 'auth_token'
    }, {
        cookie_key: 'webdeviceid',
        storage_key: 'web_device_id'
    }]

    if (window.navigator.cookieEnabled) {
        cookies.forEach(({ cookie_key, storage_key }) => {
            if (!Cookies.get(cookie_key)) {
                Cookies.set(cookie_key, getCookieValue(storage_key), { expires: 2 * 365 })
                storeDispatch(refreshCookieExpiry(storage_key));
            } else if (cookieExpiring(storage_key)) {
                Cookies.remove(cookie_key);
                Cookies.set(cookie_key, getCookieValue(storage_key), { expires: 2 * 365 })
                storeDispatch(refreshCookieExpiry(storage_key));
            }
        })
    }
}


export const scrollToTop = () => {
    document.getElementById('greenit_main_container')?.scroll({
        top: 0,
        left: 0,
    })
}