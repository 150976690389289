import { useCallback } from 'react';

// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

// IMPORT COMPONENTS
import ProductCard from '@home_components/Common/ProductCard/ProductCard';
import { Swipeable } from '@custom_components';

// IMPORT HOOKS
import { useIsScreenBiggerThan } from '@project/hooks';

// IMPORT STYLED-COMPONENTS
import {
    ImageSection,

    MainWrapper,
    MainContainer,

    CategoryName,
    CategoryButton,
    CategoryNameWrapper,

    ProductsSectionWrapper,
} from './HighlightedCategoryElements';
import { RouterPages } from '@project/routes';
import { SwiperProps } from 'swiper/react';


interface HighlightedCategoryTypes {
    products: API_PRODUCT_TYPE[];
    categoryName: string;
    categoryButtonText: string;
    invert?: boolean;
    categoryImage: string;
    categorySlug: string;
}

interface RenderItemInfoType {
    item: API_PRODUCT_TYPE
}

const HighlightedCategory = ({
    products,
    categoryName,
    categoryButtonText,
    invert = false,
    categoryImage,
    categorySlug,
}: HighlightedCategoryTypes) => {

    const render_item = ({ item }: RenderItemInfoType) => (<ProductCard {...{ product: item }} />)
    const _500 = useIsScreenBiggerThan(1600);
    const _768 = useIsScreenBiggerThan(768);
    const _1200 = useIsScreenBiggerThan(1600);
    const _1400 = useIsScreenBiggerThan(1200);

    const dismantleGridProps: SwiperProps | undefined = _1400 ? undefined : {
        grid: undefined,
    }
    const spaceBetween = _1400 ? 0 : 20;
    const slidesPerView = _1400 ? 2 : (_1200 ? 4 : (_768 ? 3 : (_500 ? 2 : 1)))
    const slidesPerGroup = slidesPerView;
    return (
        <MainContainer>
            <MainWrapper {...{ invert }}>
                <ImageSection
                    style={{
                        background: `url(${categoryImage}) center no-repeat`,
                        backgroundSize: 'auto 100%',
                    }}
                >
                    <CategoryNameWrapper>
                        <CategoryName>{categoryName}</CategoryName>
                        <CategoryButton
                            page={RouterPages.ListingCategoryPage}
                            pagePathArgs={{ category: categorySlug }}>
                            {categoryButtonText}
                        </CategoryButton>
                    </CategoryNameWrapper>
                </ImageSection>

                <ProductsSectionWrapper>
                    <Swipeable {...{
                        render_item,
                        data: products,
                        slide_key: 'product_id',
                        additional_swiper_props: {
                            spaceBetween,
                            slidesPerView,
                            slidesPerGroup,
                            grid: { rows: 2, fill: 'row' },
                            ...(dismantleGridProps)
                        },
                        container_styles: { height: '100%' }
                    }} />
                </ProductsSectionWrapper>
            </MainWrapper>
        </MainContainer>
    );
};

export default HighlightedCategory;
