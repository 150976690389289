import {
    HTTP_REQUEST,
    HTTP_METHODS,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import { API_SAVED_CARDS_TYPE, DIBSY_SAVED_PAYMENT_OPTION_TYPE } from '../types';

const getSavedCardsFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/saved-credit-cards/', {
        method: HTTP_METHODS.GET,
        options: {
            providedAccessToken, validateCookies: true,
        },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};

const processCardInformation = (
    cards: API_SAVED_CARDS_TYPE[]
): DIBSY_SAVED_PAYMENT_OPTION_TYPE[] => {
    return cards.map((saved_card) => ({
        ...saved_card,
        payment_type: 'saved',
        payment_id: saved_card.id,
    }));
};

const getSavedCreditCards = async (token?: string): Promise<
    RESPONSE_TYPE<DIBSY_SAVED_PAYMENT_OPTION_TYPE[]>
> => {
    try {
        const response = await getSavedCardsFetch(token);
        const body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<DIBSY_SAVED_PAYMENT_OPTION_TYPE[]>(
                processCardInformation(body)
            )
            : FAILURE_RESPONSE('GET_SAVED_CARDS', body);
    } catch (error) {
        return FAILURE_RESPONSE('GET_SAVED_CARDS', error);
    }
};

export default getSavedCreditCards;
