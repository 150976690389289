//IMPORT COMPONENTS
import FAQ from './FAQ/FAQ';
import ProductDetails from './ProductDescription/ProductDescription';

//IMPORT STYLED COMPONENTS
import {
    FAQWrapper,
    MainContainer,
    ProductDetailsWrapper,
} from './ProductDescriptionAndFAQElements';


// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';


interface ProductDetailsFAQType {
    product: API_PRODUCT_TYPE;
}

const ProductDetailsAndFAQ = ({ product }: ProductDetailsFAQType) => (
    <MainContainer>
        <ProductDetailsWrapper>
            <ProductDetails {...{ product }} />
        </ProductDetailsWrapper>
    </MainContainer>
);

export default ProductDetailsAndFAQ;
