import {
    BlackTint,
    ImageBackground,

    ListingTitleText,
    ListingPhraseText,
    ListingTitleContainer,

    NameAndIcon,

    SortingIcon,
    SortingContainer,
    SortingButtonTitle,

    NumberOfProductsText,
    NumberOfProductsAndFilter,
    NumberOfProductsContainer,

} from '@pages/Listing/ListingElements';


import AllProductsBanner from '@assets/images/listings/AllProductsBanner.jpg';


import { API_CATEGORY_TYPE } from '@api/types';
import { useTranslation } from 'react-i18next';

interface TitleProps {
    count: number;
    toggleSideMenu: () => void;
    activeCategory: API_CATEGORY_TYPE;
}

interface ListingTitle_Props {
    image: string | null;
    name: string;
    subheader: string;
}


const ListingTitle = ({ count, toggleSideMenu, activeCategory }: TitleProps) => {
    const { t } = useTranslation();

    const ListingTitle_ = ({ image, name, subheader }: ListingTitle_Props) => {
        return (
            <>
                {Boolean(image) && <ImageBackground {...{ src: image || '' }} />}
                <BlackTint />
                <NameAndIcon>
                    <ListingTitleText>{name}</ListingTitleText>
                    <ListingPhraseText>{subheader}</ListingPhraseText>
                </NameAndIcon>

                <NumberOfProductsAndFilter>
                    <SortingContainer onClick={toggleSideMenu}>
                        <SortingButtonTitle>{t('listings.changeCategory')}</SortingButtonTitle>
                    </SortingContainer>
                    {
                        !Boolean(count) &&
                        <NumberOfProductsContainer>
                            <NumberOfProductsText>
                                {count} Items
                            </NumberOfProductsText>
                        </NumberOfProductsContainer>
                    }

                </NumberOfProductsAndFilter>
            </>
        )
    }

    return (
        <ListingTitleContainer>
            {
                activeCategory && ListingTitle_({ ...activeCategory })
            }
            {
                activeCategory === null && ListingTitle_({
                    image: AllProductsBanner,
                    name: t('listings.allProducts'),
                    subheader: t('listings.allProductsSubtitle'),
                })
            }
        </ListingTitleContainer>
    );
};

export default ListingTitle;
