import React, { useCallback, useEffect, useMemo } from 'react';

// IMPORT TYPES
import { APP_STATE_CART_PRODUCT_TYPE } from '@api/user/cart/types';

// IMPORT API HELPERS
import {
    deleteCartItem,
    updateCartItemQty,
} from '@api/helpers';

// IMPORT COMPONENTS;
import {

    ItemImage,
    ItemName,
    ItemPrice,
    ItemSubName,

    ItemQuantity,
    MainContainer,

    Plus,
    Minus,
    PlusMinusText,
    ContentWrapper,

    ImageContainer,
    ImageAndDescription,
    ItemQuantityContainer,
    ItemDescriptionContainer,

    DiscountText,
    DiscountText2,
    DiscountBadge,
    PreviousText,
} from '@overlays/cart/components/CardItemCardElements';

import {
    AdditionalInformation,
    CategoryInformation,
    NameAndImageContainer,
    ProductImage,
    Quantity,
    Total,
} from '@pages/Cart/CartElements';
import {
    QtyPlus,
    QtyMinus,
    QtyComponent,
    CardContainer,

    ProductName,
    ProductDetail,
    ProductDescription,
} from '@cart_components/CartCardElements';
import { SubCategoryCircles } from '../category';

// IMPORT HOOKS
import { useImmer } from "use-immer";
import { useIsScreenBiggerThan } from '@project/hooks';

// IMPORT ROUTES
import { RouterPages } from '@project/routes';

// IMPORT STORE HELPERS
import { useDispatch } from 'react-redux';
import { notifyMessage, promiseMessage } from '@overlays/notifications';
import { useTranslation } from 'react-i18next';
import { API_PRODUCT_VARIANT_TYPE } from '@api/types/variant';



const updateQtyDelay = 500;

interface CartState {
    item_quantity: number;
    last_reference?: NodeJS.Timeout;
}

const CartCard = ({
    overlay,
    cart_item,
    close_cart_modal,
}: {
    overlay?: boolean;
    product_index: number;
    close_cart_modal?: () => void;
    cart_item: APP_STATE_CART_PRODUCT_TYPE;
}) => {
    const { t } = useTranslation();
    const isScreenBig = useIsScreenBiggerThan(768);
    const {
        product_sku,
        cart_item_id,
        actual_price,
        variant_name,
        variant_image,
        requested_quantity,
        available_quantity,
        price_entered_at,
        product: { shortened_display_name, name, root_category, slug, variants },

    } = cart_item;
    const { additionalName } = (() => {
        switch (cart_item.product.product_type) {
            case 'Plant':
                const { scientific_name } = cart_item.product;
                return {
                    additionalName: scientific_name,
                };

            default:
                return { additionalName: '' };
        }
    })();

    const [state, setState] = useImmer<CartState>({
        item_quantity: requested_quantity
    });


    const { is_in_sale, non_sale_price, price } = useMemo(() => {
        const current_variant = variants.find(variant => variant.sku === product_sku);
        return current_variant || { is_in_sale: false, non_sale_price: 0, price: 0 }
    }, [product_sku, variants])


    const sale_factor = useMemo(() => {
        const current_price = price;
        const previous_price = +(non_sale_price || 0);
        const price_difference = Math.abs(previous_price - current_price);

        return previous_price > 0 ? (price_difference * 100) / previous_price : 0
    }, [price, non_sale_price])

    const updateLastReference = useCallback((new_reference?: NodeJS.Timeout) => {
        setState((draft) => {
            if (draft.last_reference) { clearTimeout(draft.last_reference) }
            draft.last_reference = new_reference
        })
    }, [state.last_reference]);


    const removeItem = async () => {
        state.last_reference && clearTimeout(state.last_reference)
        updateLastReference();

        promiseMessage({
            promise: deleteCartItem(cart_item_id),
            error: `Something went wrong, Please try again!`,
            success: `Removed ${shortened_display_name || name} from Cart!`,
            loading: `Removing ${shortened_display_name || name} from Cart...`,
        })
    };

    const decrementQuantity = () => {
        if (state.item_quantity === 1) {
            removeItem();
            return;
        }
        updateItemQty(state.item_quantity - 1);
        setState((draft) => {
            draft.item_quantity && draft.item_quantity--
        })
    }

    const incrementQuantity = () => {
        if (state.item_quantity === available_quantity) {
            notifyMessage({
                notification_type: 'ERROR',
                message: `Maximum quantity reached for the ${shortened_display_name || name}!`,
            })
            return;
        }

        updateItemQty(state.item_quantity + 1);
        setState((draft) => {
            draft.item_quantity < available_quantity && draft.item_quantity++
        })
    };

    const maxReached = (() => {
        return state.item_quantity === available_quantity;
    })()


    const updateItemQty = (quantity: number) => {
        const pendingFunction = async () => {
            await updateCartItemQty(cart_item_id, quantity);
        };

        let timeout = setTimeout(() => {
            pendingFunction();
            updateLastReference();
            clearTimeout(timeout);
        }, updateQtyDelay);

        updateLastReference(timeout);
    };

    const showMobileView = Boolean(!isScreenBig) || Boolean(overlay);

    useEffect(() => {
        let cancelled = false;
        if (requested_quantity && !cancelled) {
            setState(draft => {
                draft.item_quantity = requested_quantity
            })
        }
        return () => { cancelled = true; }
    }, [requested_quantity])

    return (
        <>
            {showMobileView ? (
                <MainContainer>
                    <ContentWrapper>
                        {
                            is_in_sale &&
                            <DiscountBadge>
                                <DiscountText>
                                    {sale_factor}<span style={{ fontSize: '.65rem' }}>%</span>
                                </DiscountText>
                                <DiscountText2>
                                    off
                                </DiscountText2>
                            </DiscountBadge>
                        }
                        <ImageAndDescription {...{
                            page: RouterPages.ProductDetailsPage,
                            onClick: close_cart_modal || (() => { }),
                            state: { productInfo: cart_item.product },
                            pagePathArgs: { root_category_slug: root_category.slug, slug },
                        }}>
                            <ImageContainer>
                                <ItemImage src={variant_image} />
                            </ImageContainer>
                            <ItemDescriptionContainer>
                                <ItemName>{shortened_display_name || name}</ItemName>
                                <ItemSubName>{additionalName}</ItemSubName>
                                <ItemSubName>
                                    {variant_name.split(' ')[0] === 'Variant'
                                        ? ''
                                        : variant_name}
                                </ItemSubName>
                                <ItemPrice>{t("common.QAR")} {actual_price * state.item_quantity}</ItemPrice>
                                {
                                    is_in_sale &&
                                    <ItemPrice style={{ fontWeight: 'bold', textDecoration: 'line-through', color: 'lightgray' }}>
                                        {t("common.QAR")} {non_sale_price || 0}
                                    </ItemPrice>
                                }
                            </ItemDescriptionContainer>
                        </ImageAndDescription>
                        <ItemQuantityContainer>
                            <Minus {...{ onClick: decrementQuantity }}>
                                <PlusMinusText>-</PlusMinusText>
                            </Minus>
                            <ItemQuantity>{state.item_quantity}</ItemQuantity>
                            <Plus {...{
                                onClick: incrementQuantity,
                                style: { opacity: maxReached ? 0.3 : 1 }
                            }}>
                                <PlusMinusText>+</PlusMinusText>
                            </Plus>
                        </ItemQuantityContainer>
                    </ContentWrapper>
                </MainContainer >
            ) : (
                <CardContainer>
                    {
                        is_in_sale &&
                        <DiscountBadge>
                            <DiscountText>
                                {sale_factor}<span style={{ fontSize: '.65rem' }}>%</span>
                            </DiscountText>
                            <DiscountText2>
                                off
                            </DiscountText2>
                        </DiscountBadge>
                    }
                    <NameAndImageContainer>
                        <ImageContainer {...{
                            style: {
                                aspectRatio: '1',
                                width: 'auto',
                                maxHeight: '100%',
                                minHeight: '100%',
                            }
                        }}>
                            <ProductImage {...{
                                src: variant_image,
                            }} />
                        </ImageContainer>
                        <ProductDescription>
                            <ProductName>{name}</ProductName>
                            <ProductDetail>{additionalName}</ProductDetail>
                            <ProductDetail>
                                {variant_name.split(' ')[0] === 'Variant' ? '' : variant_name}
                            </ProductDetail>
                            <ProductDetail style={{ fontWeight: 'bold' }}>
                                {t("common.QAR")} {actual_price * state.item_quantity}
                                {
                                    is_in_sale &&
                                    <>{'\xA0'}
                                        <span style={{
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            color: 'lightgray',
                                            textDecoration: 'line-through',
                                        }}>
                                            {t("common.QAR")} {non_sale_price || 0}
                                        </span>
                                    </>
                                }
                            </ProductDetail>

                        </ProductDescription>
                    </NameAndImageContainer>
                    <AdditionalInformation>
                        <Quantity>
                            <QtyMinus {...{ onClick: decrementQuantity }}>
                                -
                            </QtyMinus>
                            <QtyComponent>{state.item_quantity}</QtyComponent>
                            <QtyPlus {...{
                                onClick: incrementQuantity,
                                style: { opacity: maxReached ? 0.3 : 1 }
                            }}>
                                +
                            </QtyPlus>
                        </Quantity>
                    </AdditionalInformation>
                </CardContainer>
            )
            }
        </>
    );
};


export { CartCard };
export * from './Summary';
export * from './GiftCard';
export { default as Summary } from './Summary';