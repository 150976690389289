import i18n from 'i18next';

// todo: translations on this page

import {
    Cancel,
    ShopText,
    ShopForIndoorPlants,
    NotFoundContentContainer,
    BackgroundImageContainer,

    NotFoundTitleText,
    NotFoundSectionButton,
    NotFoundTitleContainer,
    NotFoundDescriptionText,
    NotFoundSectionDescription,
    NotFoundTextInnerContainer,
} from './NotFoundElements';
import { useState } from 'react';
import { RouterPages } from '@project/routes';
import { useTranslation } from 'react-i18next';
import {
    BackgroundImage_1,
    BackgroundImage_1_Flipped,
} from '@home_components/DownloadApp/DownloadAppElements';
import { useIsScreenBiggerThan } from '@project/hooks';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    const { t } = useTranslation();
    const [cancel, setCancel] = useState(false);

    const isXLarge = useIsScreenBiggerThan(1000);
    const isLarge = useIsScreenBiggerThan(768);
    const isMedium = useIsScreenBiggerThan(500);
    const toggleCancel = () => {
        setCancel(prev => !prev);
    };

    return (
        <NotFoundContentContainer>
            <Helmet><title>{t('misc.notFoundMetaTitle')}</title></Helmet>
            {
                !cancel &&
                <ShopForIndoorPlants>
                    <ShopText {...{
                        page: RouterPages.ListingPage,
                    }} replace>
                        See all Products
                    </ShopText>
                    {
                        isMedium &&
                        <ShopText {...{
                            page: RouterPages.ListingCategoryPage,
                            pagePathArgs: { category: 'indoor-plants' }
                        }} replace>
                            Shop for Indoor Plants
                        </ShopText>
                    }
                    {
                        isLarge &&
                        <ShopText {...{
                            page: RouterPages.ListingCategoryPage,
                            pagePathArgs: { category: 'outdoor-plants' }
                        }} replace>
                            Shop for Outdoor Plants
                        </ShopText>
                    }
                    {
                        isXLarge &&
                        <ShopText {...{
                            page: RouterPages.ListingCategoryPage,
                            pagePathArgs: { category: 'pots-and-vases' }
                        }} replace>
                            Shop for Pots
                        </ShopText>
                    }


                    <Cancel {...{ onClick: toggleCancel }}>
                        x
                    </Cancel>
                </ShopForIndoorPlants>
            }

            <BackgroundImageContainer {...{ cancelled: cancel }}>
                {i18n.dir() === 'ltr' ? <BackgroundImage_1 /> : <BackgroundImage_1_Flipped />}
                <NotFoundTitleContainer>
                    <NotFoundTextInnerContainer>
                        <NotFoundTitleText>
                            {'Error'}{' '}
                            <span style={{
                                color: '#118B19',
                                fontWeight: 'bolder',
                                fontFamily: 'ManropeBold'
                            }} >
                                {'404'}
                            </span>{' '}
                        </NotFoundTitleText>
                        <NotFoundSectionDescription>
                            <NotFoundDescriptionText>
                                The page you have requested could not be found. Please go to our homepage to continue exploring our website.
                            </NotFoundDescriptionText>
                        </NotFoundSectionDescription>
                        <NotFoundSectionButton {...{ page: RouterPages.Home }}>
                            Go to Home
                        </NotFoundSectionButton>
                    </NotFoundTextInnerContainer>
                </NotFoundTitleContainer>

            </BackgroundImageContainer>
        </NotFoundContentContainer >
    );
};

export default NotFound;
