import styled from 'styled-components';
import { COLORS } from '@project/styles';
import { Link } from '@project/routes/components';
import { PRODUCT_CARD_MOBILE_WIDTH } from '@home_components/Common/ProductCard/ProductCardElements';

export const MainContainer = styled.div`
    width: 100%;
    height: 1000px;
    display: flex;
    
    padding: 5%; 
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    transition: all 0.5s ease;
    @media screen and (max-width: 1400px) {
        height: auto;
    }
    
`;

export const MainWrapper = styled.div<{ invert: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-direction: ${({ invert }) => invert ? 'row-reverse' : 'row'};
    justify-content: space-between;

    @media screen and (max-width: 1400px) {
        flex-direction: column;
    }
`;

export const ImageSection = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (max-width: 1400px) {
        width: 100%;
        max-height: 600px;
        min-height: 600px;
    }

    @media screen and (max-width: 768px) {
        max-height: 400px;
        min-height: 400px;
    }
`;

export const CategoryName = styled.h1`
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 12px black;
`;

export const CategoryNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 60px;
    @media screen and (max-width: 1400px) {
        margin: 30px;
    }
`;

export const CategoryButton = styled(Link)`
    border: 0;
    margin-top: 5px;
    width: 26%;
    padding: 12px;
    color: #fff;
    background-color: ${COLORS.ORANGE};
    border-radius: 5px;
    min-width: 100px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    &:hover {
        background-color: #d59620;
    }
    @media screen and (max-width: 1400px) {
        width: 80%;
    }
`;
export const ProductsSectionWrapper = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    transition: all 0.5s ease;

    @media screen and (max-width: 1400px) {
        width: 100%;
    }

`;
