import { useCallback, useEffect } from 'react';

// IMPORT TYPES
import {
    CHECKOUT_STATE,
    REPOTTED_PRODUCTS_TYPE,
    API_SCHEDULE_TIME_TYPE,
    API_GIFT_ADDRESS_TYPE,
    APP_STATE_ORDER_TYPE,
    APP_STATE_CUSTOM_CARD_TYPE,
} from '@api/types';
import { Moment } from 'moment';
import { RootState } from '@redux/store';
import { DIBSY_PAYMENT_OPTION } from '@api/user/cards/types';


// IMPORT HELPERS
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';
import { notifyMessage, promiseMessage } from '@overlays/notifications';


// IMPORT COMPONENTS
import {
    PaymentSummary,
    CheckoutButtonContainer,
} from './SummaryElements';
import {
    validateOTP,
    processOrder,
    validateRepots,
    validateAddress,
    validatePayments,
    validateSchedule,
    validateUserName,
    validateUserEmail,
    computeRepottingCost,
    validateUserPhoneNumber,
    CHECKOUT_VALIDATION_ERRORS,
} from '@api/checkout/helpers';
import { Divider } from '@mui/material';
import GiftCard from './components/GiftCardSection';
import { PaymentSection } from '../CheckoutElements';
import PersonalDetails from './components/UserSection';
import PaymentDetails from './components/PaymentSection';
import GiftCardOptions from './components/GiftCardOptions';
import AdditionalNotes from './components/AdditionalNotes';
import OTPVerification from './components/OTPVerification';
import RepottingOptions from './components/RepottingSection';
import ScheduleInformation from './components/ScheduleSection';
import { SectionTitle, SummaryContainer } from './components/AdditionalElements';
import { Summary as CheckoutSummary, ADDITIONAL_COST_TYPE, LoadingButton } from '@custom_components';


// IMPORT HOOKS
import { useState } from 'react';
import { COLORS } from '@project/styles';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';



export interface GIFT_CARD_TYPE {
    sender: string;
    recipient: string;
    message: string;
}


export const PaymentSnapShot = () => {
    const { t } = useTranslation();

    const {
        cart_items,
        delivery_cost_after_discount,
        total_cart_price_after_discount,
    } = useSelector((state: RootState) => state.cart)


    const [state, setState] = useImmer<CHECKOUT_STATE>({
        plants_in_pots: [],
        repotting_cost_expected_by_user: 0,
        delivery_cost_expected_by_user: delivery_cost_after_discount,
        total_price_expected_by_user: total_cart_price_after_discount,
    })

    const is_repottable = (() => {
        const types = cart_items.map((item) => item.product.product_type)
        return types.includes('Plant') && types.includes('Pot');
    })();

    const setActivePayment = (payment_option?: DIBSY_PAYMENT_OPTION) => {
        setState(draft => {
            draft.payment_option = payment_option;
        })
    }

    const setRepottingOptions = (repots: REPOTTED_PRODUCTS_TYPE[]) => {
        setState(draft => {
            draft.plants_in_pots = repots;
            draft.repotting_cost_expected_by_user = computeRepottingCost(repots.length)
        })
    }

    const setDeliveryAddress = (address?: string) => {
        setState(draft => {
            if (address) {
                draft.delivery_address = address;
                draft.gift_delivery_address = undefined;
            }
        })
    }

    const setGiftAddress = (gift_address?: API_GIFT_ADDRESS_TYPE) => {
        setState(draft => {
            if (gift_address) {
                draft.delivery_address = undefined;
                draft.gift_delivery_address = gift_address;
            }
        })
    }

    const setDeliverySchedule = (date: Moment, time: API_SCHEDULE_TIME_TYPE) => {
        setState(draft => {
            if (
                Boolean(date)
                && Boolean(time)
                && Boolean(time.end_time)
                && Boolean(time.start_time)
            ) {
                draft.main_delivery_window = { date: date.format('YYYY-MM-DD'), ...time }
            }

        })
    }

    const validateCheckoutParams = (): [boolean, CHECKOUT_VALIDATION_ERRORS] => {
        let vCheckout: boolean;
        let messages: string[];

        const [vUserName, vUserNameError] = validateUserName()
        const [vUserEmail, vUserEmailError] = validateUserEmail()
        const [vUserPhone, vUserPhoneError] = validateUserPhoneNumber()

        const [vSchedule, vScheduleError] = validateSchedule(
            state.main_delivery_window
        )
        const [vAddress, vAddressError] = validateAddress(
            state.delivery_address,
            state.gift_delivery_address
        )
        const [vRepotting, vRepottingError] = validateRepots(
            cart_items,
            state.plants_in_pots,
            state.repotting_cost_expected_by_user
        )

        const [vPayment, vPaymentError] = validatePayments(
            Boolean(state.gift_delivery_address),
            state.payment_option,
        )

        const [vOTP, vOTPError] = validateOTP(
            state.payment_option
        )

        vCheckout = vUserName && vUserEmail && vUserPhone && vAddress && vRepotting && vSchedule && vPayment && vOTP;
        messages = [
            vUserNameError, vUserEmailError, vUserPhoneError,
            vRepottingError, vAddressError, vScheduleError,
            vPaymentError, vOTPError].filter(Boolean);

        return [vCheckout, { messages }]
    }

    const checkGiftOrderStatus = useCallback(() => {
        return Boolean(state.gift_delivery_address?.mobile_number) &&
            Boolean(state.gift_delivery_address?.recipient_name);
    }, [state.gift_delivery_address])

    const getAdditionalCostInformation = (): ADDITIONAL_COST_TYPE[] => {
        if (state.repotting_cost_expected_by_user) {
            return [{
                cost_type: t('orders.repottingCostNoColon'),
                cost_amount: state.repotting_cost_expected_by_user,
            }]
        }
        return []
    }

    const setGiftCardOption = (custom_message: APP_STATE_CUSTOM_CARD_TYPE) => {
        setState(draft => {
            draft.custom_card = {
                sender: custom_message.from,
                recipient: custom_message.to,
                message: custom_message.message,
                ...(custom_message.card_design ? { card_design: custom_message.card_design } : undefined)
            }
        })

        notifyMessage({
            message: t('gift.addSuccessMessage'),
            notification_type: 'SUCCESS',
        })
    }

    const removeGiftCardOption = () => {
        setState(draft => {
            if (draft.custom_card) {
                delete draft.custom_card;
            }
        })

        notifyMessage({
            message: t('gift.removeSuccessMessage'),
            notification_type: 'SUCCESS',
        })
    }

    const getAdditionalCostsValue = (): number => {
        return state.repotting_cost_expected_by_user || 0;
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleErrorProcessOrder = async (state: CHECKOUT_STATE): Promise<APP_STATE_ORDER_TYPE | null> => {
        setLoading(true);
        try {
            const orderResponse = await processOrder(state, navigate);
            setLoading(false);
            return orderResponse;
        } catch (error) {
            setLoading(false);
            return new Promise((rs, rj) => rj(null))
        }
    }

    const onCheckout = () => {
        let [valid, errors] = validateCheckoutParams();
        if (!valid) {
            errors.messages.forEach(message => notifyMessage({ message, notification_type: 'WARNING' }));
            return;
        } else {
            promiseMessage<APP_STATE_ORDER_TYPE | null>({
                promise: handleErrorProcessOrder(state),
                loading: t('checkout.processOrderLoading'),
                error: t('common.somethingWentWrongTryAgain'),
                success: (data: APP_STATE_ORDER_TYPE | null) => `${t("checkout.processOrderSuccess")} ${data ? ` (ID: ${data.order_id})` : ''}`,
            })
        }
    }

    const setGiftCardInformation = ({ sender, recipient, message }: GIFT_CARD_TYPE) => {
        setState(draft => {
            draft.custom_card = {
                sender,
                recipient,
                message,
            }
        })
    }

    return (
        <PaymentSummary>
            <PersonalDetails />
            <Divider />
            <ScheduleInformation {...{
                setGiftAddress,
                setDeliveryAddress,
                setDeliverySchedule,
            }} />

            {
                state.gift_delivery_address ?
                    <>
                        <Divider />
                        <GiftCard {...{ setGiftCardInformation }} />
                    </>
                    : null
            }
            {
                is_repottable &&
                <>
                    <Divider />
                    <RepottingOptions {...{ setRepottingOptions }} />
                </>
            }
            <Divider />
            <GiftCardOptions {...{
                customCard: state.custom_card,
                setGiftCardOption, removeGiftCardOption,
            }} />
            <Divider />
            <AdditionalNotes {...{
                setAdditionalNotesToState: (notes) => {
                    setState(draft => {
                        if (notes) { draft.user_additional_notes = notes }
                        else { delete draft.user_additional_notes }
                    });
                }
            }} />
            <Divider />
            <PaymentDetails {...{
                setActivePayment,
                disableCashOrders: checkGiftOrderStatus(),
            }} />
            <Divider />
            <OTPVerification {...{ cash_payment: state.payment_option?.payment_type === 'cash' }} />
            <SummaryContainer>
                <SectionTitle>{t("checkout.paymentSummary")}</SectionTitle>
                <CheckoutSummary {...{
                    additionalCosts: getAdditionalCostInformation(),
                    totaladditionalChange: getAdditionalCostsValue(),
                }} breakdown />
            </SummaryContainer>
            <Divider />
            <CheckoutButtonContainer>
                {

                }
                <LoadingButton {...{
                    onClick: onCheckout,
                    disabled: loading,
                    buttonState: {
                        submitted: false,
                        success: null,
                        error: '',
                        loading,
                    },
                    style: { width: '100%' },
                    buttonTitle: t('checkout.placeYourOrder'),
                    colorScheme: COLORS.AUTH.LOGIN,
                    payment_option: state.payment_option,
                }} />
            </CheckoutButtonContainer>
        </PaymentSummary>
    )
}

const Summary = () => {
    return (
        <PaymentSection>
            <PaymentSnapShot />
        </PaymentSection>
    );
};

export default Summary;
