import React, { useCallback, useEffect, useMemo, useState } from 'react';

// IMPORT COMPONENTS
import {
    RepotRow,
    SectionTitle,
    RepotPlantIcon,
    RepottingContainer,
    RepotPotInformation,
    RepotPlantInformation,
    AdditionalOptionsContainer,
    SectionSubInformation,
} from './AdditionalElements';
import { MenuItem } from '@mui/material';
import { Loading } from '@custom_components';
import { PotBlocker, SelectPot } from '../../CheckoutElements';

// IMPORT HELPERS
import { useSelector } from 'react-redux';
import { getRepotStrategyText } from '@api/checkout/helpers';
import { getExpandedProductList, getVariantInformation } from '@project/helpers';
import getRepotConfiguration, { defaultRepottingConfig } from '@api/checkout/repot';

// IMPORT TYPE

import {
    POT_SHAPE,
    API_POT_VARIANT_TYPE,
    API_PLANT_VARIANT_TYPE,
    API_PRODUCT_VARIANT_TYPE,
} from '@api/types/variant';
import {
    REPOTTED_POTS_TYPE,
    APP_STATE_CART_PRODUCT_TYPE,
} from '@api/user/cart/types';
import { RootState } from '@redux/store';
import { API_REPOTTING_CONFIGURATION_TYPE, REPOTTED_PRODUCTS_TYPE } from '@api/types';


// IMPORT ASSETS
import { GiMonsteraLeaf, GiMapleLeaf, GiOakLeaf, GiVineLeaf } from 'react-icons/gi';

// IMPORT STYLES
import { COLORS } from '@project/styles';
import { ComingSoon } from '@pages/Listing/ListingElements';
import { useIsScreenBiggerThan } from '@project/hooks';
import { useTranslation } from 'react-i18next';







interface AdditionalOptionsProps {
    setRepottingOptions: (repots: REPOTTED_PRODUCTS_TYPE[]) => void;
}

const AdditionalOptions = ({ setRepottingOptions }: AdditionalOptionsProps) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const { repotting_configuration } = useSelector((state: RootState) => state.checkout);
    const [repotConfig, setRepotConfig] = useState<API_REPOTTING_CONFIGURATION_TYPE | null>(repotting_configuration);

    const cartItems = useSelector((state: RootState) => state.cart.cart_items)

    const _500 = useIsScreenBiggerThan(500);
    const repotting_pots = useMemo(() => getExpandedProductList(cartItems, 'Pot', _500 ? 'long' : 'short'), [_500, cartItems])
    const repottable_plants = useMemo(() => getExpandedProductList(cartItems, 'Plant', _500 ? 'long' : 'short'), [_500, cartItems])

    const [repot, setRepot] = useState<REPOTTED_POTS_TYPE>({});


    const setPlantPotPair = (plant_index: number, pot_index: number) => {
        if (!isPotSelected(plant_index, pot_index)) {
            setRepot(prev => ({ ...prev, [plant_index]: pot_index }))
        }
    }

    const Icon = useCallback((props: any, icon_index: number) => {
        let icons = [GiMonsteraLeaf, GiMapleLeaf, GiOakLeaf, GiVineLeaf];
        return (icons[icon_index])(props)
    }, [])

    const isPlantIndexActive = (plant_index: number) => {
        return repot[plant_index] !== undefined;
    }

    const isPotSelected = (plant_index: number, pot_index: number) => {
        return Object.values(repot).includes(pot_index) && (((repot[plant_index] !== undefined) ? repot[plant_index] : -1) !== pot_index);
    }

    const getSVGOnPlantSize = useCallback((selected: boolean, plant_index: number) => {
        return Icon({
            ...{
                fill: selected
                    ? COLORS.GREEN
                    : COLORS.RED,
                width: '90%',
                height: '90%',

            }
        }, plant_index)
    }, [Icon])

    const getDimsOnPotShape = (shape: POT_SHAPE | null, diameter: string, length: string, width: string) => {
        if (!shape) { return '0cm' }
        switch (shape) {
            case 'Round':
                return diameter;
            default:
                return `(${width} x ${length})`
        }
    };

    useEffect(() => {
        let cancelled = true;

        const repotConfiguration = async () => {
            setLoading(true);
            const { status, response_body } = await getRepotConfiguration();
            if (status && !cancelled) {
                setRepotConfig(response_body);
            }
            setTimeout(() => setLoading(false), 1000);
        }

        !repotConfig && repotConfiguration();
        return () => { cancelled = false; }
    }, [])

    useEffect(() => {
        let cancelled = false;

        setRepottingOptions(Object.keys(repot).map((plant_index) => ({
            plant_sku: repottable_plants[+plant_index].product_information.product_sku,
            pot_sku: repotting_pots[repot[+plant_index] as number].product_information.product_sku,
        })))

        return () => { cancelled = true; }
    }, [repot])

    return (
        <AdditionalOptionsContainer {...{ componentLoading: loading }}>
            {
                loading ? <Loading />
                    : <>
                        <SectionTitle>
                            {t('checkout.repotYourPlants')}
                        </SectionTitle>
                        {
                            Boolean(repotConfig) ?
                                <>
                                    <SectionSubInformation>
                                        {getRepotStrategyText(repotConfig as unknown as API_REPOTTING_CONFIGURATION_TYPE)}
                                    </SectionSubInformation>
                                    <RepottingContainer {...{ number_of_repottable_plants: repottable_plants.length }}>
                                        {
                                            repottable_plants.map(({
                                                product_information: { product, product_sku },
                                                product_longform_name, random_associated_number }, plant_index) => {
                                                const plant_variant_default_name = product.variants.find(
                                                    ({ sku }: API_PRODUCT_VARIANT_TYPE) => sku === product_sku
                                                )?.display_name as string;

                                                const plant_variant_info = getVariantInformation<API_PLANT_VARIANT_TYPE>(product_sku, product);

                                                const plant_variant_diameter = plant_variant_info.find(
                                                    ({ var_key }) => var_key === 'Diameter'
                                                )?.var_val;

                                                const selected = isPlantIndexActive(plant_index);
                                                return (
                                                    <RepotRow {...{ key: `${product.product_id}${product_longform_name}` }}>
                                                        <RepotPlantInformation>
                                                            <RepotPlantIcon {...{ selected }}>
                                                                {getSVGOnPlantSize(selected, random_associated_number)}
                                                            </RepotPlantIcon>
                                                            <div style={{ width: 'auto' }}>{product_longform_name} - <span style={{ fontWeight: 'normal' }}>
                                                                {plant_variant_diameter || plant_variant_default_name}
                                                            </span>
                                                            </div>
                                                        </RepotPlantInformation>
                                                        <RepotPotInformation>
                                                            <SelectPot {...{
                                                                label: repot[plant_index] === undefined ? t('checkout.selectAPot') : '',
                                                                value: repot[plant_index] !== undefined ? (repot[plant_index] as number) + 1 : '',
                                                                onChange: (e) => {
                                                                    setPlantPotPair(plant_index, (+(e.target.value) as number) - 1)
                                                                },
                                                                sx: { width: repot[plant_index] === undefined ? '200px' : 'auto' }
                                                            }} select>
                                                                {repotting_pots.map(({ product_information: {
                                                                    product, product_sku
                                                                }, product_longform_name }, pot_index) => {
                                                                    const pot_variant_info = getVariantInformation<API_POT_VARIANT_TYPE>(
                                                                        product_sku, product
                                                                    );

                                                                    const pot_variant_shape = pot_variant_info.find(
                                                                        ({ var_key }) => var_key === 'Pot Shape'
                                                                    )?.var_val.trimEnd() || null;

                                                                    const pot_variant_diameter = pot_variant_info.find(
                                                                        ({ var_key }) => var_key === 'Diameter'
                                                                    )?.var_val || '';

                                                                    const pot_variant_length = pot_variant_info.find(
                                                                        ({ var_key }) => var_key === 'Length'
                                                                    )?.var_val || '';

                                                                    const pot_variant_width = pot_variant_info.find(
                                                                        ({ var_key }) => var_key === 'Width'
                                                                    )?.var_val || '';

                                                                    return (
                                                                        <MenuItem {...{
                                                                            key: pot_index + 1,
                                                                            value: pot_index + 1,
                                                                        }}>
                                                                            {product_longform_name} - {getDimsOnPotShape(
                                                                                pot_variant_shape as POT_SHAPE | null,
                                                                                pot_variant_diameter,
                                                                                pot_variant_length,
                                                                                pot_variant_width
                                                                            )}
                                                                            {isPotSelected(plant_index, pot_index) && <PotBlocker />}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </SelectPot>
                                                        </RepotPotInformation>
                                                    </RepotRow>
                                                )
                                            })
                                        }
                                    </RepottingContainer>
                                </>
                                :
                                <ComingSoon style={{ height: 200 }}><h5>{t('checkout.repottingUnavailable')}</h5></ComingSoon>
                        }
                    </>
            }
        </AdditionalOptionsContainer>
    )
}

export default AdditionalOptions;