import { useCallback, useEffect, useState } from 'react';

// IMPORT TYPES
import { API_CUSTOM_CARD_TYPE, APP_STATE_CUSTOM_CARD_TYPE, CUSTOM_CARD_TYPE } from '@api/types';

// IMPORT HELPERS
import { useImmer } from 'use-immer';
import { HiGift } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

//IMPORT PRODUCT DETAIL COMPONENTS
import {
    GiftCardAddButton,
    NonGiftCardAddButton,

    GiftCardImageSection,
    GiftCardTopContainer,
    GiftCardTitleContainer,
    GiftCardDesignContainer,
    GiftCardTextInputContainer,
} from './GiftCardElements';

import { Swipeable } from '@custom_components';
import { Divider, TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import { HTTP_METHODS, HTTP_REQUEST } from '@api/interactions';
import { CUSTOM_CARDS_TYPE, CUSTOM_CARD_RESPONSE_TYPE } from '@api/user/cart/types';
import { TextInput, TextInputOneField, TextInputTwoFields, TextInputWrapper } from '@form_components';


async function getCustomGiftCardDesigns(onSuccessCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('user/cart/custom-card-designs/', {
        method: HTTP_METHODS.GET,
        options: { validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => onSuccessCallback(response))
        .catch(err => console.error(err))
}

interface RenderItemProps {
    item: CUSTOM_CARD_RESPONSE_TYPE
}

export const GiftCard = ({ name, buttonTitle, preExistingCard, onCancel, onSuccess }: {
    name: string;
    onCancel: () => void;
    buttonTitle: string;
    preExistingCard?: CUSTOM_CARD_TYPE;
    onSuccess: (customMessage?: APP_STATE_CUSTOM_CARD_TYPE) => void;
}) => {
    const { t } = useTranslation();
    const [mainError, setMainError] = useState<boolean>(false);
    const [giftCards, setGiftCards] = useState<CUSTOM_CARDS_TYPE>([]);
    const [mainErrorString, setMainErrorString] = useState<string>('');
    const [activeDesign, setActiveDesign] = useState<string | undefined>(preExistingCard?.card_design);
    const [customMessageErrors, setCustomMessageErrors] = useImmer<[boolean, boolean, boolean]>([false, false, false]);
    const [customMessage, setCustomMessage] = useImmer<Partial<APP_STATE_CUSTOM_CARD_TYPE>>(
        preExistingCard ? {
            to: preExistingCard.recipient,
            from: preExistingCard.sender,
            message: preExistingCard.message,
        } : {})

    // Flower Bouquet Carousel
    const slidesPerView = 1;
    const slidesPerGroup = 1;

    const handleSelectCardDesign = (design_id: string) => {
        if (activeDesign !== design_id) { setActiveDesign(design_id) }
        else { setActiveDesign(undefined) }
    }

    const render_item = ({ item }: RenderItemProps) => (
        <GiftCardDesignContainer>
            <GiftCardTopContainer>
                <GiftCardTitleContainer>
                    <DialogContentText>
                        <span style={{ fontWeight: 'bolder', color: 'black' }}>
                            {item.title}
                        </span>
                    </DialogContentText>
                    <DialogContentText>
                        <span style={{ color: 'darkgray' }}>
                            {item.description}
                        </span>
                    </DialogContentText>
                </GiftCardTitleContainer>
            </GiftCardTopContainer>
            <GiftCardImageSection
                style={{
                    background: `url(${item.design.image}) center no-repeat`,
                    backgroundSize: 'auto 100%',
                }}
            />
        </GiftCardDesignContainer>
    )

    const getInitialSlide = useCallback(() => {
        const idx = !(preExistingCard?.card_design) ? 0 : giftCards.findIndex((card) => card.id === preExistingCard.card_design)
        return Math.max(idx, 0);
    }, [preExistingCard, giftCards])

    const validCustomMessage = useCallback(() => {
        const bools = [Boolean(customMessage.to), Boolean(customMessage.from), Boolean(customMessage?.message)]

        if (!(bools.every((x) => x === true))) {
            setMainError(true);
            setMainErrorString(t('gift.giftCardAddError'));
            bools.forEach((elem, index) => setCustomMessageErrors(draft => { draft[index] = !elem }))
            return false;
        }
        return true;
    }, [customMessage, activeDesign]);

    const handleAddGiftCard = () => {
        if (!validCustomMessage()) {
            return;
        }
        if (customMessage.to) {
            const activeCardDesign = activeDesign ? { card_design: activeDesign } : undefined;
            onSuccess({
                to: customMessage.to || '',
                from: customMessage.from || '',
                message: customMessage.message || '',
                ...activeCardDesign,
            })
        } else {
            onSuccess()
        }
    }


    useEffect(() => {
        let cancelled = false;
        if (!cancelled) {
            getCustomGiftCardDesigns((response: CUSTOM_CARDS_TYPE) => {
                setGiftCards(response);
                !preExistingCard && setActiveDesign(response.length ? response[0].id : undefined);
            });
        }
        return () => { cancelled = true; }
    }, [])

    return (
        <>
            <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                {t('gift.addAGiftMessage')}
            </DialogTitle>
            <DialogContentText
                id="alert-dialog-description"
                style={{ margin: '0px 25px 15px 25px' }}>
                {t('gift.addAGiftMessageText1')}
                <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                    {`\xA0${t('gift.addAGiftMessageText2')}\xA0`}
                </span>
                {t('gift.addAGiftMessageText3')}
                <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>
                    {`\xA0${t('gift.addAGiftMessageText4')}\xA0`}
                </span>
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                    {
                        Boolean(mainError)
                        && (!Boolean(customMessage.to)
                            || !Boolean(customMessage.from)
                            || !Boolean(customMessage.message))
                        && mainErrorString
                    }
                </span>
            </DialogContentText>
            <Divider style={{ margin: '0px 25px 0px 25px' }} />
            <DialogContent style={{ overflowY: 'auto' }}>
                {
                    Boolean(giftCards.length) &&
                    <Swipeable {...{
                        data: giftCards,
                        render_item,
                        slide_key: 'id',
                        additional_swiper_props: {
                            initialSlide: getInitialSlide(),
                            onActiveIndexChange: (swiper) => {
                                setActiveDesign(giftCards[swiper.activeIndex].id)
                            },
                            slidesPerView,
                            slidesPerGroup,
                        }
                    }} />
                }
                <GiftCardTextInputContainer>
                    <TextInputOneField>
                        <TextInput {...{
                            required: true,
                            label: t('gift.from'),
                            variant: 'outlined',
                            value: customMessage.from || '',
                            error: customMessageErrors[1] && !Boolean(customMessage.from),
                            onChange: (e) => setCustomMessage(draft => {
                                if (!draft) { draft = { from: e.target.value } }
                                else {
                                    draft.from = e.target.value;
                                }
                            }),
                        }} />
                    </TextInputOneField>
                    <TextInputOneField>
                        <TextInput {...{
                            required: true,
                            label: t('gift.to'),
                            variant: 'outlined',
                            value: customMessage.to || '',
                            error: customMessageErrors[0] && !Boolean(customMessage.to),
                            onChange: (e) => setCustomMessage(draft => {
                                if (!draft) { draft = { to: e.target.value } }
                                else {
                                    draft.to = e.target.value;
                                }
                            }),
                        }} />
                    </TextInputOneField>
                    <TextInputTwoFields>
                        <TextInput {...{
                            required: true,
                            label: t('gift.from'),
                            variant: 'outlined',
                            value: customMessage.from || '',
                            error: customMessageErrors[1] && !Boolean(customMessage.from),
                            onChange: (e) => setCustomMessage(draft => {
                                if (!draft) { draft = { from: e.target.value } }
                                else {
                                    draft.from = e.target.value;
                                }
                            }),
                        }} />
                        <TextInput {...{
                            required: true,
                            label: t('gift.to'),
                            variant: 'outlined',
                            value: customMessage.to || '',
                            error: customMessageErrors[0] && !Boolean(customMessage.to),
                            onChange: (e) => setCustomMessage(draft => {
                                if (!draft) { draft = { to: e.target.value } }
                                else {
                                    draft.to = e.target.value;
                                }
                            }),
                        }} />
                    </TextInputTwoFields>
                    <TextInputWrapper>
                        <TextField
                            {...{
                                multiline: true,
                                label: t('gift.writeMessageHere'),
                                rows: 4,
                                required: true,
                                error: customMessageErrors[2] && !Boolean(customMessage.message),
                                value: customMessage.message || '',
                                fullWidth: true,
                                onChange: (e) =>
                                    setCustomMessage(draft => {
                                        if (!draft) { draft = { message: e.target.value } }
                                        else { draft.message = e.target.value; }
                                    })
                            }}
                        />
                    </TextInputWrapper>
                </GiftCardTextInputContainer>
                <Divider />
            </DialogContent>
            <DialogActions style={{ padding: '15px 25px 15px 25px' }}>
                <NonGiftCardAddButton
                    onClick={onCancel}>
                    {t('common.cancel')}
                </NonGiftCardAddButton>
                <GiftCardAddButton
                    onClick={handleAddGiftCard}>{buttonTitle + '  '}
                    <span style={{ height: '100%', alignItems: 'center', justifyContent: 'center', fontSize: '1rem' }}><HiGift /></span></GiftCardAddButton>
            </DialogActions>
        </>
    );
}


