import styled from 'styled-components';

// IMPORT ASSETS
import Gift from '@pages/Home/assets/gift.svg';
import FoundedInQatar from '@pages/Home/assets/qatar.svg';
import SameDayDelivery from '@pages/Home/assets/truck.svg';
import { COLORS } from '@project/styles';


export const MainWrapper = styled.div`
    margin: 2% 0%;
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    align-items: center;
    transition: all 0.5s;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;


export const InformationBannerContainer = styled.div<{ dark_mode?: boolean }>`
    width: 100%;
    height: auto;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-around;
    
    background-color: ${({ dark_mode }) => !dark_mode ? '#14b72d' : COLORS.DARK_GREEN};
    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        height: 280px;
    };
`;

export const BannerInformationWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 4% 2%;
    overflow: hidden;
    
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;

    
    @media screen and (max-width: 768px) {
        width: 350px;
        justify-content: flex-start;
        margin: 0 5% 0 5%;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
    }
`;
export const BannerTextWrapper = styled.div`
    /* width: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const BannerHeading = styled.h3`
    margin: 0;
    color: #fff;
    font-weight: 800;
`;
export const BannerDescription = styled.h5`
    margin: 0;
    font-weight: normal;
    color: #fff;
`;

// ICONS
export const BannerImgContainer = styled.div`
    max-width: 70px;
    min-width: 70px;
    max-height: 70px;
    min-height: 70px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    margin: 0 15px;
    border-radius: 35px;
    background-color: #FFF;
    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        max-width: 55px;
        min-width: 55px;
        max-height: 55px;
        min-height: 55px;
    }
`


export const FoundedInQatarImg = styled.img.attrs({
    src: `${FoundedInQatar}`,
})`
    width: 60%;
    object-fit: contain;
`;
export const SameDayDeliveryImg = styled.img.attrs({
    src: `${SameDayDelivery}`,
})`
    width: 60%;
    object-fit: contain;
`;
export const GiftImg = styled.img.attrs({
    src: ` ${Gift}`,
})`
    width: 50%;
    object-fit: contain;
`;
