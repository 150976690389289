import styled from 'styled-components';

// IMPORT ASSETS
import emptyCartImage from '@assets/images/cart/emptyCart.png';

// IMPORT STYLES
import {
    COLORS,
    BIG_HEADER_HEIGHT,
    BIG_CONTENT_CONTAINER_HEIGHT,
} from '@project/styles';

import { TextField } from '@mui/material';

export const CartPageContainer = styled.div<{ fadeOut: boolean }>`
    display: flex;
    position: absolute;
    flex-direction: column;
    /* justify-content: center; */
    /* margin: 50px 0; */
    width: 90%;
    opacity: ${({ fadeOut }) => fadeOut ? 0 : 1};
    z-index: ${({ fadeOut }) => fadeOut ? 0 : 1};
    transition: all 0.2s ease;
    @media screen and (max-width: 1000px) {
        margin: 0;
        height: auto;
    }
`;

export const PageTitle = styled.h1`
    font-size: 35px;
    @media screen and (max-width: 1000px) {
        font-size: 30px;
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    /* border: 1px solid black; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ProductHeaders = styled.div``;

export const EmptyCart = styled.img.attrs({
    src: emptyCartImage,
})`
    width: 45%;
    align-self: center;
    margin-top: 50px;
    @media screen and (max-width: 1000px) {
        width: 60%;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: space-between;
    border-bottom: 1px solid ${COLORS.LIGHTER_GREY};
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
    }
`;

export const CheckoutButton = styled.div`
    background-color: ${COLORS.GREEN};
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

export const CheckoutText = styled.p`
    font-size: 20px;
    color: white;
`;

export const SectionHeading = styled.h3`
    opacity: 0.5;
`;

export const NameAndImageContainer = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
`;

export const ProductImage = styled.img`
    width: 30%;
    margin-right: 20px;
`;

export const ProductTitle = styled.h1``;
export const ProductSubTitle = styled.h3``;

export const Quantity = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    height: 50%;
`;

export const Price = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    /* border: 1px solid black; */
    height: 50%;
`;

export const Total = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    height: 50%;
`;

export const CheckoutMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const CheckoutSection = styled.div`
    width: 35%;
    display: flex;
    position: relative;
    ::-webkit-scrollbar {
        display: none;
    }
    justify-content: center;
    transition: all 0.75s ease;
    background-color: ${COLORS.LIGHT_GREEN};
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const PaymentSection = styled.div`
    width: 100%;
    padding: 0px 20% 80px 20%;
    transition: all 0.75s ease;
    background-color: ${COLORS.WHITE};
    ::-webkit-scrollbar { display: none };
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: auto;
        padding: 0px 5%;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        height: auto;
        padding: 0px 10%;
    }
`;


export const TitleWrapper = styled.div`
    border-bottom: 1px solid ${COLORS.LIGHTER_GREY};
`;

export const DetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const DetailTitle = styled.div`
    display: flex;
    padding: 10px 0px;
    font-size: 1rem;
    font-weight: bold;
`;

export const PromoDiscountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;
export const PromoCodeSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;
export const SectionTitle = styled.h2``;

export const TotalCostWrapper = styled.div`
    border-top: 1px solid #0000007d;
    margin: 20px 0;
    padding: 20px 0;
`;

export const TopSection = styled.div`
    /* border: 1px solid black; */
`;

export const SelectPot = styled(TextField)`
    position: relative;
    width: auto;
    height: 60%;
    color: ${COLORS.DARKER_GREY};
`

export const PotBlocker = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    background-color: ${COLORS.WHITE_SMOKE};
`

