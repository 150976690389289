import React, { useEffect, useMemo } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// IMPORT TYPES
import { APP_STATE_ORDER_TYPE, DELIVERY_WINDOW_TYPE } from '@api/types';

// IMPORT COMPONENTS
import {
    Title,
    Button,
    DeliveryRow,
    DeliveryTime,
    DeliveryWindow,
    DeliveryContainer,
    AnimationContainer,

    StatusMessage,
    StatusContainer,
    LoadingContainer,
    StatusInformationContainer,
} from './components/PaymentElements';
import Lottie from 'react-lottie-player';

// IMPORT STYLES
import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';

// IMPORT HOOKS
import { useImmer } from 'use-immer';
import { useIsScreenBiggerThan } from '@project/hooks';

// IMPORT API
import directPaymentPoll from '@api/checkout/payment/poll';
import { getOrderTotalCost, processPaymentPolling } from '@api/checkout/helpers';
import hostedPaymentPoll from '@api/checkout/payment/hosted/poll';


// IMPORT ASSETS
import paymentFailed from './lotties/payment-fail.json';
import paymentLoading from './lotties/payment-loading.json';
import paymentSucceeded from './lotties/payment-success.json';

interface PaymentInformation {
    payment_identifier?: string;
    checkout_type: 'direct' | 'hosted' | 'cash';
    order_information: APP_STATE_ORDER_TYPE;

}

interface LottieJSONs {
    payment_failed_animation_data?: object;
    payment_loading_animation_data?: object;
    payment_succeeded_animation_data?: object;
}

interface PaymentState extends LottieJSONs {
    failure_reason?: string;
    payment_processed: boolean;
    is_successful_payment?: boolean;
}


// IMPORT HELPERS
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearCart } from '@redux/state/cart';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { purchase_gtag_report_conversion } from 'lib/common/google_analytics';


const PaymentPage = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isBig = useIsScreenBiggerThan(768);
    const isMedium = useIsScreenBiggerThan(500);

    const [state, setState] = useImmer<PaymentState>({
        payment_processed: false,
    })

    const { order_information, checkout_type, payment_identifier } = location.state as PaymentInformation;

    const navigatToCheckout = () => {
        navigate('/checkout', { replace: true })
    }

    const navigateToHome = () => {
        navigate('/', { replace: true })
    }

    const navigateToOrders = () => {
        navigate('/orders', { replace: true })
    }

    const pollFunction = useMemo(() => (checkout_type === 'direct' ? directPaymentPoll : hostedPaymentPoll), [checkout_type])

    const handleSuccess = () => {
        setTimeout(() => setState(draft => {
            if (!draft.payment_processed) {
                draft.payment_processed = true;
                draft.is_successful_payment = true;
            }
        }), 2000)
    }

    const handleFailure = (message?: string) => {
        setTimeout(() => setState(draft => {
            if (!draft.payment_processed) {
                draft.payment_processed = true;
                draft.is_successful_payment = false;
                draft.failure_reason = message;
            }
        }), 2000)
    }

    const animationIconDim = isBig ? '50%' : (isMedium ? '60%' : '70%')


    const getDeliveryDate = ({ date, start_time, end_time }: DELIVERY_WINDOW_TYPE) => {
        return `${moment(date).format('MMM Do YY')}, ${moment(start_time, 'hh:mm').format('h A')} - ${moment(end_time, 'hh:mm').format('h A')}`
    }

    const getPaymentUnsuccessfulMessage = (failure_message?: string) => {
        let baseTitle: string = t('payment.baseTitle') + '\n'
        let basePrompt: string = t('payment.basePrompt');
        let baseAction: string = t('payment.baseAction');

        if (!failure_message || failure_message === 'Error') { return baseTitle + `${basePrompt} ${baseAction}` }
        else if (failure_message.search(/[P|p]lease/gm)) { return baseTitle + failure_message }
        else { return baseTitle + `${failure_message + (Boolean(failure_message.trimEnd().match(/.+[\.|!|?]/gm)?.length) ? '' : '.')} ${baseAction}` }
    }

    useEffect(() => {
        const getHostedCheckoutResponse = () => {
            processPaymentPolling(
                order_information,
                payment_identifier as string,
                pollFunction,
                undefined,
                handleSuccess,
                handleFailure)
        }

        switch (checkout_type) {
            case 'cash':
                setState({
                    payment_processed: true,
                    is_successful_payment: true,
                })
                return;
            default:
                !state.payment_processed && getHostedCheckoutResponse();
                return;
        }

    }, [])

    useEffect(() => {
        setState(draft => {
            draft.payment_failed_animation_data = paymentFailed;
            draft.payment_loading_animation_data = paymentLoading;
            draft.payment_succeeded_animation_data = paymentSucceeded;
        })
        // todo: figure out how to lazy load json objects;
    }, []);

    const dispatch = useDispatch();

    useEffect(() => {
        if (state.is_successful_payment) {
            dispatch(clearCart());
        }
        if (state.is_successful_payment) {
            purchase_gtag_report_conversion(order_information.order_id, getOrderTotalCost(order_information))
        }
    }, [state.is_successful_payment])

    return (
        <div {...{
            style: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.WHITE,
                height: `${BIG_CONTENT_CONTAINER_HEIGHT}vh`,
            }
        }}>
            <Helmet><title>{t('payment.metaTitle')}</title></Helmet>
            <AnimationContainer>
                {
                    !state.payment_processed ?
                        // LOADING ANIMATION
                        (
                            state.payment_loading_animation_data ?
                                <LoadingContainer>
                                    <Lottie loop play {...{
                                        speed: 2,
                                        style: { width: '100%' },
                                        animationData: state.payment_loading_animation_data,
                                    }} />
                                </LoadingContainer> : null
                        ) :
                        (
                            state.is_successful_payment ?
                                // SUCCESS ANIMATION
                                (
                                    state.payment_succeeded_animation_data ?
                                        <StatusContainer>
                                            <Lottie play {...{
                                                loop: 4,
                                                style: { height: animationIconDim },
                                                animationData: state.payment_succeeded_animation_data,
                                            }} />

                                            <StatusInformationContainer>
                                                <StatusMessage {...{ color: COLORS.GREEN_BRIGHT }}>
                                                    {t('payment.paymentSuccessful')}
                                                </StatusMessage>
                                                <StatusMessage {...{ color: COLORS.GREEN_BRIGHT }}>
                                                    {t('payment.orderIdentification', { order_identification_number: order_information.order_id })}
                                                </StatusMessage>
                                                <DeliveryContainer>
                                                    <DeliveryRow>
                                                        <Title>{t('payment.deliveringOn')}</Title>
                                                        <DeliveryTime>
                                                            {getDeliveryDate(order_information.delivery_schedule_window)}
                                                        </DeliveryTime>

                                                    </DeliveryRow>
                                                </DeliveryContainer>
                                            </StatusInformationContainer>
                                            <Button {...{
                                                color: COLORS.GREEN_BRIGHT,
                                                onClick: navigateToHome
                                            }}>
                                                {t('payment.backToHome')}
                                            </Button>
                                            <Button {...{
                                                color: COLORS.ORANGE,
                                                onClick: navigateToOrders
                                            }}>
                                                {t('payment.trackYourOrder')}
                                            </Button>
                                        </StatusContainer>
                                        : null
                                ) :
                                (
                                    state.is_successful_payment !== undefined ?
                                        // FAILURE ANIMATION
                                        (
                                            state.payment_failed_animation_data ?
                                                <StatusContainer>
                                                    <Lottie play {...{
                                                        loop: 4,
                                                        style: { height: animationIconDim },
                                                        animationData: state.payment_failed_animation_data,
                                                    }} />
                                                    <StatusInformationContainer>
                                                        <StatusMessage {...{ color: COLORS.RED }}>
                                                            {getPaymentUnsuccessfulMessage(state.failure_reason)}
                                                        </StatusMessage>
                                                    </StatusInformationContainer>
                                                    <Button {...{
                                                        color: COLORS.RED,
                                                        onClick: navigatToCheckout,
                                                    }}>
                                                        {t('payment.backToCheckout')}
                                                    </Button>
                                                </StatusContainer> : null
                                        )
                                        : null
                                )
                        )
                }
            </AnimationContainer>
        </div>
    )
}

export default PaymentPage