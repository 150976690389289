/* Todo:
This component was written hastly without eyes on quality, due to time constraints. 
Some recommended improvements:

- Mobile layout fix
- Move API fetching to proper folder and structure
- Uncomment and fix sidemenu with filters/sorting (its broken currently)
- Reduce number of required variables and organize neatly in component
- Type the component properly, (input props, state values, etc.)
- Add infinite scroll
- Don't refresh on change category, or a sort/filter apply, instead use <Link> tags to render
- Now page numbers will show all pages, so if 25 pages, will show buttons as 1,2,3,etc all the way to 25.
  Fix this to show a '...' instead in between, so it will be: 1, 2 .... 24, 25 or something like that. 
  Not a priority since we don't have that many products, but still good to have.

*/

import { useCallback, useEffect, useState } from 'react';

//IMPORT STYLED COMPONENTS
import {
    Cancel,
    CancelAbsolute,
    LeftContainer,
    RightContainer,
    ListingWrapper,

    FilterDivider,
    FilterContainer,
    RightDivider,
    ProductsContainer,
    ComingSoon,
} from './ListingElements';


//IMPORT COMPONENTS
import { IoIosClose } from 'react-icons/io';
import Products from '@listing_components/Products';
import QuickAddProduct from '@listing_components/QuickAdd';
import ListingTitle from '@listing_components/ListingTitle';
import PageSelector from '@listing_components/PageSelector';

//IMPORT HOOKS
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

//IMPORT API HELPERS
import { API_PRODUCT_TYPE } from '@api/types';
import { HTTP_METHODS, HTTP_REQUEST } from '@api/interactions';


import { Divider } from '@mui/material';
import { GetDataFromDOM, Loading } from '@custom_components';
import { useFirstRender, useIsScreenBiggerThan } from '@project/hooks';
import FilterInfo from '@listing_components/FilterSideMenu/FilterInfo';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';



// Fix this garbage later
async function getListingsRequest(requestData: any, successCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('listing/', {
        method: HTTP_METHODS.GET,
        options: { data: requestData, validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => { successCallback(response) })
        .catch(err => console.error(err))
}

// Fix this garbage later
async function getCategoriesRequest(successCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('product/category/?parent=root', {
        method: HTTP_METHODS.GET,
        options: {},
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => { successCallback(response) })
        .catch(err => console.error(err))
}

interface SortType {
    sortName: string | null;
    sortValue: string | null
}

const Listing = (props: any) => {
    const PAGE_SIZE = 20;
    const { t } = useTranslation();
    const { category: inputCategory } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [category, setCategory] = useState(inputCategory || undefined)
    const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
    const [listingsApiData, setListingsApiData] = useState({ results: [], count: 0 });


    const { count, results } = listingsApiData;
    const numberOfPages = Math.ceil(count / PAGE_SIZE);

    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<any>(null);
    const [displayLoading, setDisplayLoading] = useState(false);
    const [activeCategory, setActiveCategory] = useState<any>(undefined);

    const isFirstRender = useFirstRender();
    const [openSort, setOpenSort] = useState(false);
    const [openQuickAdd, setOpenQuickAdd] = useState(false);
    const [sort, setSort] = useState<SortType>({ sortName: null, sortValue: null });
    const [quickAddProduct, setQuickAddProduct] = useState<API_PRODUCT_TYPE | undefined>()

    const toggleSideMenu = () => { setOpenSort((prevState) => !prevState) };

    const closeExpandedView = () => {
        setOpenQuickAdd(false);
        setQuickAddProduct(undefined);
    }

    const getListings = useCallback(() => {
        setLoading(true);
        setDisplayLoading(true);
        const requestData: any = { page_size: PAGE_SIZE, page: page }
        if (category) { requestData.category = category }
        if (sort.sortName) { requestData[sort.sortName] = sort.sortValue }
        getListingsRequest(requestData, (response: any) => {
            setListingsApiData(response);
            setLoading(false);
        })
    }, [page, PAGE_SIZE, sort, category])

    const changePageNumber = (newPage: number) => {
        setSearchParams({ page: newPage.toString() })
        setPage(newPage);
    }

    useEffect(() => {
        let cancelled = false;
        !cancelled && setPage(parseInt(searchParams.get('page') || '1'))
        return () => { cancelled = true; }
    }, [searchParams])

    useEffect(() => {
        if (isFirstRender) {
            const listingsFromDOM = props?.responseDataDOM?.api_data?.products;
            if (listingsFromDOM) { setListingsApiData(listingsFromDOM); return }
        }

        getListings();
    }, [page, category])

    const _900 = useIsScreenBiggerThan(900);

    useEffect(() => {
        if (!_900) {
            setOpenQuickAdd(false);
            setQuickAddProduct(undefined);
        }
    }, [_900])

    useEffect(() => {
        setCategory(inputCategory);
        setPage(parseInt(searchParams.get('page') || '1'));
    }, [inputCategory])

    useEffect(() => {
        let cancelled = false;
        let timeout: NodeJS.Timeout;
        if (!loading) { if (!cancelled) { timeout = setTimeout(() => !cancelled && setDisplayLoading(false), 1500) } }
        return () => { cancelled = true; clearTimeout(timeout) }
    }, [loading])


    useEffect(() => {
        if (isFirstRender) {
            const categoriesFromDOM = props?.responseDataDOM?.api_data?.root_categories;
            if (categoriesFromDOM) {
                setCategories(categoriesFromDOM);
                return;
            }
        }
        getCategoriesRequest(setCategories)
    }, [])

    useEffect(() => {
        if (!category) {
            setActiveCategory(null);
        }
        else if (categories) {
            let activeCategory_ = categories?.find((cat: { slug: string | undefined; }) => cat.slug === category) || null;
            setActiveCategory(activeCategory_);
        }
    }, [category, categories])

    // uncomment and add when listings change on category no longer uses an href
    // useEffect(() => { setActiveCategory(category) }, [category]);

    return (
        <ListingWrapper>

            {
                Boolean(activeCategory !== undefined) &&
                <Helmet>
                    <title>{activeCategory ? t('listing.metaTitle', { category: activeCategory.name }) : t('listing.allProductsMetaTitle')}</title>
                    <meta name="description" content={activeCategory ? t('listing.metaDescription', { category: activeCategory.name }) : t('listing.metaDescriptionAllProducts')} />
                </Helmet>
            }
            {
                Boolean(results && activeCategory !== undefined) &&
                <FilterContainer {...{ is_open: openSort }}>
                    <FilterInfo {...{ categories, activeCategory, sideMenuOpen: openSort, toggleSideMenu }} />
                    <FilterDivider {...{ is_open: openSort }}>
                        <Divider {...{ orientation: 'vertical' }} />
                    </FilterDivider>
                </FilterContainer>
            }
            <LeftContainer>
                {
                    activeCategory !== undefined && <ListingTitle {...{
                        count,
                        toggleSideMenu,
                        activeCategory,
                    }} />
                }

                {
                    displayLoading ? <Loading /> : (
                        <ProductsContainer>
                            {
                                Boolean(results && results.length) ?
                                    <>
                                        <Products {...{
                                            products: results,
                                            expandedProduct: quickAddProduct?.product_id,
                                            setAddInfo: (product: API_PRODUCT_TYPE) => {
                                                setOpenQuickAdd(true);
                                                setQuickAddProduct(product);
                                            }
                                        }} />
                                        <PageSelector
                                            {...{
                                                currentPage: page,
                                                maxPages: numberOfPages,
                                                changePageNumber: changePageNumber,
                                            }}
                                        />
                                    </>
                                    :
                                    <ComingSoon><h1>Coming Soon</h1></ComingSoon>
                            }
                        </ProductsContainer>
                    )
                }


            </LeftContainer>
            {
                results &&
                <RightContainer {...{ is_open: openQuickAdd }}>
                    {Boolean(quickAddProduct) &&
                        <>
                            <QuickAddProduct {...{ product: quickAddProduct as API_PRODUCT_TYPE }} />
                            <CancelAbsolute {...{ is_open: openQuickAdd, isSortOpen: openSort, onClick: closeExpandedView }}>
                                <IoIosClose {...{ style: { fontSize: '1.6rem', color: 'white' } }} />
                            </CancelAbsolute>
                            <RightDivider {...{ is_open: openQuickAdd }}>
                                <Divider {...{ orientation: 'vertical' }} />
                            </RightDivider>
                        </>
                    }
                </RightContainer>
            }

        </ListingWrapper>
    );
};;

export default GetDataFromDOM(Listing);
