import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const i18nCookieName = 'i18n_language'
export const i18nLocalStorageName = 'i18n_language'
export const defaultLang = 'en'
export const availableLanguages = ['en', 'ar']

import translationsEnglish from './translations/en/common.json';
import translationsArabic from './translations/ar/common.json';


export function initTranslation() {
    i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: { en: { common: translationsEnglish }, ar: { common: translationsArabic } },
            debug: false,
            defaultNS: 'common',
            // lng: 'cimode', //  Uncomment to see translation keys live
            fallbackLng: defaultLang,
            interpolation: { escapeValue: false },
            supportedLngs: availableLanguages,
            detection: {
                order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
                caches: ['cookie', 'localStorage'],
                lookupCookie: i18nCookieName,
                lookupLocalStorage: i18nCookieName,
                cookieMinutes: 525600,
                lookupFromPathIndex: 0,
            }
        });
};

