import styled from 'styled-components';
import profilePic from '@assets/images/user/placeholderProfilePic.jpg';
import logOutButton from '@assets/images/user/logOutIcon.png';
import { COLORS } from '../../../../common/styles';
import cameraIcon from '@assets/images/user/camera.png';
import { GiRamProfile } from 'react-icons/gi';
// PROFILE IMAGE AND BUTTONS
export const ProfileImageAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
export const ImageAndName = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    align-items: center;
`;
export const UserNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 30px;
`;
export const UserNameText = styled.h1`
    font-weight: normal;
    margin: 0;
`;
export const UserEmail = styled.h3`
    margin: 0;
    font-weight: normal;
    opacity: 0.5;
`;
export const CameraIcon = styled.img.attrs({
    src: cameraIcon,
})`
    display: flex;
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    transition: opacity .25s ease;
`;

export const ImagesWrapper = styled.div``;
export const ProfileImage = styled.img`
    width: 150px;
    transition: filter .25s ease;
`;
export const ProfileImageWrapper = styled.div`
    /* background-size: cover; */
    margin-top: -50px;
    cursor: pointer;
    border: 5px solid white;

    width: 150px;
    height: 150px;
    border-radius: 200px;
    left: 320px;
    bottom: -95px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color .25s ease;

    @media screen and (max-width: 800px) {
        width: 100px;
        height: 100px;
    }

    &:hover ${CameraIcon} {
        opacity: 1;
    }

    &:hover ${ProfileImage} {
        filter: brightness(50%);
    }
`;


export const ButtonsContainer = styled.div`
    display: flex;
    margin: 0 50px;
    justify-content: flex-end;
    align-items: center;
    width: 12%;
    @media screen and (max-width: 800px) {
        margin: 0 10px;
    }
`;
export const LogoutButton = styled.div`
    border: 1px solid ${COLORS.RED};
    height: 50%;
    width: 100px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const LogoutButtonText = styled.h3`
    margin: 0;
    font-weight: normal;
    color: ${COLORS.RED};
`;

export const LogOutButtonMobile = styled.img.attrs({
    src: logOutButton,
})`
    width: 30px;
    height: 30px;
    margin-right: 25px;
`;

export const UserFacelessIconLarge = styled(GiRamProfile)`
    color: green;
    font-size: 5rem;
`

export const FacelessIconWrapper = styled.div`
    /* background-size: cover; */
    margin-top: -50px;
    border: 5px solid green;
    background-color: ${COLORS.WHITE_SMOKE};
    width: 150px;
    height: 150px;
    border-radius: 200px;
    cursor: pointer;
    left: 320px;
    bottom: -95px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color .25s ease;


    @media screen and (max-width: 800px) {
        width: 100px;
        height: 100px;
    }

    &:hover ${CameraIcon} {
        opacity: 1;
    }

    &:hover {
        background-color: rgba(200, 200, 200, 1);
    }

`;