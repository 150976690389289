import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 800px) {
        align-items: center;
    }
`;
export const NewPasswordWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    height: 130px;
    @media screen and (max-width: 800px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;
export const TextInput = styled(TextField)`
    width: 48%;
    margin-top: 20px;
    @media screen and (max-width: 800px) {
        width: 90%;
    }
`;

export const SubmitButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const TitleWrapper = styled.div`
    margin: 30px 0;
`;
