import styled from 'styled-components';
import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';


export const OrderMainWrapper = styled.div`
    display: flex;
    position: relative;
    min-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
    padding: 0px 20px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${COLORS.WHITE};
    @media screen and (max-width: 768px) {
        padding: 1% 0%;
    }
`