
// IMPORT TYPES
import { APP_STATE_ORDER_DETAIL_TYPE, OrderStatusType, ORDER_STATUS } from "@api/types";

// IMPORT HELPERS
import moment from 'moment';
import { getOrderLighterColor, getOrderMainColor, isCancelledOrder } from "@project/helpers";

// IMPORT COMPONENTS
import {
    TimeInfo,
    TimelineRow,
    DescriptionInfo,

    EndBlock,
    StartBlock,
    MiddleBlock,
    OrderTimelineContainer,
    InfoSubheader,
    InfoHeader,
} from "./TicketElements";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const OrderTimelineBlocks = (props: APP_STATE_ORDER_DETAIL_TYPE) => {
    const {
        status,
        failed_time,
        pickup_time,
        accepted_time,
        delivered_time,
        created_at,
    } = props;

    const { t } = useTranslation();

    const isCancelled = isCancelledOrder(status);
    const active_main_color = getOrderMainColor(status);
    const active_light_color = getOrderLighterColor(status);

    const getFailHeader = (status: OrderStatusType) => {
        switch (status) {
            case ORDER_STATUS.COULD_NOT_FULFILL:
                return t('orders.orderNotFulfilled');
            case ORDER_STATUS.CANCELLED:
                return t('orders.orderCancelled');
            default: return t('orders.orderFailed');
        }
    }

    const getFailInfo = (status: OrderStatusType) => {
        switch (status) {
            case ORDER_STATUS.COULD_NOT_FULFILL:
                return t('orders.orderCouldNotFulfillBody');
            case ORDER_STATUS.CANCELLED:
                return t('orders.orderCancelledBody');
            default:
                return t('orders.orderFailedBody');
        }
    }
    const blocks = useMemo(() => {

        const blocks: (React.FC)[] = [];
        const FailBlock = () => (
            <TimelineRow>
                <TimeInfo>
                    {
                        Boolean(failed_time) && <>
                            <InfoHeader>{moment(failed_time).format('h:mm A')}</InfoHeader>
                            <InfoSubheader>{moment(failed_time).format('Do MMM YY')}</InfoSubheader>
                        </>
                    }
                </TimeInfo>
                <EndBlock {...{ active_main_color, active_light_color }} activated />
                <DescriptionInfo>
                    {
                        Boolean(isCancelled) && <>
                            <InfoHeader>{getFailHeader(status)}</InfoHeader>
                            <InfoSubheader>{getFailInfo(status)}</InfoSubheader>
                        </>
                    }
                </DescriptionInfo>
            </TimelineRow>
        );

        blocks.push(() => (
            <TimelineRow>
                <TimeInfo>
                    <InfoHeader>{moment(created_at).format('h:mm A')}</InfoHeader>
                    <InfoSubheader>{moment(created_at).format('Do MMM YY')}</InfoSubheader>
                </TimeInfo>
                <StartBlock {...{ active_main_color, active_light_color }} activated />
                <DescriptionInfo>
                    <InfoHeader>{t("orders.orderReceived")}</InfoHeader>
                    <InfoSubheader>{t("orders.orderReceivedBody")}</InfoSubheader>
                </DescriptionInfo>
            </TimelineRow>
        ))

        if (!Boolean(accepted_time)) {
            if (isCancelled) {
                blocks.push(FailBlock);
                return blocks;
            }
        }

        blocks.push(() => (
            <TimelineRow>
                <TimeInfo>
                    {
                        Boolean(accepted_time) &&
                        <>
                            <InfoHeader>{moment(accepted_time).format('h:mm A')}</InfoHeader>
                            <InfoSubheader>{moment(accepted_time).format('Do MMM YY')}</InfoSubheader>
                        </>
                    }
                </TimeInfo>
                <MiddleBlock {...{ active_main_color, active_light_color, activated: Boolean(accepted_time) }} />
                <DescriptionInfo>
                    <InfoHeader>{t("orders.orderAccepted")}</InfoHeader>
                    <InfoSubheader>{t("orders.orderAcceptedBody")}</InfoSubheader>
                </DescriptionInfo>
            </TimelineRow>
        ))

        if (!Boolean(pickup_time)) {
            if (isCancelled) {
                blocks.push(FailBlock);
                return blocks;
            }
        }

        blocks.push(() => (
            <TimelineRow>
                <TimeInfo>
                    {
                        Boolean(pickup_time) &&
                        <>
                            <InfoHeader>{moment(pickup_time).format('h:mm A')}</InfoHeader>
                            <InfoSubheader>{moment(pickup_time).format('Do MMM YY')}</InfoSubheader>
                        </>
                    }
                </TimeInfo>
                <MiddleBlock {...{ active_main_color, active_light_color, activated: Boolean(pickup_time) }} />
                <DescriptionInfo>
                    <InfoHeader>{t("orders.deliveringYourOrder")}</InfoHeader>
                    <InfoSubheader>{t("orders.deliveringYourOrderBody")}</InfoSubheader>
                </DescriptionInfo>
            </TimelineRow>
        ))

        if (!Boolean(delivered_time)) {
            if (isCancelled) {
                blocks.push(FailBlock);
                return blocks;
            }
        }

        blocks.push(() => (
            <TimelineRow>
                <TimeInfo>
                    {
                        Boolean(delivered_time) &&
                        <>
                            <InfoHeader>{moment(delivered_time).format('h:mm A')}</InfoHeader>
                            <InfoSubheader>{moment(delivered_time).format('Do MMM YY')}</InfoSubheader>
                        </>
                    }
                </TimeInfo>
                {
                    isCancelled
                        ? <MiddleBlock {...{ active_main_color, active_light_color, activated: Boolean(delivered_time) }} />
                        : <EndBlock {...{ active_main_color, active_light_color, activated: Boolean(delivered_time) }} />
                }
                <DescriptionInfo>
                    <InfoHeader>{t("orders.orderDelivered")}</InfoHeader>
                    <InfoSubheader>{t('orders.orderDeliveredBody')}</InfoSubheader>
                </DescriptionInfo>
            </TimelineRow>
        ))

        if (isCancelled) {
            blocks.push(FailBlock);
        }

        return blocks;
    }, [props])

    return (
        <OrderTimelineContainer>
            {
                blocks.map((Block, key) => (<Block {...{ key }} />))
            }
        </OrderTimelineContainer>
    )
}