
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import {
    MAKE_ORDER_PARAMS,
    APP_STATE_ORDER_TYPE,
    API_ORDER_DETAIL_TYPE,
} from '@api/types';
import { getOrderStatus } from '@api/checkout/helpers';


const makeOrderFetch = async (data: MAKE_ORDER_PARAMS) => {
    const HttpRequest = new HTTP_REQUEST(`order/`, {
        method: HTTP_METHODS.POST,
        options: { data, validateCookies: true, json: true },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};

const makeOrder = async (data: MAKE_ORDER_PARAMS): Promise<RESPONSE_TYPE<APP_STATE_ORDER_TYPE>> => {
    try {
        const response = await makeOrderFetch(data);
        let response_body: API_ORDER_DETAIL_TYPE = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<APP_STATE_ORDER_TYPE>({
                ...response_body,
                status: getOrderStatus(response_body.status),
            })
        } else {
            return FAILURE_RESPONSE('MAKE_ORDER', response_body)
        }
    } catch (error) {
        return FAILURE_RESPONSE('MAKE_ORDER', error)
    }
}


export default makeOrder;