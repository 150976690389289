import React from 'react';

// IMPORT COMPONENTS
import {
    LoadingWrapper,
    LoadingFullScreen,
} from './LoadingElements';


// IMPORT ASSETS
import { MagnifyingGlass } from 'react-loader-spinner';

interface LoadingProps {
    fullScreen?: boolean;
}

export const Loading = (props: LoadingProps) => {

    const { fullScreen } = {
        fullScreen: false,
        ...props
    }

    return fullScreen ?
        <LoadingFullScreen>
            <MagnifyingGlass {...{
                visible: true,
                height: 200,
                width: 200,
                color: '#5cbb6b',
                glassColor: '#c0efff',
                ariaLabel: "MagnifyingGlass-loading",
                wrapperClass: "MagnifyingGlass-wrapper",
            }} />
        </LoadingFullScreen>
        :
        <LoadingWrapper>
            <MagnifyingGlass {...{
                visible: true,
                height: 200,
                width: 200,
                color: '#5cbb6b',
                glassColor: '#c0efff',
                ariaLabel: "MagnifyingGlass-loading",
                wrapperClass: "MagnifyingGlass-wrapper",
            }} />
        </LoadingWrapper>

}