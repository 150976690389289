import { useTranslation } from 'react-i18next';
import {
    SocialLogo,
    SocialMedia,
    SocialIcons,
    SocialMediaWrap,
    SocialIconsLink,

    WebsiteRights,

    WebsiteDocsContainer,
    TermsOfService,
    PrivacyPolicy,
    DownloadOnLogos,
    FollowUsOnLogos,

    FooterWrap,
    FooterContainer,

    FooterLink,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLinksWrapper,
    FooterLinksContainer,
    AppStoreLink,
    AppStoreLinkImage,
    PlayStoreLinkImage,
    PlayStoreLink,
    FollowUsOnFacebookLink,
    FollowUsOnFacebookImage,
    FollowUsOnInstagramLink,
    FollowUsOnInstagramImage,
} from './FooterElements';
import { RouterPages } from '@project/routes';
import greenitIconWhite from '@assets/images/footer/greenitIconWhite.png';
import { useMatch } from 'react-router-dom';
import { availableLanguages } from '@translation';
import { HOSTNAME } from '@project/config';


export const Footer = () => {
    const { t } = useTranslation();

    const matchesPaymentLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/payment/*`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })();

    const matchesCartLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/cart`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesCheckoutLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/checkout`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesLoginLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/login`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesForgotPasswordLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/passowrd/forgot`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesSignupLanguages = (() => {
        return availableLanguages.map((lang) => useMatch({
            path: `${lang}/auth/signup`,
            caseSensitive: true,
        }) !== null).find(elem => Boolean(elem)) !== undefined;
    })()

    const matchesSignup = useMatch({
        path: 'auth/signup',
        caseSensitive: true, end: true,
    }) !== null || matchesSignupLanguages;

    const matchesForgotPassword = useMatch({
        path: 'auth/password/forgot',
        caseSensitive: true, end: true,
    }) !== null || matchesForgotPasswordLanguages;

    const matchesPayment = useMatch({
        path: 'payment/*',
        caseSensitive: true,
    }) !== null || matchesPaymentLanguages;

    const matchesCart = useMatch({
        path: 'cart',
        caseSensitive: true, end: true,
    }) !== null || matchesCartLanguages;

    const matchesLogin = useMatch({
        path: 'auth/login',
        caseSensitive: true, end: true,
    }) !== null || matchesLoginLanguages;

    const matchesCheckout = useMatch({
        path: 'checkout',
        caseSensitive: true, end: true,
    }) !== null || matchesCheckoutLanguages;

    const deactivateDisplay = matchesPayment || matchesCart || matchesCheckout || matchesLogin || matchesSignup || matchesForgotPassword;

    return (
        <FooterContainer {...{ deactivateDisplay }}>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t('footer.Company')}</FooterLinkTitle>
                            <FooterLink page={RouterPages.Home}>{t('footer.Home')}</FooterLink>
                            <FooterLink page={RouterPages.Home}>{t('footer.FAQ')}</FooterLink>
                            <FooterLink page={RouterPages.Home}>{t('footer.About')}</FooterLink>
                            <FooterLink page={RouterPages.Home}>{t('footer.Contact')}</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t('footer.Account')}</FooterLinkTitle>
                            <FooterLink page={RouterPages.Login}>{t('footer.loginOrSignup')}</FooterLink>
                            <FooterLink page={RouterPages.UserProfilePage}>{t('footer.AccountInfo')}</FooterLink>
                            <FooterLink page={RouterPages.OrdersPage}>{t('footer.myOrders')}</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t('footer.downloadApp')}</FooterLinkTitle>
                            <DownloadOnLogos>
                                <AppStoreLink>
                                    <AppStoreLinkImage />
                                </AppStoreLink>
                                <PlayStoreLink>
                                    <PlayStoreLinkImage />
                                </PlayStoreLink>
                            </DownloadOnLogos>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <div style={{ height: 'auto', }}>
                                <FooterLinkTitle>{t('footer.followUs')}</FooterLinkTitle>
                                <FollowUsOnLogos>
                                    <FollowUsOnFacebookLink>
                                        <FollowUsOnFacebookImage />
                                    </FollowUsOnFacebookLink>
                                    <FollowUsOnInstagramLink>
                                        <FollowUsOnInstagramImage />
                                    </FollowUsOnInstagramLink>
                                </FollowUsOnLogos>
                            </div>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo href="/">
                            <img
                                style={{ width: '15%', maxWidth: 30 }}
                                src={greenitIconWhite}
                            />
                        </SocialLogo>
                        <WebsiteRights>
                            {t('footer.companyTrademarkSentence')}
                        </WebsiteRights>
                        <WebsiteDocsContainer>
                            <PrivacyPolicy href={`${HOSTNAME}/privacy-policy`}>
                                {t('footer.privacyPolicy')}
                            </PrivacyPolicy>
                            <div style={{ color: 'white' }}> | </div>
                            <TermsOfService href="https://www.greenit.qa/docs/terms-of-service">
                                {t('footer.termsOfService')}
                            </TermsOfService>
                        </WebsiteDocsContainer>
                        <SocialIcons>
                            <SocialIconsLink
                                href='https://www.facebook.com/Greenit-105408695224073'
                                target="_blank"
                                aria-label="Facebook"
                            ></SocialIconsLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};
