import { useEffect, useState } from 'react';

// IMPORT TYPES
import {
    API_ADDRESS_ERROR_TYPE,
    API_ADDRESS_TYPE,
    AreaType,
    default_address,
    default_address_errors,
    PRIVATE_API_ADDRESS_TYPE,
} from '@api/user/address/types';

// IMPORT COMPONENTS
import {
    MenuItem,
    InputLabel,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import {
    Map,
    LoadingButton,
    PrivateButtonState,
    PrivateCoordinates,
} from '@custom_components';
import PhoneNumberInput from '../../../pages/UserProfile/Tabs/AddressAndCardsTab/PhoneNumberInput';

// IMPORT STYLED COMPONENTS

import {
    MapContainer,

    TextInput,
    TextInputWrapper,
    TextInputTwoFields,
    TextInputOneField,
    InputSectionTitle,
    AddressFormControl,
    SelectAddressType,

    TitleWrapper,
    TitleButtonsWrapper,
} from '@form_components';

import {
    CommonButton,
    CommonButtonText,
} from '@styled_components';
import { RenderFields } from './RenderFields';
import { AddNewAddressesContainer } from './AddressFormElements';
import { AddAddressButtonWrapper } from '../../../pages/UserProfile/Tabs/AddressAndCardsTab/AddressesAndCardsElements';


//IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HELPERS
import {
    LatLng,
    DEFAULT_LATLNG,
    validAddress,
} from '../../../pages/UserProfile/Tabs/AddressAndCardsTab/SavedAddresses/addressHelpers';

// IMPORT API INTERACTIONS
import {
    addNewAddress,
    updateUserAddressState,
} from '@redux/state/user';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import addUserAddress from '@api/user/address/add';
import updateUserAddress from '@api/user/address/update';
import { promiseMessage } from '@overlays/notifications';


import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';


interface AddAddressDetails {
    type: 'Add';
    open: boolean;
    formTitle: string;
    hideForm: () => void;
}

interface EditAddressDetails {
    type: 'Edit';
    open: boolean;
    formTitle: string;
    hideForm: () => void;
    editAddress: API_ADDRESS_TYPE;
}

type AddressDetailsTypes = AddAddressDetails | EditAddressDetails;

const AddressForm = (props: AddressDetailsTypes) => {
    const { t } = useTranslation();

    // STATE MANAGEMENT
    const { type, open, hideForm, formTitle } = props;

    useEffect(() => {
        let cancelled = false;

        if (!cancelled) {
            setAddressForm(
                type === 'Edit' ? props.editAddress : default_address
            );
        }

        return () => { cancelled = true }
    }, [type, type === 'Edit' && props.editAddress]);

    const [addressForm, setAddressForm] = useState<
        PRIVATE_API_ADDRESS_TYPE | API_ADDRESS_TYPE
    >(default_address);


    const {
        area,
        is_main,
        street_name,
        address_type,
        street_number,
        address_nickname,
        additional_directions,
    } = addressForm;


    const [formValidation, setFormValidation] = useImmer<{
        status: boolean;
        errors: Partial<API_ADDRESS_ERROR_TYPE>;
    }>({ status: false, errors: default_address_errors });


    const [submitButtonState, setSubmitButtonState] = useState<PrivateButtonState & {
        isValid: boolean;
    }>({
        loading: false,
        submitted: false,
        success: null,
        error: '',
        isValid: false,
    });

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        let cancelled = false;
        const { status } = validAddress(addressForm);
        if (status && !cancelled) {
            setSubmitButtonState((state) => ({ ...state, isValid: true }));
        }
        return () => { cancelled = true }
    }, [addressForm]);


    // HELPER FUNCTIONS
    const onMapMarkerMoved = useCallback(
        ({ latitude, longitude }: PrivateCoordinates) => {
            const parsedLat = parseFloat(latitude.toFixed(4));
            const parsedLng = parseFloat(longitude.toFixed(4));
            setAddressForm((state) => ({
                ...state,
                latitude: parsedLat,
                longitude: parsedLng,
            }));
        },
        []
    );

    const dispatch = useDispatch();


    const addOrUpdateAddress = async (): Promise<boolean> => {
        setFormValidation(validAddress(addressForm));
        setSubmitted(true);

        if (submitButtonState.isValid) {
            setSubmitButtonState((state) => ({
                ...state,
                loading: true,
                submitted: true,
            }));

            if (type === 'Add') {
                const response = await addUserAddress(addressForm);
                if (response.status) {
                    dispatch(addNewAddress(response.response_body));
                    setSubmitButtonState((state) => ({
                        ...state,
                        loading: false,
                        success: true,
                    }));
                } else {
                    setSubmitButtonState((state) => ({
                        ...state,
                        loading: false,
                        success: false,
                        error:
                            response.error?.error_message ||
                            'There has been an issue while submitting',
                    }));
                }
                if (response.status) { hideForm(); }
                return new Promise((rs, rj) => response.status ? rs(true) : rj(false));

            } else {
                const response = await updateUserAddress(
                    props.editAddress.address_id,
                    addressForm
                );
                if (response.status) {
                    setSubmitButtonState((state) => ({
                        ...state,
                        loading: false,
                        success: true,
                    }));
                    dispatch(updateUserAddressState(response.response_body));
                } else {
                    setSubmitButtonState((state) => ({
                        ...state,
                        loading: false,
                        success: false,
                        error:
                            response.error?.error_message ||
                            'There has been an issue while submitting',
                    }));
                }

                if (response.status) { hideForm(); }

                return new Promise((rs, rj) => response.status ? rs(true) : rj(false));
            }
        }

        return new Promise((_, rj) => rj(false));
    };

    const handleSubmit = () => {
        promiseMessage({
            promise: addOrUpdateAddress(),
            success: type === 'Add'
                ? 'Address successfully added!'
                : 'Address successfully updated!',
            loading: 'Validating and setting address...',
            error: 'There has been an issue while submitting'
        })
    }

    const saveNumber = (number: string) => {
        setAddressForm(state => ({
            ...state,
            mobile_number: number,
        }))
    }

    const getLocationOnType = useCallback(() => {
        switch (type) {
            case 'Add':
                return undefined;
            case 'Edit':
                return ({
                    latitude: props.editAddress.latitude,
                    longitude: props.editAddress.longitude,
                })
        }
    }, [type, props])

    return (
        <AddNewAddressesContainer {...{ isOpen: open }}>
            <TitleWrapper>
                <InputSectionTitle>{formTitle}</InputSectionTitle>
                <TitleButtonsWrapper>
                    <CommonButton
                        {...{
                            style: {
                                margin: '0 5px',
                                padding: '0 10px',
                                width: 'auto',
                            },
                            color: COLORS.RED,
                            onClick: hideForm,
                        }}
                    >
                        <CommonButtonText
                            {...{
                                color: COLORS.RED,
                                style: {
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                }
                            }}
                        >
                            Cancel
                        </CommonButtonText>
                    </CommonButton>
                </TitleButtonsWrapper>
            </TitleWrapper>

            <MapContainer>
                <Map {...{
                    form_opened: open,
                    on_set_position: onMapMarkerMoved,
                    location: getLocationOnType(),
                }} interactible />
            </MapContainer>

            <TextInputTwoFields>
                <TextInput
                    {...{
                        label: t("userProfile.addressNickname"),
                        variant: 'outlined',
                        value: address_nickname || '',
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                address_nickname: e.target.value,
                            })),
                    }}
                />

                <AddressFormControl
                    required={true}
                    error={formValidation.errors.address_type}
                >
                    <InputLabel>{t("userProfile.addressType")}</InputLabel>
                    <SelectAddressType
                        {...{
                            required: true,
                            label: t("userProfile.addressType"),
                            value: address_type || '',
                            onChange: (e) =>
                                setAddressForm((state) => ({
                                    ...state,
                                    address_type: e.target
                                        .value as API_ADDRESS_TYPE['address_type'],
                                })),
                        }}
                    >
                        <MenuItem value={'House'}>{t("userProfile.house")}</MenuItem>
                        <MenuItem value={'Apartment'}>{t("userProfile.apartment")}</MenuItem>
                        <MenuItem value={'Compound'}>{t("userProfile.compound")}</MenuItem>
                        <MenuItem value={'Office'}>{t("userProfile.office")}</MenuItem>
                    </SelectAddressType>
                </AddressFormControl>
            </TextInputTwoFields>

            <TextInputTwoFields>
                <AddressFormControl
                    required={true}
                    error={formValidation.errors.area}
                >
                    <InputLabel id="demo-simple-select-helper-label">
                        {t("userProfile.areaName")}
                    </InputLabel>
                    <SelectAddressType
                        {...{
                            required: true,
                            label: t('userProfile.areaName'),
                            value: area || '',
                            onChange: (e) =>
                                setAddressForm((state) => ({
                                    ...state,
                                    area: e.target.value as string,
                                })),

                            MenuProps: { style: { maxHeight: 200 } },
                        }}
                    >
                        {AreaType.map((addressName, index) => (
                            <MenuItem
                                {...{ key: index, value: addressName || '' }}
                            >
                                {addressName}
                            </MenuItem>
                        ))}
                    </SelectAddressType>

                </AddressFormControl>
                <PhoneNumberInput
                    {...{
                        saveNumber,
                        isSubmitted: submitted,
                        isValid: !formValidation.errors
                            .mobile_number as boolean,
                    }}
                />
            </TextInputTwoFields>

            <TextInputTwoFields>
                <TextInput
                    {...{
                        label: t("userProfile.streetName"),
                        variant: 'outlined',
                        value: street_name || '',
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                street_name: e.target.value,
                            })),
                    }}
                />
                <TextInput
                    {...{
                        required: true,
                        label: t("userProfile.streetNumber"),
                        variant: 'outlined',
                        value: street_number || '',
                        error: formValidation.errors.street_number,
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                street_number: e.target.value,
                            })),
                    }}
                />
            </TextInputTwoFields>

            {/* 

                Single Field Variants for Small Screen Sizes
                *************** STARTS HERE ****************
            
            */}

            <TextInputOneField>
                <TextInput
                    {...{
                        label: t("userProfile.addressNickname"),
                        variant: 'outlined',
                        value: address_nickname || '',
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                address_nickname: e.target.value,
                            })),
                    }}
                />
            </TextInputOneField>

            <TextInputOneField>
                <AddressFormControl
                    required={true}
                    error={formValidation.errors.address_type}
                >
                    <InputLabel>{t("userProfile.addressType")}</InputLabel>
                    <SelectAddressType
                        {...{
                            required: true,
                            label: t("userProfile.addressType"),
                            value: address_type || '',
                            onChange: (e) =>
                                setAddressForm((state) => ({
                                    ...state,
                                    address_type: e.target
                                        .value as API_ADDRESS_TYPE['address_type'],
                                })),
                        }}
                    >
                        <MenuItem value={t('userProfile.house')}></MenuItem>
                        <MenuItem value={t('userProfile.apartment')}></MenuItem>
                        <MenuItem value={t('userProfile.compound')}></MenuItem>
                        <MenuItem value={t('userProfile.office')}></MenuItem>
                    </SelectAddressType>
                </AddressFormControl>
            </TextInputOneField>

            <TextInputOneField>
                <AddressFormControl
                    required={true}
                    error={formValidation.errors.area}
                >
                    <InputLabel id="demo-simple-select-helper-label">
                        {t("userProfile.areaName")}
                    </InputLabel>
                    <SelectAddressType
                        {...{
                            required: true,
                            label: t("userProfile.areaName"),
                            value: area || '',
                            onChange: (e) =>
                                setAddressForm((state) => ({
                                    ...state,
                                    area: e.target.value as string,
                                })),

                            MenuProps: { style: { maxHeight: 200 } },
                        }}
                    >
                        {AreaType.map((addressName, index) => (
                            <MenuItem
                                {...{ key: index, value: addressName || '' }}
                            >
                                {addressName}
                            </MenuItem>
                        ))}
                    </SelectAddressType>
                </AddressFormControl>
            </TextInputOneField>

            <TextInputOneField>
                <PhoneNumberInput
                    {...{
                        saveNumber,
                        isSubmitted: submitted,
                        isValid: !formValidation.errors
                            .mobile_number as boolean,
                    }}
                />
            </TextInputOneField>

            <TextInputOneField>
                <TextInput
                    {...{
                        label: t("userProfile.streetName"),
                        variant: 'outlined',
                        value: street_name || '',
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                street_name: e.target.value,
                            })),
                    }}
                />
            </TextInputOneField>

            <TextInputOneField>
                <TextInput
                    {...{
                        required: true,
                        label: t("userProfile.streetNumber"),
                        variant: 'outlined',
                        value: street_number || '',
                        error: formValidation.errors.street_number,
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                street_number: e.target.value,
                            })),
                    }}
                />
            </TextInputOneField>

            {/* 

                Single Field Variants for Small Screen Sizes
                **************** ENDS HERE *****************
            
            */}

            <RenderFields {...{
                formValidation,
                setAddressForm,
                newAddressForm: addressForm,
            }} />

            <TextInputWrapper>
                <TextField
                    {...{
                        multiline: true,
                        label: t("userProfile.additionalDirections"),
                        rows: 4,
                        value: additional_directions || '',
                        fullWidth: true,
                        onChange: (e) =>
                            setAddressForm((state) => ({
                                ...state,
                                additional_directions: e.target.value,
                            })),
                    }}
                />
            </TextInputWrapper>

            <FormControlLabel
                control={
                    <Checkbox
                        {...{
                            checked: is_main,
                            onClick: () =>
                                setAddressForm((state) => ({
                                    ...state,
                                    is_main: !state.is_main,
                                })),
                            style: { color: COLORS.GREEN },
                        }}
                    />
                }
                {...{ label: t("userProfile.setAsMainAddress") }}
            />
            <AddAddressButtonWrapper>
                <LoadingButton
                    {...{
                        style: {
                            opacity: submitButtonState.isValid ? 1 : 0.5,
                            height: 50,
                            width: '100%',
                            borderRadius: 10,
                        },
                        colorScheme: COLORS.BASIC_LOADING_COLOR_SCHEME,
                        buttonTitle:
                            type === 'Edit' ? t("userProfile.editAddress") : t("userProfile.addAddresses"),
                        buttonState: submitButtonState,
                        onClick: handleSubmit,
                        disabled: false,
                    }}
                />
            </AddAddressButtonWrapper>
        </AddNewAddressesContainer>
    );
};

export { AddressForm };
