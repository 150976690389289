import { COLORS } from '@project/styles';
import styled from 'styled-components';


export const SubCategoryContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    max-width: 160px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const SubCategoryCircle = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    margin: 2.5px 5px 2.5px 0px;
    border-radius: 1000px;
    align-items: center;
    justify-content: center;

    border: solid 2px ${COLORS.WHITE};


    &:hover {
        transform: scale(1.05);
    }
`