import styled from 'styled-components';

// IMPORT COMPONENTS
import { Link } from '@project/routes/components';

//IMPORT STYLES
import { COLORS } from '@project/styles';

import '@project/fonts';
import { RiDeleteBack2Fill } from 'react-icons/ri';

interface GiftMessageContainerProps {
    isGift: boolean;
}

export const GiftMessageContainer = styled.div<GiftMessageContainerProps>`
    width: 100%;
    transition: height 0.3s;
    height: ${(props) => (props.isGift ? '130px' : '12px')};
    overflow: hidden;
    padding: 20px 0;
    margin-bottom: 20px;
`;


export const GiftMessageTitle = styled.p`
    margin-top: 0;
    font-weight: normal;
`;


export const GiftMessageTextBox = styled.textarea<GiftMessageContainerProps>`
    border: 1px solid ${COLORS.DARK_GREY};
    height: 100px;
`;


export const SubtotalWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin: 0 10px;
`;

export const DiscountWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    margin-left: 30px;
`;

export const DiscountTitle = styled.p`
    margin: 0;
    font-size: 15px;
    font-weight: normal;
    color: darkred;
`;


export const DiscountPrice = styled.p`
    margin: 0;
    font-size: 15px;
    font-weight: normal;
    color: darkred;
`;

export const PromoCodeWrapper = styled.div`
    display: flex;
    height: 40px;
    margin: 8px 10px;
    
    flex-direction: row;
    align-items: center;
    padding: 0px 0px;
    border-radius: 10px;
    padding-bottom: 15px;
    justify-content: space-between;
`

export const DeleteIcon = styled(RiDeleteBack2Fill)`
    cursor: pointer;
`

export const PromoCodeAndActions = styled.div`
    display: flex;
    height: 50px;
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

export const PromoCode = styled.h3`
    margin-right: 10px;
`
export const minimumAmountContainer = styled.div`
    display: flex;

`

export const PromoCodeTitle = styled.p`
    margin: 0;
    font-size: 1rem;
`;

export const CartTitle = styled.p`
    margin: 0;
    font-size: 20px;
`;

export const SubtotalTitle = styled.p`
    margin: 0;
    font-size: 25px;
`;


export const SubtotalPrice = styled.p`
    margin: 0;
    font-size: 25px;
`;


export const SubTotalAndCheckout = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 10px 10px 10px;
    flex-direction: column;
`;


export const CheckoutButton = styled.div`
    background-color: ${COLORS.DARK_GREEN};
    height: 60px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 20px;
`;

export const EmptyCartButton = styled.div`
    background-color: ${COLORS.GREY};
    height: 70px;
    width: 100%;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
`;


export const CheckoutText = styled.h3`
    font-size: 1.7rem;
    padding: 0;
    color: white;
`;
