import React, { Fragment, useEffect, useState } from 'react';

// IMPORT COMPONENTS
import {
    CartMain,
    TopSection,
    PageTitle,
    NoCartItems,
    CartSection,
    MarginWrapper,
    CheckoutSection,
} from '@pages/Cart/CartElements';
import { Divider } from '@mui/material';
import { Summary } from '@custom_components';
import { CartCard } from '@custom_components';
import { TitleWrapper } from '@form_components';
import { CheckoutButton, CheckoutText } from './CartElements';


// IMPORT HELPERS
import { COLORS } from '@project/styles';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { getCartItems } from '@api/helpers';
import { RouterPages } from '@project/routes';
import { useTranslation } from 'react-i18next';
import OverRequestedItems from '@overlays/cart/components/OverRequestedItems';
import { RESPONSE_TYPE, FAILURE_RESPONSE, SUCCESS_RESPONSE } from '@api/interactions';
import { OVER_REQUESTED_ITEM_TYPE, getOverRequestedItems } from '@api/user/cart/helpers';
import { Helmet } from 'react-helmet';


const OrderProcessFlow = () => {
    useEffect(getCartItems, []);
    const { t } = useTranslation();
    const { cart_items } = useSelector((state: RootState) => state.cart);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [overRequests, setOverRequests] = useState<OVER_REQUESTED_ITEM_TYPE[]>([]);

    const onCloseDialog = () => {
        setOpenDialog(false)
    }

    const onToggleDialog = () => {
        setOpenDialog(prev => !prev)
    }

    const checkCartValidity = async (): Promise<RESPONSE_TYPE<boolean>> => {
        const overRequestedItems = await getOverRequestedItems();

        if (overRequestedItems.length) {
            onToggleDialog();
            setOverRequests(overRequestedItems);
            return FAILURE_RESPONSE('CART_VALIDITY', false);
        }

        return SUCCESS_RESPONSE(true);
    }


    return (
        <CartMain>
            <Helmet>
                <title>{t('cart.cartMetaTitle')}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <CartSection>
                {
                    cart_items.length > 0 ? (
                        cart_items.map(
                            (cart_item, product_index) => (
                                <Fragment {...{ key: cart_item.cart_item_id, }}>
                                    <CartCard {...{ cart_item, product_index }} />
                                    <Divider />
                                </Fragment>
                            )
                        )
                    ) : (
                        <NoCartItems>
                            <h3>
                                {t("cart.cartIsEmpty")}
                            </h3>
                        </NoCartItems>
                    )
                }
            </CartSection>
            {
                Boolean(cart_items.length) &&
                <CheckoutSection>
                    <MarginWrapper>
                        <TitleWrapper>
                            <PageTitle {...{ style: { color: 'green' } }}>{t("cart.orderSummary")}</PageTitle>
                        </TitleWrapper>
                        <Summary {...{
                            customColor: 'green',
                            onCheckoutPress: checkCartValidity,
                        }} promocode checkout breakdown />
                    </MarginWrapper>
                </CheckoutSection>
            }
            <OverRequestedItems {...{ openDialog, onCloseDialog, items: overRequests }} />
        </CartMain>
    );
};

export default OrderProcessFlow;
