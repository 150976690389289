// MODULE IMPORTS
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// API INTERACTIONS
import changePassword from '@api/auth/password/forgot';

// STYLED COMPONENTS
import {
    InputSectionTitle,
    InputSectionSubTitle,
} from '@form_components';
import {
    Main,
    TextInput,
    TitleWrapper,
    NewPasswordWrapper,
} from './ChangePasswordElements';
import { LoadingButton } from '@custom_components';

// IMPORT HOOKS
import {
    useFormSubmission,
    useNewPasswordValidator,
} from '@project/hooks';
import { COLORS } from '@project/styles';
import { useTranslation } from 'react-i18next';

const useHelperTextStyles = makeStyles(() => ({
    root: {
        color: 'red',
    },
}));
const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    textInput: {
        display: 'flex',
        height: '90%',
        alignItems: 'center',
    },
};
export const ResetPasswordForm = () => {
    const { t } = useTranslation();

    // HOOKS==
    const navigate = useNavigate();
    const helperTextStyles = useHelperTextStyles();
    const [disableButton, setDisableButton] = useState(false);
    const {
        passwordError,
        confirmPasswordError,
        password,
        confirmPassword,
        setPassword,
    } = useNewPasswordValidator();
    const [oldPassword, setOldPassword] = useState('');
    useEffect(() => {
        if (passwordError || confirmPasswordError) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [passwordError, confirmPasswordError]);
    const [{ showPasswordError, showConfirmPasswordError }, setShowErrors] =
        useState({
            showPasswordError: false,
            showConfirmPasswordError: false,
        });
    const [forgotPasswordState, setForgotPasswordState] = useFormSubmission(
        () => changePassword([oldPassword, password, confirmPassword])
    );
    const onSubmit = async () => {
        const response = await changePassword([
            oldPassword,
            password,
            confirmPassword,
        ]);
    };
    return (
        <Main>
            <TitleWrapper>
                <InputSectionTitle {...{ centerOnMobile: true }}>
                    {t("userProfile.changePassword")}
                </InputSectionTitle>
                <InputSectionSubTitle {...{ centerOnMobile: true }}>
                    {t("userProfile.enterNewPassword")}
                </InputSectionSubTitle>
            </TitleWrapper>
            {/* {forgotPasswordState.isSuccessful ? (
                <ResponsiveText {...{ color: COLORS.GREEN, margin: 0 }}>
                    Your password has been changed successfully
                </ResponsiveText>
            ) : (
                <ResponsiveText {...{ color: COLORS.RED, margin: 0 }}>
                    {forgotPasswordState.ErrorMsg}
                </ResponsiveText>
            )} */}
            <TextInput
                {...{
                    label: t("userProfile.oldPassword"),
                    type: 'password',
                    variant: 'outlined',
                    // onBlur: () =>
                    //     setShowErrors((state) => ({
                    //         ...state,
                    //         showPasswordError: true,
                    //     })),
                    helperText: showPasswordError && passwordError,
                    FormHelperTextProps: {
                        classes: {
                            root: helperTextStyles.root,
                        },
                    },
                    onChange: (e) => setOldPassword(e.target.value),
                }}
            />
            <NewPasswordWrapper>
                <TextInput
                    {...{
                        label: t("userProfile.newPassword"),
                        type: 'password',
                        variant: 'outlined',
                        onBlur: () =>
                            setShowErrors((state) => ({
                                ...state,
                                showPasswordError: true,
                            })),
                        helperText: showPasswordError && passwordError,
                        FormHelperTextProps: {
                            classes: {
                                root: helperTextStyles.root,
                            },
                        },
                        onChange: (e) =>
                            setPassword((state) => ({
                                ...state,
                                password: e.target.value,
                            })),
                    }}
                />
                <TextInput
                    {...{
                        label: t("userProfile.confirmNewPassword"),
                        type: 'password',
                        variant: 'outlined',

                        onBlur: () =>
                            setShowErrors((state) => ({
                                ...state,
                                showConfirmPasswordError: true,
                            })),
                        helperText:
                            showConfirmPasswordError && confirmPasswordError,
                        FormHelperTextProps: {
                            classes: {
                                root: helperTextStyles.root,
                            },
                        },
                        onChange: (e) =>
                            setPassword((state) => ({
                                ...state,
                                confirmPassword: e.target.value,
                            })),
                    }}
                />
            </NewPasswordWrapper>

            <LoadingButton
                {...{
                    style: {
                        height: 50,
                        width: '100%',
                        marginTop: 50,
                        borderRadius: 10,
                    },
                    onClick: onSubmit,
                    disabled: disableButton,
                    buttonTitle: t("userProfile.changePassword"),
                    buttonState: forgotPasswordState,
                    colorScheme: COLORS.BASIC_LOADING_COLOR_SCHEME,
                }}
            />
        </Main>
    );
};
