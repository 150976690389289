import Tools from '@assets/images/home/CategoryDisplay/Tools.png';
import { API_POT_TYPE } from '@api/types';
import { useTranslation } from 'react-i18next';
import HighlightedCategory from './HighlightedCategory/HighlightedCategory';

const PotSection = ({ products }: { products: API_POT_TYPE[] }) => {
    const { t } = useTranslation();

    return (
        <HighlightedCategory
            {...{
                products,
                categoryName: t('home.potsAndVases'),
                categorySlug: 'pots-and-vases',
                categoryButtonText: t('home.allProducts'),
                invert: true,
                categoryImage: Tools,
            }}
        />
    );
};

export default PotSection;
