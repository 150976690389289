import styled from 'styled-components';

import { BASE_TRANSITION_TIME, COLORS } from '@project/styles';

import '@project/fonts';


export const HighlightedText = styled.div`
    font-size: 1rem;
    font-weight: bold;
`

export const NonHighlightedText = styled.span`
    font-size: 1rem;
    color: ${COLORS.DARKER_GREY};
    font-weight: normal;
    margin-left: 5px;
`

export const ColorOptions = styled.div`
    display: flex;
    overflow-x: auto;
    margin: 10px 0px;
    flex-direction: row;
`

export const PotDimensionOptions = styled.div`
    display: flex;
    margin: 10px 0px;  
    flex-direction: row;
    align-content: flex-start;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin: 3px 0px;
    }
`

export const InformationBox = styled.div<{ numberOfOptions: number }>`
    width: ${({ numberOfOptions }) => numberOfOptions > 1 ? '100%' : '50%'};
    position: relative;
    display: flex;
    height: 50px;
    padding: 10px;
    margin: 1px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: solid 2px ${COLORS.CREAM};

    @media screen and (max-width: 768px) {
        height: 60px;
        width: 100px;
    }
`

interface InformationTextProps {
    is_available: boolean;
}

export const InformationText = styled.div<InformationTextProps>`
    display: flex;
    align-self: center;
    justify-self: center;
    text-align: center;
    transition: all ${BASE_TRANSITION_TIME}s;
    opacity: ${(props) => (props.is_available ? 1 : 0.3)};
    &:hover {
        opacity: ${(props) => (props.is_available ? 1 : 0)};
    }
`

export const OutOfStockBox = styled.div<{ number_of_options: number }>`
    position: absolute;
    z-index: 1000;
    height: max;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.number_of_options > 3 ? '1rem' : '1.3rem'};
    font-weight: bolder;
    color: ${COLORS.WHITE};
    transition: all ${BASE_TRANSITION_TIME}s;

    &:hover {
        opacity: 0.8;
        background-color: ${COLORS.DARKER_GREY};
    }
`

export const ColorBallOuter = styled.div`
    width: 50px;
    padding: 1px;
    height: 50px;
    display: flex;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 100000px;
    align-items: center;
    justify-content: center;
    border: solid 2.5px ${COLORS.LIGHTER_GREY};
    transition: all ${BASE_TRANSITION_TIME}s;
`

export const ColorBall = styled.div`
    margin: 4px;
    display: flex;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 100000px;
`