import { COLORS } from '@project/styles';
import styled from 'styled-components';

// MAIN
export const MainContainer = styled.div`
    transition: height 0.5s;
    position: relative;
    min-height: 580px;
    margin-bottom: 100px;
`;
