import React, { ChangeEvent, useState } from 'react';

// IMPORT COMPONENTS
import {
    SectionTitle,
    SectionSubInformation,
    AdditionalNotesContainer,
} from './AdditionalElements';
import * as DOMpurify from 'dompurify';
import { TextInput, TextInputSingleField } from '@form_components';
import { useTranslation } from 'react-i18next';

interface AdditionalNotesProps {
    setAdditionalNotesToState: (notes: string) => void;
}

const AdditionalNotes = ({ setAdditionalNotesToState }: AdditionalNotesProps) => {
    const { t } = useTranslation();
    const [notes, setNotes] = useState<string>('');

    const handleNotesChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setNotes(DOMpurify.sanitize(e.target.value))
        }
    }

    return (
        <AdditionalNotesContainer>
            <SectionTitle>{t("checkout.additionalNotes")}</SectionTitle>
            <SectionSubInformation>{t("checkout.additionalNotesPlaceholder")}</SectionSubInformation>
            <TextInputSingleField>
                <TextInput {...{
                    value: notes,
                    multiline: true,
                    rows: 8,
                    style: { width: '100%' },
                    onChange: handleNotesChange,
                    placeholder: t("checkout.yourAdditionalNotes"),
                    onBlur: () => setAdditionalNotesToState(notes)
                }} />
            </TextInputSingleField>
        </AdditionalNotesContainer>
    )
}

export default AdditionalNotes;