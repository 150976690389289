import { useEffect, useState } from 'react';

// IMPORT STYLED COMPONENTS
import {
    MenuWrapper,
    CategoryName,
    CategoryImage,
    CategoryContainer,
    CategoryItemWrapper,
} from './CategoryPickerElements';

import { COLORS } from '@project/styles';
import { RouterPages } from '@project/routes';
import { ENCODE_QUERY_PARAMS } from '@api/interactions';

// Import Types
import { API_CATEGORY_TYPE } from '@api/types';

// IMPORT CUSTOM COMPONENTS
import { SwiperProps } from 'swiper/react';
import { Swipeable } from '@custom_components';
import { useIsScreenBiggerThan } from '@project/hooks';



const Category = ({ image, name, slug }: API_CATEGORY_TYPE) => {
    if (image) { image = ENCODE_QUERY_PARAMS(image, { h: '200', w: '200' }) }

    return (
        <CategoryItemWrapper page={RouterPages.ListingCategoryPage} pagePathArgs={{ category: slug }}>
            {
                Boolean(image) &&
                <CategoryImage style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }} />
            }
            <CategoryName>{name}</CategoryName>
        </CategoryItemWrapper>
    )
};

const CategoryPicker = ({ categories: data }: { categories: API_CATEGORY_TYPE[] }) => {
    const render_item = ({ item }: { item: API_CATEGORY_TYPE }) => (<Category {...item} />)

    const _768 = useIsScreenBiggerThan(768);
    const _500 = useIsScreenBiggerThan(500);
    const _1200 = useIsScreenBiggerThan(1200);
    const _1000 = useIsScreenBiggerThan(1000);

    const slidesPerView = _1200 ? 7 : (_1000 ? 5 : (_768 ? 4 : (_500 ? 3 : 2)))
    const additional_swiper_props: SwiperProps = {
        slidesPerView,
        pagination: false,
        grid: _768 ? undefined : { rows: 2, fill: 'row' },
        slidesPerGroup: slidesPerView,
    }
    return (
        <CategoryContainer>
            <MenuWrapper>
                {Boolean(data && data.length) && (
                    <Swipeable {...{
                        data,
                        render_item,
                        slide_key: 'slug',
                        additional_swiper_props,
                        container_styles: { height: '100%', padding: '0% 1%' },

                    }} />
                )}
            </MenuWrapper>
        </CategoryContainer>
    );
};

export default CategoryPicker;
