import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';

// IMPORT HEPERS
import {
    mapPromocode,
    mapCartProducts,
    addIndividualQuantities,
} from '../helpers';

// IMPORT TYPES
import { CART_TYPE } from '../types';


export const removeItemFromCartFetch = async (cart_item_id: string) => {
    const HttpRequest = new HTTP_REQUEST(`user/cart/${cart_item_id}/`, {
        method: HTTP_METHODS.DELETE,
        options: {
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Make Order');
    }
};


const removeItemFromCart = async (id: string): Promise<RESPONSE_TYPE<CART_TYPE>> => {
    try {
        const response = await removeItemFromCartFetch(id);
        let body = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CART_TYPE>({
                ...body,
                cart_items: mapCartProducts(body.cart_items),
                promo_code: mapPromocode(body.promo_code),
                num_items: addIndividualQuantities(body.cart_items)
            })
            : FAILURE_RESPONSE('REMOVE_CART', body)
    } catch (error) {
        return FAILURE_RESPONSE('REMOVE_CART', error)
    }
};

export default removeItemFromCart;