import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    background-color: #f8f1e3;
    height: 800px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Heading = styled.h1`
    margin: 0;
`;
export const SubHeading = styled.h3`
    font-weight: 300;
    margin: 0;
    margin-bottom: 20px;
`;

export const TestimonialWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`;

export const TestimonialCardContainer = styled.div`
    /* width: 100%; */
    display: flex;
    justify-content: center;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
`;

export const InstagramPosts = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    padding: 0% 5%;
    justify-content: space-evenly;
`;