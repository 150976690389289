import { routesInfo, RouterPages } from '@project/routes'
import { getPathWithLang } from '@translation';

export type PagePathArgsType = { [k: string]: string };
export type SearchParamsType = { [k: string]: string | number };

export const resolvePath = (path: string, pathArgs: PagePathArgsType = {}, searchParams = {}) => {
    /* 
    Sort keys in descending length, so when we do replacement, we do not accidently
    replace a prefix of another key. For example:

    Imagine keys 'hello' and 'hell'. If 'hell' is processed first,
    we might accidenty replace 'hell' from 'hello' first. 
    */

    const keys = Object.keys(pathArgs);
    const keysInDescendingLength = keys.sort((a, b) => b.length - a.length);

    for (const arg of keysInDescendingLength) {
        path = path.replace(`:${arg}`, pathArgs[arg])
    }

    if (Object.keys(searchParams).length !== 0) {
        const urlSearchParams = new URLSearchParams(searchParams);
        path = `${path}?${urlSearchParams.toString()}`;
    }

    return path;
}

export const getPathFromPage = (page: RouterPages, pagePathArgs?: PagePathArgsType, pageSearchParams?: SearchParamsType) => {
    let path = routesInfo[page].path;
    if (!path.startsWith('/')) {
        path = '/' + path;
    }
    return resolvePath(path, pagePathArgs, pageSearchParams)
}

export const getLangPathFromPage = (page: RouterPages, pagePathArgs?: PagePathArgsType, pageSearchParams?: SearchParamsType) => {
    const toPath = getPathFromPage(page, pagePathArgs, pageSearchParams);
    return getPathWithLang(toPath);
}