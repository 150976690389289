import {
    HTTP_METHODS,
    HTTP_REQUEST,
    getFirstError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import { getInitialSetUpData } from '@api/helpers';

type CUSTOM_RESPONSE = {
    key: string;
    auth_token_cookie: string;
};

const api_type = 'SIGN_IN';

export const signInFetch = async (email: string, password: string) => {
    const data: any = {
        email,
        password,
    };

    const HttpRequest = new HTTP_REQUEST('auth/login/', {
        method: HTTP_METHODS.POST,
        options: { data },
    });
    const Response = await HttpRequest.callFetch();
    return Response;
};


export default async (
    username: string,
    password: string
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await signInFetch(username, password);
        const APIResponse = await response.json();

        if (response.ok) {
            // Fetch User Information Asynchronously
            // ... - ...
            getInitialSetUpData({
                auth_token: APIResponse.key,
                auth_token_cookie: APIResponse.auth_token_cookie,
            }, true)
            // ... - ...

            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>(APIResponse);
        }

        return FAILURE_RESPONSE(
            api_type,
            getFirstError(APIResponse) || 'Invalid Information'
        );
    } catch (error) {
        return FAILURE_RESPONSE(api_type, 'Invalid login credentials.');
    }
};

export type SIGN_IN_RESPONSE = CUSTOM_RESPONSE