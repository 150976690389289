import {
    AuthTop,
    AuthLeft,
    AuthMain,
    AuthBottom,
    AuthNavLogo,
    AuthContainer,
    AuthFormsContainer,
    AuthMainCatchPhrase,
    GreenitNavContainer,
    GreenitNavLogoContainer,
    AuthNavLogoArabic,
} from '../AuthElements';
import { LogInForm } from './LogInForm';
import TutorialCards from '../components/TutorialCards';
import { BaseButton, ResponsiveText } from '@custom_components';


// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HELPERS
import { RouterPages } from '@project/routes';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';



const Login = () => {
    const direction = i18next.dir();
    const [params, _] = useSearchParams();

    const { t } = useTranslation();
    const nextPath = params.get('next');

    return (
        <AuthContainer>
            <Helmet><title>{t('auth.loginMetaTitle')}</title></Helmet>
            <AuthLeft {...{ color: COLORS.AUTH.LOGIN.SECONDARY }}>
                <AuthTop>
                    <AuthMainCatchPhrase {...{ color: COLORS.AUTH.LOGIN.TERTIARY }}>
                        {t("auth.tutorialCatchphrase")}
                    </AuthMainCatchPhrase>
                </AuthTop>
                <AuthBottom>
                    <TutorialCards {...{ colors: COLORS.AUTH.LOGIN }} />
                </AuthBottom>
            </AuthLeft>
            <AuthMain>
                <GreenitNavContainer {...{
                    page: RouterPages.SignUp,
                    pageSearchParams: nextPath ? { next: nextPath } : undefined
                }}>
                    <BaseButton {...{
                        style: {
                            marginTop: '2%',
                            cursor: 'pointer',
                        }, onClick: () => { },
                    }}>
                        <ResponsiveText props={{ marginTop: '5%', marginBottom: '35%' }}>
                            {t('auth.newToGreenit')}{' '}
                            <span style={{ color: COLORS.AUTH.LOGIN.PRIMARY, fontWeight: 'bolder' }}>
                                {t('auth.signUp')}
                            </span>
                        </ResponsiveText>
                    </BaseButton>
                </GreenitNavContainer>
                <GreenitNavLogoContainer>
                    {
                        direction === 'ltr' ? < AuthNavLogo  {...{ page: RouterPages.Home }} />
                            : <AuthNavLogoArabic  {...{ page: RouterPages.Home }} />
                    }
                </GreenitNavLogoContainer>
                <AuthFormsContainer>
                    <LogInForm {...{ nextPath }} />
                </AuthFormsContainer>
            </AuthMain>
        </AuthContainer>
    );
};

export default Login;
