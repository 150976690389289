import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    z-index: 10;
    overflow: hidden;
    position: absolute;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: ${COLORS.NOTIFICATION_LIGHT_GREEN};
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    max-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`

export const LoadingFullScreen = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    opacity: 0.85;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: ${COLORS.WHITE};
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: solid 1px ${COLORS.LIGHT_GREEN};
`

export const LottieContainer = styled.div`
    width: 60%;
    height: 60%;
    display: flex;
    overflow: hidden;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    background: ${COLORS.WHITE};
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`