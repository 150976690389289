import React, { Children, useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/grid";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./swipe.scss";

// Import Required Modules
import styled from "styled-components";
import { Grid, Keyboard, Mousewheel, Navigation, Pagination, Autoplay } from "swiper";

interface SwipeableProps<T> {
    data: T[];
    slide_key?: keyof T;
    number_rows?: boolean | number;
    render_item: React.FunctionComponent<{ item: T }>;

    // Optional Parameters
    additional_swiper_props?: SwiperProps;
    container_styles?: React.CSSProperties;
}


const MAX_SLIDES_PER_VIEW = 4;

const SwipeableContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 2% 0%;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export function Swipeable<T>({ data, slide_key, number_rows, render_item, container_styles, additional_swiper_props }: SwipeableProps<T>) {
    return (
        <SwipeableContainer {...{ style: container_styles }}>
            <Swiper {...{
                spaceBetween: 20,
                slidesPerView: MAX_SLIDES_PER_VIEW,
                pagination: {
                    clickable: true,
                    dynamicBullets: true,
                },
                slidesPerGroup: 4,
                slidesPerGroupSkip: 0,
                mousewheel: {
                    forceToAxis: true,
                },
                modules: [Grid, Keyboard, Navigation, Pagination, Mousewheel, Autoplay],
            }} grabCursor navigation keyboard {...additional_swiper_props}>
                {
                    data.map((element, _) => (
                        <SwiperSlide {...{
                            style: { width: 'auto', height: 'auto' },
                            key: `${Boolean(slide_key && element[slide_key]) ? element[slide_key as keyof T] : _}`
                        }}>
                            {render_item({ item: element })}
                        </SwiperSlide>))
                }
            </Swiper>
        </SwipeableContainer >
    );
}
