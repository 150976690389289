
//IMPORT API RESPONDERS
import getCart from '@api/user/cart/get';
import addCartItem from '@api/user/cart/add';
import removeCartItem from '@api/user/cart/remove';
import updateCartItemQuantity from '@api/user/cart/update';


//IMPORT TYPES
import { API_USER_TYPE } from '@api/user/types';
import { APP_STATE_CUSTOM_CARD_TYPE } from '@api/types';
import { APP_STATE_CART_PRODUCT_TYPE } from '@api/user/cart/types';


//IMPORT STORE INTERACTIONS
import { authChecker, getAuthToken, webDeviceIDChecker } from '@project/helpers';


// IMPORT API HELPERS
import getSavedCards from '@api/user/cards/get';
import getServiceArea from '@api/settings/area';
import getRepotConfiguration from '@api/checkout/repot';
import { getUserAddresses } from '@api/user/address/get';
import getDeliveryAvailability from '@api/checkout/date';
import getUserInformation, { getUserAvatar, updateUserInformation } from '@api/user';



// IMPORT STORE HELPERS
import {
    updateUserAvatar,
    syncUserCardsWithDb,
    syncUserDetailsWithDb,
    syncUserAddressesWithDb,
    clearUser,
    updateUserPhoneNumber,
    updateUserEmail,
    updateUserFirstName,
    updateUserLastName,
} from '@redux/state/user';
import { signOut } from '@redux/state/auth';
import importCart from './user/cart/import';
import { store, storeDispatch } from '@redux/store';
import { clearCart, syncCartWithDB } from '@redux/state/cart';
import { deleteToken, setServiceArea } from '@redux/state/settings';
import { syncRepotConfigWithDB, syncScheduleWithDB } from '@redux/state/checkout';
import { deleteTokenCookiePair, getRefreshDateFromDaysDelta, setTokenCookiePair } from '@redux/state/cookie';
import { sign_up_gtag_report_conversion, add_to_cart_gtag_report_conversion } from 'lib/common/google_analytics';



export const AddToCart = async (
    sku: string,
    qty: number,
    current_price: number,
    customMessage?: APP_STATE_CUSTOM_CARD_TYPE
): Promise<string | null> => {
    const response = await addCartItem(
        sku,
        qty,
        current_price,
        customMessage
    );
    if (response.status) {
        add_to_cart_gtag_report_conversion()
        storeDispatch(syncCartWithDB(response.response_body));
        return new Promise((rs, _) => rs(''))
    }
    let errorResponse: any = response.response_body;
    if (errorResponse && errorResponse.show_error[0] === 'True') {
        return new Promise((_, rj) => rj(errorResponse.errors[0] as string));
    }
    return new Promise((_, rj) => rj(null));
};

export const getGuestCartItems = webDeviceIDChecker<APP_STATE_CART_PRODUCT_TYPE[]>(
    async (_?: string) => {
        const response = await getCart();
        if (response.status) {
            storeDispatch(syncCartWithDB(response.response_body));
        }
        return response.response_body.cart_items || [];
    }
);

export const getAuthenticatedCartItems = authChecker<string | undefined, APP_STATE_CART_PRODUCT_TYPE[]>(
    async (token?: string) => {
        const response = await getCart(token);
        if (response.status) {
            storeDispatch(syncCartWithDB(response.response_body));
        }
        return response.response_body.cart_items || [];
    },
    { token_override: true }
);

export const getCartItems = () => { getAuthToken() ? getAuthenticatedCartItems() : getGuestCartItems() };

export const deleteCartItem = async (cart_item_id: string): Promise<string | null> => {
    const response = await removeCartItem(cart_item_id);
    if (response.status) {
        storeDispatch(syncCartWithDB(response.response_body));
        return new Promise((rs, _) => rs(''))
    }
    return new Promise((_, rj) => rj(null));
};

export const updateCartItemQty = async (cart_item_id: string, itemQty: number) => {
    const response = await updateCartItemQuantity(cart_item_id, itemQty);
    if (response.status) {
        storeDispatch(syncCartWithDB(response.response_body));
    }
};


export const initializeAuthCookies = (cookie: string) => {
    // SET AUTH TOKEN COOKIE TO STATE WITHOUT VERIFICATION
    storeDispatch(
        setTokenCookiePair({
            cookie,
            type: 'auth_token',
            cookie_refresh_date: getRefreshDateFromDaysDelta(2 * 365),
        })
    )
}

export const getAuthenticatedData = (auth_token?: string) => {
    getAuthenticatedCartItems(auth_token);
    getProfileDetails(auth_token);
    getConfigOptions(auth_token);
    return;
}

export const getInitialSetUpData = ({ auth_token, auth_token_cookie }: {
    auth_token: string;
    auth_token_cookie: string;
}, is_email_sign_in = false) => {
    const hasCartItems = store.getState().cart.cart_items.length > 0;
    initializeAuthCookies(auth_token_cookie);
    getAuthenticatedData(auth_token);
    hasCartItems && importCart(auth_token);
    !is_email_sign_in && sign_up_gtag_report_conversion();
}

export const getMapDeliveryRegionData = async (auth_token?: string) => {
    const response = await getServiceArea(auth_token);
    if (response.status) {
        storeDispatch(setServiceArea(response.response_body))
    }
}

export const getRepottingConfigurationData = async (auth_token?: string) => {
    const response = await getRepotConfiguration(auth_token);
    if (response.status) {
        storeDispatch(syncRepotConfigWithDB(response.response_body));
    }
}

export const getDeliveryAvailabilityData = async (auth_token?: string) => {
    const response = await getDeliveryAvailability(auth_token);
    if (response.status) {
        storeDispatch(syncScheduleWithDB(response.response_body));
    }
}

export const getConfigOptions = (auth_token?: string) => {
    getMapDeliveryRegionData(auth_token);
    getDeliveryAvailabilityData(auth_token);
    getRepottingConfigurationData(auth_token);
}

export const getGuestData = () => {
    getGuestCartItems();
    return;
}

export const saveUserAvatar = async (token?: string) => {
    const response = await getUserAvatar(token);
    if (response) {
        storeDispatch(
            updateUserAvatar(response.response_body.url)
        );
    }
}

export const saveUserProfileInfo = async (token?: string) => {
    const userInfoResponse = await getUserInformation(token);
    if (userInfoResponse.status) {
        storeDispatch(
            syncUserDetailsWithDb(userInfoResponse.response_body as API_USER_TYPE)
        );
    }
    saveUserAvatar(token);
}

export const saveUserAddresses = async (token?: string) => {
    const userAddressResponse = await getUserAddresses(token);
    if (userAddressResponse.status) {
        storeDispatch(
            syncUserAddressesWithDb(userAddressResponse.response_body)
        );
    }
}

export const saveUserCreditCards = async (token?: string) => {
    const userCardsResponse = await getSavedCards(token);
    if (userCardsResponse.status) {
        storeDispatch(
            syncUserCardsWithDb(userCardsResponse.response_body)
        );
    }
}

export const getProfileDetails = authChecker<string | undefined, void>(
    async (token?: string) => {
        saveUserProfileInfo(token);
        saveUserAddresses(token);
        saveUserCreditCards(token);
    },
    { token_override: true }
)


export const handleServerUnauthorized = (guest_cart_unauthorized = false) => {
    const Cookies = require('js-cookie');
    // Remove Auth Token On Unauthorized Requests
    Cookies.remove('authtoken');
    storeDispatch(deleteTokenCookiePair('auth_token'))

    // Remove Web Device Id if Guest Cart returns Unauthorized
    if (guest_cart_unauthorized) {
        storeDispatch(deleteToken('web_id'))
        storeDispatch(deleteTokenCookiePair('web_device_id'))
    }

    // Reset Store Persisted Data
    storeDispatch(signOut());
    storeDispatch(clearCart());
    storeDispatch(clearUser());

}

export const updateFirstName = authChecker<string, boolean>(
    async (first_name?: string) => {
        const updateResponse = await updateUserInformation({ first_name })
        if (updateResponse.status) {
            storeDispatch(updateUserFirstName(first_name as string));
            return new Promise((rs, rj) => rs(true))
        }
        return new Promise((rs, rj) => rj(false))
    }
)

export const updateLastName = authChecker<string, boolean>(
    async (last_name?: string) => {
        const updateResponse = await updateUserInformation({ last_name })
        if (updateResponse.status) {
            storeDispatch(updateUserLastName(last_name as string));
            return new Promise((rs, rj) => rs(true))
        }
        return new Promise((rs, rj) => rj(false))
    }
)

export const slugify = (name: string) => {
    return name.toLowerCase().split(' ').join('-');
}

export const updatePhone = authChecker<string, boolean>(
    async (otp_phone_number?: string) => {
        const updateResponse = await updateUserInformation({ otp_phone_number })
        if (updateResponse.status) {
            storeDispatch(updateUserPhoneNumber(otp_phone_number as string));
            return new Promise((rs, rj) => rs(true))
        }
        return new Promise((rs, rj) => rj(false))
    }
)


export const updateEmail = authChecker<string, string | null>(
    async (email?: string) => {
        const updateResponse = await updateUserInformation({ email })
        if (updateResponse.status) {
            storeDispatch(updateUserEmail(email as string));
            return new Promise((rs, rj) => rs(null))
        }
        const error = updateResponse.response_body.email[0];
        return new Promise((rs, rj) => rj(error || null))
    }
)