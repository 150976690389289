import React, { ChangeEvent, useCallback, useEffect } from 'react';

// IMPORT COMPONENTS
import {
    Accordion,
    AccordionItem,
    AccordionContent,
} from '@custom_components';
import {
    OptionRow,
    PaymentIcon,
    PaymentLabel,
    AddCardWrapper,
    OptionInformation,
} from './AdditionalElements';
import {
    IconInput,
    TextInput,
    TextInputTwoFields,
    TextInputSingleField,
    TextInputOneField,
} from '@form_components';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

// IMPORT HELPERS
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';
import { cardType, renderCardTypeIcon, renderPaymentIcon } from '@api/checkout/helpers';


const creditCardValidator = require('card-validator');
// IMPORT TYPES
import {
    DIBSY_PAYMENT_OPTION,
    CREDIT_CARD_BRAND_TYPE,
    DIBSY_ADDED_PAYMENT_OPTION_TYPE,
} from '@api/user/cards/types';
import { RootState } from '@redux/store';
import { GreenButton } from '@styled_components';
import { useIsScreenBiggerThan } from '@project/hooks';
import { notifyMessage } from '@overlays/notifications';
import { useTranslation } from 'react-i18next';


interface ADDED_CARD_STATE {
    card_cv2: string;
    card_number: string;
    card_expiry: string;
    is_saved_card: boolean;
    card_cv2_error: boolean;
    card_number_error: boolean;
    card_expiry_error: boolean;
    card_holders_name: string;
    disable_save_card_option: boolean;
    credit_card_type: CREDIT_CARD_BRAND_TYPE;

    // Optional Parameters
    edited_payment_id?: string;
}

interface AddCardProps {
    active: string;
    option: DIBSY_PAYMENT_OPTION;
    handleClick: (id: string) => void;
    handleChecked: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
    addCreditCardOption: (option: Omit<DIBSY_ADDED_PAYMENT_OPTION_TYPE, 'payment_id'>, edit_payment_id?: string) => void;

    // Optional Props
    editPaymentInfo?: DIBSY_ADDED_PAYMENT_OPTION_TYPE;
}

const getPaymentStateFromAddedInformation = (
    editPayment: DIBSY_ADDED_PAYMENT_OPTION_TYPE
): ADDED_CARD_STATE => ({
    card_cv2_error: false,
    card_number_error: false,
    card_expiry_error: false,
    card_cv2: editPayment.card_cv2,
    card_number: editPayment.cc_number,
    edited_payment_id: editPayment.payment_id,
    is_saved_card: editPayment.save_card_for_user,
    credit_card_type: cardType(editPayment.cc_number),
    card_holders_name: editPayment.card_holders_name || '',
    card_expiry: `${editPayment.expiry_month}/${editPayment.expiry_year}`,
    disable_save_card_option: cardType(editPayment.cc_number) === CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS,
})

const AddACreditCard = (props: AddCardProps) => {
    const {
        active,
        option,
        handleClick,
        handleChecked,
        editPaymentInfo,
        addCreditCardOption,
    } = props;
    const { t } = useTranslation();

    const { full_name } = useSelector((state: RootState) => state.user.details)
    const [state, setState] = useImmer<ADDED_CARD_STATE>({
        card_cv2: '',
        card_number: '',
        card_expiry: '',
        is_saved_card: true,
        card_holders_name: full_name,
        card_cv2_error: false,
        card_number_error: false,
        card_expiry_error: false,
        disable_save_card_option: false,
        credit_card_type: CREDIT_CARD_BRAND_TYPE.INVALID,
        ...(editPaymentInfo ? getPaymentStateFromAddedInformation(editPaymentInfo) : {})
    })

    const addDisplayBreaks = useCallback((
        text: string,
        breakType: ' ' | '/',
        spacing: number,
        amex = false,
    ) => {
        if (amex) {
            if (text.length > 10) {
                return `${text.slice(0, 4)} ${text.slice(4, 10)} ${text.slice(10)}`;
            } else if (text.length > 4) {
                return `${text.slice(0, 4)} ${text.slice(4)}`
            } else return text
        }

        let start = text.slice(0, spacing)
        let remaining = text.slice(spacing)
        let final = start + breakType
        while (start.length === spacing && remaining.length) {
            start = remaining.slice(0, spacing)
            remaining = remaining.slice(spacing)
            final = final + start + breakType
        }

        return final.slice(0, final.length - 1)
    }, [])

    const testLength = (text: string, length: number) => {
        return text.length <= length;
    }

    const setCreditCardNumber = (e: ChangeEvent<HTMLInputElement>) => {
        if (!Number.isInteger(+e.target.value.charAt(e.target.value.length - 1))) { return }

        if (!e.target.value.length) {
            setState(draft => {
                draft.card_number = '';
                draft.card_number_error = false;
                draft.credit_card_type = CREDIT_CARD_BRAND_TYPE.INVALID;
            })
            return;
        }

        const cardBrand = cardType(e.target.value);
        const isAmexCard = cardBrand === CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS;
        const displayText = addDisplayBreaks(e.target.value.replaceAll(' ', ''), ' ', 4, isAmexCard)

        if (!testLength(e.target.value, isAmexCard ? 17 : 19)) { return; }

        setState(draft => {
            draft.card_number = displayText;
            draft.credit_card_type = cardBrand
        })

        if (!creditCardValidator.number(e.target.value).isValid) {
            setState(draft => { draft.card_number_error = true })
        } else { setState(draft => { draft.card_number_error = false }) }
    }

    const setExpiryDate = (e: ChangeEvent<HTMLInputElement>) => {
        if (!Number.isInteger(+e.target.value.charAt(e.target.value.length - 1))) {
            if (e.target.value.charAt(e.target.value.length - 1) !== '/') { return }
        }

        if (!e.target.value.length) {
            setState(draft => {
                draft.card_expiry = '';
                draft.card_expiry_error = false
            })
            return;
        }
        let newText = e.target.value
        if (e.target.value.length === 1) {
            if (+(e.target.value) && +(e.target.value) >= 2) {
                newText = '0' + newText
            }
        }

        if (!testLength(e.target.value, 5)) { return; }

        const displayText = addDisplayBreaks(newText.replaceAll('/', ''), '/', 2)
        setState(draft => { draft.card_expiry = displayText })

        if (!creditCardValidator.expirationDate(newText).isValid) {
            setState(draft => { draft.card_expiry_error = true })
        } else { setState(draft => { draft.card_expiry_error = false }) }
    }

    const setCardCVV = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value.length) {
            setState(draft => {
                draft.card_cv2 = '';
                draft.card_cv2_error = false
            })
            return;
        }

        const isAmexCard = state.credit_card_type === CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS;
        const maxLength = isAmexCard ? 4 : 3

        if (!testLength(e.target.value, maxLength)) { return; }

        setState(draft => {
            draft.card_cv2 = e.target.value
        })

        if (!creditCardValidator.cvv(e.target.value, maxLength).isValid) {
            setState(draft => { draft.card_cv2_error = true })
        } else { setState(draft => { draft.card_cv2_error = false }) }

    }

    const setCardHoldersName = (e: ChangeEvent<HTMLInputElement>) => {
        setState(draft => {
            draft.card_holders_name = e.target.value;
        })
    }

    const handeSaveCardCheck = () => {
        setState(draft => {
            !draft.disable_save_card_option && (draft.is_saved_card = !draft.is_saved_card)
        })
    }

    const verifyCardInformation = useCallback(() => {
        return !(
            !Boolean(state.card_cv2) || state.card_cv2_error ||
            !Boolean(state.card_expiry) || state.card_expiry_error ||
            !Boolean(state.card_number) || state.card_number_error
        )
    }, [
        state.card_cv2_error,
        state.card_expiry_error,
        state.card_number_error
    ])

    const resetState = useCallback(() => {
        setState({
            card_cv2: '',
            card_number: '',
            card_expiry: '',
            is_saved_card: true,
            card_cv2_error: false,
            card_number_error: false,
            card_expiry_error: false,
            edited_payment_id: undefined,
            card_holders_name: full_name,
            disable_save_card_option: false,
            credit_card_type: CREDIT_CARD_BRAND_TYPE.INVALID,
        })
    }, [full_name])

    const setCardInformation = () => {
        if (!verifyCardInformation()) {
            notifyMessage({
                message: t('checkout_new.creditCardIncompleteWarning'),
                notification_type: 'WARNING'
            })
            return;
        }

        addCreditCardOption({
            payment_type: 'added',
            card_cv2: state.card_cv2,
            cc_number: state.card_number,
            save_card_for_user: state.is_saved_card,
            card_holders_name: state.card_holders_name,
            expiry_year: state.card_expiry.split('/')[1],
            expiry_month: state.card_expiry.split('/')[0],
        }, editPaymentInfo?.payment_id);

        resetState();

    }
    // Disable Saving Credit Card If It is American Express.
    useEffect(() => {
        let cancelled = false;
        if (state.credit_card_type === CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS) {
            setState(draft => {
                !cancelled && (draft.disable_save_card_option = true);
                !cancelled && (draft.is_saved_card = false)
            })
        } else {
            setState(draft => {
                !cancelled && (draft.disable_save_card_option = false)
                !cancelled && (draft.is_saved_card = true)
            })
        }
        return () => { cancelled = true }
    }, [state.credit_card_type])

    // Populate State with Edit Card Request Details
    useEffect(() => {
        let cancelled = false;
        !cancelled &&
            editPaymentInfo &&
            setState(_ => getPaymentStateFromAddedInformation(editPaymentInfo))

        !cancelled && !editPaymentInfo && resetState();
        return () => { cancelled = true }
    }, [editPaymentInfo])

    const _800 = useIsScreenBiggerThan(800);
    const _500 = useIsScreenBiggerThan(500);

    return (
        <Accordion {...{ rows: 1 }}>
            <AccordionItem {...{
                title: <OptionRow {...{
                    key: option.payment_id,
                    disabled: false,
                    onClick: () => handleClick(option.payment_id)
                }} >
                    <OptionInformation {...{ style: { width: '100%' } }}>
                        <Checkbox {...{
                            color: 'success',
                            sx: { fontWeight: 'bold' },
                            checked: active === option.payment_id,
                            onChange: (e) => handleChecked(e, option.payment_id)
                        }} />
                        <PaymentIcon>
                            {renderPaymentIcon(option)}
                        </PaymentIcon>
                        <PaymentLabel>{t("checkout.addACreditCard")}</PaymentLabel>
                    </OptionInformation>
                </OptionRow>,
                textColor: '#222',
                contentHeight: _800 ? 500 : (_500 ? 700 : 900),
                exteriorOpen: active === option.payment_id,
            }}>
                <AccordionContent>
                    <AddCardWrapper {...{ contentHeight: _800 ? 500 : (_500 ? 700 : 900) }}>
                        <TextInputSingleField>
                            <IconInput>
                                <TextInput {...{
                                    type: '',
                                    variant: 'outlined',
                                    label: t('checkout_new.cardNumber'),
                                    style: { width: '100%' },
                                    onChange: setCreditCardNumber,
                                    value: state.card_number || '',
                                    error: state.card_number_error,
                                    InputProps: {
                                        endAdornment: <InputAdornment {...{ position: 'end' }}>
                                            {renderCardTypeIcon(state.credit_card_type)}
                                        </InputAdornment>
                                    },
                                }} />
                            </IconInput>

                        </TextInputSingleField>
                        <TextInputTwoFields>
                            <TextInput
                                {...{
                                    value: state.card_expiry,
                                    label: t('checkout_new.cardExpiry'),
                                    variant: 'outlined',
                                    onChange: setExpiryDate,
                                    error: state.card_expiry_error,
                                }}
                            />
                            <TextInput
                                {...{
                                    value: state.card_cv2,
                                    label: t('checkout_new.cardCVV2'),
                                    type: 'number',
                                    variant: 'outlined',
                                    onChange: setCardCVV,
                                    error: state.card_cv2_error,
                                }}
                            />
                        </TextInputTwoFields>
                        <TextInputOneField>
                            <TextInput
                                {...{
                                    value: state.card_expiry,
                                    label: t('checkout_new.cardExpiry'),
                                    variant: 'outlined',
                                    onChange: setExpiryDate,
                                    error: state.card_expiry_error,

                                }}
                            />
                        </TextInputOneField>
                        <TextInputOneField>
                            <TextInput
                                {...{
                                    value: state.card_cv2,
                                    label: t('checkout_new.cardCVV2'),
                                    variant: 'outlined',
                                    onChange: setCardCVV,
                                    error: state.card_cv2_error,
                                }}
                            />
                        </TextInputOneField>
                        <TextInputSingleField>
                            <IconInput>
                                <TextInput {...{
                                    variant: 'outlined',
                                    label: t('checkout_new.cardHoldersName'),
                                    style: { width: '100%' },
                                    onChange: setCardHoldersName,
                                    value: state.card_holders_name || '',
                                }} />
                            </IconInput>
                        </TextInputSingleField>
                        <TextInputTwoFields>
                            <OptionRow {...{
                                disabled: false,
                                onClick: handeSaveCardCheck,
                                style: {
                                    alignItems: 'center',
                                    paddingRight: '20px',
                                    justifyContent: 'flex-start',
                                }
                            }}>
                                <Checkbox {...{
                                    color: 'success',
                                    sx: { fontWeight: 'normal' },
                                    checked: !state.disable_save_card_option && state.is_saved_card,
                                    disabled: state.disable_save_card_option,
                                    onChange: (e) => handeSaveCardCheck(),
                                }} />
                                <PaymentLabel {...{ disabled: state.disable_save_card_option }}>
                                    {t('checkout_new.saveCreditCardText')}
                                </PaymentLabel>
                            </OptionRow>
                            <GreenButton
                                onClick={setCardInformation}
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem',
                                    width: '40%',
                                }}>
                                {editPaymentInfo ? t('checkout_new.editCreditCard') : t('checkout_new.addCreditCard')}
                            </GreenButton>
                        </TextInputTwoFields>
                        <TextInputOneField>
                            <OptionRow {...{
                                disabled: false,
                                onClick: handeSaveCardCheck,
                                style: {
                                    alignItems: 'center',
                                    paddingRight: '20px',
                                    justifyContent: 'flex-start',
                                }
                            }}>
                                <Checkbox {...{
                                    color: 'success',
                                    sx: { fontWeight: 'normal' },
                                    checked: !state.disable_save_card_option && state.is_saved_card,
                                    disabled: state.disable_save_card_option,
                                    onChange: (e) => handeSaveCardCheck(),
                                }} />
                                <PaymentLabel {...{ disabled: state.disable_save_card_option }}>
                                    {t('checkout_new.saveCreditCardText')}
                                </PaymentLabel>
                            </OptionRow>
                        </TextInputOneField>
                        <TextInputOneField>
                            <GreenButton
                                onClick={setCardInformation}
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem',
                                    width: _500 ? '40%' : 'auto',
                                }}>
                                {editPaymentInfo ? t('checkout_new.editCreditCard') : t('checkout_new.addCreditCard')}
                            </GreenButton>
                        </TextInputOneField>
                    </AddCardWrapper>
                </AccordionContent>
            </AccordionItem>
        </Accordion >
    )
}

export default AddACreditCard;