import React, { useEffect } from 'react';

// IMPORT STYLE LIBRARIES
import i18next from 'i18next';
import rtlPlugin from 'stylis-plugin-rtl';

// IMPORT COMPONENTS
import { ThemeProvider } from '@mui/styles';

// IMPORT HELPERS
import { createTheme } from '@mui/material/styles';

// IMPORT HOOKS
import { useTranslation } from 'react-i18next';
import { StyleSheetManager, StylisPlugin } from 'styled-components';


function withRoot(Component: React.ElementType) {
    function WithRoot(props: any) {
        const { i18n } = useTranslation();

        useEffect(() => {
            document.dir = i18next.dir();
        }, [i18n]);

        return (
            <StyleSheetManager {...{ stylisPlugins: i18next.dir() === 'rtl' ? [rtlPlugin as unknown as StylisPlugin] : undefined }}>
                <ThemeProvider {...{ theme: createTheme({ direction: i18next.dir() }) }}>
                    <Component {...props} />
                </ThemeProvider>
            </StyleSheetManager>
        );
    }

    return WithRoot;
}

export default withRoot;
