import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { COLORS } from '@project/styles';


// IMPORT HOOKS
import { useSelector } from 'react-redux';
import { useGeoLocation } from '@project/hooks';


// IMPORT TYPES
import { RootState } from '@redux/store';
import { LatLng } from '@api/user/address/types';
import { defaultLatLng } from 'lib/common/default';
import { MAPBOX_PUBLIC_API_KEY } from '@project/config';


// IMPORT COMPONENTS
import {
    MapRef,
    Map as MapboxMap,
} from 'react-map-gl';
import { FlyToOptions, MapboxEvent } from 'mapbox-gl';
import { GreenButton, GreenitMapMarker } from '@styled_components';

import { Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


// IMPORT HELPERS
import { isPointInPolygon } from 'geolib';

const MapBlocker = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: solid 1px ${COLORS.DARK_GREEN};
`

const MarkerBox = styled.div<{ visible: string }>`
    display: flex;
    width: 46px;
    height: 80px;
    position: absolute;
    align-self: center;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    transform: translateY(-36px);
    opacity: ${({ visible }) => visible === 'true' ? 1 : 0};
`

export interface PrivateCoordinates {
    latitude: number;
    longitude: number;
}

export interface PrivateMapBoxViewState {
    zoom: number;
    latitude: number;
    longitude: number;
}

interface MapProps {
    interactible?: boolean;
    location?: LatLng;
    form_opened?: boolean;
    custom_map_styles?: React.CSSProperties;
    on_set_position?: (latLng: LatLng) => void;
}

const Map = (props: MapProps) => {
    const {
        location,
        form_opened,
        interactible,
        custom_map_styles,
        on_set_position,
    } = {
        form_opened: false,
        interactible: false,
        custom_map_styles: {},
        location: defaultLatLng,
        on_set_position: () => { },
        ...props,
    }

    const mapRef = useRef<MapRef>(null);
    const { loaded, coordinates } = useGeoLocation();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { service_area } = useSelector((state: RootState) => state.settings)


    const setCoordinatesOnDragEnd = (event: MapboxEvent) => {
        if (interactible) {
            const { lat: latitude, lng: longitude } = event.target.getCenter();
            const zoom = event.target.getZoom();

            if (isPointInPolygon({ latitude, longitude }, service_area)) {
                on_set_position({ latitude, longitude })
            } else { openAlert() }
        }
    }

    const closeAlert = () => {
        setDialogOpen(false);
    }

    const openAlert = () => {
        setDialogOpen(true);
    }


    useEffect(() => {
        let cancelled = false;
        if (!cancelled && loaded && coordinates !== undefined) {
            (on_set_position || ((_: LatLng) => { }))(coordinates);
            if (!cancelled && !location) {
                mapRef.current?.flyTo({
                    zoom: 15,
                    maxDuration: 4000,
                    center: {
                        lat: coordinates.latitude,
                        lng: coordinates.longitude,
                    },
                })
            }
        }

        return () => { cancelled = true; }
    }, [loaded, coordinates]);


    useEffect(() => {
        let cancelled = false
        if (!cancelled && location) {
            mapRef.current?.flyTo({
                maxDuration: 4000,
                zoom: 13,
                center: {
                    lat: location.latitude,
                    lon: location.longitude,
                },
            } as FlyToOptions)
        }
        return () => { cancelled = true; }
    }, [location])

    return (
        <>
            <MapboxMap {...{
                id: 'greenit_mapbox_map',
                initialViewState: {
                    zoom: 11,
                    latitude: 25.27432593221387,
                    longitude: 51.50355984917667,
                },
                style: {
                    position: 'relative',
                    height: interactible ? 500 : '100%',
                    ...custom_map_styles
                },
                ref: mapRef,
                onIdle: setCoordinatesOnDragEnd,
                mapboxAccessToken: MAPBOX_PUBLIC_API_KEY,
                mapStyle: 'mapbox://styles/mapbox/streets-v11',
            }} />
            <MarkerBox {...{ visible: (interactible && form_opened).toString() }}>
                <GreenitMapMarker />
            </MarkerBox>
            {!Boolean(interactible) && <MapBlocker />}
            {
                Boolean(interactible) &&
                <Dialog
                    open={dialogOpen}
                    onClose={closeAlert}
                >
                    <DialogTitle id="alert-dialog-title">
                        OUTSIDE DELIVERY ZONE!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Looks like the address you have marked is outside of our delivery zone 😔.
                            Kindly note that we only deliver orders near or contained in Doha.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <GreenButton onClick={closeAlert}>CLOSE</GreenButton>
                    </DialogActions>
                </Dialog>
            }

        </>
    )
};

export { Map };
