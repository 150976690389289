import { useEffect, useState } from "react";

// IMPORT COMPONENTS
import { GetDataFromDOM, Loading } from "@custom_components";
import { OrderMainWrapper } from "./OrderElements";
import PageSelector from "@listing_components/PageSelector";

import { API_ORDER_DETAIL_TYPE } from "@api/types";
import { HTTP_METHODS, HTTP_REQUEST } from "@api/interactions";

import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { OrderTicket } from "./components/OrderTickets";
import { useFirstRender } from "@project/hooks";
import { mapOrders } from "@project/helpers";

import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from "@project/styles";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


// Fix this garbage later
async function getOrdersRequest(requestData: any, successCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('order/', {
        method: HTTP_METHODS.GET,
        options: { data: requestData, validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => { successCallback(response) })
        .catch(err => console.error(err))
}


const NoOrders = styled.div`
    display: flex;
    
    margin: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: ${COLORS.DARKER_GREY};
    height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`

const Orders = (props: any) => {
    const { t } = useTranslation();

    const PAGE_SIZE = 5;
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'))
    const [ordersAPI, setOrdersAPI] = useState<{ count: number, results: API_ORDER_DETAIL_TYPE[] }>({ count: 0, results: [] });

    const [loading, setLoading] = useState(false);
    const [displayLoading, setDisplayLoading] = useState(false);
    const orders = mapOrders(ordersAPI.results);
    const { count } = ordersAPI;
    const numberOfPages = Math.ceil(count / PAGE_SIZE);
    const isFirstRender = useFirstRender();

    function changePageNumber(newPage: number) {
        setSearchParams({ page: newPage.toString() })
        setPage(newPage);
    }

    const getOrders = () => {
        setLoading(true);
        setDisplayLoading(true);
        getOrdersRequest({ page_size: PAGE_SIZE, page: page }, (response: any) => {
            setOrdersAPI(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (isFirstRender) {
            const ordersFromDOM = props?.responseDataDOM?.api_data?.orders;
            if (ordersFromDOM) { setOrdersAPI(ordersFromDOM); return }
        }
        getOrders();
    }, [page])


    useEffect(() => {
        let cancelled = false;
        let timeout: NodeJS.Timeout;
        if (!loading) { if (!cancelled) { timeout = setTimeout(() => !cancelled && setDisplayLoading(false), 1500) } }
        return () => { cancelled = true; clearTimeout(timeout) }
    }, [loading])

    return (
        <OrderMainWrapper>
            <Helmet><title>{t('order.metaTitle')}</title></Helmet>
            {
                displayLoading ? <Loading /> : (
                    Boolean(orders && orders.length) ?
                        <>
                            {orders.map((order, key) => <OrderTicket {...order} {...{ key }} />)}
                            <PageSelector {...{ currentPage: page, maxPages: numberOfPages, changePageNumber: changePageNumber }} />
                        </>
                        :
                        <NoOrders>
                            <h1>You have no orders from Greenit.</h1>
                        </NoOrders>
                )
            }
        </OrderMainWrapper>
    )
}

export default GetDataFromDOM(Orders);