import {
    HTTP_METHODS, HTTP_REQUEST,
    FAILURE_RESPONSE, RESPONSE_TYPE, SUCCESS_RESPONSE
} from '@api/interactions';

export const verifyOTPFetch = async (otp_code: string) => {
    const HttpRequest = new HTTP_REQUEST(`user/otp/verify-otp/`, {
        method: HTTP_METHODS.POST,
        options: { data: { otp_code } }
    })

    const Response = await HttpRequest.callFetch()
    return Response
}


type CUSTOM_RESPONSE = null
const api_type = 'VERIFY_OTP'

const verifyOTP = async (otp_code: string): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await verifyOTPFetch(otp_code)
        const success = response.ok

        return success ?
            SUCCESS_RESPONSE<CUSTOM_RESPONSE>(null)
            : FAILURE_RESPONSE(api_type)

    } catch (err) {
        return FAILURE_RESPONSE(api_type)
    }
}

export default verifyOTP;