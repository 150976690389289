import { LatLng, SERVICE_AREA_TYPE } from '@api/user/address/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SETTINGS_TOKENS_TYPE {
    web_id: string | null;
}

interface SETTINGS_PROMPTS {
    maintainence: boolean;
    unauthorized: boolean;
}

interface SETTINGS_STATE {
    service_area: LatLng[];
    tracking: SETTINGS_TOKENS_TYPE;
    prompts: SETTINGS_PROMPTS;
}

interface SET_TOKEN_STATE {
    token_key: keyof SETTINGS_TOKENS_TYPE;
    token_value: string;
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        service_area: [],
        tracking: {
            web_id: null,
        },
        prompts: {
            maintainence: false,
            unauthorized: false,
        }
    } as SETTINGS_STATE,


    reducers: {
        clearTracking: (state) => {
            state.service_area = [];
            state.tracking = {
                web_id: null,
            };
            state.prompts = {
                maintainence: false,
                unauthorized: false,
            };
        },

        setServiceArea: (state, { payload }: PayloadAction<SERVICE_AREA_TYPE>) => {
            state.service_area = payload
        },

        setToken: (state, { payload: { token_key, token_value } }: PayloadAction<SET_TOKEN_STATE>) => {
            state.tracking[token_key] = token_value;
        },

        deleteToken: (state, { payload: token_key }: PayloadAction<keyof SETTINGS_TOKENS_TYPE>) => {
            state.tracking[token_key] = null;
        },
    },
});


// Action creators are generated for each case reducer function
export const { setToken, deleteToken, setServiceArea, clearTracking } = settingsSlice.actions;

export default settingsSlice.reducer;

