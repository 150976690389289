import React from 'react';

// IMPORT STYLES
import { COLORS } from "@project/styles";

// IMPORT TYPES
import { API_PRODUCT_CATEGORY_TYPE } from "@api/types"

// IMPORT STYLED COMPONENTS
import Tooltip from '@mui/material/Tooltip';
import { SubCategoryCircle, SubCategoryContainer } from "./CustomElements"



interface SubCategoryCircleProps {
    product_id: string;
    sub_categories: API_PRODUCT_CATEGORY_TYPE[];
}

export const SubCategoryCircles = ({ product_id, sub_categories }: SubCategoryCircleProps) => (
    <SubCategoryContainer>
        {
            sub_categories.filter(cat => Boolean(cat.icon)).slice(0, 6).map(({ name, slug, color_code, icon }) => (
                <Tooltip {...{
                    title: name,
                    placement: 'top',
                    leaveDelay: 200,
                    key: `${product_id}_${slug}`,
                }} arrow>
                    <SubCategoryCircle {...{ style: { backgroundColor: color_code || COLORS.GREEN } }}>
                        <img src={icon as string} style={{
                            width: '80%',
                            height: '80%',
                            padding: '4%',
                            objectFit: 'contain',
                        }} />
                    </SubCategoryCircle>
                </Tooltip>
            ))
        }
    </SubCategoryContainer>
)