import { useTranslation } from 'react-i18next';
import {
    TitleRow,
    CategoryTitle,
    CategoryTitleWrapper,
    TestimonialsContainer,
} from './TestimonialsElements';

// IMPORT HOOKS
import { useIsScreenBiggerThan } from '@project/hooks';


// IMPORT COMPONENTS
import { Swipeable } from '@custom_components';
import TestimonialCard from './components/TestimonialCard';
import { SwiperProps } from 'swiper/react';

const STEP1 = 1400;
const STEP2 = 1024;
const STEP3 = 768;

const Testimonials = () => {
    const { t } = useTranslation();

    const big = useIsScreenBiggerThan(STEP1);
    const medium = useIsScreenBiggerThan(STEP2);
    const small = useIsScreenBiggerThan(STEP3);

    const data = [
        { title: t('home.testimonialName1'), text: t('home.testimonial1') },
        { title: t('home.testimonialName2'), text: t('home.testimonial2') },
        { title: t('home.testimonialName3'), text: t('home.testimonial3') },
        { title: t('home.testimonialName4'), text: t('home.testimonial4') },
        { title: t('home.testimonialName5'), text: t('home.testimonial5') },
    ];

    const additional_swiper_props: SwiperProps = big ?
        {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 100,
            pagination: false,
        } : (
            medium ? {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 50,
                pagination: false,
            } : {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 50,
                pagination: false,
            })

    return (
        <TestimonialsContainer>
            <TitleRow>
                <CategoryTitleWrapper>
                    <CategoryTitle>
                        {t('home.whatCustomers')}{' '}
                        <span style={{ fontWeight: 'bold' }}>{t('home.sayAboutUs')}</span>
                    </CategoryTitle>
                </CategoryTitleWrapper>
            </TitleRow >
            <Swipeable {...{
                data,
                slide_key: 'title',
                additional_swiper_props,
                render_item: ({ item }: any) => (<TestimonialCard {...item} />),
            }} />
        </TestimonialsContainer >
    );
};

export default Testimonials;
