import React, { useState } from 'react';
import {
    BlackLine,
    Card,
    CardBackContent,
    CardChip,
    CardFrontContent,
    CardInfo,
    CardLabel,
    CardLogo,
    CardNumber,
    CardSecret,
    CardSecretLast,
    CardSection,
    CardSpace25,
    CardSpace75,
} from './CardElements';
import { useSelector } from 'react-redux';

// IMPORT TYPES
import { RootState } from '@redux/store';
import { API_SAVED_CARDS_TYPE } from '@api/user/cards/types';

const CreditCard = ({ card }: { card: API_SAVED_CARDS_TYPE }) => {
    const { first_name, last_name } = useSelector((state: RootState) => state.user.details)
    return (
        <Card>
            <CardSection {...{ isFront: true }}>
                <CardChip {...{ isFront: true }} />
                <CardLogo {...{ isFront: true }} />
                <CardFrontContent>
                    <CardNumber>{card.card_number}</CardNumber>
                    <CardSpace75>
                        <CardLabel>Card holder</CardLabel>
                        <CardInfo>{card.card_holder || `${first_name} ${last_name}`}</CardInfo>
                    </CardSpace75>
                    <CardSpace25>
                        <CardLabel>Expires</CardLabel>
                        <CardInfo>{card.card_expiry}</CardInfo>
                    </CardSpace25>
                </CardFrontContent>
            </CardSection>
        </Card>
    );
};

export default CreditCard;
