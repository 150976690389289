import { getInitialSetUpData } from '@api/helpers';
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    getFirstError,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
    RESPONSE_TYPE,
} from '@api/interactions';


export const signUpFetch = async (
    email: string,
    password: string,
    confirmPassword: string
) => {
    const data = {
        email,
        password1: password,
        password2: confirmPassword,
    };

    const HttpRequest = new HTTP_REQUEST('auth/registration/', {
        method: HTTP_METHODS.POST,
        options: { data },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


interface CUSTOM_RESPONSE {
    key: string;
    auth_token_cookie: string
}

const api_type = 'SIGN_UP';
const signUpResponder = async (
    username: string,
    password: string,
    confirmPassword: string
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await signUpFetch(username, password, confirmPassword);
        const APIResponse = await response.json();

        if (response.ok) {
            // Fetch User Information Asynchronously
            // ... - ...
            getInitialSetUpData({
                auth_token: APIResponse.key,
                auth_token_cookie: APIResponse.auth_token_cookie,
            });
            // ... - ...

            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>({
                key: APIResponse.key,
                auth_token_cookie: APIResponse.auth_token_cookie,
            });
        }
        return FAILURE_RESPONSE(
            api_type,
            getFirstError(APIResponse) || 'Invalid Information'
        );
    } catch (error) {
        return FAILURE_RESPONSE(api_type, 'Error during sign-up');
    }
};

export default signUpResponder;

export type SIGN_UP_RESPONSE = CUSTOM_RESPONSE
