import styled from 'styled-components';
import { BASE_TRANSITION_TIME } from '@project/styles';

export const TestimonialsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background-color: #D4F1D8;
    flex-direction: column;
    padding: 5% 2%;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    transition: all ${BASE_TRANSITION_TIME}s;
    @media screen and (max-width: 768px) {
        padding-top: 5%;
        height: 550px;
    }
`;


// TITLE STYLING
export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width: 85%;
    transition: all ${BASE_TRANSITION_TIME}s;
    @media screen and (max-width: 768px) {
        justify-content: center;
        margin-left: 5%;
        margin-right: 5%;
    }
`;

export const CategoryTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CategoryTitle = styled.h1`
    font-weight: normal;
    transition: font-size 0.5s ease;
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;
export const ButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
// CARD STYLING
export const TestimonialWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TestimonialCardContainer = styled.div`
    width: 500px;
    height: 250px;
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    margin: 0% 0%;
    padding: 2% 8%;

    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;


    transition: width 0.5s;
    @media screen and (max-width: 768px) {
        width: 80%;
        padding: 1% 5%;
        height: 350px;
        margin-top: 5%;
        align-items: center;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0% 5%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
`;

export const CardTitle = styled.h2`
    font-weight: bold;
    margin: 0;
    padding: 0% 5px;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }
`;
export const CardText = styled.h4`
    font-weight: normal;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
        text-align: justify;
    }
`;
