import React from 'react';

import {
    toast,
    Toaster,
    ToastBar,
    ValueOrFunction,
    Renderable
} from 'react-hot-toast';
import { IconTheme } from 'react-hot-toast';
import { ToastType } from 'react-hot-toast/dist/core/types';

import {
    ToastDismiss,
    ToastDismissContainer
} from './ToastElements';

import { COLORS } from '@project/styles';
import { RiFileWarningFill, RiFileWarningLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
export type NOTIFICATION_TYPE = 'SUCCESS' | 'ERROR' | 'LOADING' | 'WARNING'


export interface NotifyMessageProps {
    message: string;
    additional_text?: string;
    notification_type: NOTIFICATION_TYPE;
}

export const getSecondaryToastColor = (type: ToastType) => {
    switch (type) {
        case 'success': return COLORS.NOTIFICATION_LIGHT_GREEN;
        case 'error': return COLORS.NOTIFICATION_LIGHT_RED;
        case 'custom': return COLORS.NOTIFICATION_LIGHT_ORANGE;
        default: return COLORS.NOTIFICATION_LIGHT_ORANGE;

    }
}

export const getPrimaryToastColor = (type: ToastType) => {
    switch (type) {
        case 'success': return COLORS.NOTIFICATION_GREEN;
        case 'error': return COLORS.NOTIFICATION_RED;
        case 'custom': return COLORS.NOTIFICATION_ORANGE;
        default: return COLORS.NOTIFICATION_ORANGE;
    }
}

export const notifyMessage = ({
    message,
    notification_type
}: NotifyMessageProps) => {

    let iconTheme: IconTheme;

    switch (notification_type) {
        case "SUCCESS":
            toast.success(message, { duration: 3000 })
            break;
        case "ERROR":
            toast.error(message, { duration: 3000 })
            break;
        case "LOADING":
            toast.loading(message, { duration: 3000 })
            break;
        case "WARNING":
            toast(message, {
                icon: <RiFileWarningFill {...{ fill: COLORS.NOTIFICATION_ORANGE, fontSize: '2rem' }} />,
                style: {
                    backgroundColor: COLORS.NOTIFICATION_LIGHT_ORANGE,
                    border: `solid 1px ${COLORS.NOTIFICATION_ORANGE}`,
                    color: COLORS.NOTIFICATION_ORANGE,
                },
                duration: 3000,
            })
            break;
        default:
            toast(message)
            return;
    }
}

interface PromiseMessageProps<T> {
    promise: Promise<T>;
    error: ValueOrFunction<Renderable, T>;
    success: ValueOrFunction<Renderable, T>;
    loading: Renderable;
}

export function promiseMessage<T>({
    promise, success, error, loading
}: PromiseMessageProps<T>) {
    toast.promise<T>(promise, {
        loading,
        success,
        error
    })
}

const CustomToaster = () => {
    const translate = useTranslation();
    return (
        <Toaster {
            ...{
                containerStyle: {
                    position: 'fixed',
                },
                toastOptions: {
                    position: 'bottom-left',
                    success: {
                        duration: 3000,
                        iconTheme: {
                            primary: COLORS.NOTIFICATION_GREEN,
                            secondary: COLORS.WHITE_SMOKE,
                        },
                        style: {
                            color: COLORS.NOTIFICATION_GREEN,
                            border: `1px solid ${COLORS.NOTIFICATION_GREEN}`,
                            backgroundColor: COLORS.NOTIFICATION_LIGHT_GREEN,
                        }
                    },
                    error: {
                        duration: 3000,
                        iconTheme: {
                            primary: COLORS.NOTIFICATION_RED,
                            secondary: COLORS.WHITE_SMOKE,
                        },
                        style: {
                            height: 70,
                            color: COLORS.NOTIFICATION_RED,
                            border: `1px solid ${COLORS.NOTIFICATION_RED}`,
                            backgroundColor: COLORS.NOTIFICATION_LIGHT_RED,
                        }
                    },
                    custom: {
                        duration: 3000,
                        iconTheme: {
                            primary: COLORS.NOTIFICATION_ORANGE,
                            secondary: COLORS.WHITE_SMOKE,
                        },
                        style: {
                            height: 70,
                            color: COLORS.NOTIFICATION_ORANGE,
                            border: `1px solid ${COLORS.NOTIFICATION_ORANGE}`,
                            backgroundColor: COLORS.NOTIFICATION_LIGHT_ORANGE,
                        }
                    },
                    loading: {
                        iconTheme: {
                            primary: COLORS.NOTIFICATION_ORANGE,
                            secondary: COLORS.WHITE,
                        },
                        style: {
                            height: 70,
                            color: COLORS.NOTIFICATION_ORANGE,
                            border: `1px solid ${COLORS.NOTIFICATION_ORANGE}`,
                            backgroundColor: COLORS.NOTIFICATION_LIGHT_ORANGE,
                        }
                    }
                }
            }
        } >
            {
                (t) => (
                    <ToastBar
                        toast={t} >
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                                {
                                    t.type !== 'loading' && (
                                        <ToastDismissContainer>
                                            <ToastDismiss
                                                style={{ fontSize: '1rem' }}
                                                onClick={() => toast.dismiss(t.id)
                                                }
                                                primaryColor={getPrimaryToastColor(t.type)}
                                                secondaryColor={getSecondaryToastColor(t.type)} >
                                                {translate.t('misc.dismiss')}
                                            </ ToastDismiss>
                                        </ ToastDismissContainer>
                                    )
                                }
                            </>
                        )}
                    </ToastBar>
                )
            }
        </Toaster>
    )
}

export default CustomToaster;