import {
    ENCODE_FORM_DATA,
    HTTP_METHODS,
    HTTP_REQUEST,
} from '@api/interactions';
import { storeDispatch } from '@redux/store';
import {
    addCustomBouquetImageKeys,
    addProductRecommendationsImageKeys,
    addProductReviewImageKeys,
    setUserAvatarImageKeys,
} from '@redux/state/upload';
import { imagePostTypeWithAspectRatio } from './types';

interface IMAGE_TYPE {
    file_name: string;
    image_width: number;
    image_height: number;
    content_type: CONTENT_TYPE;
}

interface S3_POST_PARAMS {
    url: string;
    fields: {
        key: string;
        [k: string]: string;
    };
}

export type CONTENT_TYPE = 'image/png' | 'image/jpeg' | 'image/gif';
export type IMAGE_UPLOAD_TYPE =
    | 'recommendation'
    | 'review'
    | 'profile'
    | 'bouquet';

const getImageBlob = async (
    image: imagePostTypeWithAspectRatio
): Promise<Blob> => {
    const imageResponse = await fetch(image.uri);
    return await imageResponse.blob();
};

export const sendImageToS3 = async (
    s3_url: string,
    s3_parameters: any,
    image: imagePostTypeWithAspectRatio
): Promise<boolean> => {
    const imageBlob = await getImageBlob(image);

    const imageUploadResponse = await fetch(s3_url, {
        method: HTTP_METHODS.POST,
        headers: {},
        body: ENCODE_FORM_DATA({
            ...s3_parameters,
            file: imageBlob,
        }),
    });
    return imageUploadResponse.ok;
};

export type imageUploadPreSignedURLGenType =
    | 'user/avatar/'
    | 'product/review/generate-image-url/'
    | 'user/services/vote-for-new-product/generate-image-url/'
    | 'user/services/request-custom-bouquet/generate-image-url/';

export const getImageUploadURL = (
    type: IMAGE_UPLOAD_TYPE
): imageUploadPreSignedURLGenType => {
    switch (type) {
        case 'profile':
            return 'user/avatar/';
        case 'review':
            return 'product/review/generate-image-url/';
        case 'bouquet':
            return 'user/services/request-custom-bouquet/generate-image-url/';
        case 'recommendation':
            return 'user/services/vote-for-new-product/generate-image-url/';
    }
};

export const addImageKeyToState = (
    key: string,
    type: IMAGE_UPLOAD_TYPE
): void => {
    switch (type) {
        case 'profile':
            storeDispatch(setUserAvatarImageKeys(key));
            return;
        case 'review':
            storeDispatch(addProductReviewImageKeys(key));
            return;
        case 'bouquet':
            storeDispatch(addCustomBouquetImageKeys(key));
            return;
        case 'recommendation':
            storeDispatch(addProductRecommendationsImageKeys(key));
            return;
    }
};

export const uploadImage = async (
    image: imagePostTypeWithAspectRatio,
    type: IMAGE_UPLOAD_TYPE
): Promise<boolean> => {
    const uploadURL = getImageUploadURL(type);

    const HttpRequest = new HTTP_REQUEST(uploadURL, {
        method: HTTP_METHODS.POST,
        options: {
            data: {
                file_name: image.name,
                image_width: image.width,
                image_height: image.height,
                content_type: image.type,
            } as IMAGE_TYPE,
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        if (Response.ok) {
            const { url, fields }: S3_POST_PARAMS = await Response.json();
            const { key: db_image_path_key, ..._ } = fields;
            const successfulUploadToS3 = await sendImageToS3(
                url,
                fields,
                image
            );
            if (successfulUploadToS3) {
                addImageKeyToState(db_image_path_key, type);
                return true;
            }
            return false;
        }
        return false;
    } catch (error) {
        return false;
    }
};
