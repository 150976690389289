import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// IMPORT COMPONENTS
import App from './lib/App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

// IMPORT HELPERS
import { initTranslation } from '@translation';

import './index.css';
import { GOOGLE_WEB_CLIENT_ID } from './config';

initTranslation();

ReactDOM.render(
    <GoogleOAuthProvider {...{ clientId: GOOGLE_WEB_CLIENT_ID }}>
        <React.StrictMode>
            <BrowserRouter>
                <Suspense fallback={<div><h1></h1></div>}>
                    <App />
                </Suspense>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>,
    document.getElementById('root')
);
