import { CSSProperties, memo, useEffect, useState } from 'react';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT MUI COMPONENTS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress, Button, Typography } from '@mui/material';

import '@project/fonts';

export interface PrivateButtonState {
    error: string;
    loading: boolean;
    submitted: boolean;
    success: boolean | null;
}

interface LoadingButtonProps {
    disabled: boolean;
    buttonTitle: string;
    onClick: () => void;
    style?: CSSProperties;
    textStyle?: CSSProperties;
    colorScheme: {
        PRIMARY: string;
        SECONDARY: string;
        TERTIARY: string;
    };
    buttonState: PrivateButtonState;
}
const styles = {
    button: {
        width: '70%',
        height: '60px',
        marginTop: '1%',
        fontWeight: 'bold',
    },
};
const LoadingButton = memo(
    ({ disabled, colorScheme, buttonTitle, onClick, buttonState, style, textStyle }: LoadingButtonProps) => {
        const { loading, submitted, success, error } = buttonState;
        const { button } = styles;
        const buttonColor = !submitted
            ? colorScheme.PRIMARY
            : success
                ? colorScheme.PRIMARY
                : (error ? colorScheme.TERTIARY : colorScheme.PRIMARY);
        const [showText, setShowText] = useState(true);

        useEffect(() => {
            if (!submitted) {
                setShowText(true);
            }
        }, [submitted])

        return (
            <Button
                {...{
                    name: 'submit',
                    variant: 'contained',
                    disabled,
                    onClick: () => {
                        !disabled && onClick();
                        setShowText(false);
                    },
                    style: {
                        ...button,
                        opacity: disabled ? 0.4 : 1,
                        backgroundColor: buttonColor,
                        ...style,
                    },
                }}
            >
                {showText ? (
                    <Typography style={{ color: 'white', fontWeight: '900', fontSize: '1.3rem', ...textStyle }}>
                        {buttonTitle}
                    </Typography>
                ) : loading ? (
                    <CircularProgress
                        size="2em"
                        thickness={5}
                        style={{ color: COLORS.WHITE }}
                    />
                ) : success ? (
                    <CheckCircleIcon
                        style={{ color: colorScheme.SECONDARY }}
                        fontSize="large"
                    />
                ) : (
                    <Typography style={{ color: 'white', fontWeight: '900', fontSize: '1.3rem', ...textStyle }}>
                        {buttonTitle}
                    </Typography>
                )}
            </Button>
        );
    }
);


export const StaticLoadingButton = ({ buttonTitle, onClick, style, colorScheme, textStyle }:
    Pick<LoadingButtonProps, 'buttonTitle' | 'onClick' | 'colorScheme' | 'style' | 'textStyle'>) => (
    <Button
        {...{
            onClick,
            name: 'submit',
            variant: 'contained',
            disabled: false,
            style: {
                ...(styles.button),
                backgroundColor: colorScheme.PRIMARY,
                ...style,
            },
        }}>
        <Typography style={{ color: 'white', fontWeight: '900', fontSize: '1.3rem', ...textStyle }}>
            {buttonTitle}
        </Typography>
    </Button>
)

export default LoadingButton;