import { COLORS } from '@project/styles';
import styled from 'styled-components';


export const AnimationContainer = styled.div`
    display: flex;
    width: 60%;
    height: 90%;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100%
    }

    @media screen and (max-width: 500px) {
        width: 100%
    }
`

export const StatusMessage = styled.div<{ color: string }>`
    display: flex;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: ${props => props.color};
    @media screen and (max-width: 768px) {
        font-size: 1.8rem;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.4rem;
        padding: 0% 2%;
    }
`

export const DeliveryContainer = styled.div`
    display: flex;
    margin-top: 20px;
    width: 90%;
    position: relative;
    flex-direction: column;
`

export const DeliveryRow = styled.div`
    display: flex;
    color: #333;
    width: 100%;
    font-size: 1.4rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    

    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 500px) {
        font-size: 1rem;
    }
`

export const Title = styled.div`
    display: flex;
    font-size: 1.6rem;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.2rem;
    }
`

export const DeliveryTime = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
`

export const DeliveryWindow = styled.div`
    color: #555;
`

export const StatusContainer = styled.div`
    display: flex;
    height: 90%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    align-items: center;
    justify-self: flex-start;
    flex-direction: column;
    padding: 0% 0% 10% 0%;

    @media screen and (max-width: 768px) {
        padding: 0% 0% 15% 0%;
    }

    @media screen and (max-width: 500px) {
        padding: 0% 0% 20% 0%;
    }
`

export const StatusInformationContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2% 5%;
`

export const LoadingContainer = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    align-self: center;
    transform: translateX(20%);
    
    
    @media screen and (max-width: 768px) {
        top: 5%;
        width: 70%
    };
    @media screen and (max-width: 500px) {
        top: 15%;
        width: 65%;
    }
`

export const Button = styled.div<{ color: string }>`
    width: 500px;
    padding: 10px 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.8rem;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    color: ${COLORS.WHITE};
    background-color: ${props => props.color};
    @media screen and (max-width: 768px) {
        width: 80%;
        font-size: 1.8rem;
    };
    @media screen and (max-width: 500px) {
        width: 85%;
        font-size: 1.4rem;
    }
`