import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';


import {
    API_DIBSY_PAYMENT_TYPE,
    PAYMENT_INITIALIZATION_PARAMS,
} from '@api/checkout/types';


const processPaymentFetch = async (data: PAYMENT_INITIALIZATION_PARAMS) => {
    const HttpRequest = new HTTP_REQUEST('payment/initialize-payment/', {
        method: HTTP_METHODS.POST,
        options: { data },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


const processPayment = async (data: PAYMENT_INITIALIZATION_PARAMS):
    Promise<RESPONSE_TYPE<API_DIBSY_PAYMENT_TYPE>> => {
    try {
        const response = await processPaymentFetch(data);
        let body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<API_DIBSY_PAYMENT_TYPE>(body)
            : FAILURE_RESPONSE('GET_PAYMENT_LINK', body)
    } catch (error) {
        return FAILURE_RESPONSE('GET_PAYMENT_LINK', error)
    }
};


export default processPayment;