import {
    HTTP_METHODS,
    HTTP_REQUEST,
    getFirstError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

export const resetPasswordViaEmailFetch = async (email: string) => {
    const HttpRequest = new HTTP_REQUEST('auth/password/reset/', {
        method: HTTP_METHODS.POST,
        options: { data: { email } },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


type CUSTOM_RESPONSE = null;
const api_type = 'RESET_PASSWORD';

const resetPasswordViaEmail = async (
    email: string
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await resetPasswordViaEmailFetch(email);
        let APIResponse = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>(null);
        }
        return FAILURE_RESPONSE(
            api_type,
            getFirstError(APIResponse) || 'Invalid Information'
        );
    } catch (err) {
        return FAILURE_RESPONSE(api_type, 'Error resetting password');
    }
};


export default resetPasswordViaEmail;
