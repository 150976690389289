
import React, { useEffect } from 'react';

// IMPORT HELPERS
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';
import { useDidMount } from '@project/hooks';
import {
    updatePhone,
    updateEmail,
    updateLastName,
    updateFirstName,
    getProfileDetails,
} from '@api/helpers';

// IMPORT TYPES
import { RootState } from '@redux/store';

// IMPORT COMPONENTS
import {
    TextInput,
    TextInputOneField,
    TextInputTwoFields,
} from '@form_components';
import { notifyMessage, promiseMessage } from '@overlays/notifications';
import { PersonalDetailsContainer, SectionTitle } from './AdditionalElements';
import PhoneNumberInput from '@pages/UserProfile/Tabs/AddressAndCardsTab/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
interface LocalUserState {
    email: string;
    first_name: string;
    last_name: string;
    otp_phone_number: string | null;
}

const PersonalDetails = () => {

    const { t } = useTranslation();

    useDidMount(() => { getProfileDetails() })

    const {
        details: { first_name, last_name, email, is_fake_temporary_email, is_otp_verified, otp_phone_number }
    } = useSelector((state: RootState) => state.user);


    const [state, setState] = useImmer<LocalUserState>({
        last_name,
        first_name,
        otp_phone_number,
        email: is_fake_temporary_email ? '' : email,
    })


    const setUserFirstName = () => {
        if (state.first_name === first_name) {
            return;
        }

        if (!state.first_name) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.firstNameNotSet')
            })
        } else {
            promiseMessage({
                promise: updateFirstName(state.first_name),
                error: t('userSection.firstNameError'),
                success: t('userSection.firstNameSuccess'),
                loading: t('userSection.firstNameLoading'),
            })
        }
    }

    const setUserLastName = () => {
        if (state.last_name === last_name) { return; }

        if (!state.last_name) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.lastNameNotSet')
            })
        } else {
            promiseMessage({
                promise: updateLastName(state.last_name),
                error: t('userSection.lastNameError'),
                success: t('userSection.lastNameSuccess'),
                loading: t('userSection.lastNameLoading'),
            })
        }
    }

    const setUserEmail = () => {
        if (state.email === email) { return; }

        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if (!state.email) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.emailNotSet')
            })
        } else if (!(emailRegex.test(state.email))) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.emailErrorShort1') + ' ' + t('userSection.emailErrorShort2')
            })
        } else {
            promiseMessage({
                promise: updateEmail(state.email),
                error: (error) => `${error + '.' || t('userSection.emailErrorShort1')}` + ' ' + t('userSection.emailErrorShort2'),
                success: t('userSection.emailSuccess'),
                loading: t('userSection.emailLoading')
            })
        }
    }

    const setPhoneNumber = (phone_number: string) => {
        if (is_otp_verified) { return; }

        const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
        if (!phone_number) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.phoneNumberNotSet')
            })
        } else if (!(phoneNumberRegex.test(phone_number))) {
            notifyMessage({
                notification_type: 'ERROR',
                message: t('userSection.phoneNumberError')
            })
        } else {
            promiseMessage({
                promise: updatePhone(phone_number),
                error: t('userSection.phoneNumberError'),
                success: t('userSection.phoneNumberSuccess'),
                loading: t('userSection.phoneNumberLoading'),
            })
        }
    }

    useEffect(() => {
        setState(draft => { draft.otp_phone_number = otp_phone_number })
    }, [otp_phone_number]);

    return (
        <PersonalDetailsContainer>
            <SectionTitle>{t("checkout.yourDetails")}</SectionTitle>
            <TextInputTwoFields>
                <TextInput
                    {...{
                        value: state.first_name || '',
                        label: t('checkout.firstName'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.first_name = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: first_name ? true : false,
                        },
                        onBlur: setUserFirstName,
                    }}
                />
                <TextInput
                    {...{
                        value: state.last_name || '',
                        label: t('checkout.lastName'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.last_name = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: last_name ? true : false,
                        },
                        onBlur: setUserLastName
                    }}
                />
            </TextInputTwoFields>
            <TextInputTwoFields>
                <TextInput
                    {...{
                        value: state.email || '',
                        label: t('common.email'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.email = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: !is_fake_temporary_email ? true : false,
                        },
                        onBlur: setUserEmail
                    }}
                />

                {
                    state.otp_phone_number ? <TextInput
                        {...{
                            value: otp_phone_number || '',
                            label: t('checkout.mobileNumber'),
                            variant: 'outlined',
                            InputProps: {
                                readOnly: true,
                            },
                        }}
                    />
                        :
                        <PhoneNumberInput
                            {...{
                                isValid: true,
                                isSubmitted: false,
                                saveNumber: () => { },
                                onBlur: setPhoneNumber,
                            }}
                        />
                }

            </TextInputTwoFields>
            <TextInputOneField>
                <TextInput
                    {...{
                        value: state.first_name || '',
                        label: t('checkout.firstName'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.first_name = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: first_name ? true : false,
                        },
                        onBlur: setUserFirstName
                    }}
                />
            </TextInputOneField>
            <TextInputOneField>
                <TextInput
                    {...{
                        value: state.last_name || '',
                        label: t('checkout.lastName'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.last_name = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: last_name ? true : false,
                        },
                        onBlur: setUserLastName
                    }}
                />
            </TextInputOneField>
            <TextInputOneField>
                <TextInput
                    {...{
                        value: state.email || '',
                        label: t('common.email'),
                        variant: 'outlined',
                        onChange: (e) => {
                            setState(draft => {
                                draft.email = e.target.value;
                            })
                        },
                        InputProps: {
                            readOnly: !is_fake_temporary_email ? true : false,
                        },
                        onBlur: setUserEmail
                    }}
                />
            </TextInputOneField>
            <TextInputOneField>
                {
                    state.otp_phone_number ? <TextInput
                        {...{
                            value: otp_phone_number || '',
                            label: t('checkout.mobileNumber'),
                            variant: 'outlined',
                            InputProps: {
                                readOnly: true,
                            },
                        }}
                    />
                        :
                        <PhoneNumberInput
                            {...{
                                isValid: true,
                                isSubmitted: false,
                                saveNumber: () => { },
                                onBlur: setPhoneNumber,
                            }}
                        />
                }
            </TextInputOneField>
        </PersonalDetailsContainer >
    )
}

export default PersonalDetails;