// Import Redux Helpers
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Import Navigation Helpers
import SiteRoutes from '@project/routes';

// Import State Helpers

import { persistor, store } from './store';

import { Helmet } from 'react-helmet';

//Styling mui(for RTL) HOC
import { Box } from '@mui/system';
import withRoot from './withRoot';
import CustomToaster from '@overlays/notifications';
import { Header, Footer } from './common/custom_components';

// Import Headers
import styled from 'styled-components';
import { theme } from './common/styles';
import { useDidMount } from './common/hooks';

import { appMountFunctions } from '@project/helpers';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './App.css';
import { useTranslation } from 'react-i18next';

const MainAppContainer = styled(Box)`
    display: flex;
    height: 100vh;
    overflow-y: auto;
    font-weight: bold;
    flex-direction: column;
    font-family: ManropeBold;
    ::-webkit-scrollbar { display: none };
`


const App = () => {
    useDidMount(appMountFunctions)
    const { t } = useTranslation();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider {...{ dateAdapter: AdapterMoment }}>
                <Provider {...{ store }}>
                    <PersistGate {...{ persistor, loading: <></> }}>
                        <MainAppContainer id={'greenit_main_container'}>
                            <div>
                                <Helmet>
                                    <title>{t('meta.title')}</title>
                                    <meta name="description" content={t('meta.description')} />
                                </Helmet>
                                <Header />
                                <SiteRoutes />
                                <Footer />
                                <CustomToaster />
                            </div>
                        </MainAppContainer >
                    </PersistGate>
                </Provider>
            </LocalizationProvider >
        </ThemeProvider >
    );
};

export default withRoot(App);
