//IMPORT API  HELPERS
import { API_PRODUCT_TYPE } from '@api/types';

//IMPORT STYLED COMPONENTS
import ProductCard from '@home_components/Common/ProductCard/ProductCard';
import { ListingProductsContainer, ProductWrapper } from '@pages/Listing/ListingElements';

interface MultiProductsProps {
    expandedProduct?: string;
    products: API_PRODUCT_TYPE[];
    setAddInfo: (productInfo: API_PRODUCT_TYPE) => void;
}

const Products = ({ products, setAddInfo, expandedProduct }: MultiProductsProps) => {
    return (
        <ListingProductsContainer>
            {products && products.map((product, index) => (
                <ProductWrapper key={index}>
                    <ProductCard
                        product={product}
                        openAdd={() => setAddInfo(product)}
                        isExpanded={expandedProduct === product.product_id} showQuickAdd />
                </ProductWrapper>
            ))}
        </ListingProductsContainer>
    );
};

export default Products;
