import React, { memo } from 'react';

// IMPORT MUI COMPONENTS
import {
    createTheme,
    ThemeProvider,
    Typography,
    TypographyProps,
} from '@mui/material';
import { TypographyStyle } from '@mui/material/styles/createTypography';

import '@project/fonts';


const theme = createTheme({
    typography: {
        fontSize: 12,
    },
});
theme.typography.h5 = {
    fontSize: '0.8rem',
    opacity: 0.6,
    '@media (min-width:600px)': {
        fontSize: '0.8rem',
    },
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
};
theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.8rem',
    },
};
theme.typography.body1 = {
    fontSize: '1rem',
    '@media (max-width:300px)': {
        fontSize: '0.9rem',
    },
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    },
};
interface TextProps {
    children: React.ReactNode;
    props?: TypographyProps;
    style?: TypographyStyle;
    color?: string;
}

export const ResponsiveText = memo(
    ({ children, props, style, color }: TextProps) => {
        return (
            <ThemeProvider theme={theme}>
                <Typography {...{ ...props, style: { ...style, color } }}>
                    {children}
                </Typography>
            </ThemeProvider>
        );
    }
);
