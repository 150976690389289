
// IMPORT TYPES
import { API_CATEGORY_TYPE } from '@api/types';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT CUSTOM COMPONENTS
import {
    TitleText,
    TitleRowInfo,

    StyledMaterialTabs,
    FilterInfoContainer,

    Categories,
    CategoryImg,
    CategoryBubble,
    CategoryPickerContainer,
} from './FilterInfoElements';
import { Tab } from '@mui/material';

// IMPORT FONTS
import '@project/fonts';

// IMPORT HELPERS
import { RouterPages } from '@project/routes';
import { getLangPathFromPage } from '@project/routes/components';
import { useNavigate } from 'react-router-dom';
import { Cancel } from '@pages/Listing/ListingElements';
import { IoIosClose } from 'react-icons/io';


interface CategoryTabProps {
    toggleSideMenu: () => void;
    categories: API_CATEGORY_TYPE[] | null;

    activeCategory?: API_CATEGORY_TYPE;
}

const CategoryTabs = ({ categories, activeCategory, toggleSideMenu }: CategoryTabProps) => {

    interface Tab_Props {
        key: string;
        color: string;
        label: string;
        value: string;
        font_weight: string;
        category_path: string;
    }

    const getTabStyle = ({ font_weight, color }: any): React.CSSProperties => ({
        opacity: 1,
        color: color,
        width: '90%',
        display: 'flex',
        minHeight: '50px',
        marginLeft: '5px',
        maxHeight: '50px',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: font_weight,
        justifyContent: 'flex-start',
    })

    const Tab_ = ({ font_weight, color, category_path, label, value }: Tab_Props): JSX.Element => {
        const navigate = useNavigate();

        const onClick = () => {
            toggleSideMenu();
            navigate(`${category_path}?page=1`);
        }
        return (
            <Tab {...{ key: label, onClick, style: getTabStyle({ font_weight, color }), label, value }} />
        )
    }

    const activeSlug = activeCategory?.slug;

    return categories ? (
        <StyledMaterialTabs {...{
            orientation: 'vertical',
            indicatorColor: undefined,
            value: activeSlug || false,
            TabIndicatorProps: { style: { backgroundColor: COLORS.DARK_GREEN } },
        }}>
            {
                <Tab_ key={'all-products'} font_weight={!activeCategory ? 'bold' : 'normal'}
                    color={!activeCategory ? COLORS.DARK_GREEN : COLORS.DARKER_GREY}
                    category_path={getLangPathFromPage(RouterPages.ListingPage)}
                    label={'All Products'}
                    value={'all-products'}
                />
            }
            {
                categories.map(({ slug, name }) => (
                    <Tab_ key={slug} font_weight={activeSlug === slug ? 'bold' : 'normal'}
                        color={activeSlug === slug ? COLORS.DARK_GREEN : COLORS.DARKER_GREY}
                        category_path={getLangPathFromPage(RouterPages.ListingCategoryPage, { 'category': slug })}
                        label={name}
                        value={slug}
                    />
                ))
            }
        </StyledMaterialTabs >
    ) : null
}


interface FilterProps {
    sideMenuOpen: boolean;
    toggleSideMenu: () => void;
    categories: API_CATEGORY_TYPE[] | null;

    activeCategory?: API_CATEGORY_TYPE;
}

const FilterInfo = ({ categories, activeCategory, toggleSideMenu, sideMenuOpen }: FilterProps) => {
    return (
        <FilterInfoContainer>
            <CategoryPickerContainer>
                <TitleRowInfo>
                    <TitleText>
                        Categories
                    </TitleText>
                    <Cancel {...{ is_open: sideMenuOpen, onClick: toggleSideMenu }}>
                        <IoIosClose {...{ style: { color: 'white', fontSize: '1.6rem' } }} />
                    </Cancel>
                </TitleRowInfo>
                <Categories>
                    <CategoryTabs {...{ categories, activeCategory, toggleSideMenu }} />
                </Categories>
            </CategoryPickerContainer>

        </FilterInfoContainer>
    );
}

export default FilterInfo