import { useCallback } from "react";

// IMPORT TYPES
import {
    PAYMENT_METHODS,
    API_ORDER_PRODUCT_TYPE,
    APP_REPOTTED_PRODUCTS_TYPE,
    APP_STATE_ORDER_DETAIL_TYPE,
} from "@api/types";

import { IoIosCash } from 'react-icons/io';
import { SiApplepay } from 'react-icons/si';
import { ImCreditCard } from 'react-icons/im';
import { BsCreditCard2BackFill } from 'react-icons/bs';
// IMPORT COMPONENTS

import {
    ProductImage,
    ProductDetails,

    SectionTitle,
    SectionProducts,

    CoupledProduct,
    BorderedProduct,

    InfoSectionContainer,
    ProductSectionContainer,
    OrderInformationContainer,

    InfoHeader,
    InfoSubheader,
    SectionOtherProducts,
    InformationSectionContainer,

    InfoSection,
    OrderDivider,

    SummaryRow,
    PromoCodeWrapper,
    InfoNormalHeader,
    PaymentIcon,
    PaymentTypeContainer,
    OrderDetailsDivider,
} from "./TicketElements"

import { BiLink } from 'react-icons/bi';
import { Divider } from "@mui/material";
import { GiftAddress } from "./GiftAddress";
import AddressCard from "@pages/UserProfile/Tabs/AddressAndCardsTab/SavedAddresses/AddressCard";


// IMPORT STYLES
import { COLORS } from "@project/styles";
import { useIsScreenBiggerThan } from "@project/hooks";
import { useTranslation } from "react-i18next";


export const OrderDetailCoupledProductCard = ({ plant, pot }: APP_REPOTTED_PRODUCTS_TYPE) => {
    const {
        name: plant_name,
        price: plant_price,
        image: plant_image,
        latin: plant_subheader,
        variant_name: plant_variant,
        product: { shortened_display_name: plant_short_name },
    } = plant;
    const {
        name: pot_name,
        image: pot_image,
        price: pot_price,
        latin: pot_subheader,
        variant_name: pot_variant,
        product: { shortened_display_name: pot_short_name },
    } = pot;

    const _768 = useIsScreenBiggerThan(768)
    return (
        <CoupledProduct>
            <BorderedProduct>
                <ProductImage {...{ src: plant_image }} />
                <ProductDetails>
                    <InfoHeader>{_768 ? plant_name : (plant_short_name || plant_name)}</InfoHeader>
                    <InfoSubheader>{plant_subheader}</InfoSubheader>
                    <InfoSubheader>{plant_variant} - {`QAR ${plant_price}`}</InfoSubheader>
                </ProductDetails>
            </BorderedProduct>
            <BiLink {...{ fontSize: '2.25rem', color: COLORS.DARK_GREEN }} />
            <BorderedProduct>
                <ProductImage {...{ src: pot_image }} />
                <ProductDetails>
                    <InfoHeader>{_768 ? pot_name : (pot_short_name || pot_name)}</InfoHeader>
                    <InfoSubheader>{pot_subheader}</InfoSubheader>
                    <InfoSubheader>{pot_variant} - {`QAR ${pot_price}`}</InfoSubheader>
                </ProductDetails>
            </BorderedProduct>
        </CoupledProduct>
    )
}

export const OrderDetailProductCard = ({
    image, name, latin: subheader, product: { shortened_display_name: short_name },
    variant_name: variant, price, quantity
}: API_ORDER_PRODUCT_TYPE) => {
    const _768 = useIsScreenBiggerThan(768);
    return (
        <BorderedProduct>
            <ProductImage {...{ src: image }} />
            <ProductDetails>
                <InfoHeader {...{ style: { textAlign: 'left' } }}>{`${_768 ? name : (short_name || name)} x ${quantity}`} </InfoHeader>
                <InfoSubheader>{subheader}</InfoSubheader>
                <InfoSubheader>{variant} - {`QAR ${price}`}</InfoSubheader>
            </ProductDetails>
        </BorderedProduct>
    )
}

const PaymentType = ({ payment_type }: { payment_type: PAYMENT_METHODS }) => {
    const { t } = useTranslation();

    const getIcon = useCallback(() => {
        switch (payment_type) {
            case 'Cash':
                return (<IoIosCash {...{ style: { fontSize: '1.4rem' } }} />)
            case 'Apple Pay':
                return (<SiApplepay {...{ style: { fontSize: '1.4rem' } }} />)
            case 'Debit Card':
                return (<BsCreditCard2BackFill {...{ style: { fontSize: '1.4rem' } }} />)
            case 'Credit Card':
                return (<ImCreditCard  {...{ style: { fontSize: '1.4rem' } }} />)
        }
    }, [payment_type])

    return (
        <SummaryRow>
            <InfoHeader>
                {t("orders.paymentType")}
            </InfoHeader>
            <PaymentTypeContainer>
                <PaymentIcon>
                    {getIcon()}
                </PaymentIcon>
                <InfoHeader>
                    {payment_type}
                </InfoHeader>
            </PaymentTypeContainer>
        </SummaryRow>
    )
}

export const OrderProducts = ({
    order_details: {
        payment_type,
        delivery_cost,
        repotting_cost,
        total_cart_price,
        delivery_cost_after_discount,
        total_cart_price_after_discount
    },
    normal_products,
    is_gift_delivery,
    delivery_address,
    repotted_products,
    gift_delivery_address,
}: APP_STATE_ORDER_DETAIL_TYPE) => {

    const { t } = useTranslation();

    const total_cost = delivery_cost_after_discount +
        total_cart_price_after_discount +
        (repotting_cost || 0);
    const total_discount = (delivery_cost - delivery_cost_after_discount) +
        (total_cart_price - total_cart_price_after_discount);
    return (
        <OrderInformationContainer>
            <InformationSectionContainer>
                <SectionTitle>{t("orders.orderDetails")}</SectionTitle>
                <InfoSectionContainer>
                    <InfoSection>
                        {
                            is_gift_delivery ?
                                <GiftAddress {...{
                                    name: gift_delivery_address!.recipient_name,
                                    phone: gift_delivery_address!.mobile_number,
                                }} />
                                :
                                <AddressCard {...{ address: delivery_address! }} disableButtons />
                        }
                    </InfoSection>
                    <InfoSection>
                        <PaymentType {...{ payment_type }} />
                        <SummaryRow>
                            <InfoHeader>
                                {t("orders.cartPrice")}
                            </InfoHeader>
                            <InfoNormalHeader>
                                {t("common.QAR")} {total_cart_price.toFixed(0)}
                            </InfoNormalHeader>
                        </SummaryRow>
                        <SummaryRow>
                            <InfoHeader>
                                {t("orders.deliveryCost")}
                            </InfoHeader>
                            <InfoNormalHeader>
                                {t("common.QAR")} {delivery_cost.toFixed(0)}
                            </InfoNormalHeader>
                        </SummaryRow>
                        {
                            Boolean(repotting_cost) &&
                            <SummaryRow>
                                <InfoHeader>
                                    {t("orders.repottingCost")}
                                </InfoHeader>
                                <InfoNormalHeader>
                                    {t("common.QAR")} {(repotting_cost as number).toFixed(0)}
                                </InfoNormalHeader>
                            </SummaryRow>
                        }
                        {
                            Boolean(total_discount) &&
                            <SummaryRow>
                                <InfoHeader {...{ style: { color: COLORS.RED } }}>
                                    Order Discount:
                                </InfoHeader>
                                <InfoHeader {...{ style: { color: COLORS.RED } }}>
                                    - {t("common.QAR")} {(total_discount as number).toFixed(0)}
                                </InfoHeader>
                            </SummaryRow>
                        }
                        <SummaryRow>
                            <InfoHeader>
                                {t("orders.totalCost")}
                            </InfoHeader>
                            <InfoHeader>
                                {t("common.QAR")} {(total_cost as number).toFixed(0)}
                            </InfoHeader>
                        </SummaryRow>
                    </InfoSection>
                </InfoSectionContainer>
            </InformationSectionContainer>

            {
                Boolean(repotted_products.length) &&
                <>
                    <ProductSectionContainer>
                        <SectionTitle>{t("orders.repottedProducts")}</SectionTitle>
                        <SectionProducts>
                            {
                                repotted_products.map((products, key) => (
                                    <OrderDetailCoupledProductCard {...{ key }} {...products} />
                                ))
                            }
                        </SectionProducts>
                    </ProductSectionContainer>
                </>
            }
            {
                Boolean(normal_products.length) &&
                <>
                    <ProductSectionContainer>
                        <SectionTitle>{
                            Boolean(repotted_products.length)
                                ? t("orders.otherProducts")
                                : t("orders.orderProducts")
                        }</SectionTitle>
                        <SectionOtherProducts>
                            {
                                normal_products.map((products, key) => (
                                    <OrderDetailProductCard {...{ key }} {...products} />
                                ))
                            }
                        </SectionOtherProducts>
                    </ProductSectionContainer>
                </>
            }
        </OrderInformationContainer >
    )
}