import React, { CSSProperties, memo, MouseEventHandler } from 'react';
const StylelessButton = memo(
    ({
        onClick,
        style,
        children,
        disabled,
    }: {
        onClick: MouseEventHandler;
        style?: CSSProperties;
        disabled?: boolean;
        children: React.ReactNode;
    }) => {
        return (
            <button
                disabled={disabled ? true : false}
                style={{
                    border: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    ...style,
                }}
                onClick={onClick}
            >
                {children}
            </button>
        );
    }
);


export default StylelessButton;