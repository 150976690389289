import styled from 'styled-components';
import { animated } from '@react-spring/web';


export const AccordionMainInfo = styled(animated.div)`
    display: flex;
    width: 100%;
    max-height: 80px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const AccordionMainTitle = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between;
`

export const AccordionText = styled.div`
    width: 100%;
    padding: 10px 0px;
    transition: all 0.3s ease;
`

export const AccordionIcon = styled(animated.div)`
    padding: 5px;
    height: 40px;
    display: flex;
    aspect-ratio: 1;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
`

export const AccordionMainContent = styled(animated.div)`
    display: flex;
    width: 100%;
    padding: 15px 0px;
    margin: 20px 0px;
    position: relative;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between;
`

export const AccordionWrapper = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`
