import React from 'react';

// IMPORT HOOLS
import { useTranslation } from 'react-i18next';

// IMPORTS COMPONENTS
import { Divider } from '@mui/material';
import { ResponsiveText } from '@custom_components';

const styles = {
    divider: {
        width: '70%',
        marginTop: '4%',
        marginBottom: '4%',
    },
    textInput: {
        width: '70%',
    },
};
export const OrDivider = () => {
    const { t } = useTranslation();
    return (
        <Divider style={styles.divider} light={false}>
            <ResponsiveText {...{ marginBottom: -5, fontWeight: 'bold' }}>
                {t('auth.or')}
            </ResponsiveText>
        </Divider>
    );
};
