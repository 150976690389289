import styled from 'styled-components';

// IMPORT COMPONENTS
import { Divider } from '@mui/material';

// IMPORT STYLES
import { COLORS } from '@project/styles';

import '@project/fonts';


export const Ticket = styled.div`
    display: flex;
    width: 95%;
    margin-top: 30px;
    padding: 10px 2.5%;
`


export const TicketInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px;
`


export const TicketIDAndStatusRow = styled.div`
    display: flex;
    position: relative;
    padding: 15px 20px 0px 20px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 650px) {
        padding: 10px 0px;
    }
`

export const TicketID = styled.div`
    display: flex;
    width: auto;
    position: relative;
    border-radius: 5px;
    padding: 10px 20px;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bolder;
    justify-content: center;
    transition: all 0.5s ease;
    @media screen and (max-width: 650px) {
        padding: 10px 0px;
        font-size: 1.2rem;
    }
`

export const CustomCardText = styled.span`
    display: flex;
    width: auto;
    position: relative;
    border-radius: 5px;
    align-items: center;
    font-size: 1.6rem;
    cursor: pointer;
    font-weight: bolder;
    justify-content: center;
    transition: all 0.5s ease;
    @media screen and (max-width: 650px) {
        padding: 10px 0px;
        font-size: 1.2rem;
    }
`

export const CustomCardImage = styled.div<{ aspectRatio: number }>`
    margin: 25px 10px 15px 10px;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    height: 275px;
    padding: 15px;
    display: flex;
    align-self: center;
    border: solid 1px gray;
    @media screen and(max-width: 768px) {
        height: 150px;
    }
`;

export const Status = styled.div`
    display: flex;
    width: 150px;
    position: relative;
    border-radius: 10px;
    padding: 10px 10px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 500px) {
        width: 125px;
    }
`

export const StatusInner = styled.div`
    display: flex;
    font-size: 1.1rem;
    text-align: center;
    font-weight: bolder;
`

// ----------- INFO COMPONENTS ---------------

export const OrderDivider = styled(Divider)`
    display: flex;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

export const OrderDetailsDivider = styled(Divider)`
    display: flex;
    @media screen and (max-width: 1200px) {
        display: none;
    }
`

export const OrderInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    @media screen and (max-width: 500px) {
        padding: 5px;
    }
`

export const LeftSide = styled.div`
    display: flex;
    width: 35%;
    padding: 20px;
    transition: all 0.5s ease;
    @media screen and (max-width: 1400px) {
        width: 40%;
        padding: 10px
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0;
    }
`

export const RightSide = styled.div`
    display: flex;
    width: 65%;
    padding: 20px 15px 20px 45px;
    transition: all 0.5s ease;
    @media screen and (max-width: 1400px) {
        width: 60%;
        padding: 10px 5px 10px 25px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0;
    }
`

// ----------- TIMELINE COMPONENTS ---------------

interface BlockProps {
    activated?: boolean;
    active_main_color: string;
    active_light_color: string;
}

export const Block = styled.div`
    display: flex;
    width: 60px;
    padding: 0px 10px;
    min-height: 100px;
    position: relative;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 50px;
        padding: 0px 25px;
    }
`

export const FullCircle = styled.div<{ color: string }>`
    display: flex;
    position: absolute;
    width: 15px;
    border-radius: 5px;
    aspect-ratio: 1;
    align-self: center;
    background-color: ${({ color }) => color};
`

export const LighterCircle = styled.div<{ color: string }>`
    display: flex;
    width: 40px;
    opacity: 0.8;
    aspect-ratio: 1;
    align-self: center;
    position: absolute;
    border-radius: 10px;
    background-color: ${({ color }) => color};
`

export const TopHalfVerticalLine = styled.div<{ activate: boolean, activate_color: string }>`
    display: flex;
    top: 0;
    width: 6px;
    height: 50%;
    align-self: center;
    position: absolute;
    background-color: ${({ activate, activate_color }) => activate ? activate_color : COLORS.DARK_GREY};
`

export const BottomHalfVerticalLine = styled.div<{ activate: boolean, activate_color: string }>`
    display: flex;
    align-self: center;
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 6px;
    background-color: ${({ activate, activate_color }) => activate ? activate_color : COLORS.DARK_GREY};
`

export const StartBlock = ({
    activated,
    active_main_color,
    active_light_color }: BlockProps) => (
    <Block>
        <LighterCircle {...{ color: active_light_color }} />
        <BottomHalfVerticalLine {...{ activate: Boolean(activated), activate_color: active_main_color }} />
        <FullCircle {...{ color: active_main_color }} />
    </Block>
)

export const EndBlock = ({
    activated,
    active_main_color,
    active_light_color }: BlockProps) => (
    <Block>
        <LighterCircle {...{ color: activated ? active_light_color : COLORS.LIGHTER_GREY }} />
        <TopHalfVerticalLine {...{ activate: Boolean(activated), activate_color: active_main_color }} />
        <FullCircle {...{ color: activated ? active_main_color : COLORS.DARKER_GREY }} />
    </Block>
)

export const MiddleBlock = ({
    activated,
    active_main_color,
    active_light_color }: BlockProps) => (
    <Block>
        <LighterCircle {...{ color: activated ? active_light_color : COLORS.LIGHTER_GREY }} />
        <TopHalfVerticalLine {...{ activate: Boolean(activated), activate_color: active_main_color }} />
        <BottomHalfVerticalLine {...{ activate: Boolean(activated), activate_color: active_main_color }} />
        <FullCircle {...{ color: activated ? active_main_color : COLORS.DARKER_GREY }} />
    </Block>
)

export const OrderTimelineContainer = styled.div`
    display: flex;
    width: calc(100% - 10px);
    padding: 5px;

    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
`

export const TimelineRow = styled.div`
    display: flex;
    width: 100%;
    height: 180px;
    flex-direction: row;

    @media screen and (max-width: 1000px) {
        height: 165px;
    }

    @media screen and (max-width: 768px) {
        height: 140px;
    }

    @media screen and (max-width: 500px) {
        height: 115px;
    }
`

export const TimeInfo = styled.div`
    width: 150px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
    @media screen and (max-width: 1000px) {
        width: 30%;
        align-items: flex-end;
        justify-content: center;
    }
`

export const InfoHeader = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    opacity: 0.9;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 500px) {
        font-size: .8rem;
    }
`

export const InfoNormalHeader = styled.div`
    font-weight: normal;
    font-size: 1.2rem;
    opacity: 0.9;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`

export const InfoSubheader = styled.div`
    font-weight: normal;
    font-style: italic;
    font-size: 1rem;
    color: ${COLORS.DARK_GREEN};

    @media screen and (max-width: 768px) {
        font-size: .8rem;
    }
`

export const PaymentTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const PaymentIcon = styled.div`
    display: flex;
    margin: 0px 10px;
    align-items: center;
    justify-content: center;
`

export const DescriptionInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    padding-right: 2%;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
    @media screen and (max-width: 1000px) {
        width: 70%;
    }
`

// ----------- ORDER PRODUCTS COMPONENTS ---------------


export const OrderInformationContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 5px;
    flex-direction: column;
`



export const ProductSectionContainer = styled.div`
    display: flex;
    padding: 0px 0px;
    border-radius: 10px;
    flex-direction: column;
    margin: 0px 0px 0px 0px;
`

export const SectionTitle = styled.h2`
    margin: 2px;
    font-weight: bold;
    text-align: center;
`

export const SectionProducts = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media screen and (max-width: 1400px) {
        flex-direction: column;
    }
`

export const SectionOtherProducts = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
    }
`
export const CoupledProduct = styled.div`
    width: 400px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    justify-content: center;

    @media screen and (max-width: 500px) {
        width: auto;
    }
`

export const BorderedProduct = styled.div`
    width: 200px;
    height: 300px;
    margin: 0 20px 20px 20px;
    transition: all 0.5s ease;
    
    @media screen and (max-width: 768px) {
        width: 150px;
        height: 250px;
        margin: 0px 0px 20px 0px;
    }

    @media screen and (max-width: 500px) {
        width: 125px;
        height: 220px;
        margin: 0px 0px 40px 0px;
    }
`

export const ProductImage = styled.img`
    height: 50%;
    width: 90%;
    padding: 10px;
    display: flex;
    object-fit: contain;
    border-radius: 5px;
    align-self: center;
    box-shadow: 1px 2px 2px 0 rgba(125, 125, 125, 0.5);
    @media screen and (max-width: 700px) {
        height: 150px;
    }
`

export const ProductDetails = styled.div`
    width: 100%;
    padding: 15px 10px 0px 10px;
`

// ---------------- OTHER ORDER INFORMATION ---------------------

export const InformationSectionContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 10px;
    flex-direction: column;
`

export const AddressInfo = styled.div`
    display: flex;
    width: 50%;
`

export const SummaryInfo = styled.div`
    display: flex;
    width: 50%;
`

export const InfoSectionContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 10px;
    flex-direction: row-reverse;
    align-items: center;
    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
    }
`

export const InfoSection = styled.div`
    display: flex;
    width: 50%;
    padding: 0px 4% 30px 4%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
        width: 100%;
        margin: 10px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 10px;
        padding: 0px 1% 2px 1%;
    }
`

// ---------------- GIFT ADDRESS ------------------

export const GiftAddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid ${COLORS.DARK_GREY};
    box-shadow: 1px 2px 2px rgba(80, 80, 80, 0.5);
    width: 90%;
    border-radius: 15px;
    height: auto;
    margin-top: 15px;
    overflow: hidden;
    transition: all 0.5s ease;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

export const GiftWrapperTop = styled.div`
    width: 100%;
    height: 125px;
    padding: 5px;
    background-color: ${COLORS.PINK};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;

`

export const GiftWrapperIcon = styled.div`
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 2%;
    z-index: 10;
    padding: 3px;
    border-radius: 6px;
    align-self: center;
    align-items: center;
    justify-content: center;  
    border: solid 2px ${COLORS.PINK};  
    background-color: ${COLORS.WHITE_SMOKE};
`

export const GiftWrapperVerticalLine = styled.div`
    width: 30px;
    height: 100%;
    left: calc(2% + 14px);
    position: absolute;
    align-items: center;
    background-color: ${COLORS.WHITE_SMOKE};
`

export const GiftWrapperHorizontalLine = styled.div`
    width: 100%;
    height: 30px;
    position: absolute;
    align-items: center;
    background-color: ${COLORS.WHITE_SMOKE};
`

export const GiftWrapperBottom = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-top: 15px;
    flex-direction: column;
`

export const PromoCodeWrapper = styled.div`
    width: 100%;
    height: 50px;
    align-self: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const SummaryRow = styled.div`
    width: calc(100% - 20px);
    display: flex;
    padding: 5px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`