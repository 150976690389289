import {
    HTTP_METHODS,
    HTTP_REQUEST,
    getFirstError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import { getInitialSetUpData } from '@api/helpers';

export const facebookSignInFetch = async (access_token: string) => {
    const HttpRequest = new HTTP_REQUEST('auth/facebook/', {
        method: HTTP_METHODS.POST,
        options: { data: { access_token } },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};

type CUSTOM_RESPONSE = {
    key: string;
    auth_token_cookie: string;
};
const api_type = 'FACEBOOK_SIGNIN';

export default async (
    accessToken: string
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await facebookSignInFetch(accessToken);
        let APIResponse = await response.json();

        if (response.ok) {
            // Fetch User Information Asynchronously
            // ... - ...
            getInitialSetUpData({
                auth_token: APIResponse.key,
                auth_token_cookie: APIResponse.auth_token_cookie,
            });
            // ... - ...

            return SUCCESS_RESPONSE<CUSTOM_RESPONSE>(APIResponse);
        }
        return FAILURE_RESPONSE(
            api_type,
            getFirstError(APIResponse) ||
            'Error with Facebook Sign-In! Try again later.'
        );
    } catch (err) {
        return FAILURE_RESPONSE(
            api_type,
            'Error with Facebook Sign-In! Try again later.'
        );
    }
};
