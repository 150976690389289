import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';


const changePasswordFetch = async (
    old_password: string,
    new_password1: string,
    new_password2: string
) => {
    const data: any = {
        old_password,
        new_password1,
        new_password2,
    };

    const HttpRequest = new HTTP_REQUEST('auth/password/change/', {
        method: HTTP_METHODS.POST,
        options: { data },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Change Password');
    }
};



type CUSTOM_RESPONSE = { key: string };
const api_type = 'CHANGE_PASSWORD';

const changePassword = async (
    args: [string, string, string]
): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await changePasswordFetch(args[0], args[1], args[2]);
        const body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>(body)
            : FAILURE_RESPONSE(api_type, body);
    } catch (err) {
        return FAILURE_RESPONSE(api_type, null);
    }
};

export default changePassword;

export type CHANGE_PASSWORD_RESPONSE = CUSTOM_RESPONSE