import styled from 'styled-components';
import { BASE_TRANSITION_TIME, COLORS } from '@project/styles';

export const Main = styled.div`
    display: flex;
    flex-direction: column;
`;
export const CardWrapper = styled.div<{ fixed: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${COLORS.DARKER_GREY};
    box-shadow: 1px 1px 1px rgba(80, 80, 80, 0.5);
    width: ${({ fixed }) => fixed ? '90%' : '48%'};
    border-radius: 15px;
    height: 230px;
    margin-top: 15px;
    align-self: ${({ fixed }) => fixed ? 'center' : 'auto'};
    transition: all ${BASE_TRANSITION_TIME / 2}s ease;
    @media screen and (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;
    }

    @media screen and (max-width: 500px) {
        height: auto;
    }
`;
export const CardDetailsSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 5px 5px;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;
export const CardLogo = styled.img.attrs({})`
    width: 45px;
    padding: 10px;
    border-radius: 160px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${COLORS.WHITE_SMOKE};
`;
export const LogoAndTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between;
`;

export const MapBox = styled.div`
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    transition: all 0.5s ease;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: ${COLORS.DARK_GREEN};
    @media screen and (max-width: 1000px) {
        width: 150px;
    }
`

export const Box = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.2);
`


export const TitleContiainer = styled.div`
    display: flex;
    flex-direction: column;
`;


export const Title = styled.div`
    font-weight: bold;
    color: #222222;
    font-size: 1.4rem;
`;

export const NickName = styled.div`
    font-weight: normal;
    font-style: italic;
    font-size: 1rem;
    color: #888888;
    
`

export const AddressWrapper = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* width: 100%; */
`;
export const AddressDetailText = styled.p`
    margin: 0;
    font-weight: normal;
`;
export const MapWrapper = styled.div`
    height: 100%;
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 800px) {
        width: 30%;
    }
`;
export const MapElement = styled.div`
    width: 95%;
    height: 95%;
    display: flex;
    border-radius: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;

export const ButtonsWrapper = styled.div`
    right: 5px;
    bottom: 5%;
    display: flex;
    position: absolute;

    padding-top: 10px;
    justify-content: flex-start;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;
interface ButtonProps {
    color: string;
}
export const Button = styled.div<ButtonProps>`
    background-color: ${p => p.color};
    height: 30px;
    border-radius: 5px;
    display: flex;
    margin-right: 10px;
    padding: 0 10px;
    font-weight: bold;
    align-items: center;
    color: ${COLORS.WHITE};
    justify-content: center;
    cursor: pointer;
`;

interface AllAddressesProps {
    expand: boolean;
}
export const AllAddresses = styled.div<AllAddressesProps>`
    position: relative;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;
    height: ${(props) => (props.expand ? `460px` : `260px`)};
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: ${(props) => (props.expand ? '0 0 10px 0' : '0')};
    overflow-y: ${(props) => (props.expand ? `auto` : `hidden`)};
    ::-webkit-scrollbar {display:none; };
    align-content: space-between;
    @media screen and (max-width: 800px) {
        flex-direction: column3;
    }
`;
export const NoAddressText = styled.h1`
    opacity: 0.3;
`;

export const TitleButtonsWrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;