import React, { useEffect } from 'react';

// IMPORT STYLED COMPONENTS
import {
    Main,
    AllAddresses,
    TitleButtonsWrapper,
} from './SavedAddressesElements';
import {
    LowOpacityH1,
    CommonButton,
    CommonButtonText,
} from '@styled_components';

import AddressCard from './AddressCard';
import { InputSectionTitle } from '@form_components';
import { TitleWrapper } from '../AddressesAndCardsElements';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';


// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT TYPES
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { API_ADDRESS_TYPE } from '@api/user/address/types';
import { useTranslation } from 'react-i18next';

const SavedAddresses = ({
    expand,
    showForm,
    toggleAddresses,
}: {
    expand: boolean;
    toggleAddresses: () => void;
    showForm: (isOpen: boolean, editDetails?: API_ADDRESS_TYPE) => void;
}) => {
    const { t } = useTranslation();

    const userAddresses = useSelector(
        (store: RootState) => store.user.addresses
    );

    return (
        <Main>
            <TitleWrapper>
                <InputSectionTitle>{t("userProfile.savedAddresses")}</InputSectionTitle>
                <TitleButtonsWrapper>
                    <CommonButton
                        {...{
                            style: {
                                width: 'auto',
                                margin: '0 10px',
                                padding: '0 10px',
                            },
                            color: COLORS.GREEN,
                            outlined: false,
                            onClick: () => showForm(true),
                        }}
                    >
                        <CommonButtonText
                            {...{
                                color: COLORS.GREEN,
                                outlined: false,
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                }
                            }}
                        >
                            {t("userProfile.addAddresses")}
                        </CommonButtonText>
                    </CommonButton>
                    {userAddresses && userAddresses.length > 2 ? (
                        <CommonButton
                            {...{
                                color: COLORS.GREEN,
                                // outlined: true,
                                onClick: toggleAddresses,
                                width: 30,
                            }}
                        >
                            {expand ? (
                                <FaChevronUp {...{ color: 'white' }} />
                            ) : (
                                <FaChevronDown {...{ color: 'white' }} />
                            )}
                        </CommonButton>
                    ) : null}
                </TitleButtonsWrapper>
            </TitleWrapper>
            <AllAddresses
                {...{
                    expand: userAddresses
                        ? userAddresses.length > 2
                            ? expand
                            : false
                        : false,
                }}
            >
                {userAddresses && userAddresses.length > 0 ? (
                    userAddresses.map((address) => (
                        <AddressCard
                            {...{
                                address,
                                showForm,
                                key: address.address_id,
                            }}
                        />
                    ))
                ) : (
                    <LowOpacityH1 style={{

                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: '25%',
                    }}>You have no saved greenit addresses</LowOpacityH1>
                )}
            </AllAddresses>
        </Main>
    );
};

export default SavedAddresses;
