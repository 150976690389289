//IMPORT STYLED COMPONENTS
import {
    Arrow,
    LeftArrow,
    RightArrow,

    PageNumber,
    PageNumberText,
    PageSelectorContainer,

    MainFullWidthContainer,
} from '@pages/Listing/ListingElements';

import i18next from 'i18next';
import { scrollToTop } from '@project/helpers';

const PageSelector = ({ currentPage, maxPages, changePageNumber }: {
    maxPages: number;
    currentPage: number;
    changePageNumber: any;
}) => {

    const dir = i18next.dir();
    const goToNextPage = (visiting_page: number) => {
        scrollToTop();
        changePageNumber(visiting_page);
    }
    return (
        <MainFullWidthContainer>
            <PageSelectorContainer>
                {
                    currentPage !== 1 ? (
                        <Arrow onClick={() => goToNextPage(currentPage - 1)}>
                            {dir === 'ltr' ? <LeftArrow /> : <RightArrow />}
                        </Arrow>
                    ) : (<Arrow {...{ style: { opacity: 0 } }} />)
                }
                {
                    Array.from(Array(maxPages)).map((_, key) => (
                        <PageNumber {...{ key, active: currentPage === key + 1, onClick: () => goToNextPage(key + 1) }} >
                            <PageNumberText>
                                {key + 1}
                            </PageNumberText>
                        </PageNumber>
                    ))
                }
                {
                    Boolean(currentPage !== maxPages && maxPages !== 0) ? (
                        <Arrow onClick={() => goToNextPage(currentPage + 1)}>
                            {dir === 'ltr' ? <RightArrow /> : <LeftArrow />}
                        </Arrow>
                    ) : (<Arrow {...{ style: { opacity: 0 } }} />)
                }
            </PageSelectorContainer>
        </MainFullWidthContainer>
    );
};

export default PageSelector;
