
import { ChangeEvent, useEffect, useState } from "react";

// IMPORT HELPERS
import i18n from 'i18next';
import { useImmer } from "use-immer";
import { useSpring } from "@react-spring/web";
import { useSelector } from "react-redux";
import { useIsScreenBiggerThan } from "@project/hooks";

// IMPORT STYLES
import styled from "styled-components";
import { COLORS } from "@project/styles";

// IMPORT TYPES
import { RootState } from "@redux/store";
import { API_GIFT_ADDRESS_TYPE } from "@api/types";
import { API_ADDRESS_TYPE } from "@api/user/address/types";

// IMPORT COMPONENTS
import {
    MainContainer,
    NoAddressTitle,
    AddAddressTitle,

    AddressActions,
    AddressInformation,
    AddressSelectionUnit,

    AddressMapContainer,
    AddressScrollContainer,
    AddressSelectionContainer,

    GiftDeliveryAddressOption,
    NormalDeliveryAddressOption,
    AddressSelectionQuestionOption,
} from "./AdditionalElements";
import AddAddress from "./AddAddress";
import { BiPlus } from "react-icons/bi";
import { FaTruck } from 'react-icons/fa';
import { IoIosGift } from 'react-icons/io';
import { Map, PrivateCoordinates } from "@custom_components";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { TextInput, TextInputOneField, TextInputTwoFields } from "@form_components";


// IMPORT DEFAULT MACROS
import { defaultLatLng } from "@project/defaults";
import PhoneNumberInput from "@pages/UserProfile/Tabs/AddressAndCardsTab/PhoneNumberInput";
import { useTranslation } from "react-i18next";

interface AddressProps {
    setDeliveryAddress: (address_id?: string) => void;
    setGiftAddress: (gift_address?: API_GIFT_ADDRESS_TYPE) => void;
}

const style = {
    width: '45px',
    height: '45px',
    display: 'flex',
    borderRadius: '5px',
    border: `1px solid ${COLORS.GREY}`,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    backgroundColor: COLORS.WHITE,
}


const AddressSection = ({ setDeliveryAddress, setGiftAddress }: AddressProps) => {
    const { t } = useTranslation();

    const addresses = useSelector((state: RootState) => state.user.addresses);

    // 0 for normal delivery, 1 for gift delivery
    const [addressType, setAddressType] = useState<0 | 1>(0);

    const isBigger600 = useIsScreenBiggerThan(600);
    const isBigger800 = useIsScreenBiggerThan(800);
    const isBigger = useIsScreenBiggerThan(1000);

    const [address, setAddress] = useState<string>(addresses.find(address => address.is_main)?.address_id || '')
    const [gift, setGift] = useImmer<API_GIFT_ADDRESS_TYPE>({
        recipient_name: '',
        mobile_number: '',
    });

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [location, setLocation] = useState<PrivateCoordinates>(defaultLatLng)
    const onChangeAddress = (e: any) => {
        setAddress(e.target.value);
    }

    const translationStyles = useSpring({
        from: { transform: 'translateX(0%)' },
        to: { transform: `translateX(${addressType * -50}%)` },
        config: { duration: 400 }
    })

    const verticalStyles = useSpring({
        from: { height: 400 },
        to: { height: 400 - addressType * (isBigger800 ? 250 : (isBigger600 ? 150 : 125)) },
        config: { duration: 400 }
    })


    const onChangeGiftName = (e: ChangeEvent<HTMLInputElement>) => {
        setGift(draft => {
            draft.recipient_name = e.target.value;
        })
    }

    const onChangeMobileNumber = (number: string) => {
        setGift(draft => {
            draft.mobile_number = number;
        })
    }

    useEffect(() => {
        if (Boolean(gift.recipient_name) && Boolean(gift.mobile_number)) {
            setGiftAddress(gift)
        }
    }, [gift])

    useEffect(() => {
        if (address) {
            setDeliveryAddress(address);
            const { latitude, longitude } = addresses.find(elem =>
                elem.address_id === address) as API_ADDRESS_TYPE
            setLocation({ latitude, longitude })
        }
    }, [address])


    useEffect(() => {
        if (!addresses.length) {
            setAddress('')
        } else { setAddress(addresses.find(address => address.is_main)?.address_id || '') }
    }, [addresses])



    useEffect(() => {
        if (!addressType) {
            setGift(
                draft => {
                    draft.mobile_number = '';
                    draft.recipient_name = '';
                }
            )
            address && setDeliveryAddress(address);
        } else {
            setDeliveryAddress(undefined)
        }
    }, [addressType])



    return (
        <AddressInformation style={verticalStyles}>
            <AddressSelectionUnit>
                <AddressSelectionQuestionOption>{t("checkout.chooseDeliveryMethod")}</AddressSelectionQuestionOption>
                <AddressActions>
                    <Button {...{
                        startIcon: i18n.dir() === 'ltr' ? <FaTruck /> : undefined,
                        onClick: () => setAddressType(0),
                        variant: !addressType ? 'contained' : 'outlined',
                        sx: {
                            margin: '0px 10px',
                            borderColor: COLORS.DARK_GREEN,
                            color: !addressType ? COLORS.WHITE : COLORS.DARK_GREEN,
                            backgroundColor: !addressType ? COLORS.DARK_GREEN : COLORS.WHITE,
                            '&:hover': {
                                backgroundColor: COLORS.DARK_GREEN,
                                borderColor: COLORS.DARK_GREEN,
                                boxShadow: 'none',
                                color: COLORS.WHITE,
                            },
                        },
                    }}>
                        {isBigger ? t('checkout.normalDelivery') : t('checkout.normal')}
                    </Button>
                    <Button {...{
                        startIcon: i18n.dir() === 'ltr' ? <IoIosGift /> : undefined,
                        onClick: () => setAddressType(1),
                        variant: addressType ? 'contained' : 'outlined',
                        sx: {
                            borderColor: COLORS.DARK_GREEN,
                            color: addressType ? COLORS.WHITE : COLORS.DARK_GREEN,
                            backgroundColor: addressType ? COLORS.DARK_GREEN : COLORS.WHITE,
                            '&:hover': {
                                backgroundColor: COLORS.DARK_GREEN,
                                borderColor: COLORS.DARK_GREEN,
                                boxShadow: 'none',
                                color: COLORS.WHITE,
                            },
                        }
                    }}>
                        {isBigger ? t('checkout.giftDelivery') : t('checkout.gift')}
                    </Button>
                </AddressActions>
            </AddressSelectionUnit>
            <AddressScrollContainer style={translationStyles}>
                {/* Basic Delivery from saved Addresses */}

                <NormalDeliveryAddressOption>
                    <AddressSelectionContainer>
                        {
                            Boolean(addresses.length) ?
                                <>
                                    <TextField {...{
                                        required: false,
                                        label: !address
                                            ? t('address.selectDeliveryAddress')
                                            : t('address.deliveryAddress'),
                                        value: address || '',
                                        sx: {
                                            width: 200,
                                            height: 'auto',
                                            position: 'relative',
                                            backgroundColor: COLORS.WHITE,
                                            color: COLORS.DARKER_GREY,
                                        },
                                        onChange: onChangeAddress,
                                    }} select>
                                        {
                                            addresses.map(
                                                ({ address_id, address_nickname, area, address_type }) => (
                                                    <MenuItem {...{
                                                        key: address_id,
                                                        value: address_id,
                                                    }}>
                                                        {`${area} - ${address_nickname || address_type}`}
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                    </TextField>
                                    <Box {...{ sx: style, onClick: handleOpen }}>
                                        <BiPlus {...{
                                            fill: COLORS.ORANGE,
                                            fontSize: '1.4rem',
                                        }} /></Box>
                                </>
                                : <MainContainer>
                                    <NoAddressTitle>
                                        You have no saved addresses.
                                    </NoAddressTitle>
                                    <AddAddressTitle onClick={handleOpen}>
                                        Add an Address
                                    </AddAddressTitle>
                                </MainContainer>
                        }
                        <AddAddress {...{ open, handleOpen, handleClose }} />

                    </AddressSelectionContainer>

                    <AddressMapContainer>
                        {
                            Boolean(address) &&
                            <Map {...{ location }} />
                        }
                    </AddressMapContainer>
                </NormalDeliveryAddressOption>

                <GiftDeliveryAddressOption>
                    <TextInputTwoFields style={{
                        width: '100%',
                        margin: '20px 20px',
                    }}>
                        <TextInput {...{
                            value: gift.recipient_name || '',
                            label: t('checkout_new.giftName'),
                            variant: 'outlined',
                            onChange: onChangeGiftName,
                        }} />
                        <PhoneNumberInput
                            {...{
                                isValid: true,
                                name: t('checkout_new.giftNumber'),
                                saveNumber: onChangeMobileNumber,
                            }} empty />

                    </TextInputTwoFields>
                    <TextInputOneField style={{ width: '100%' }}>
                        <TextInput {...{
                            value: gift.recipient_name || '',
                            label: t('checkout_new.giftName'),
                            variant: 'outlined',
                            onChange: onChangeGiftName,
                        }} />
                    </TextInputOneField>
                    <TextInputOneField style={{ width: '100%' }}>
                        <PhoneNumberInput
                            {...{
                                isValid: true,
                                name: t('checkout_new.giftNumber'),
                                saveNumber: onChangeMobileNumber
                            }} empty />
                    </TextInputOneField>
                </GiftDeliveryAddressOption>
            </AddressScrollContainer>
        </AddressInformation>
    )
}

export default AddressSection