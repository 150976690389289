// Define Global Constants
import { API_VERSION, HOSTNAME } from './config';

// IMPORT STORE
import { RootState, store } from './lib/store';

// IMPORT TYPES
import { PROMO_CODE_TYPE } from './lib/api/user/cart/types';
import { API_PRODUCT_IMAGE_TYPE } from './lib/api/types';

// IMPORT TRANSLATION HELPERS
import { getSiteLanguage } from '@translation';
import { getWebId } from '@project/helpers';
import { handleServerUnauthorized } from '@api/helpers';


const appCommonHeaders = {
    Connection: 'keep-alive',
    'Keep-Alive': 'timeout=300,max=500',
};

type HTTP_METHOD = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

const HTTP_METHODS: {
    GET: HTTP_METHOD;
    PUT: HTTP_METHOD;
    POST: HTTP_METHOD;
    PATCH: HTTP_METHOD;
    DELETE: HTTP_METHOD;
} = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};

export const getFirstError = (errorResponse: {
    display_error_msg?: string;
    errors: string[];
    show_error: boolean;
}): string | null => {
    return errorResponse.display_error_msg || (errorResponse.errors.length && errorResponse.errors[0]) || null;
};


const ENCODE_QUERY_PARAMS = (url: string, details: { [key: string]: string }) => {
    var formBody = [];
    for (var property in details) {
        var Key = property;
        var Value = details[property];
        formBody.push(Key + '=' + Value);
    }
    return url + '?' + formBody.join('&');
};



const ENCODE_BODY_PARAMS = (details: any) => {
    if (details === undefined) return undefined;
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
};

const ENCODE_FORM_DATA = (details: any) => {
    if (details === undefined) return undefined;
    const formData = new FormData();
    for (var property in details) {
        formData.append(property, details[property]);
    }

    return formData;
};

const IS_STAGING_HOST = (uri: string) => {
    return uri.includes('greenit-testing');
};

const ENCODE_JSON_DATA = (details: any) => {
    if (details === undefined) return undefined;
    return JSON.stringify(details);
};

interface FetchRequest {
    input: RequestInfo;
    init?: RequestInit;
}

interface RESPONSE_TYPE<T> {
    status: boolean;
    response_body: T;
    error?: {
        error_message: string;
    };
}

const SUCCESS_RESPONSE = <T>(body: T): RESPONSE_TYPE<T> => {
    return {
        status: true,
        response_body: body,
    };
};

const FAILURE_RESPONSE = (api_type: string, body?: any): RESPONSE_TYPE<any> => {
    return {
        status: false,
        response_body: body || null,
        error: {
            error_message: body,
        },
    };
};

type FETCH_RESPONSE_TYPE = Response;

interface HTTP_REQUEST_PARAMS {
    method: HTTP_METHOD;
    options: {
        // Optional Parameters
        data?: any;
        json?: boolean;
        sendsImage?: boolean;
        validateCookies?: boolean;
        excludeApiVersion?: boolean;
        providedAccessToken?: string;
        providedWebDeviceId?: string;
    };
}

interface API_REQUEST_ADDITIONAL_OPTIONS_TYPE {
    provided_token?: string;
}
interface IHTTP_REQUEST {
    uri: string;
    method: string;
    is_guest: boolean;
    uri_prefix: string;
    request?: FetchRequest;
    exclude_api_version: boolean;
}

export const validateCookies = async (token?: string | null) => {
    return true;
}
export class HTTP_REQUEST implements IHTTP_REQUEST {
    public uri: string;
    public json: boolean;
    public method: string;
    public is_guest: boolean;
    public uri_prefix: string;
    public sends_image: boolean;
    public validate_cookies: boolean;
    public exclude_api_version: boolean;
    public request?: FetchRequest;

    private access_token: string;
    private web_device_id: string;
    private headers: Headers;
    private data?: {};

    constructor(uri: string, params: HTTP_REQUEST_PARAMS) {
        const AppState = store.getState();
        // Set Endpoint URI Information
        this.validate_cookies = params.options.validateCookies || false;
        this.exclude_api_version = params.options.excludeApiVersion || false;
        this.uri_prefix = uri;
        this.uri = '';

        // Store Initialization
        this.web_device_id = params.options.providedWebDeviceId ||
            (() => AppState.settings.tracking.web_id || '')();

        this.access_token =
            params.options.providedAccessToken ||
            (() => AppState.auth.auth_token || '')();

        // Helpers Initialization
        this.method = params.method;
        this.data = params.options.data;
        this.json = params.options.json || false;
        this.is_guest = !store.getState().auth.signed_in;
        this.sends_image = params.options.sendsImage || false;

        // Request Construction
        this.headers = new Headers();
    }

    public callFetch = async () => {
        this.request = await this.makeRequest();
        const response = await fetch(this.request.input, this.request.init);

        if (response.status === 401) { handleServerUnauthorized(this.uri_prefix === 'user/cart/' && this.is_guest) }
        return response;
    };


    private setHeaders = () => {
        // Set Application Common Headers
        this.headers.append('Connection', 'keep-alive');
        this.headers.append('Keep-Alive', 'timeout=300,max=500');
        // this.headers.append('Staging-Secret-Key', STAGING_SECRET_KEY)

        if (this.access_token) {
            this.headers.append('Authorization', `Token ${this.access_token}`);
        }
        // Set Content Type Headers
        if (this.method !== 'GET' && this.json) {
            this.headers.append('Content-Type', 'application/json');
        } else if (this.method !== 'GET' && !this.sends_image) {
            this.headers.append(
                'Content-Type',
                'application/x-www-form-urlencoded'
            );

        } else if (this.method !== 'GET' && this.sends_image) {
            this.headers.append('Content-Type', 'multipart/form-data');
        }
        var web_device_id: string | null;
        if (web_device_id = this.web_device_id || getWebId()) {
            this.headers.append('Web-Device-Id', web_device_id as string)
        }
    };

    private makeRequest = async (): Promise<FetchRequest> => {
        this.setHeaders();

        {
            this.validate_cookies &&
                (await validateCookies(this.access_token))
        };

        const api_version = this.exclude_api_version ? '' : API_VERSION;
        this.uri = `${HOSTNAME}/${getSiteLanguage()}/${api_version}/${this.uri_prefix}`;

        switch (this.method) {
            case 'GET':
                if (this.data) {
                    this.uri = ENCODE_QUERY_PARAMS(this.uri, this.data);
                }

                return {
                    input: new Request(this.uri, {
                        method: HTTP_METHODS.GET,
                        headers: this.headers,
                    }) as RequestInfo,
                };

            case 'POST':
                return {
                    input: new Request(this.uri, {
                        method: HTTP_METHODS.POST,
                        headers: this.headers,
                        body: this.sends_image
                            ? ENCODE_FORM_DATA(this.data)
                            : this.json
                                ? ENCODE_JSON_DATA(this.data)
                                : ENCODE_BODY_PARAMS(this.data),
                    }) as RequestInfo,
                };

            case 'DELETE':
                const body = this.data
                    ? { body: ENCODE_BODY_PARAMS(this.data) }
                    : {};
                return {
                    input: new Request(this.uri, {
                        method: HTTP_METHODS.DELETE,
                        headers: this.headers,
                        ...body,
                    }) as RequestInfo,
                };

            case 'PUT':
            case 'PATCH':
                return {
                    input: this.uri as RequestInfo,
                    init: {
                        headers: this.headers,
                        method: this.method,
                        body: this.sends_image
                            ? ENCODE_FORM_DATA(this.data)
                            : ENCODE_BODY_PARAMS(this.data),
                    } as RequestInit,
                };

            default:
                throw Error('INVALID HTTP METHOD USED');
        }
    };
}

export const getPromocodeType = (x?: string): PROMO_CODE_TYPE => {
    switch (x) {
        case 'Absolute Discount':
            return PROMO_CODE_TYPE.Absolute;
        case 'Free Delivery':
            return PROMO_CODE_TYPE.FreeDelivery;
        case 'Percentage Discount':
            return PROMO_CODE_TYPE.Percentage;
        case 'Combo Percentage Discount':
            return PROMO_CODE_TYPE.ComboPercentage;
        case 'Combo Absolute Discount':
            return PROMO_CODE_TYPE.ComboAbsolute;
        case 'Percentage Discount and Free Delivery':
            return PROMO_CODE_TYPE.FreeDeliveryPercentage;
        case 'Absolute Discount and Free Delivery':
            return PROMO_CODE_TYPE.FreeDeliveryAbsolute;
        default:
            return PROMO_CODE_TYPE.Unknown;
    }
};

export const imageUrlCustomSize = (url: string, height: number | null, width: number | null) => {
    if (!width || !height) {
        return url;
    }
    return ENCODE_QUERY_PARAMS(url.toString(), { w: width.toString(), h: height.toString() });
};

class RequestError extends Error {
    public name: string;

    constructor(message: string) {
        super('RequestError: ' + message);
        this.name = 'FetchError';
    }
}

export { RequestError };

export {
    HTTP_METHODS,
    IS_STAGING_HOST,
    ENCODE_FORM_DATA,
    ENCODE_JSON_DATA,
    ENCODE_BODY_PARAMS,
    ENCODE_QUERY_PARAMS,
};

export { SUCCESS_RESPONSE, FAILURE_RESPONSE };
export type {
    RESPONSE_TYPE,
    FETCH_RESPONSE_TYPE,
    API_REQUEST_ADDITIONAL_OPTIONS_TYPE,
};

