import { getAuthenticatedCartItems } from '@api/helpers';
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';

export const importCartFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/cart/import/', {
        method: HTTP_METHODS.POST,
        options: {
            providedAccessToken,
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Import Cart');
    }
};

const importCart = async (token?: string): Promise<RESPONSE_TYPE<null>> => {
    try {
        const response = await importCartFetch(token);
        const _ = await getAuthenticatedCartItems(token);

        return response.ok
            ? SUCCESS_RESPONSE<null>(null)
            : FAILURE_RESPONSE('IMPORT_CART');
    } catch (error) {
        return FAILURE_RESPONSE('IMPORT_CART', error);
    }
};

export default importCart;
