import React, { ChangeEvent, useEffect, useState } from 'react'

// IMPORT ROUTES
import { RouterPages } from '@project/routes'

// IMPORT HELPERS
import { getCartItems, slugify } from '@api/helpers'
import addPromoCode from '@api/user/cart/promo/add'
import removePromoCode from '@api/user/cart/promo/remove'
import { promiseMessage } from '@overlays/notifications'
import { updateCartOnPromoCodeAdd } from '@redux/state/cart'

// IMPORT HOOKS
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// IMPORT COMPONENTS
import {
    CartTitle,
    CheckoutButton,
    CheckoutText,
    DeleteIcon,
    DiscountPrice,
    DiscountTitle,
    DiscountWrapper,
    EmptyCartButton,
    PromoCode,
    PromoCodeAndActions,
    PromoCodeTitle,
    PromoCodeWrapper,
    SubTotalAndCheckout,
    SubtotalPrice,
    SubtotalTitle,
    SubtotalWrapper
} from './SummaryElements';
import { TextInput } from '@form_components';


// IMPORT TYPES
import { RootState } from '@redux/store'
import { withStyles } from '@mui/styles'
import { COLORS } from '@project/styles'
import { Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getPathWithLang } from '@translation'
import { LoadingButton } from '../buttons'
import { initialFormState, useFormSubmission } from '@project/hooks'
import { RESPONSE_TYPE, SUCCESS_RESPONSE } from '@api/interactions'

export interface ADDITIONAL_COST_TYPE {
    cost_type: string;
    cost_amount: number;
    is_subraction?: boolean;
}

interface SummaryProps {
    checkout?: boolean;
    promocode?: boolean;
    breakdown?: boolean;
    customColor?: string;
    totaladditionalChange?: number;
    additionalCheckoutActions?: () => void;
    additionalCosts?: ADDITIONAL_COST_TYPE[];
    onCheckoutPress?: () => Promise<RESPONSE_TYPE<boolean>>;

}

const CssTextInput = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },

        "& .MuiInputBase-root": {
            height: '100%'
        }
    },
    focused: {
        "& .MuiInputBase-root": {
            borderColor: COLORS.DARK_GREEN
        }
    }
})(TextInput)

const Summary = ({ checkout, promocode, breakdown, onCheckoutPress,
    customColor, additionalCosts, additionalCheckoutActions, totaladditionalChange = 0 }: SummaryProps) => {
    const { t } = useTranslation();

    const [promo, setPromo] = useState<string>('');
    const [lastInvalidPromo, setLastInvalidPromo] = useState<string>('');

    const {
        promo_code_info,
        cart_items: cartItems,
        total_cart_price: cartTotalBD,
        delivery_cost_after_discount: deliveryFee,
        total_cart_price_after_discount: cartTotal, } = useSelector((store: RootState) => store.cart);
    const cartQuantity = cartItems.reduce((acc, p) => acc + p.requested_quantity, 0)

    useEffect(() => {
        getCartItems();
    }, []);

    const dispatch = useDispatch();

    const genericCheckoutButton = async (): Promise<RESPONSE_TYPE<boolean>> => {
        return SUCCESS_RESPONSE(true);
    }

    const [buttonState, setButtonState] = useFormSubmission<boolean>(() => {
        if (onCheckoutPress) {
            return onCheckoutPress();
        } else { return genericCheckoutButton(); }
    });

    const { success } = buttonState;

    const onCheckoutButtonClick = async () => {
        setButtonState((state) => ({
            ...state,
            submitted: true,
        }));
    };

    useEffect(() => {
        let cancelled = false;
        if (buttonState.success) {
            !cancelled && setButtonState(initialFormState);
            navigate(getPathWithLang('/checkout'));
            if (additionalCheckoutActions) { additionalCheckoutActions() }
        } else { setTimeout(() => { !cancelled && setButtonState(initialFormState) }, 1000) }
    }, [buttonState])

    const handlePromoCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setPromo(e.target.value)
    }

    const handlePromoCodeSubmit = () => {
        if (lastInvalidPromo !== promo) {
            promiseMessage<string>({
                promise: sendPromoCode(),
                loading: t('checkout.addingPromoCode'),
                success: t('checkout.promoCodeAccepted'),
                error: (error) => error,
            })
        }
    }

    const onPressEnter = (e: any) => {
        if (e.keyCode == 13) {
            handlePromoCodeSubmit();
        }
    }
    const navigate = useNavigate();

    const handlePromoCodeRemove = () => {
        promiseMessage<string>({
            promise: deletePromoCode(),
            loading: t("checkout.removingPromoCode"),
            success: t("checkout.promoCodeRemoved"),
            error: t("common.somethingWentWrongTryAgain"),
        })
    }

    const deletePromoCode = async (): Promise<string> => {
        if (promo_code_info) {
            const response = await removePromoCode();
            if (response.status) {
                getCartItems();
                return new Promise((rs, rj) => rs(''))
            }
            return new Promise((rs, rj) => rj(''))
        }
        return new Promise((rs, rj) => rs(''))
    };


    const sendPromoCode = async (): Promise<string> => {
        const response = await addPromoCode(promo, t);
        if (response.status) {
            dispatch(updateCartOnPromoCodeAdd(response.response_body));
            return new Promise((rs, rj) => rs(''))
        }
        setLastInvalidPromo(promo);
        return new Promise((rs, rj) => rj(response.error?.error_message || t('checkout.promoCodeInvalid')))
    }

    return (
        <SubTotalAndCheckout>
            {
                cartQuantity > 0 &&
                <>
                    {
                        promocode &&
                        <>
                            <PromoCodeWrapper>
                                <PromoCodeTitle {...{
                                    style: {
                                        color: promo_code_info ? (customColor || 'green') : '#444'
                                    },
                                }}>{t("checkout.promoCode")}</PromoCodeTitle>
                                {
                                    Boolean(promo_code_info) ?
                                        <PromoCodeAndActions>
                                            <PromoCode style={{
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                color: (customColor || 'green'),
                                            }}>{promo_code_info?.code}</PromoCode>
                                            <DeleteIcon {...{
                                                fontSize: '1.5rem',
                                                color: (customColor || 'green'),
                                                style: {
                                                    transform: i18n.dir() === 'ltr' ? 'none' : 'rotate(180deg)'
                                                },
                                                onClick: handlePromoCodeRemove
                                            }} />
                                        </PromoCodeAndActions> :
                                        <CssTextInput {...{
                                            value: promo,
                                            size: 'small',
                                            sx: { width: '40%', height: '40px' },
                                            placeholder: t("checkout.promoCodePlaceHolder"),
                                            onChange: handlePromoCodeInput,
                                            onBlur: handlePromoCodeSubmit,
                                            onKeyDown: onPressEnter,
                                        }} />

                                }
                            </PromoCodeWrapper>
                            <Divider />
                        </>
                    }
                    {
                        breakdown &&
                        <>
                            <SubtotalWrapper>
                                <CartTitle {...{ style: { color: (customColor || '#000') } }}>
                                    {t("orders.cartPrice")}
                                </CartTitle>
                                <CartTitle {...{ style: { color: (customColor || '#000') } }}>
                                    {t('common.QAR')} {cartTotalBD}
                                </CartTitle>
                            </SubtotalWrapper>

                            {
                                Boolean(promo_code_info) &&
                                <>

                                    <DiscountWrapper>
                                        <DiscountTitle {...{ style: { color: customColor || COLORS.NOTIFICATION_RED } }}>
                                            {t('common.discount')}:
                                        </DiscountTitle>
                                        <DiscountPrice {...{ style: { color: customColor || COLORS.NOTIFICATION_RED } }}>
                                            {'- ' + t('common.QAR')} {cartTotalBD - cartTotal}
                                        </DiscountPrice>
                                    </DiscountWrapper>
                                </>
                            }

                            {
                                Boolean(additionalCosts && additionalCosts?.length) &&
                                (additionalCosts as []).map(({ is_subraction, cost_amount, cost_type }) => (
                                    <DiscountWrapper {...{ key: slugify(cost_type) }}>
                                        <DiscountTitle style={{ color: is_subraction ? COLORS.NOTIFICATION_RED : (customColor || '#000') }}>
                                            {cost_type}:
                                        </DiscountTitle>
                                        <DiscountPrice style={{ color: is_subraction ? COLORS.NOTIFICATION_RED : (customColor || '#000') }}>
                                            {(is_subraction ? '- ' : '') + t('common.QAR')} {cost_amount}
                                        </DiscountPrice>
                                    </DiscountWrapper>
                                ))
                            }
                            <DiscountWrapper>
                                <DiscountTitle style={{ color: (customColor || '#000') }}>
                                    {t("checkout.deliveryFee")}
                                </DiscountTitle>
                                <DiscountPrice style={{ color: (customColor || '#000') }}>
                                    {t('common.QAR')} {deliveryFee}
                                </DiscountPrice>
                            </DiscountWrapper>
                        </>
                    }


                    <SubtotalWrapper>
                        <SubtotalTitle {...{ style: { color: (customColor || '#000') } }}>
                            {t('cartOverlay.subtotal')}:
                        </SubtotalTitle>
                        <SubtotalPrice {...{ style: { color: (customColor || '#000') } }}>
                            {t('common.QAR')} {cartTotal + deliveryFee + totaladditionalChange}
                        </SubtotalPrice>
                    </SubtotalWrapper>
                </>
            }

            {
                checkout && (cartQuantity > 0 ?
                    <LoadingButton {...{
                        buttonState,
                        disabled: false,
                        onClick: onCheckoutButtonClick,
                        style: {
                            height: 60,
                            width: '100%',
                            marginTop: 15,
                            fontWeight: 'bolder',
                        },
                        colorScheme: COLORS.AUTH.LOGIN,
                        buttonTitle: t('cartOverlay.checkout'),
                    }} />
                    :
                    <EmptyCartButton>
                        <CheckoutText>
                            {t('cartOverlay.yourCartEmpty')}
                        </CheckoutText>
                    </EmptyCartButton>)
            }

        </SubTotalAndCheckout>
    )
}

export default Summary