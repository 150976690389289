export const BuildingType = ['Apartment', 'House', 'Office', 'Compound'];

export const AreaType = [
    'Ain Khaled',
    'Al Asiri',
    'Al Aziziya',
    'Al Bidda 2',
    'Al Bidda 12',
    'Al Dafna 60',
    'Al Dafna 61',
    'Al Doha Al Jadeeda',
    'Al Ebb',
    'Al Egla',
    'Al Froosh',
    'Al Gassar 61',
    'Al Gassar 66',
    'Al Ghanim Al Jadeed',
    'Al Gharrafa',
    'Al Hilal',
    'Al Hitmi',
    'Al Jasra',
    'Al Jebail',
    'Al Jeryan',
    'Al Karaana',
    'Al Kharaitiyat',
    'Al Kharayej',
    'Al Kheesa',
    'Al Khor',
    'Al Khulaifat',
    'Al Luqta',
    'Al Maamoura 43',
    'Al Maamoura 56',
    'Al Mansoura',
    'Al Markhiya',
    'Al Mashaf',
    'Al Masrouhiya',
    'Al Mearad',
    'Al Messila',
    'Al Mirqab',
    'Al Mirqab Al Jadeed',
    'Al Muntazah',
    'Al Najada',
    'Al Nasraniya',
    'Al Rehayya',
    'Al Rufaa',
    'Al Sadd 38',
    'Al Sadd 39',
    'Al Sailiya',
    'Al Sakhama',
    'Al Seej',
    'Al Shagub',
    'Al Sheehaniya',
    'Al Tarfa',
    'Al Thakhira',
    'Al Themaid',
    'Al Thumama 46',
    'Al Thumama 47',
    'Al Thumama 50',
    'Al Waab',
    'Al Wajba',
    'Al Wakrah',
    'Al Wukair',
    'Aspire Zone',
    'Baaya',
    'Bani Hajer',
    'Barahat Al Jufairi',
    'Barwa City',
    'Barwa Village',
    'Bu Fesseela',
    'Bu Garn',
    'Bu Glaila',
    'Bu Hamour',
    'Bu Sidra',
    'Dahl Al Hamam',
    'Doha International Airport',
    'Doha Port',
    'Duhail',
    'Education City',
    'Fereej Abdel Aziz',
    'Fereej Al Amir',
    'Fereej Al Asmakh',
    'Fereej Al Manaseer',
    'Fereej Al Murra',
    'Fereej Al Nasr',
    'Fereej Al Soudan 54',
    'Fereej Al Soudan 55',
    'Fereej Al Zaeem',
    'Fereej Bin Derhem',
    'Fereej Bin Mahmoud 22',
    'Fereej Bin Mahmoud 23',
    'Fereej Bin Omran',
    'Fereej Kulaib',
    'Gharrafat Al Rayyan',
    'Hamad International Airport',
    'Hamad Medical City',
    'Hazm Al Markhiya',
    'Industrial Area',
    'Izghawa 51',
    'Izghawa 71',
    'Jabal Thuaileb',
    'Jelaiah',
    'Jery Al Dabi',
    'Jery Al Matrooshi',
    'Jeryan Jenaihat',
    'Jeryan Nejaima',
    'Jery Khabbab',
    'Katara Cultural Village',
    'Leabaib',
    'Leatooriya',
    'Lebday',
    'Lebsayyer',
    'Leghwairiya',
    'Legtaifiya',
    'Lejbailat',
    'Lekhwair',
    'Lijmiliya',
    'Luaib',
    'Lusail',
    'Madinat Al Kaaban',
    'Madinat Khalifa North',
    'Madinat Khalifa South',
    'Mebaireek',
    'Mehairja',
    'Mesaimeer',
    'Muaither 53',
    'Muaither 55',
    'Muaither Al Wukair',
    'Muraikh',
    'Mushaireb 3',
    'Mushaireb 4',
    'Mushaireb 13',
    'Najma',
    'New Al Hitmi',
    'New Al Rayyan',
    'New Industrial Area',
    'New Slata',
    'Nuaija 41',
    'Nuaija 43-Fareej Al Ali',
    'Nuaija 44',
    'Old Airport',
    'Old Al Ghanim 6',
    'Old Al Ghanim 16',
    'Old Al Rayyan',
    'Onaiza 63',
    'Onaiza 65',
    'Onaiza 66',
    'Qatar National Convention Center',
    'Ras Bu Abboud 28',
    'Ras Bu Abboud 29',
    'Ras Bu Funtas',
    'Rawdat Abal Heeran',
    'Rawdat Al Hamama',
    'Rawdat Al Jahhaniya',
    'Rawdat Al Khail',
    'Rawdat Egdaim',
    'Rumaila 12',
    'Rumaila 21',
    'Semaisma',
    'Shariya',
    'Slata',
    'Snay Lehmaidi',
    'Souq Waqif',
    'Tenbek',
    'The Pearl',
    'Umm Al Amad',
    'Umm Al Juwashen',
    'Umm Al Seneem',
    'Umm Al Zubar West',
    'Umm Besher',
    'Umm Ebairiya',
    'Umm Garn',
    'Umm Garn',
    'Umm Ghuwailina',
    'Umm Lekhba',
    'Umm Slal Ali',
    'Umm Slal Mohammed',
    'Wadi Aba Seleel',
    'Wadi Al Banat',
    'Wadi Al Ewaira',
    'Wadi Al Sail 12',
    'Wadi Al Sail 20',
    'Wadi Al Wasah',
    'Wholesale Market',
    'Area Not Listed'
];

export interface API_ADDRESS_TYPE {
    address_id: string;
    is_main: boolean;
    area: string;
    address_nickname?: string;
    address_type: 'House' | 'Apartment' | 'Compound' | 'Office' | '';
    street_name?: string;
    street_number: string;
    building?: string;
    floor?: string;
    office?: string;
    compound_name?: string;
    apartment_number?: string;
    additional_directions?: string;
    mobile_number: string;
    landline_number?: string;
    longitude: number;
    latitude: number;
    delta_longitude: number;
    delta_latitude: number;
}


export interface API_GIFT_ADDRESS_TYPE {
    recipient_name: string;
    mobile_number: string;
}

export type PRIVATE_API_ADDRESS_TYPE = Omit<API_ADDRESS_TYPE, 'address_id'>;

export type ADDRESS_UPDATE_TYPE =
    | Partial<PRIVATE_API_ADDRESS_TYPE>
    | { is_main: boolean };

export interface API_ADDRESS_ERROR_TYPE {
    map: boolean;
    area: boolean;
    floor: boolean;
    office: boolean;
    building: boolean;
    coordinates: boolean;
    street_name: boolean;
    address_type: boolean;
    street_number: boolean;
    mobile_number: boolean;
    compound_name: boolean;
    address_nickname: boolean;
    apartment_number: boolean;
    additional_directions: boolean;
}
export const empty_PRIVATE_API_address: PRIVATE_API_ADDRESS_TYPE = {
    is_main: false,
    address_nickname: '',
    address_type: 'Compound',
    street_name: '',
    street_number: '',
    compound_name: '',
    building: '',
    area: '',
    floor: '',
    office: '',
    apartment_number: '',
    additional_directions: '',
    mobile_number: '+974-',
    landline_number: '',
    longitude: -1,
    latitude: -1,
    delta_longitude: 0,
    delta_latitude: 0,
};

export const default_address: API_ADDRESS_TYPE = {
    address_id: '',
    is_main: false,
    address_nickname: '',
    address_type: '',
    street_name: '',
    street_number: '',
    building: '',
    area: '',
    floor: '',
    office: '',
    compound_name: '',
    apartment_number: '',
    additional_directions: '',
    mobile_number: '',
    longitude: -1,
    latitude: -1,
    delta_longitude: -1,
    delta_latitude: -1,
};

export const default_address_errors: API_ADDRESS_ERROR_TYPE = {
    map: false,
    area: false,
    floor: false,
    office: false,
    building: false,
    coordinates: false,
    street_name: false,
    address_type: false,
    street_number: false,
    compound_name: false,
    mobile_number: false,
    apartment_number: false,
    address_nickname: false,
    additional_directions: false,
};


export interface LatLng {
    latitude: number;
    longitude: number;
}

export type SERVICE_AREA_TYPE = LatLng[]

export interface API_POLYGON_COORDINATES {
    polygon_coordinates: string[]
}
