import {
    HTTP_REQUEST,
    HTTP_METHODS,
    RequestError,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

const deleteAddressFetch = async (address_id: string) => {
    const HttpRequest = new HTTP_REQUEST(`user/address/${address_id}/`, {
        method: HTTP_METHODS.DELETE,
        options: {
            validateCookies: true,
        },
    });
    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError(
            `Failed To Delete Address with pk = ${address_id}`
        );
    }
};


const deleteUserAddress = async (address_id: string) => {
    try {
        const response = await deleteAddressFetch(address_id);
        return response.ok
            ? SUCCESS_RESPONSE<any>(response)
            : FAILURE_RESPONSE('DELETE_ADDRESS');
    } catch (error) {
        return FAILURE_RESPONSE('DELETE_ADDRESS', error);
    }
};

export default deleteUserAddress;