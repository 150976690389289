import styled from 'styled-components';


export const ToastDismissContainer = styled.div`
    position: relative;
    margin: 2px;
    border-radius: 2px;
`


export const ToastDismiss = styled.div<{
    primaryColor: string;
    secondaryColor: string;
}>`
    position: relative;
    height: 35px;
    display: flex;
    padding: 0 4px;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    border: solid 1px ${({ secondaryColor }) => secondaryColor};
    &:hover {
        border: solid 1px ${({ primaryColor }) => primaryColor};
        box-shadow: 2px 2px 2px rgba(80, 80, 80, 0.2);
    }
`


export const CustomToaster = styled.div`
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
`


export const CustomToasterText = styled.div`
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
`