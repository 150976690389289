
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RootState } from '@redux/store';
import { useDispatch, useSelector } from 'react-redux';


// IMPORT COMPONENTS
import {
    OTPButton,
    OTPContainer,
    SectionTitle,
    SectionSubInformation,
    OTPInformationContainer,
} from './AdditionalElements';
import { TextInput, TextInputSingleField } from '@form_components';
import { updateUserPhoneNumber, updateUserPhoneNumberWithOTP } from '@redux/state/user';
import PhoneNumberInput from '@pages/UserProfile/Tabs/AddressAndCardsTab/PhoneNumberInput';
import { promiseMessage } from '@overlays/notifications';
import { sendOTPRequest, sendOTPVerification } from '@api/checkout/helpers';
import { COLORS } from '@project/styles';
import { useDidMount } from '@project/hooks';
import { useTranslation } from 'react-i18next';


interface OTPProps {
    cash_payment: boolean;
}

const OTPVerification = ({ cash_payment }: OTPProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let Ref = useRef<NodeJS.Timer>(null);
    const [otp, setOtp] = useState<string>('');
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [disableOTP, setDisableOTP] = useState<boolean>(false);
    const [resendTimer, setResendTimer] = useState<string>('00')
    const { is_otp_verified, otp_phone_number } = useSelector((state: RootState) => state.user.details)

    const onSavePhoneNumber = (number: string) => {
        const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

        if (phoneNumberRegex.test(number)) {
            dispatch(updateUserPhoneNumber(number));
        }
    }

    const handleOTPSendSuccess = () => {
        setOtpSent(true);
    }

    const handleOTPVerifySuccess = () => {
        setDisableOTP(false);
        dispatch(updateUserPhoneNumberWithOTP(otp_phone_number || ''))
    }

    const handleOTPVerifyFailure = () => {
        setDisableOTP(false);
    }

    const handleOTPVerification = (e: ChangeEvent<HTMLInputElement>) => {
        setOtp(e.target.value);

        if (e.target.value.length === 5) {
            setDisableOTP(true);
            promiseMessage({
                promise: sendOTPVerification(e.target.value, handleOTPVerifySuccess, handleOTPVerifyFailure),
                loading: t('otp.verifyLoading'),
                success: t('otp.verifySuccess'),
                error: t('otp.verifyError'),
            })
        }
    }

    const getTimeRemaining = (e: string) => {
        const total = Date.parse(e) - Date.parse((new Date()) as any);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return { total, hours, minutes, seconds };
    }

    const startTimer = (e: any) => {
        let { total, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setResendTimer(seconds > 9 ? seconds.toString() : '0' + seconds)
        }
    }

    const clearTimer = (e: any) => {
        setResendTimer('60');

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => startTimer(e), 1000)
        // @ts-ignore
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }


    const resendOTP = () => {
        !otpRequestDisabled && sendOTP();
    }

    const sendOTP = () => {
        const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
        if (phoneNumberRegex.test(otp_phone_number || '')) {
            onClickReset();
            promiseMessage({
                promise: sendOTPRequest(otp_phone_number || '', handleOTPSendSuccess),
                loading: t('otp.sendLoading'),
                success: t('otp.sendSuccess'),
                error: t('otp.sendError'),
            })
        }
    }

    const otpRequestDisabled = resendTimer !== '00';

    return (
        <> {
            cash_payment && !is_otp_verified &&
            <OTPContainer>
                <SectionTitle>{t('otp.title')}</SectionTitle>
                <SectionSubInformation>
                    {t('otp.subtext')}
                </SectionSubInformation>
                <OTPInformationContainer>
                    {
                        !otpSent ?
                            <>
                                <PhoneNumberInput {...{
                                    isValid: true,
                                    isSubmitted: true,
                                    saveNumber: onSavePhoneNumber,
                                    onBlur: onSavePhoneNumber,
                                }} />
                                <OTPButton {...{ onClick: sendOTP }}>
                                    {t('otp.sendOTP')}
                                </OTPButton>
                            </>
                            :
                            <>
                                <TextInput {...{
                                    value: otp,
                                    name: 'OTP',
                                    placeholder: t('otp.enterOTPHere'),
                                    disabled: disableOTP,
                                    onChange: handleOTPVerification,
                                }} />
                                <OTPButton {...{
                                    onClick: resendOTP,
                                    style: {
                                        ...(!otpRequestDisabled ? {} : {
                                            color: COLORS.GREY,
                                            cursor: 'default',
                                            border: `solid 1px ${COLORS.GREY}`,
                                            '&:hover': {
                                                color: COLORS.GREY,
                                                transform: 'scale(1)',
                                                border: `solid 1px ${COLORS.GREY}`
                                            }
                                        })
                                    },
                                }}>
                                    {otpRequestDisabled ? t('otp.resendOTPIn') + ' ' + resendTimer + ' ' + (!(+resendTimer - 1) ? t('otp.second') : t('otp.seconds')) : t('otp.resendOTP')}
                                </OTPButton>
                            </>
                    }
                </OTPInformationContainer>
            </OTPContainer>
        }

        </>
    )
}

export default OTPVerification