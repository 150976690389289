import { InstagramEmbed } from 'react-social-media-embed';
import { BIG_SCREEN_WIDTH } from '@project/styles';
import { useIsScreenBiggerThan } from '@project/hooks';
import { Carousel, Swipeable } from '@custom_components';
import { useTranslation } from 'react-i18next';
import { Heading, InstagramPosts, MainContainer, SubHeading, TestimonialCardContainer, TestimonialWrapper } from './InstagramDisplayElements';

const IgPost = ({ url }: { url: string }) => (
    <TestimonialWrapper>
        <TestimonialCardContainer>
            <InstagramEmbed
                {...{
                    url,
                    width: 328,
                    style: {
                        borderRadius: 12,
                    },
                    height: 500,
                }}
            />
        </TestimonialCardContainer>
    </TestimonialWrapper>
);

const InstagramDisplay = () => {
    const { t } = useTranslation();
    const _768 = useIsScreenBiggerThan(768);
    const _1600 = useIsScreenBiggerThan(1600);
    const _1200 = useIsScreenBiggerThan(1200);

    const data = [
        'https://www.instagram.com/p/ChMK1dXN7zE/',
        'https://www.instagram.com/p/CggZWDyjx8W/',
        'https://www.instagram.com/p/CgD4ayfu0XP/',
        'https://www.instagram.com/p/CgQ8jVBMCOD/',
    ];

    const render_item = ({ item: url }: { item: string }) => (<IgPost {...{ url }} />)


    const slidesPerView = _1600 ? 4 : (_1200 ? 3 : (_768 ? 2 : 1));
    const slidesPerGroup = slidesPerView;
    return (
        <MainContainer>
            <Heading>@greenit.qa</Heading>
            <SubHeading>{t('home.instagramDisplaySubheader')}</SubHeading>

            <InstagramPosts>
                <Swipeable {...{ data, render_item, additional_swiper_props: { slidesPerView, slidesPerGroup, mousewheel: false } }} />
            </InstagramPosts>
        </MainContainer>
    );
};

export default InstagramDisplay;
