import styled from 'styled-components';

// IMPORT FONTS
import '@project/fonts';
import { Link } from '@project/routes/components';

const SCREEN_SIZE_BIG = 1100;
const SCREEN_SIZE_SMALL = 500;
const SCREEN_SIZE_MEDIUM = 768;


const RELATIVE_PATH_TO_HOME_SECTION_IMAGE = 'images/hero_section_background_image.png';
const RELATIVE_PATH_TO_HOME_SECTION_MOBILE_IMAGE = 'images/hero_section_mobile_image.png';
const RELATIVE_PATH_TO_HOME_SECTION_TABLET_IMAGE = 'images/hero_section_tablet_image.png';


export const HeroContainer = styled.div`
    display: flex;
    height: 750px;
    max-height: 750px;
    position: relative;
    background-color: #F3F1EC;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
    }

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        padding-top: 20%;
        height: 500px;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
    }
`;

export const HeroImage = styled.div`
    width: 100%;
    z-index: 0;
    display: flex;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_HOME_SECTION_IMAGE}) no-repeat center;
    background-size: cover;

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        display: none;
    }
`;


export const HeroImageMobile = styled.div`
    display: none;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_TO_HOME_SECTION_TABLET_IMAGE}) no-repeat center;
    background-size: cover;
    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        z-index: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
`;
export const HeroTitleContainer = styled.div`
    left: 8%;
    right: 8%;
    width: 60%;
    display: flex;
    align-self: center;
    position: absolute;
    justify-content: flex-start;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        top: 10%;
        width: 70%;
    }
`;

export const HeroTextInnerContainer = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1200px) {
        width: 100%;
        height: 60%;
        margin-top: 20px;
        justify-content: center;
    }
`;

export const HeroTitleText = styled.h1`
    margin: 0;
    color: #224229; 
    font-weight: 200;
    font-size: 3.2rem;

    
    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 2.5rem;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 2rem;
    }
`;


export const HeroDescriptionText = styled.h3`
    color: #224229; 
    font-weight: normal;
    font-size: 1.6rem;
    margin-bottom: 35px;

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 1.3rem;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 1.1rem;
    }
`;

export const HeroSectionDescription = styled.div`
    padding-right: 20%;
`;

export const Button = styled(Link)`
    width: 180px;
    padding: 12px 20px;
    color: #fff;
    background-color: #118B19;
    border-radius: 5px;
    font-weight: bolder;
    font-size: 1.4rem;
    
    @media screen and (max-width: 768px) {
        display: none;
    }

    text-align: center;
    text-decoration: none;
    transition: all 0.5s ease;

    &:hover {
        background-color: #007804;
    }
`;
