import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';

import {
    CART_TYPE,
    API_CART_TYPE,
} from '../types';

import { mapCartProducts, mapPromocode, addIndividualQuantities } from '../helpers';

export const fixCartFetch = async () => {
    const HttpRequest = new HTTP_REQUEST('user/cart/fix/', {
        method: HTTP_METHODS.POST,
        options: { validateCookies: true },
    });

    try {
        return await HttpRequest.callFetch();
    } catch (error) {
        throw new RequestError('Failed To Fix Cart');
    }
};


type CUSTOM_RESPONSE = CART_TYPE

const fixCart = async (): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await fixCartFetch();
        let body: API_CART_TYPE = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>(
                {
                    ...body,
                    cart_items: mapCartProducts(body.cart_items),
                    promo_code: mapPromocode(body.promo_code),
                    num_items: addIndividualQuantities(body.cart_items)
                }
            )
            : FAILURE_RESPONSE('FIX_CART')
    } catch (error) {
        return FAILURE_RESPONSE('FIX_CART', error)
    }
};

export default fixCart;