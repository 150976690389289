import styled from 'styled-components';
import { TextField, Select, FormControl } from '@mui/material';
import { COLORS } from './styles';

export const MapContainer = styled.div`
    display: flex;
    width: 100%;
    height: 500px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`


export const TextInputTwoFields = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
        display: none
    }
`;

export const TextInputOneField = styled.div`
    width: 100%;
    display: none;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 800px) {
        display: flex
    }
`;

export const TextInputSingleField = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 800px) {
        display: flex
    }
`;

export const TextInput = styled(TextField)`
    width: 48%;
    transition: all 0.75s ease;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export const FullWidthTextInput = styled(TextField)`
    width: 100%;
    transition: all 0.75s ease; 
`;

export const IconInput = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease;
`

export const TextInputWrapper = styled.div`
    margin: 20px 0;
`;

export const SelectAddressType = styled(Select)`
    /* width: 100%; */
`;
export const AddressFormControl = styled(FormControl)`
    width: 48%;
    max-height: 50%;
    transition: all 0.75s ease;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

interface InputSectionTitleProps {
    centerOnMobile?: boolean;
}
export const InputSectionTitle = styled.h2<InputSectionTitleProps>`
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    width: auto;
    font-weight: bold;
    color: #333333;
    transition: all 0.75s ease;
    @media screen and (max-width: 800px) {
        ${(props) =>
        props.centerOnMobile
            ? `
                width:100%;
                text-align: center;
                `
            : `
                width: auto;
                font-size: 1.3rem;
                `}
    }
`;
export const InputSectionSubTitle = styled.h4<InputSectionTitleProps>`
    font-weight: normal;
    margin: 0;
    @media screen and (max-width: 800px) {
        ${(props) =>
        props.centerOnMobile
            ? `
                width:100%;
                text-align: center;

                `
            : `
                width: 60%;
                `}
    }
`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
`;

export const TitleButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid black; */
    flex-direction: row;
`;