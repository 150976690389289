import { LanguageName, LanguageChangerContainer } from './LanguageChangerElements';
import { changeSiteLanguage, getSiteLanguage } from '@translation';
import { AvailableLanguagesType } from '@project/types'

const LanguageChanger = () => {
    const options = [
        { displayLanguageName: 'EN', name: 'en' },
        { displayLanguageName: 'عر', name: 'ar' }
    ];

    const isEnglish = getSiteLanguage() === options[0].name;

    const currentLangIndex = isEnglish ? 0 : 1;

    const handleLanguageChange = (name: string) => {
        changeSiteLanguage(name as AvailableLanguagesType);
    }

    return (
        <LanguageChangerContainer>
            {
                options.map(({ name, displayLanguageName }, key) =>
                    <LanguageName {...{
                        key, active: currentLangIndex === key,
                        onClick: currentLangIndex === key ? () => { } : () => handleLanguageChange(name)
                    }}>
                        {displayLanguageName}
                    </LanguageName>)
            }
        </LanguageChangerContainer>
    );
};

export default LanguageChanger;
