import {
    HTTP_REQUEST,
    HTTP_METHODS,
    RequestError,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';
import { API_ADDRESS_TYPE, default_address } from '@api/user/address/types';


export const singleAddressFetch = async (address_id: string) => {
    const HttpRequest = new HTTP_REQUEST(`user/address/${address_id}/`, {
        method: HTTP_METHODS.GET,
        options: {
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Get Single Address');
    }
};

export const addressFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/address/', {
        method: HTTP_METHODS.GET,
        options: { providedAccessToken },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Get User Addresses');
    }
};


import { RootState, store } from '@redux/store';

export const getUserAddress = async (address_id: string) => {
    try {
        if (!store.getState().auth.auth_token) {
            return FAILURE_RESPONSE('GET_ADDRESS');
        }
        const response = await singleAddressFetch(address_id);
        const body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<API_ADDRESS_TYPE>(body)
            : FAILURE_RESPONSE('GET_ADDRESS', body);
    } catch (error) {
        return FAILURE_RESPONSE('GET_ADDRESS', error);
    }
};


export const getMainAddress = async () => {
    try {
        const { user: { addresses } } = (store.getState() as RootState);
        if (addresses.length) {
            return addresses.find((address) => Boolean(address.is_main))
        }
        const response = await addressFetch();
        const body: API_ADDRESS_TYPE[] = await response.json();
        const mainAddress =
            body.filter((elem) => elem.is_main)[0] || default_address;
        return response.ok
            ? SUCCESS_RESPONSE<API_ADDRESS_TYPE>(mainAddress)
            : FAILURE_RESPONSE('GET_ADDRESSES');
    } catch (error) {
        return FAILURE_RESPONSE('GET_MAIN_ADDRESS', error);
    }
};


export const getUserAddresses = async (token?: string) => {
    try {
        const response = await addressFetch(token);
        const body = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<API_ADDRESS_TYPE[]>(body)
            : FAILURE_RESPONSE('GET_ADDRESSES');
    } catch (error) {
        return FAILURE_RESPONSE('GET_ADDRESSES', error);
    }
};
