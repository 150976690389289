// IMPORT TYPES
import { APP_STATE_ORDER_DETAIL_TYPE, ORDER_STATUS } from "@api/types";

// IMPORT HELPERS
import { useState } from "react";
import {
    isCancelledOrder,
    getOrderMainColor,
    getOrderLighterColor,
} from "@project/helpers";

// IMPORT COMPONENTS
import {
    Ticket,
    TicketID,

    Status,
    StatusInner,

    TicketInner,
    TicketIDAndStatusRow,

    LeftSide,
    RightSide,
    OrderDivider,
    CustomCardText,
    CustomCardImage,
    OrderInfoContainer,
} from "./TicketElements";
import { OrderProducts } from "./OrderProducts";
import { OrderTimelineBlocks } from "./OrderTimelineBlocks";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { useIsScreenBiggerThan } from "@project/hooks";
import { FaEnvelope, FaEnvelopeOpenText, FaEnvelopeOpen } from "react-icons/fa";
import { useTranslation } from "react-i18next";




export type OrderTicketProps = APP_STATE_ORDER_DETAIL_TYPE;


export const OrderTicket = (order_info: OrderTicketProps) => {
    const { status, order_id } = order_info;
    const main_color = getOrderMainColor(status);
    const light_color = getOrderLighterColor(status);
    const _500 = useIsScreenBiggerThan(500);
    const _768 = useIsScreenBiggerThan(768);

    const { t } = useTranslation();
    const design = order_info.custom_card?.card_design;
    const designAspectRatio = (design?.design.image_width || 1) / (design?.design.image_height || 1);
    const dialogMaxWidth = _768 ? designAspectRatio * 275 : designAspectRatio * 150

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const getCustomCardIconOnStatus = () => {
        if (isCancelledOrder(order_info.status)) {
            return FaEnvelopeOpen;
        }
        if (order_info.status === ORDER_STATUS.DELIVERED) { return FaEnvelopeOpenText }
        return FaEnvelope;
    }

    const showCustomCardSnapShot = () => {
        setDialogOpen(true);
    }

    const closeMessageSnapshot = () => {
        setDialogOpen(false)
    }

    return (
        <>
            <Ticket>
                <TicketInner {...{ style: { border: `solid 2px ${main_color}` } }}>
                    <TicketIDAndStatusRow>
                        <TicketID>
                            <span {...{ style: { fontWeight: 'bold', marginLeft: '10px' } }}>{_500 ? `${t("orders.order")}\xA0\xA0` : '#'}</span>
                            {order_id + `${order_info.custom_card ? (_500 ? `\xA0\xA0${t("gift.with")}\xA0\xA0\xA0` : '\xA0+\xA0') : ""}`}
                            {order_info.custom_card &&
                                <CustomCardText {...{ onClick: showCustomCardSnapShot }}>
                                    {getCustomCardIconOnStatus()({
                                        fontSize: _500 ? '2rem' : '1.5rem',
                                        fontWeight: 'bold',
                                        color: main_color,
                                    })}
                                </CustomCardText>}
                        </TicketID>

                        <Status {...{ style: { backgroundColor: light_color } }}>
                            <StatusInner {...{ style: { color: main_color } }}>
                                {status}
                            </StatusInner>
                        </Status>
                    </TicketIDAndStatusRow>

                    <OrderInfoContainer>
                        <LeftSide>
                            <OrderTimelineBlocks {...order_info} />
                        </LeftSide>

                        <OrderDivider {...{ orientation: 'vertical' }} />
                        <RightSide>
                            <OrderProducts {...order_info} />
                        </RightSide>
                    </OrderInfoContainer>
                </TicketInner>
            </Ticket >
            {
                order_info.custom_card &&
                <Dialog {...{
                    open: dialogOpen,
                    style: {
                        marginLeft: _768 ? ((window.innerWidth - (dialogMaxWidth + 135)) / 2) : undefined,
                        maxWidth: _768 ? dialogMaxWidth + 135 : undefined,
                    },
                    onClose: closeMessageSnapshot,
                }}>
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{
                            fontWeight: 'bolder',
                            marginBottom: '0px',
                        }}
                    >
                        {order_info.custom_card.card_design ? order_info.custom_card.card_design.title : t('gift.customMessageTitle')}
                    </DialogTitle>
                    <DialogContent>
                        {
                            order_info.custom_card.card_design &&
                            <DialogContentText id="alert-dialog-description">
                                {order_info.custom_card.card_design.description}
                            </DialogContentText>
                        }
                        <DialogContentText
                            id="alert-dialog-description"
                            style={{ padding: '15px 0px 0px 0px' }}>
                            <span {...{ style: { fontWeight: 'bold' } }}>{t('gift.to')}:{'\xA0'}</span>{order_info.custom_card.recipient || ''}
                        </DialogContentText>
                        <DialogContentText
                            id="alert-dialog-description"
                            style={{ padding: '0px 0px 15px 0px' }}>
                            <span {...{ style: { fontWeight: 'bold' } }}>{t('gift.from')}:{'\xA0'}</span>{order_info.custom_card.sender || ''}
                        </DialogContentText>
                        <DialogContentText>
                            {order_info.custom_card.message}
                        </DialogContentText>
                        {
                            Boolean(order_info.custom_card.card_design && _768) &&
                            <CustomCardImage
                                aspectRatio={(order_info.custom_card.card_design?.design.image_width || 1) / (order_info.custom_card.card_design?.design.image_height || 1)}
                                style={{ backgroundImage: `url(${order_info.custom_card.card_design?.design.image})`, backgroundSize: 'contain' }} />

                        }
                    </DialogContent>
                </Dialog>
            }

        </>
    )
}