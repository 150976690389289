import { BASE_TRANSITION_TIME, COLORS } from '@project/styles';
import styled from 'styled-components';

export const MainContainer = styled.div`
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    transition: all .5s;
    @media screen and (max-width: 1000px) {
        padding: 10px 0% 20px 0%;
        flex-direction: column;
    }
`;
export const ProductDetailsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ${BASE_TRANSITION_TIME}s;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
export const FAQWrapper = styled.div`
    width: 100%;
    transition: all ${BASE_TRANSITION_TIME}s;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
