import React, { useState } from 'react';

// IMPORT STYLES
import styled from 'styled-components';
import { COLORS } from '@project/styles';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AddressForm } from '@custom_components';


import './Address.css'
import { useIsScreenBiggerThan } from '@project/hooks';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: window.innerWidth * 0.4,
    maxHeight: window.innerHeight * 0.8,
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 5,
    boxShadow: 10,
    p: 4,
};


const AddAddress = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

    const smallScreen = useIsScreenBiggerThan(600);
    const mediumScreen = useIsScreenBiggerThan(1000);

    const modalSx = {
        ...style,
        width: !smallScreen
            ? window.innerWidth * 0.8
            : !mediumScreen
                ? window.innerWidth * 0.7
                : window.innerWidth * 0.5,
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalSx}
                className={'address-form-checkout'}>
                <AddressForm {...{
                    open,
                    type: 'Add',
                    hideForm: handleClose,
                    formTitle: 'Add an Address',
                }} />
            </Box>
        </Modal>
    )
}

export default AddAddress