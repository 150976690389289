import styled from "styled-components";

import {
    ORDER_STATUS,
    OrderStatusType,

    CHECKOUT_STATE,
    PAYMENT_METHODS,
    MAKE_ORDER_PARAMS,
    DELIVERY_WINDOW_TYPE,
    API_GIFT_ADDRESS_TYPE,
    REPOTTED_PRODUCTS_TYPE,
    API_SCHEDULE_TIMING_TYPE,
    API_REPOTTING_CONFIGURATION_TYPE,
    APP_STATE_ORDER_TYPE,
    API_ORDER_DETAIL_PRODUCT_TYPE,
    API_ORDER_SCHEDULE_TYPE,
} from "@api/types";

import {
    APPLE_PAY_TYPE,
    API_CARD_BRANDS,
    DIBSY_PAYMENT_OPTION,
    CREDIT_CARD_BRAND_TYPE,
    DIBSY_DEFAULT_PAYMENT_OPTION_TYPE,
    DIBSY_PRIORITY_PAYMENT_OPTION_TYPE,
    DIBSY_SAVED_PAYMENT_OPTION_TYPE,
    DIBSY_ADDED_PAYMENT_OPTION_TYPE,
} from "@api/user/cards/types";
import creditCardType from 'credit-card-type';
import { CARD_INFORMATION_TYPE } from '@api/checkout/types';
import { APP_STATE_CART_PRODUCT_TYPE } from "@api/user/cart/types";

import {
    HOSTED_PAYMENT_INITIALIZATION_PARAMS,
    ADDED_CREDIT_CARD_PAYMENT_INITIALIZATION_PARAMS,
    RECURRING_CREDIT_CARD_PAYMENT_INITIALIZATION_PARAMS,
} from "./types";

// IMPORT COMPONENTS
import { GrAmex } from 'react-icons/gr';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { FcMoneyTransfer } from 'react-icons/fc';
import { SiVisa, SiMastercard, SiApplepay } from 'react-icons/si';
import { BsCreditCard, BsCreditCard2Front } from 'react-icons/bs';
import { CommonButton, CommonButtonText } from '@styled_components';


// IMPORT STYLES
import { COLORS } from "@project/styles";

// IMPORT HELPERS
import { store } from "@redux/store";
import verifyOTP from "./payment/otp/verify";
import requestOTP from "./payment/otp/request";

import failOrder from "@api/checkout/order/fail";
import makeOrder from "@api/checkout/order/create";
import processPayment from "@api/checkout/payment";
import { NavigateFunction } from "react-router-dom";
import getCreditCardToken from "@api/checkout/payment/token";
import { ApplePayButton, APPLE_PAY_BUTTON_TYPES } from '@custom_components';
import resolvePaymentPoll, { PollFunction } from "@api/checkout/payment/poll";

// Translation Helpers
import i18next from 'i18next';
import { useTranslation } from "react-i18next";


let ApplePaySession = (window as any).ApplePaySession;

export const getRepotStrategyText = (
    repot_configuration: API_REPOTTING_CONFIGURATION_TYPE
) => {
    switch (repot_configuration.repotting_cost_strategy) {
        case 'flat_fee_repotting':
            return i18next.t('checkout.flatFeeRepottingMessage').replace("<$price>", `${repot_configuration.flat_fee_repotting_cost || 0}`)
        case 'charge_each_repotting':
            return i18next.t('checkout.chargeEachRepotting').replace("<$price>", `${repot_configuration.each_repotting_cost || 0}`)
        default:
            return i18next.t('checkout.multipleRepotDiffFee')
                .replace("<$price1>", `${repot_configuration.first_repotting_cost || 0}`)
                .replace("<$price2>", `${repot_configuration.second_repotting_cost || 0}`)
    }

}

export const generateAlphaNumericId = (used_ids: string[], count: number, length: number): string[] => {
    let generated_id: string = ''
    let processed_ids: string[] = [];
    for (let index = 0; index < count; index++) {
        while (used_ids.includes(generated_id = Math.random().toString(36).toLocaleUpperCase().slice(2, length + 2))) { continue }
        processed_ids.push(generated_id)
    }

    return processed_ids;
}

export const getPriorityPayments = (payment_id: string): APPLE_PAY_TYPE[] => {
    return []
    // if (ApplePaySession && ApplePaySession.canMakePayments()) {
    //     return [{ payment_id, payment_type: 'apple_pay' }]
    // } return [];
}

export const generateDefaultPayments = (): {
    default_payments: DIBSY_DEFAULT_PAYMENT_OPTION_TYPE[];
    priority_payments: DIBSY_PRIORITY_PAYMENT_OPTION_TYPE[];
} => {
    const generated_payment_ids = generateAlphaNumericId([], 4, 12);
    return {
        default_payments: [{
            payment_type: 'debit',
            payment_id: generated_payment_ids.at(1) || '2',
        }, {
            payment_type: 'cash',
            payment_id: generated_payment_ids.at(0) || '1',
        }],
        priority_payments: getPriorityPayments(generated_payment_ids.at(3) || '4'),
    }
}


export const getPaymentTitle = (option: DIBSY_PAYMENT_OPTION): string => {
    switch (option.payment_type) {
        case 'apple_pay':
            return i18next.t('checkout.applePay');
        case 'added':
            switch (cardType(option.cc_number)) {
                case CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS:
                    return `XXXX-${option.cc_number
                        .slice(option.cc_number.length - 5, option.cc_number.length)}`;
                default:
                    return `XXXX-${option.cc_number
                        .slice(option.cc_number.length - 4, option.cc_number.length)}`;
            }
        case 'saved': return option.card_number;
        case 'cash': return i18next.t('checkout.payWithCash')
        case 'credit': return i18next.t('checkout.creditCard')
        case 'debit': return i18next.t('checkout.debitCard')

        default: return ''
    }
}

export const cardType = (cc_number: string) => {
    if (!cc_number.length) {
        return CREDIT_CARD_BRAND_TYPE.INVALID;
    }
    const matchedCards = creditCardType(cc_number)
    const cardTypeInformation =
        !matchedCards.length
            ? undefined
            : matchedCards[0];
    if (!cardTypeInformation) {
        return CREDIT_CARD_BRAND_TYPE.INVALID;
    }

    switch (cardTypeInformation.type) {
        case 'visa':
            return CREDIT_CARD_BRAND_TYPE.VISA;
        case 'mastercard':
            return CREDIT_CARD_BRAND_TYPE.MASTERCARD;
        case 'american-express':
            return CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS;
        default:
            return CREDIT_CARD_BRAND_TYPE.INVALID;
    }
}

export const getCardTypeFromLabel = (label: API_CARD_BRANDS) => {
    switch (label) {
        case 'Amex':
            return CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS;
        case 'Visa':
            return CREDIT_CARD_BRAND_TYPE.VISA;
        case 'Mastercard':
            return CREDIT_CARD_BRAND_TYPE.MASTERCARD;
        default:
            return CREDIT_CARD_BRAND_TYPE.INVALID;
    }
}

export enum PAYMENT_TYPE {
    CASH,
    DEBIT,
    CREDIT,
}

export const setApplePayAsActiveIfNecessary = async (success: Function) => {
    if (ApplePaySession) {
        if (await ApplePaySession.canMakePaymentsWithActiveCard('merchant.com.greenit.app')) {
            success();
        }
    }
}

export const getActivePaymentOption = (payment_options: DIBSY_PAYMENT_OPTION[]) => {
    // Check for primary card in saved payments


    let option: DIBSY_PAYMENT_OPTION | undefined = undefined;
    if (option = payment_options.find(option => option.payment_type === 'saved' && option.is_primary_card)) {
        return option.payment_id;
    } else return payment_options.find(option => option.payment_type === 'apple_pay')?.payment_id || ''
}


export const renderCardTypeIcon = (type: CREDIT_CARD_BRAND_TYPE): JSX.Element => {
    switch (type) {
        case CREDIT_CARD_BRAND_TYPE.AMERICAN_EXPRESS:
            return GrAmex({ fill: '#333', fontSize: '2rem' })
        case CREDIT_CARD_BRAND_TYPE.MASTERCARD:
            return SiMastercard({ fill: '#333', fontSize: '1.5rem' })
        case CREDIT_CARD_BRAND_TYPE.VISA:
            return SiVisa({ fill: '#333', fontSize: '1.75rem' })
        default:
            return BsCreditCard2Front({ fill: '#333', fontSize: '1.5rem' })
    }
}

export const renderPaymentIcon = (option: DIBSY_PAYMENT_OPTION): JSX.Element => {
    switch (option.payment_type) {
        case 'apple_pay':
            return SiApplepay({
                fill: '#333', fontSize: '1.65rem',

            })
        case 'added':
            return renderCardTypeIcon(cardType(option.cc_number));
        case 'saved':
            return renderCardTypeIcon(getCardTypeFromLabel(option.card_label));
        case 'credit':
            return BsCreditCard2Front({ fill: '#333', fontSize: '1.5rem' })

        case 'debit':
            return BsCreditCard({ fill: '#333', fontSize: '1.5rem' })

        case 'cash':
            return FaMoneyBillAlt({ fill: '#333', fontSize: '1.5rem' })
        default:
            return FcMoneyTransfer({ fill: '#333', fontSize: '1.5rem' })
    }
}

interface ProceedToCheckoutProps {
    payment_option?: DIBSY_PAYMENT_OPTION;
    onClick: () => void;
}


const getApplePaySupportedVersion = () => {
    for (let index = 15; index > 0; index--) {
        if (ApplePaySession.supportsVersion(index)) {
            return index
        }
    }

    return 1;
}


const ApplePayPaymentContainer = styled.div`
    width: 100%;
    height: 75px;
    z-index: 100;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const CheckoutButton = ({ payment_option, onClick }: ProceedToCheckoutProps): JSX.Element => {
    const { t } = useTranslation();

    const is_apple_pay_checkout = payment_option?.payment_type === 'apple_pay';


    if (is_apple_pay_checkout) {
        // @ts-ignore
        return (
            <ApplePayPaymentContainer {...{ onClick }}>
                <ApplePayButton {...{ locale: 'en_US', type: APPLE_PAY_BUTTON_TYPES.CONTINUE, buttonstyle: 'black' }} />
            </ApplePayPaymentContainer>
        )
    }

    return (
        <CommonButton {...{
            onClick,
            color: COLORS.DARK_GREEN,
            style: {
                width: '100%',
                height: 75,
                marginTop: '20px',
                padding: '12px 0px',
                borderRadius: 10,
                cursor: 'pointer',
            }
        }}>
            <CommonButtonText {...{
                color: COLORS.WHITE,
                style: {
                    fontWeight: 'bold',
                    fontSize: '1.6rem',
                }
            }} >
                {t("checkout.placeYourOrder")}
            </CommonButtonText>
        </CommonButton>
    )
}

export const getOrderStatus = (api_status: string): OrderStatusType => {
    var key: keyof typeof ORDER_STATUS;
    for (key in ORDER_STATUS) {
        var currVal = ORDER_STATUS[key];
        if (api_status === currVal) {
            return currVal;
        }
    }
    return ORDER_STATUS.UNKNOWN;
};

export interface CHECKOUT_VALIDATION_ERRORS {
    messages: string[]
}

export const computeRepottingCost = (number_of_repots: number) => {
    const {
        repotting_cost_strategy,
        each_repotting_cost,
        first_repotting_cost,
        second_repotting_cost,
        flat_fee_repotting_cost,
    } = (store.getState().checkout.repotting_configuration as API_REPOTTING_CONFIGURATION_TYPE);

    if (!number_of_repots) { return 0 }

    switch (repotting_cost_strategy) {
        case 'charge_each_repotting':
            return number_of_repots * each_repotting_cost;
        case 'flat_fee_repotting':
            return flat_fee_repotting_cost;
        case 'x_for_single_repot_y_for_multiple_repot':
            return number_of_repots > 1 ? second_repotting_cost : first_repotting_cost;
    }
}

export type VALIDATED_RETURN_TYPE = [boolean, string]

export const validateRepots = (
    cart_items: APP_STATE_CART_PRODUCT_TYPE[],
    repots: REPOTTED_PRODUCTS_TYPE[],
    total_repotting_cost: number
): VALIDATED_RETURN_TYPE => {

    if (!repots.length && total_repotting_cost === 0) { return [true, ''] }

    let quantities: { [sku: string]: number } = {};
    repots.forEach(({ plant_sku, pot_sku }) => {
        quantities[plant_sku] = (quantities[plant_sku] + 1) || 1;
        quantities[pot_sku] = (quantities[pot_sku] + 1) || 1
    })

    Object.keys(quantities).forEach((sku: string) => {
        if ((cart_items.find(item => item.product_sku === sku)?.requested_quantity || 0) < quantities[sku]) {
            return [false, i18next.t('checkout.repottingNotAvailableInCart')]
        }
    })

    const number_of_repotted_pairs = repots.length;

    const {
        repotting_cost_strategy,
        each_repotting_cost,
        first_repotting_cost,
        second_repotting_cost,
        flat_fee_repotting_cost,
    } = (store.getState().checkout.repotting_configuration as API_REPOTTING_CONFIGURATION_TYPE);

    const errorMsg = i18next.t('checkout.incorrectRepottingCharged')

    switch (repotting_cost_strategy) {
        case 'charge_each_repotting':
            const validEach = total_repotting_cost === (
                number_of_repotted_pairs * each_repotting_cost
            );
            return [validEach, validEach ? '' : errorMsg];

        case 'flat_fee_repotting':
            const validFlat = total_repotting_cost === flat_fee_repotting_cost;
            return [validFlat, validFlat ? '' : errorMsg];

        case 'x_for_single_repot_y_for_multiple_repot':
            const validXY = total_repotting_cost === (
                number_of_repotted_pairs > 1
                    ? second_repotting_cost
                    : first_repotting_cost);
            return [validXY, validXY ? '' : errorMsg];
    }
}


export const validateOTP = (payment_option?: DIBSY_PAYMENT_OPTION): [boolean, string] => {
    if (!payment_option) { return [true, ''] }
    switch (payment_option?.payment_type) {
        case 'cash':
            if (!store.getState().user.details.is_otp_verified) {
                return [false, i18next.t('checkout.verifyMobileAddress')]
            }
            return [true, '']
        default:
            return [true, '']
    }
}

export const validatePayments = (
    is_gift_order: boolean,
    payment_option?: DIBSY_PAYMENT_OPTION,
): [boolean, string] => {

    if (!payment_option) {
        return [false, i18next.t("checkout.selectValidPayment")];
    }
    let { payment_type } = payment_option;

    switch (payment_type) {
        case 'cash':
            if (is_gift_order) {
                return [false, i18next.t("checkout.giftCannotPayByCash")];
            };
            return [true, ''];
        default: return [true, ''];
    }
}

export const validateUserEmail = (): [boolean, string] => {
    const { details: { email, is_fake_temporary_email } } = store.getState().user;

    if (!email || is_fake_temporary_email) {
        return [false, i18next.t("checkout.provideEmailAddress")]
    }
    return [true, ''];
}

export const validateUserName = (): [boolean, string] => {
    const { details: { first_name, last_name, } } = store.getState().user;
    if (!first_name && !last_name) {
        return [false, i18next.t("checkout.provideFirstAndLastName")];
    }
    return [true, ''];
}

export const validateUserPhoneNumber = (): [boolean, string] => {
    const { details: { otp_phone_number } } = store.getState().user;
    if (!Boolean(otp_phone_number)) { return [false, i18next.t("checkout.provideValidMobileNum")] }
    return [true, ''];

}

export const validateAddress = (
    delivery_address?: string,
    gift_delivery_address?: API_GIFT_ADDRESS_TYPE
): VALIDATED_RETURN_TYPE => {

    if (!delivery_address && !gift_delivery_address) {
        return [false, i18next.t('checkout.selectDeliveryAddressOption')]
    } else if (delivery_address && gift_delivery_address) {
        return [false, i18next.t('checkout.pickOneDeliveryOption')]
    } else {
        if (delivery_address) {
            const { addresses } = store.getState().user;
            if (!addresses.find(address => address.address_id === delivery_address)) {
                return [false, i18next.t('checkout.invalidAddressChooseAnother')]
            }
            return [true, ''];
        } else {
            if (gift_delivery_address?.mobile_number && gift_delivery_address.recipient_name) {
                return [true, '']
            } else return [false, i18next.t('giftAddressInvalidPlsUpdateInfo')]
        }

    }
}

export const validateSchedule = (window?: DELIVERY_WINDOW_TYPE): VALIDATED_RETURN_TYPE => {
    if (!window) {
        return [false, i18next.t('checkout.selectDeliveryWindow')];
    } else {
        const { date, start_time: window_start_time, end_time: window_end_time } = window;
        const {
            possible_timing_windows,
            delivery_options: { single_order: { timings } }
        } = store.getState().checkout.delivery_availability as API_ORDER_SCHEDULE_TYPE;

        if (!possible_timing_windows.find(({ start_time, end_time }) =>
            start_time === window_start_time
            && end_time === window_end_time)) {
            return [false, i18next.t('checkout.invalidTimeSlot')]
        }

        let valid_windows_for_selected_date: API_SCHEDULE_TIMING_TYPE | undefined;
        if (!(valid_windows_for_selected_date = timings.find(time_objects => Object.keys(time_objects)[0] === date))) {
            return [false, i18next.t('checkout.invalidDeliveryDate')]
        }

        if (!valid_windows_for_selected_date[Object.keys(valid_windows_for_selected_date)[0]].find(
            ({ start_time, end_time }) =>
                start_time === window_start_time
                && end_time === window_end_time)) {
            return [false, i18next.t('checkout.invalidTimeSlot')]
        }
        return [true, '']
    }
}

const getPaymentTypeFromOption = (option: DIBSY_PAYMENT_OPTION): PAYMENT_METHODS => {
    switch (option.payment_type) {
        case 'cash':
            return 'Cash';
        case 'apple_pay':
            return 'Apple Pay';
        case 'debit':
            return 'Debit Card';

        case 'added':
        case 'credit':
        case 'saved':
            return 'Credit Card';
        default:
            throw new Error('invalid payment option selected!')
    }
}


const mapCheckoutStateToMakeOrderParams = ({
    payment_option,
    main_delivery_window,
    ...order_params }: CHECKOUT_STATE): MAKE_ORDER_PARAMS => {
    Object.keys(order_params).forEach((param) => {
        if ((order_params as any)[param as any] === undefined) {
            delete (order_params as any)[(param as any)]
        }
    })
    return {
        ...order_params,
        payment_type: getPaymentTypeFromOption(payment_option as DIBSY_PAYMENT_OPTION),
        main_delivery_window: main_delivery_window as DELIVERY_WINDOW_TYPE,
    }
}


const mapOrderItemToLineItem = ({ product, product_sku, quantity, price }: API_ORDER_DETAIL_PRODUCT_TYPE): ApplePayJS.ApplePayLineItem => {

    let computedProductLabel: string = product.shortened_display_name || product.name;

    if (product.variants.length > 1) {
        const variantDisplayName = product.variants.find((variant) => variant.sku === product_sku)?.display_name || 'Base';
        computedProductLabel = `${computedProductLabel} (${variantDisplayName})`
    }
    if (quantity > 1) {
        computedProductLabel = `${computedProductLabel} x ${quantity}`
    }

    return {
        type: 'final',
        label: computedProductLabel,
        amount: (quantity * price).toFixed(2),
    }
}

// Unimplemented Payment Processes!

export const processApplePayPayment = async (
    order: APP_STATE_ORDER_TYPE,
    total: number,
    navigate: NavigateFunction
): Promise<APP_STATE_ORDER_TYPE | null> => {
    // let appleSupportedVersion: number = 0;

    // if (ApplePaySession) {
    //     const merchant_identifier = 'merchant.com.greenit.app'

    //     const canMakeCardPayments = await ApplePaySession.canMakePaymentsWithActiveCard(merchant_identifier)
    //     if (appleSupportedVersion = getApplePaySupportedVersion() - 1) {
    //         const lineItems: ApplePayJS.ApplePayLineItem[] = order.order_items.map(mapOrderItemToLineItem)
    //         const paymentRequestData: ApplePayJS.ApplePayPaymentRequest = {
    //             lineItems,
    //             countryCode: 'QR',
    //             currencyCode: 'QAR',
    //             supportedNetworks: ['visa', 'masterCard'],
    //             merchantCapabilities: ['supports3DS'],
    //             total: { label: 'Greenit', amount: total.toFixed(2) },
    //         }

    //         let applePaySession = new ApplePaySession(appleSupportedVersion, paymentRequestData)

    //         applePaySession.oncancel = (event: any) => {
    //             return new Promise((_, rj) => rj(null));
    //         }

    //         applePaySession.onvalidatemerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
    //             // event.
    //         }
    //         applePaySession.begin();
    //     }
    // }
    // await failOrder(order.order_id);
    // waitSync(1200000);
    return new Promise((_, rj) => rj(null))
}


export const processSavedCardPayment = async (
    order: APP_STATE_ORDER_TYPE,
    { payment_id }: DIBSY_SAVED_PAYMENT_OPTION_TYPE,
    total_payment_amount: number,
    navigate: NavigateFunction
): Promise<APP_STATE_ORDER_TYPE | null> => {

    const paymentResponse = await processPayment({
        total_payment_amount,
        order: order.order_id,
        saved_credit_card: payment_id,
    } as RECURRING_CREDIT_CARD_PAYMENT_INITIALIZATION_PARAMS);


    if (paymentResponse.status) {
        const {
            payment_process_identifier
        } = paymentResponse.response_body

        if (payment_process_identifier) {
            return processPaymentPolling(order, payment_process_identifier, resolvePaymentPoll, navigate)
        }
    }

    // ERROR HANDLING
    failOrder(order.order_id);
    return new Promise((_, rj) => rj(null));
}

export const mapAddedOptionsToDibsyOptions = (card_info: DIBSY_ADDED_PAYMENT_OPTION_TYPE): CARD_INFORMATION_TYPE => ({
    cardCVC: card_info.card_cv2,
    cardExpiryMonth: card_info.expiry_month,
    cardExpiryYear: card_info.expiry_year,
    cardNumber: card_info.cc_number.replaceAll('-', '').replaceAll(' ', ''),
    cardHolder: card_info.card_holders_name,
})


export const redirectToCheckoutLink = (link: string): void => {
    window.location.href = link;
}


// ---------------------------------------------
//  MAIN PAYMENT POLLING RESOLVER FUNCTION
// ---------------------------------------------
export const waitSync = (seconds: number) => {
    var start = Date.now(),
        now = start;
    while (now - start < (seconds) * 1000) {
        now = Date.now();
    }
}


export const processPaymentPolling = (
    order: APP_STATE_ORDER_TYPE,
    payment_identifier: string,
    poll_function: PollFunction,
    navigate?: NavigateFunction,
    handleSuccess?: () => void,
    handleFailure?: (message: string | undefined) => void,
): Promise<APP_STATE_ORDER_TYPE | null> => {

    const processPollRequest = async (): Promise<APP_STATE_ORDER_TYPE | null> => {
        const paymentPollResponse = await poll_function(payment_identifier)

        if (paymentPollResponse.status) {
            const {
                status,
                checkout_link,
                failure_message } = paymentPollResponse.response_body;

            switch (status) {
                case 'Pending':
                    waitSync(1.5);
                    return processPollRequest();

                case 'Success':
                    handleSuccess && handleSuccess()

                    if (checkout_link) {
                        redirectToCheckoutLink(checkout_link);
                        return new Promise((rs, _) => rs(order));
                    }
                    navigate && navigate(`/payment/status/?order=${order.order_id}`, {
                        state: {
                            checkout_type: 'direct',
                            order_information: order,
                            payment_identifier,
                        }, replace: true
                    })
                    return new Promise((rs, _) => rs(order))
            }

            handleFailure && handleFailure(failure_message);
            return new Promise((_, rj) => rj(failure_message || ''));
        }
        return new Promise((_, rj) => rj(null));
    }
    return processPollRequest();
}

// ---------------------------------------------

export const processAddedCardPayment = async (
    order: APP_STATE_ORDER_TYPE,
    added_parameters: DIBSY_ADDED_PAYMENT_OPTION_TYPE,
    total_payment_amount: number,
    navigate: NavigateFunction
): Promise<APP_STATE_ORDER_TYPE | null> => {

    const cardTokenResponse = await getCreditCardToken(mapAddedOptionsToDibsyOptions(added_parameters))
    if (cardTokenResponse.status) {
        const paymentResponse = await processPayment({
            total_payment_amount,
            order: order.order_id,
            card_token: cardTokenResponse.response_body.cardToken,
            should_save_card: added_parameters.save_card_for_user,
        } as ADDED_CREDIT_CARD_PAYMENT_INITIALIZATION_PARAMS)

        if (paymentResponse.status) {
            const { payment_process_identifier } = paymentResponse.response_body
            if (payment_process_identifier) return processPaymentPolling(order, payment_process_identifier, resolvePaymentPoll, navigate)
        }
    }

    failOrder(order.order_id);
    return new Promise((_, rj) => rj(null));
}


export const processHostedPayment = async (
    order: APP_STATE_ORDER_TYPE,
    total_payment_amount: number,
): Promise<APP_STATE_ORDER_TYPE | null> => {

    const paymentResponse = await processPayment({
        total_payment_amount,
        order: order.order_id,
    } as HOSTED_PAYMENT_INITIALIZATION_PARAMS)


    if (paymentResponse.status) {
        const { payment_process_identifier } = paymentResponse.response_body;
        if (payment_process_identifier) return processPaymentPolling(order, payment_process_identifier, resolvePaymentPoll)
    }

    failOrder(order.order_id);
    return new Promise((_, rj) => rj(null));
}


export const processOrder = async (state: CHECKOUT_STATE, navigate: NavigateFunction):
    Promise<APP_STATE_ORDER_TYPE | null> => {
    const mappedState = mapCheckoutStateToMakeOrderParams(state);
    const orderResponse = await makeOrder(mappedState);
    const total_amount =
        state.delivery_cost_expected_by_user
        + state.total_price_expected_by_user
        + state.repotting_cost_expected_by_user

    if (orderResponse.status) {
        const order = orderResponse.response_body;

        if (!state.payment_option) {
            failOrder(order.order_id);
            return new Promise((_, rj) => rj(null));
        }

        switch (state.payment_option!.payment_type) {
            case 'debit':
            case 'credit':
                return processHostedPayment(order, total_amount)

            case 'apple_pay':
                return processApplePayPayment(order, total_amount, navigate)

            case 'added':
                return processAddedCardPayment(order, state.payment_option, total_amount, navigate)

            case 'saved':
                return processSavedCardPayment(order, state.payment_option, total_amount, navigate)

            case 'cash':
                navigate && navigate(`/payment/status/?order=${order.order_id}`, {
                    state: {
                        checkout_type: 'cash',
                        order_information: order,
                    }, replace: true
                })
                return new Promise((rs, _) => rs(orderResponse.response_body));

            default:
                failOrder(order.order_id);
                return new Promise((_, rj) => rj(null));
        }
    }

    return new Promise((_, rj) => rj(null));
}


export const sendOTPRequest = async (otp_number: string, successCallback: () => void): Promise<null> => {
    const paymentResponse = await requestOTP(otp_number)

    if (paymentResponse.status) {
        successCallback();
        return new Promise((rs, rj) => rs(null))
    }
    return new Promise((_, rj) => rj(null));
}

export const sendOTPVerification = async (otp_code: string, successCallback: () => void, failureCallback: () => void): Promise<null> => {
    const paymentResponse = await verifyOTP(otp_code)

    if (paymentResponse.status) {
        successCallback();
        return new Promise((rs, rj) => rs(null))
    }
    failureCallback();
    return new Promise((_, rj) => rj(null));
}

export const getOrderTotalCost = (order: APP_STATE_ORDER_TYPE) => {
    return order.total_cart_price_after_discount + order.delivery_cost_after_discount + (order.repotting_cost || 0)
} 