import {
    HTTP_REQUEST,
    HTTP_METHODS,
    RequestError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import { CART_TYPE } from '@api/user/cart/types';


export const removePromoCodeFetch = async () => {
    const HttpRequest = new HTTP_REQUEST('user/cart/remove-promo-code/', {
        method: HTTP_METHODS.POST,
        options: {
            validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Remove Promocode');
    }
};


type CUSTOM_RESPONSE = {
    newPrices: Partial<CART_TYPE>;
};

const removePromoCode = async (): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await removePromoCodeFetch();
        let body = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>({
                newPrices: {
                    total_cart_price: body.total_cart_price,
                    delivery_cost_for_single_order: body.delivery_cost_for_single_order,
                    total_cart_price_after_discount: body.total_cart_price_after_discount,
                    total_payable_due_for_single_order: body.total_payable_due_for_single_order,
                    delivery_cost_for_single_order_after_discount: body.delivery_cost_for_single_order_after_discount,
                },
            })
            : FAILURE_RESPONSE('PROMO_CODE', {});
    } catch (error) {
        return FAILURE_RESPONSE('PROMO_CODE', error);
    }
};


export default removePromoCode;