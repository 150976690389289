import styled from 'styled-components';
import banner from '@assets/images/user/TitleBanner/plantBanner.png';
import bannerMobile from '@assets/images/user/TitleBanner/plantBannerMobile.png';

// TOP BANNER
export const TopBannerContainer = styled.div`
    position: relative;
    overflow: hidden;
`;
export const ImageBanner = styled.img.attrs({
    src: banner,
})`
    width: 100%;
`;
export const ImageBannerMobile = styled.img.attrs({
    src: bannerMobile,
})`
    width: 100%;
`;
export const BannerTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    @media screen and (max-width: 800px) {
        justify-content: flex-start;
        width: 50%;
        height: 90%;
        align-items: center;
        left: 30px;
    }
`;
export const BannerTitleText = styled.h1`
    margin: 0;
`;
