import { useEffect, useMemo } from 'react';

// IMPORT TYPES
import { API_PRODUCT_IMAGE_TYPE } from '@api/types';

import "react-image-gallery/styles/scss/image-gallery.scss";
import './Carousel.scss';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

//IMPORT STYLES
import { imageUrlCustomSize } from '@api/interactions';

export interface ProductCarouselProps {
    quickAdd?: boolean;
    displayableImages: API_PRODUCT_IMAGE_TYPE[];
}

const ProductCarousel = ({ displayableImages }: ProductCarouselProps) => {
    const items = useMemo((): ReactImageGalleryItem[] => displayableImages.map(
        (image) => ({
            original: image.image,
            originalWidth: image.image_width,
            originalHeight: image.image_height,
            thumbnail: imageUrlCustomSize(image.image, 400, 400),
        })), [displayableImages])

    return (
        <ImageGallery {...{
            items,
            lazyLoad: true,
            showNav: true,
            showIndex: false,
            showBullets: false,
            slideInterval: 2000,
            showPlayButton: false,
            stopPropagation: true,
            useBrowserFullscreen: true,
            showFullscreenButton: false,
            thumbnailPosition: 'left',
        }} />
    );
};

export default ProductCarousel;
