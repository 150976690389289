//IMPORT STYLED COMPONENTS
import { AddToCart } from '@api/helpers';
import { imageUrlCustomSize } from '@api/interactions';
import { API_PRODUCT_TYPE } from '@api/types';
import { promiseMessage } from '@overlays/notifications';
import { getFirstVariant, getFirstVariantInfo, isProductAvailable } from '@project/helpers';
import { useIsScreenBiggerThan } from '@project/hooks';
import {
    BundleSectionWrapper,
    BundleItem,
    BundleImageWrapper,
    BundleItemImage,
    BundleItemNameWrapper,
    BundleItemName,
    BundleItemSubName,
    BundlePriceWrapper,
    BundlePrice,
    AddButtonImg,
    BundleSectionTitleText,
    BundlePriceText,
} from './TopElements';

const BundleSection = ({ complementaryProducts }: { complementaryProducts: API_PRODUCT_TYPE[] }) => {
    const _768 = useIsScreenBiggerThan(768);
    return (
        <BundleSectionWrapper>
            <BundleSectionTitleText>
                Frequently Bought Together
            </BundleSectionTitleText>

            {
                complementaryProducts.map((item) => {
                    const is_available = isProductAvailable(item);
                    const { price, sku } = getFirstVariantInfo(item);

                    const addBundledItemToCart = () => {
                        promiseMessage({
                            promise: AddToCart(sku, 1, price),
                            success: `Added ${item.shortened_display_name || item.name} to Cart!`,
                            loading: `Adding ${item.shortened_display_name || item.name} to Cart...`,
                            error: (error: string | null) => `Something went wrong, ${error || 'Please try again!'}.`,
                        })
                    }
                    return (
                        <BundleItem key={item.product_id}>
                            <BundleImageWrapper>
                                <BundleItemImage src={imageUrlCustomSize(item.product_images[0].image, 250, 250)} />
                            </BundleImageWrapper>
                            <BundleItemNameWrapper>
                                <BundleItemName>{!_768 ? item.shortened_display_name || item.name : item.name}</BundleItemName>
                                <BundleItemSubName>{item.root_category.name}</BundleItemSubName>
                            </BundleItemNameWrapper>
                            {
                                is_available ?
                                    <>
                                        <BundlePriceWrapper>

                                            <BundlePrice>
                                                <BundlePriceText>QAR</BundlePriceText>
                                                <BundlePriceText>{price}</BundlePriceText>
                                            </BundlePrice>
                                        </BundlePriceWrapper>
                                        <AddButtonImg {...{ onClick: addBundledItemToCart }} />
                                    </>
                                    :
                                    <BundlePriceText>OUT OF STOCK</BundlePriceText>
                            }


                        </BundleItem>
                    )
                })
            }
        </BundleSectionWrapper>
    );
};

export default BundleSection;
