import { useMemo, useState } from 'react';

//IMPORT SITEMAP
import { RouterPages } from '@project/routes';

//IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

import {
    Top,
    Name,
    Cart,
    Bottom,
    Details,

    CartAdd,
    SaleText,
    CartFill,
    Subheader,
    BannerText,
    SaleBanner,
    SaleTextSmall,
    EasyGrowBanner,
    PriceContainer,
    PriceTextSmall,
    OutOfStockBand,
    ProductWrapper,
    ProductCardLink,
    PriceTextNormal,
    ProductActionSheet,
    ActionCircleQuickAdd,
} from './ProductCardElements';

// IMPORT ICONS AND STYLES
import { COLORS } from '@project/styles';
import { BiExpandAlt } from 'react-icons/bi';

// IMPORT HELPERS
import { BsCartPlusFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useIsScreenBiggerThan } from '@project/hooks';
import { imageUrlCustomSize } from '@api/interactions';
import { getProductSubheading } from '@project/helpers';
import { promiseMessage } from '@overlays/notifications';
import { AddToCart as addProductToCart } from '@api/helpers';
import { IoMdPricetags } from 'react-icons/io'
import { ReactComponent as EasyGrow } from './assets/listings_product_easy_grow.svg';

interface ProductCardProps {
    product: API_PRODUCT_TYPE;
    openAdd?: () => void;
    isExpanded?: boolean;
    showQuickAdd?: boolean;
}

const ProductCard = ({ product, ...optionalProps }: ProductCardProps) => {
    const {
        name,
        slug,
        price,
        variants,
        is_in_sale,
        root_category,
        product_images,
        non_sale_price,
        shortened_display_name,
    } = product;

    const { t } = useTranslation();

    const { openAdd, isExpanded, showQuickAdd } = {
        openAdd: () => { },
        isExpanded: false,
        showQuickAdd: false,
        ...optionalProps,
    }

    const easilyGrowable = useMemo(() => product.product_type === 'Plant' && product.easy_grow_percentage >= 8, [product]);


    const _500 = useIsScreenBiggerThan(500);
    const isAvailable = useMemo(() => variants.findIndex((elem) => elem.quantity > 0) >= 0, [variants]);
    const availableVariantsIndex = useMemo(() => variants.findIndex((elem) => elem.quantity > 0), [variants]);


    const saleFactor = useMemo(() => {
        const current_price = +price;
        const previous_price = +(non_sale_price || '0');
        const price_difference = Math.abs(previous_price - current_price);

        return !previous_price ? 0 : Math.round((price_difference * 100) / previous_price);
    }, [is_in_sale, price, non_sale_price])

    const onAddToCart = () => {
        const { sku, price } = variants[availableVariantsIndex];
        promiseMessage({
            promise: addProductToCart(sku, 1, price),
            success: `Added ${product.shortened_display_name || product.name} to Cart!`,
            loading: `Adding ${product.shortened_display_name || product.name} to Cart...`,
            error: (error: string | null) => `Something went wrong, ${error || 'Please try again!'}.`,
        })
    }

    const openQuickDescription = openAdd;
    const productSubheader = getProductSubheading(product);

    return (
        <ProductWrapper {...{ isexpanded: isExpanded ? isExpanded.toString() : undefined }}>
            <ProductCardLink {...{
                state: { productInfo: product },
                page: RouterPages.ProductDetailsPage,
                pagePathArgs: { root_category_slug: root_category.slug, slug: slug }
            }}>
                {
                    easilyGrowable && isAvailable && <EasyGrowBanner>
                        <EasyGrow {...{ height: '65%', color: 'white' }} />
                        <BannerText> {t('listings.easyGrow')} </BannerText>
                    </EasyGrowBanner>
                }
                {
                    is_in_sale && isAvailable && <SaleBanner {...{ style: { top: easilyGrowable ? 'calc(7% + 50px)' : '7%' } }}>
                        <IoMdPricetags {...{ height: '65%', color: 'white' }} />
                        <BannerText>{`${saleFactor}% off`} </BannerText>
                    </SaleBanner>
                }

                <Top src={imageUrlCustomSize((product_images[0] as any).transparent_image || (product_images[0] as any).image, 300, 300)} />
            </ProductCardLink>
            <Bottom>
                <Details>
                    {
                        isAvailable &&
                        <PriceTextNormal>
                            QAR {price}
                            {is_in_sale &&
                                <>
                                    <span>{'\xA0\n'}</ span>
                                    <SaleText>
                                        QAR {(+(non_sale_price || price)).toFixed(0)}
                                    </SaleText>
                                </>
                            }
                        </PriceTextNormal>
                    }
                    {
                        isAvailable && <PriceContainer {...{ onClick: onAddToCart }}>
                            <PriceTextSmall>
                                QAR {price}
                                {is_in_sale &&
                                    <>
                                        <span>{'\xA0\n'}</ span>
                                        <SaleTextSmall>
                                            QAR {(+(non_sale_price || price)).toFixed(0)}
                                        </SaleTextSmall>
                                    </>
                                }
                            </PriceTextSmall>
                            <BsCartPlusFill {...{ color: 'white', fontSize: '1.5rem' }} />
                        </PriceContainer>
                    }
                    <Name>{(shortened_display_name) ? shortened_display_name : name}</Name>
                    {productSubheader && <Subheader>{productSubheader}</Subheader>}
                </Details>
                {
                    isAvailable &&
                    <CartAdd {...{ onClick: onAddToCart }}>
                        <Cart {...{ color: COLORS.DARK_GREEN, fontSize: _500 ? '2.3rem' : '1.65rem' }} />
                        <CartFill {...{ color: COLORS.DARK_GREEN, fontSize: _500 ? '2.3rem' : '1.65rem' }} />
                    </CartAdd>
                }
            </Bottom>
            {
                !isAvailable ? <OutOfStockBand {...{
                    state: { productInfo: product },
                    page: RouterPages.ProductDetailsPage,
                    pagePathArgs: { root_category_slug: root_category.slug, slug: slug }
                }}>OUT OF STOCK</OutOfStockBand> :
                    <ProductActionSheet>
                        {
                            showQuickAdd &&
                            <ActionCircleQuickAdd {...{ onClick: openQuickDescription }}>
                                <BiExpandAlt {...{ fill: COLORS.WHITE, fontSize: '1.3rem' }} />
                            </ActionCircleQuickAdd>
                        }
                    </ProductActionSheet>
            }

        </ProductWrapper >
    );
};

export default ProductCard;
