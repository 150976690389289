import styled from 'styled-components';
import { Link } from 'routes/components/Link';
import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';



const SCREEN_SIZE_BIG = 1100;
const SCREEN_SIZE_SMALL = 500;
const SCREEN_SIZE_MEDIUM = 768;


export const NotFoundContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
    min-height: ${BIG_CONTENT_CONTAINER_HEIGHT}vh;
`

export const ShopForIndoorPlants = styled.div`
    display: flex;
    height: 6vh;
    width: 100%;
    color: green;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${COLORS.NOTIFICATION_LIGHT_GREEN};
`

export const ShopText = styled(Link)`
    display: flex;
    color: green;
    font-size: 1.2rem;
    text-decoration: none;
`

export const Cancel = styled.div`
    right: 2%;
    width: 30px;
    height: 65%;
    display: flex;
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    position: absolute;
    align-self: center;
    align-items: center;
    justify-content: center;
`

export const BackgroundImageContainer = styled.div<{ cancelled: boolean }>`
    width: 100%;    
    display: flex;
    position: relative;
    background-color: ${COLORS.CREAM};
    height: ${({ cancelled }) => `${BIG_CONTENT_CONTAINER_HEIGHT - (cancelled ? 0 : 6)}vh`};
`

export const NotFoundTitleContainer = styled.div`
    left: 8%;
    right: 8%;
    width: 60%;
    z-index: 10;
    height: auto;
    display: flex;
    align-self: center;
    position: absolute;
    padding: 5% 0%;
    justify-content: flex-start;

    @media screen and (max-width: ${SCREEN_SIZE_BIG}px) {
        top: 10%;
        width: 70%;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        right: auto;
        height: auto;
        padding: 4% 8%;
        justify-content: center;
    }
`;

export const NotFoundTextInnerContainer = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1200px) {
        width: 100%;
        height: 60%;
        margin-top: 20px;
        justify-content: center;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        width: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
    }
`;

export const NotFoundTitleText = styled.h1`
    margin: 0;
    color: #224229; 
    font-weight: bold;
    font-size: 6rem;
    
    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 4.5rem;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 2.8rem;
    }
`;


export const NotFoundDescriptionText = styled.h3`
    color: #224229; 
    font-weight: normal;
    font-size: 1.8rem;
    margin-bottom: 35px;

    @media screen and (max-width: ${SCREEN_SIZE_MEDIUM}px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        font-size: 1.3rem;
        text-align: center;
    }
`;

export const NotFoundSectionDescription = styled.div`
    padding-right: 20%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${SCREEN_SIZE_SMALL}px) {
        padding-right: 0%;
    }
`;

export const NotFoundSectionButton = styled(Link)`
    width: 200px;
    padding: 15px 20px;
    color: #fff;
    background-color: #118B19;
    border-radius: 5px;
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;
    transition: all 0.5s ease;

    &:hover {
        background-color: #007804;
    }
`;