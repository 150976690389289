import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import {
    mapPromocode,
    mapCartProducts,
    addIndividualQuantities,
} from '@api/user/cart/helpers';

import { CART_TYPE, API_CART_TYPE } from '@api/user/cart/types';


export const getCartFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/cart/', {
        method: HTTP_METHODS.GET,
        options: {
            providedAccessToken, validateCookies: true,
        },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};

type CUSTOM_RESPONSE = CART_TYPE

export const getCart = async (token?: string): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await getCartFetch(token);
        let body: API_CART_TYPE = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>(
                {
                    ...body,
                    cart_items: mapCartProducts(body.cart_items),
                    promo_code: mapPromocode(body.promo_code),
                    num_items: addIndividualQuantities(body.cart_items)
                }
            )
            : FAILURE_RESPONSE('GET_CART', body)
    } catch (error) {
        return FAILURE_RESPONSE('GET_CART', error)
    }
};

export default getCart;