
import { Routes, Route } from 'react-router-dom';
import { availableLanguages } from '@translation';


// IMPORT PAGES
import Home from '@pages/Home';
import Orders from '@pages/Orders';
import Cart from '@pages/Cart';
import About from '@pages/About';
import Payment from '@pages/Payment';
import Login from '@pages/Auth/Login';
import SignUp from '@pages/Auth/SignUp';

import Checkout from '@pages/Checkout';
import NotFound from '@pages/Notfound';
import Listing from '@pages/Listing/Listing';

import OrderDetails from '@pages/OrderDetails';
import ResetPassword from '@pages/ResetPassword';
import ForgotPassword from '@pages/Auth/ForgotPassword';

import ProductsDetails from '@pages/ProductDetails';
import UserProfile from '@pages/UserProfile/UserProfile';
import { PaymentRedirect } from '@pages/Payment/components';


import Protected, { GuestProtected } from './protected';
import { PrivacyPolicyPage } from '@pages/PrivacyPolicy/PrivacyPolicy';


export enum RouterPages {
    Home,
    Cart,
    About,
    Login,
    SignUp,
    Checkout,
    OrdersPage,
    PaymentPage,
    ListingPage,
    ResetPassword,
    ForgotPassword,
    UserProfilePage,
    PaymentRedirect,
    OrderDetailsPage,
    ProductDetailsPage,
    ListingCategoryPage,
    PrivacyPolicyPage,
}

export type RoutesInfoType = {
    [K in RouterPages]: {
        title: string,
        path: string,
        description?: string,
    };
}

export interface InternalRouteType {
    element: JSX.Element;
    routerPage: RouterPages;
}

export const routesInfo: RoutesInfoType = {
    [RouterPages.Home]: {
        title: 'Home',
        path: '',
        description: 'The first page users see when they enter the site',
    },
    [RouterPages.PrivacyPolicyPage]: {
        title: 'Privacy Policy',
        path: 'privacy-policy',
        description: 'Privacy Policy',
    },
    [RouterPages.About]: {
        title: 'About',
        path: 'about',
        description: 'About Page',
    },
    [RouterPages.Login]: {
        title: 'Login',
        path: 'auth/login',
        description: 'Login Page',
    },
    [RouterPages.SignUp]: {
        title: 'SignUp',
        path: 'auth/signup',
        description: 'SignUp Page',
    },
    [RouterPages.ForgotPassword]: {
        title: 'Forgot Password',
        path: 'auth/password/forgot',
        description: 'Forgot Password Page',
    },
    [RouterPages.ResetPassword]: {
        title: 'ResetPassword',
        path: 'auth/password/reset/confirm/:uidb64/:token',
        description: 'Reset password page',
    },
    [RouterPages.ListingPage]: {
        title: 'Listing',
        path: 'listing/',
        description: 'All products from a specific category',
    },
    [RouterPages.ListingCategoryPage]: {
        title: 'Listing',
        path: 'listing/:category/',
        description: 'All products from a specific category',
    },
    [RouterPages.ProductDetailsPage]: {
        title: 'Product Details',
        path: 'product/:root_category_slug/:slug',
        description: 'Details of a specific products',
    },
    [RouterPages.UserProfilePage]: {
        title: 'User Profile',
        path: 'user-profile',
        description: 'show user their details, order history, saved cards, and saved location',
    },
    [RouterPages.Cart]: {
        title: 'cart',
        path: 'cart',
        description: 'show user the items in their cart',
    },
    [RouterPages.Checkout]: {
        title: 'checkout',
        path: 'checkout',
        description: 'user views items and checks out',
    },
    [RouterPages.OrdersPage]: {
        title: 'Your Orders',
        path: 'orders',
        description: 'show the users their active orders and past orders',
    },
    [RouterPages.OrderDetailsPage]: {
        title: 'Order Details',
        path: 'order-details/:id',
        description: 'show the users their order information',
    },
    [RouterPages.PaymentRedirect]: {
        title: 'Payment Redirect',
        path: 'payment/payment-redirect/:redirect_identifier/',
        description: 'payment redirect callback for hosted checkout payments',
    },
    [RouterPages.PaymentPage]: {
        title: 'Order Details',
        path: 'payment/status/',
        description: 'show the users their order information',
    }
};

const languagePrefixes = ['', ...availableLanguages];  // Default empty prefix and language prefixes

const languagePrefixedGuestRoutes = (
    languagePrefixes.map((prefix) => (
        <Route key={`${prefix}_guest_protected`} path={prefix} element={<GuestProtected />}>
            <Route path={routesInfo[RouterPages.Login].path} element={<Login />} />
            <Route path={routesInfo[RouterPages.SignUp].path} element={<SignUp />} />
            <Route path={routesInfo[RouterPages.ForgotPassword].path} element={<ForgotPassword />} />
        </Route>
    ))
);

const languagePrefixedProtectedRoutes = (
    languagePrefixes.map((prefix) => (
        <Route key={`${prefix}_protected`} path={prefix} element={<Protected />}>
            <Route path={routesInfo[RouterPages.Checkout].path} element={<Checkout />} />
            <Route path={routesInfo[RouterPages.OrdersPage].path} element={<Orders />} />
            <Route path={routesInfo[RouterPages.PaymentPage].path} element={<Payment />} />
            <Route path={routesInfo[RouterPages.UserProfilePage].path} element={<UserProfile />} />
        </Route>
    ))
);

const languagePrefixedPublicRoutes = (
    languagePrefixes.map((prefix) => (
        <Route key={`${prefix}_public`} path={prefix}>
            <Route path={routesInfo[RouterPages.Cart].path} element={<Cart />} />
            <Route path={routesInfo[RouterPages.Home].path} element={<Home />} />
            <Route path={routesInfo[RouterPages.PrivacyPolicyPage].path} element={<PrivacyPolicyPage />} />
            {/* <Route path={routesInfo[RouterPages.About].path} element={<About />} /> */}
            {/* todo: fix below path*/}
            {/* <Route path={routesInfo[RouterPages.ResetPassword].path} element={<ResetPassword />} /> */}
            {/* <Route path={routesInfo[RouterPages.About].path} element={<About />} /> */}
            <Route path={routesInfo[RouterPages.ListingPage].path} element={<Listing />} />
            <Route path={routesInfo[RouterPages.ListingCategoryPage].path} element={<Listing />} />
            <Route path={routesInfo[RouterPages.PaymentRedirect].path} element={<PaymentRedirect />} />
            <Route path={routesInfo[RouterPages.ProductDetailsPage].path} element={<ProductsDetails />} />
        </Route >
    ))
);

const SiteRoutes = () => (
    <Routes>
        {languagePrefixedPublicRoutes}
        {languagePrefixedProtectedRoutes}
        {languagePrefixedGuestRoutes}
        <Route path="*" element={<NotFound />} />
    </Routes >
);

export default SiteRoutes;