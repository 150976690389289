import React from 'react';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HOOKS
import { useTranslation } from 'react-i18next';

// IMPORT COMPONENTS
import { Checkbox, FormControlLabel } from '@mui/material';


export const RememberMe = ({
    setRememberMe,
    color,
}: {
    color?: string;
    setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '70%',
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        defaultChecked
                        onClick={() => setRememberMe((state) => !state)}
                        style={{ color: color || COLORS.GREEN }}
                    />
                }
                label={<div {...{ style: { color: color || COLORS.GREEN, fontWeight: 'bold' } }}>{t('auth.RememberMe')}</div>}
            />
        </div>
    );
};
