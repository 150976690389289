import { ChangeEvent, useEffect, useState } from 'react';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT TYPES
import { RootState } from '@redux/store';

// IMPORT HELPERS
import { getCartItems } from '@api/helpers';

// IMPORT HOOKS
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormSubmission, useIsScreenBiggerThan } from '@project/hooks';


// IMPORT COMPONENTS

import {
    CartSubTitle,
    CartMainTitle,
    DrawerContainer,
    CartItemsContainer,
    CartTitleContainer,
    DrawerContentWrapper,

    GoToCartPage,
} from './CartElements';
import i18next from 'i18next';
import Divider from '@mui/material/Divider';
import { RouterPages } from '@project/routes';
import { CartCard, Summary } from '@custom_components';
import {
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import {
    getOverRequestedItems,
    OVER_REQUESTED_ITEM_TYPE,
} from '@api/user/cart/helpers';
import OverRequestedItems from './components/OverRequestedItems';


interface CartProps {
    isCartOpen: boolean;
    toggleCart: () => void;
}

const Cart = ({ isCartOpen, toggleCart }: CartProps) => {
    const {
        promo_code_info,
        cart_items: cartItems,
        total_cart_price: cartTotalBD } = useSelector((store: RootState) => store.cart);
    const cartQuantity = cartItems.reduce((acc, p) => acc + p.requested_quantity, 0)

    const minAmountReached = !promo_code_info || (promo_code_info && (
        (promo_code_info.minimum_required_amount || 0) <= cartTotalBD))

    useEffect(() => {
        getCartItems();
    }, []);

    const { t } = useTranslation();
    const isBig = useIsScreenBiggerThan(800);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [overRequests, setOverRequests] = useState<OVER_REQUESTED_ITEM_TYPE[]>([]);

    const onCloseDialog = () => {
        setOpenDialog(false)
    }

    const onToggleDialog = () => {
        setOpenDialog(prev => !prev)
    }

    const checkCartValidity = async (): Promise<RESPONSE_TYPE<boolean>> => {
        const overRequestedItems = await getOverRequestedItems();

        if (overRequestedItems.length) {
            onToggleDialog();
            setOverRequests(overRequestedItems);
            return FAILURE_RESPONSE('CART_VALIDITY', false);
        }

        toggleCart();
        return SUCCESS_RESPONSE(true);
    }

    return (
        <DrawerContainer {...{
            open: isCartOpen,
            onClose: toggleCart,
            size: isBig ? 500 : 340,
            direction: i18next.dir() === 'ltr' ? 'right' : 'left',
        }}>
            <DrawerContentWrapper>
                <CartTitleContainer>
                    <CartMainTitle>{t('cartOverlay.yourCart')} <span style={{
                        fontWeight: 'normal',
                        fontSize: '1.2rem'
                    }}>({cartQuantity})</span></CartMainTitle>
                    <CartSubTitle>{t('cartOverlay.subTitle')}</CartSubTitle>
                    <GoToCartPage
                        {...{
                            onClick: toggleCart,
                            page: RouterPages.Cart,
                            style: {
                                opacity: 1,
                                marginRight: 25,
                                color: 'green',
                            },
                        }}
                    >
                        {t('cartOverlay.goToCartPage')}
                    </GoToCartPage>
                </CartTitleContainer>
                <Divider />
                {!minAmountReached &&
                    <h4 style={{ color: COLORS.ORDER_STATUS.CANCELLED, textAlign: 'center', margin: 10 }}>
                        {t('cart.minimumRequiredAmountText')} {promo_code_info?.minimum_required_amount || 0}
                    </h4>}
                <CartItemsContainer>
                    {
                        Boolean(cartItems) && cartQuantity > 0 ? (
                            <>
                                {cartItems.map((cart_item, product_index) => (
                                    <CartCard {...{
                                        cart_item,
                                        product_index,
                                        close_cart_modal: toggleCart,
                                        key: cart_item.cart_item_id,
                                    }} overlay />
                                ))}
                            </>
                        )
                            :
                            <h3 style={{
                                color: COLORS.DARKER_GREY,
                                position: 'absolute',
                                top: -15,
                                left: 10,
                            }}>{t("cartOverlay.yourCartIsEmpty")}</h3>
                    }
                </CartItemsContainer>
                <Divider />
                <Summary {...{ onCheckoutPress: checkCartValidity }} checkout promocode />
                <OverRequestedItems {...{ openDialog, onCloseDialog, items: overRequests }} />
            </DrawerContentWrapper>
        </DrawerContainer>
    );
};

export default Cart;
