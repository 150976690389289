import {
    API_CUSTOM_CARD_TYPE,
    API_PRODUCT_TYPE,
    APP_STATE_CUSTOM_CARD_TYPE,
} from '../../types';

enum PROMO_CODE_TYPE {
    Absolute,
    Percentage,
    FreeDelivery,
    ComboPercentage,
    ComboAbsolute,
    FreeDeliveryPercentage,
    FreeDeliveryAbsolute,
    Unknown,
}

export interface CUSTOM_CARD_IMAGE_TYPE {
    image: string;
    image_width: number;
    image_height: number;
}

export interface CUSTOM_CARD_RESPONSE_TYPE {
    id: string;
    title: string;
    description: string;
    design: CUSTOM_CARD_IMAGE_TYPE;
}

export type CUSTOM_CARDS_TYPE = CUSTOM_CARD_RESPONSE_TYPE[]


interface API_PROMO_CODE_INFO {
    type: string;
    code: string;
    minimum_required_amount?: number;
    absolute_discount_amount?: number;
    percentage_decrease_amount?: number;
    combo_absolute_discount_amount?: number;
    combo_percentage_decrease_amount?: number;
}

interface APP_STATE_PROMO_CODE_INFO {
    code: string;
    type: PROMO_CODE_TYPE;
    minimum_required_amount?: number;
    absolute_discount_amount?: number;
    percentage_decrease_amount?: number;
    combo_absolute_discount_amount?: number;
    combo_percentage_decrease_amount?: number;
}

interface API_CART_PRODUCT_TYPE {
    cart_item_id: string;
    product_sku: string;
    actual_price: number;
    requested_quantity: number;
    price_entered_at: number;
    available_quantity: number;
    product: API_PRODUCT_TYPE;
    custom_card?: API_CUSTOM_CARD_TYPE;
}

interface APP_STATE_CART_PRODUCT_TYPE {
    variant_name: string;
    cart_item_id: string;
    product_sku: string;
    actual_price: number;
    requested_quantity: number;
    variant_image: string;
    price_entered_at: number;
    available_quantity: number;
    product: API_PRODUCT_TYPE;
    custom_card?: APP_STATE_CUSTOM_CARD_TYPE;
}

interface CART_TYPE {
    cart_items: APP_STATE_CART_PRODUCT_TYPE[];
    num_items: number;
    total_cart_price: number;
    promo_code?: APP_STATE_PROMO_CODE_INFO;
    delivery_cost_for_single_order: number;
    total_cart_price_after_discount: number;
    total_payable_due_for_single_order: number;
    delivery_cost_for_single_order_after_discount: number;
}

export const emptyCartType: CART_TYPE = {
    cart_items: [],
    num_items: 0,
    total_cart_price: 0,
    delivery_cost_for_single_order: 0,
    total_cart_price_after_discount: 0,
    total_payable_due_for_single_order: 0,
    delivery_cost_for_single_order_after_discount: 0,
};

interface API_CART_TYPE {
    cart_items: API_CART_PRODUCT_TYPE[];
    total_cart_price: number;
    promo_code?: API_PROMO_CODE_INFO;
    delivery_cost_for_single_order: number;
    total_cart_price_after_discount: number;
    total_payable_due_for_single_order: number;
    delivery_cost_for_single_order_after_discount: number;
}

interface cartItemType {
    id: cartItemIdType;
    price: number;
    name: string;
    subheader: string;
    display_name: string;
    image: string;
    type: string;
    quantity: number;
}

type cartItemIdType = string;

interface guestCartItemType {
    id: string;
    quantity: number;
}

export { PROMO_CODE_TYPE };
export type {
    cartItemType,
    cartItemIdType,
    guestCartItemType,
    CART_TYPE,
    API_CART_TYPE,
    API_CART_PRODUCT_TYPE,
    APP_STATE_CART_PRODUCT_TYPE,
    API_PROMO_CODE_INFO,
    APP_STATE_PROMO_CODE_INFO,
};

export interface LISTED_CART_PRODUCT_TYPE {
    product_longform_name: string;
    random_associated_number: number;
    product_information: APP_STATE_CART_PRODUCT_TYPE
}

export interface REPOTTED_POTS_TYPE {
    [plant_index: number]: number | undefined
}