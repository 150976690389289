import { useEffect, useState } from 'react';

// IMPORT COMPONENTS
import Tabs from './Tabs/Tabs';
import TopBanner from './components/TopBanner/TopBanner';
import ProfileImageAndButtons from './components/PorfileImageAndButtons/ProfileImageAndButtons';

//IMPORT STYLED COMPONENTS
import { MainContainer } from './UserProfileElements';

// IMPORT TYPES
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const UserProfile = () => {
    const { t } = useTranslation();

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        goToTop();
        // getUserDetails();
    }, []);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openImageSelector, setOpenImageSelector] = useState<boolean>(false);
    const onClickChangeImage = () => {
        setOpenImageSelector(true);
        setSelectedTab(0);
        goToImage();
    };
    const goToImage = () => {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        });
    };
    return (
        <MainContainer>
            <Helmet><title>{t('userProfile.metaTitle')}</title></Helmet>
            <TopBanner />
            <ProfileImageAndButtons {...{ onClickChangeImage }} />
            <Tabs
                {...{
                    selectedTab,
                    setSelectedTab,
                    openImageSelector,
                    setOpenImageSelector,
                }}
            />
        </MainContainer>
    );
};

export default UserProfile;
