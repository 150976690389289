import { ENVIRONMENT } from "@project/config";

export function add_to_cart_gtag_report_conversion() {
  ENVIRONMENT === 'PRODUCTION' &&
  gtag('event', 'conversion', {
    'send_to': 'AW-11065172238/6f7GCI2agY0YEI7CpJwp'
  });
}


export function begin_checkout_gtag_report_conversion() {
  ENVIRONMENT === 'PRODUCTION' &&
  gtag('event', 'conversion', { 'send_to': 'AW-11065172238/rYP2CJaagY0YEI7CpJwp' });
}

export function purchase_gtag_report_conversion(transaction_id, amount_in_riyals) {
  ENVIRONMENT === 'PRODUCTION' &&
  gtag('event', 'conversion', {
    'send_to': 'AW-11065172238/_GOxCJOagY0YEI7CpJwp',
    'value': +((amount_in_riyals / 3.64).toFixed(2)), // converts to int with 2 decimal points
    'currency': 'USD',
    'transaction_id': transaction_id
  });
}


export function sign_up_gtag_report_conversion() {
  ENVIRONMENT === 'PRODUCTION' &&
  gtag('event', 'conversion', { 'send_to': 'AW-11065172238/zj20CJCagY0YEI7CpJwp' });
}

