export * from './configuration';

import Cookies from 'js-cookie'
import i18next from 'i18next';
import { availableLanguages, defaultLang, i18nCookieName } from './configuration';
import { AvailableLanguagesType } from '@project/types';


// todo: remove if not used
export const isLangInPath = (path: string) => {
    const splitURL = path.split("/")
    if (splitURL.length > 1) {
        for (const lang of availableLanguages) {
            if (splitURL[1] === lang) {
                return true;
            }
        }
    }
    return false;
}

export const getPathWithLang = (path: string) => {
    const splitURL = path.split("/")

    // if language code exists in path, it should be in the 1st index
    if (splitURL.length > 1) {
        for (const lang of availableLanguages) {
            if (splitURL[1] === lang) {
                return path;
            }
        }
    }

    const currentLanguage = getSiteLanguage();

    // don't prepend lang prefix if lang is default
    if (currentLanguage === defaultLang) {
        return path;
    }


    // otherwise, insert language code in 0th index
    splitURL[0] = currentLanguage;
    return '/' + splitURL.join("/");
}


export const changeSiteLanguage = (language: AvailableLanguagesType) => {
    const { pathname, origin, search } = window.location
    let currentURL = pathname + search  // Gets the current full URL

    if (currentURL[0] == '/') {
        currentURL = currentURL.slice(1);
    }

    Cookies.remove(i18nCookieName);
    Cookies.set(i18nCookieName, language);

    const splitURL = currentURL.split("/")
    for (const lang of availableLanguages) {
        // If language code is in URL, replace the language code with the new one
        if (splitURL.length > 0 && splitURL[0] === lang) {
            splitURL[0] = language;
            window.location.replace(`${origin}/${splitURL.join("/")}`);
            return;
        }
    }

    // Otherwise, add the language code to the beginning of the URL
    window.location.replace(`${origin}/${language}/${splitURL.join("/")}`);
};


export const getSiteLanguage = () => {
    return i18next.resolvedLanguage || defaultLang;
};
