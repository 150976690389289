import { memo } from 'react';
import { useSelector } from 'react-redux';

// IMPORT HOOKS
import { useIsScreenBiggerThan } from '../../../../common/hooks';

// IMPORT STYLED COMPONENTS
import {
    TopBannerContainer,
    ImageBanner,
    BannerTitleContainer,
    BannerTitleText,
    ImageBannerMobile,
} from './TopBannerElements';
import { getSiteLanguage } from '@translation';
import { useTranslation } from 'react-i18next';


const TopBanner = memo(() => {
    const { t } = useTranslation();

    const isBig = useIsScreenBiggerThan(800);
    const currentLanguage = getSiteLanguage();
    return (
        <TopBannerContainer>
            {isBig ? (
                <ImageBanner
                    style={{
                        transform:
                            currentLanguage === 'ar' ? 'scaleX(-1)' : 'none',
                    }}
                />
            ) : (
                <ImageBannerMobile
                    style={{
                        transform:
                            currentLanguage === 'ar' ? 'scaleX(-1)' : 'none',
                    }}
                />
            )}
            <BannerTitleContainer>
                <BannerTitleText>{t("userProfile.accountDetails")}</BannerTitleText>
            </BannerTitleContainer>
        </TopBannerContainer>
    );
});

export default TopBanner;
