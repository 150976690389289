
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

// IMPORT COMPONENTS
import {
    SectionTitle,
    GiftCardContainer,
    SectionSubInformation,
} from './AdditionalElements';
import { TextInput, TextInputOneField, TextInputSingleField, TextInputTwoFields } from '@form_components';

// IMPORT HELPERS
import * as DOMPurify from 'dompurify';
import { GIFT_CARD_TYPE } from '..';
import { useTranslation } from 'react-i18next';

interface GiftCardProps {
    setGiftCardInformation: (card: GIFT_CARD_TYPE) => void;
}

const GiftCard = ({ setGiftCardInformation }: GiftCardProps) => {
    const { t } = useTranslation();
    const [sender, setSender] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [recipient, setRecipient] = useState<string>('');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, setStateFunction: Dispatch<SetStateAction<string>>) => {
        setStateFunction(DOMPurify.sanitize(e.target.value))
    }

    useEffect(() => {
        if (sender && message && recipient) {
            setGiftCardInformation({ sender, message, recipient })
        }
    }, [sender, message, recipient])

    return (
        <GiftCardContainer>
            <SectionTitle>
                {t('gift.title')}
            </SectionTitle>
            <SectionSubInformation>
                {t('gift.subtext')}
            </SectionSubInformation>
            <TextInputTwoFields>
                <TextInput {...{
                    value: sender,
                    placeholder: t('gift.from'),
                    onChange: (e: any) => handleInputChange(e, setSender)
                }} />
                <TextInput {...{
                    value: recipient,
                    placeholder: t('gift.to'),
                    onChange: (e: any) => handleInputChange(e, setRecipient)
                }} />
            </TextInputTwoFields>
            <TextInputOneField>
                <TextInput {...{
                    value: sender,
                    placeholder: t('gift.from'),
                    onChange: (e: any) => handleInputChange(e, setSender)
                }} />
            </TextInputOneField>
            <TextInputOneField>
                <TextInput {...{
                    value: recipient,
                    placeholder: t('gift.to'),
                    onChange: (e: any) => handleInputChange(e, setRecipient)
                }} />
            </TextInputOneField>
            <TextInputSingleField>
                <TextInput {...{
                    rows: 8,
                    value: message,
                    style: { width: '100%' },
                    placeholder: t('gift.writeMessageHere'),
                    onChange: (e: any) => handleInputChange(e, setMessage)
                }} multiline />
            </TextInputSingleField>
        </GiftCardContainer>
    )
}

export default GiftCard;