import { useState, useEffect } from 'react';

// MODULE IMPORTS
import {
    Slide,
    Checkbox,
    TextField,
    Typography,
    createTheme,
    FormControlLabel,
} from '@mui/material';
import i18next from 'i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT API HELPERS
import signIn, { SIGN_IN_RESPONSE } from '@api/auth/signin';

// IMPORT STORE HELPERS
import { signIn as signInStore } from '@redux/state/auth';

// IMPORT HOOKS
import {
    useFormSubmission,
} from '@project/hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//CUSTOM COMPONENTS
import { RouterPages } from '@project/routes';
import { useNavigate } from 'react-router-dom';
import { OrDivider } from '../components/OrDivider';
import { SocialMediaLogin } from '../components/SocialMediaLogin';
import { LoadingButton, ResponsiveText } from '@custom_components';
import { ChangePasswordContainer } from '../AuthElements';
import { RememberMe } from '../components/RememberMe';
import { getPathWithLang } from '@translation';


const styles = {
    divider: {
        width: '70%',
        marginTop: '5%',
        marginBottom: '5%',
    },
    textInput: {
        width: '70%',
    },
    forgotPassword: {
        marginLeft: 240,
        color: 'grey',
        marginTop: '3%',
    },
    loginButtonsAndTextInput: {
        display: 'flex',
        height: '90%',
        alignItems: 'center',
    },
    forgotPasswordButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '70%',
        border: 0,
        backgroundColor: 'white',
    },
    rememberMeDiv: {
        width: '70%',
        display: 'flex',
        justifyContent: 'flex-start',
    },
};

interface LoginProps {
    nextPath: string | null;
}

let theme = createTheme({
    typography: {
        htmlFontSize: 10,
    },
});

theme.typography.h5 = {
    fontSize: '1rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
};

export const LogInForm = ({ nextPath }: LoginProps) => {
    //HOOKS ==
    const {
        textInput,
        forgotPasswordButton,
        loginButtonsAndTextInput,
    } = styles;

    const [{ username, password }, setLogin] = useState({
        username: '',
        password: '',
    });

    const { t } = useTranslation();
    const [rememberMe, setRememberMe] = useState(true);
    const [logInState, setLogInState] = useFormSubmission<SIGN_IN_RESPONSE>(() => signIn(username, password));

    const { response, success, error } = logInState;

    // HELPERS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSubmit = async () => {
        setLogInState((state) => ({
            ...state,
            submitted: true,
        }));
    };

    const onPressEnter = (e: any) => {
        if (e.keyCode == 13 && Boolean(username) && Boolean(password)) {
            onSubmit();
        }
    }

    useEffect(() => {
        setError('')
    }, [])

    useEffect(() => {
        if (response.status && response.response_body) {
            dispatch(signInStore(response.response_body.key));
            navigate(getPathWithLang(nextPath ? nextPath : '/'), { replace: true });
        }
    }, [response]);

    const setError = (error_msg: string) => {
        setLogInState(prev => ({
            ...prev, error: error_msg
        }))
    }

    return (
        <Slide {...{
            in: true,
            exit: true,
            enter: false,
            direction: 'left',
            unmountOnExit: true
        }}>
            <div style={{
                display: 'flex',
                width: '100%',
                paddingBottom: 40,
                flexDirection: 'column',
            }}>
                <ResponsiveText props={{
                    variant: 'h3',
                    marginLeft: '17%',
                    marginRight: '17%',
                    marginTop: '5%',
                    marginBottom: '1%',
                    fontWeight: '900',
                }}>
                    {t('auth.logIn')}
                </ResponsiveText>
                <ResponsiveText props={{
                    variant: 'h5',
                    marginLeft: '17%',
                    marginRight: '17%',
                }}>
                    {t('auth.enterCredentials')}
                </ResponsiveText>

                <div style={{
                    ...loginButtonsAndTextInput,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <SocialMediaLogin {...{ nextPath, setError }} />
                    <OrDivider />
                    <TextField
                        {...{
                            id: 'emailInput',
                            label: t('common.email'),
                            style: textInput,
                            variant: 'outlined',
                            onChange: (e) =>
                                setLogin((state) => ({
                                    ...state,
                                    username: e.target.value,
                                })),
                            onKeyDown: onPressEnter,
                        }}
                    />
                    <ChangePasswordContainer
                        page={RouterPages.ForgotPassword}
                        pageSearchParams={nextPath ? { next: nextPath } : undefined}
                        style={forgotPasswordButton}>
                        <Typography
                            style={{
                                color: COLORS.AUTH.LOGIN.PRIMARY,
                                marginTop: '5%',
                                marginBottom: 5,
                                fontWeight: 'bolder',
                            }}
                        >
                            {t('auth.forgotPassword')}
                        </Typography>
                    </ChangePasswordContainer>

                    <TextField
                        {...{
                            label: t('common.password'),
                            type: 'password',
                            variant: 'outlined',
                            dir: i18next.dir(),
                            style: { width: '70%' },
                            onChange: (e) =>
                                setLogin((state) => ({
                                    ...state,
                                    password: e.target.value,
                                })),
                            onKeyDown: onPressEnter,
                        }}
                    />
                    <RememberMe {...{ setRememberMe, color: COLORS.AUTH.LOGIN.PRIMARY }} />
                    {!success && (
                        <Typography style={{
                            width: '70%',
                            margin: error && '10px',
                            color: COLORS.RED,
                            textAlign: 'center',
                        }}>
                            {error}
                        </Typography>
                    )}
                    <LoadingButton
                        {...{
                            disabled: false,
                            onClick: onSubmit,
                            buttonState: logInState,
                            buttonTitle: t('auth.logIn'),
                            colorScheme: COLORS.AUTH.LOGIN,
                            style: { fontWeight: 'bolder' },
                        }}
                    />
                </div>
            </div>
        </Slide>
    );
};
