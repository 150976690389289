import {
    HTTP_REQUEST,
    HTTP_METHODS,
    RequestError,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import { API_USER_TYPE } from './types';

export const userFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/', {
        method: HTTP_METHODS.GET,
        options: { providedAccessToken },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Get User Information');
    }
};


export const updateUserFetch = async (data: Partial<API_USER_TYPE>) => {
    const HttpRequest = new HTTP_REQUEST('user/', {
        method: HTTP_METHODS.PATCH,
        options: { data },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Get User Information');
    }
};
export const userAvatarFetch = async (providedAccessToken?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/avatar/?dont-redirect=true', {
        method: HTTP_METHODS.GET,
        options: { providedAccessToken },
    });
    try {
        const Response = await HttpRequest.callFetch();
        const url = Response.url;
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Get User AVATAR');
    }
};


const getUserInformation = async (token?: string) => {
    try {
        const response = await userFetch(token);
        const body = await response.json();

        if (response.ok) {
            return SUCCESS_RESPONSE<API_USER_TYPE>(body);
        } else {
            return FAILURE_RESPONSE('GET_USER', body);
        }
    } catch (error) {
        return FAILURE_RESPONSE('GET_USER', error);
    }
};

export const updateUserInformation = async (data: Partial<API_USER_TYPE>) => {
    try {
        const response = await updateUserFetch(data);
        const body = await response.json();

        if (response.ok) {
            return SUCCESS_RESPONSE<API_USER_TYPE>(body);
        } else {
            return FAILURE_RESPONSE('GET_USER', body);
        }
    } catch (error) {
        return FAILURE_RESPONSE('GET_USER', error);
    }
};

export const getUserAvatar = async (token?: string) => {
    try {
        const response = await userAvatarFetch(token);
        const body = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<any>(body);
        } else {
            return FAILURE_RESPONSE('GET_USER_AVATAR', body);
        }
    } catch (error) {
        return FAILURE_RESPONSE('GET_USER_AVATAR', error);
    }
};

export default getUserInformation;

