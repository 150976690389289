// IMPORT STYLED COMPONENTS
import {
    ButtonWrapper,
    CategoryTitle,
    CategoryTitleWrapper,
    HighlightedItemsContainer,
    HorizontalScrollDisplayWrapper,
    SectionIconImage,
    TitleRow,
    AllProductsButton
} from './HighlightedSectionElements';

//IMPORT TYPES
import {
    API_PRODUCT_TYPE,
} from '@api/types';
import { IconType } from 'react-icons/lib';

//IMPORT COMPONENTS
import { RouterPages } from '@project/routes';
import { Swipeable } from '@custom_components';
import ProductCard from '@home_components/Common/ProductCard/ProductCard';
import { COLORS } from '@project/styles';
import { useIsScreenBiggerThan } from '@project/hooks';


interface HighlightedSectionType {
    title: string;
    buttonText: string;
    boldedTitle?: string;
    sectionIcon: IconType;
    data: API_PRODUCT_TYPE[];
    buttonCategorySlug?: string;
}

interface RenderItemProps {
    item: API_PRODUCT_TYPE
}

const HighlightedSection = ({
    title,
    data,
    buttonText,
    boldedTitle,
    sectionIcon,
    buttonCategorySlug
}: HighlightedSectionType) => {

    let buttonArgs;

    if (buttonCategorySlug) {
        buttonArgs = {
            page: RouterPages.ListingCategoryPage,
            pagePathArgs: { category: buttonCategorySlug }
        }
    } else {
        buttonArgs = {
            page: RouterPages.ListingPage
        }
    }

    const render_item = ({ item }: RenderItemProps) => (<ProductCard {...{ product: item, itemId: item.product_id, openAdd: () => { }, isExpanded: false }} />)

    const _500 = useIsScreenBiggerThan(500);
    const _1000 = useIsScreenBiggerThan(1000);
    const _1400 = useIsScreenBiggerThan(1400);

    const slidesPerView = _1400 ? 4 : (_1000 ? 3 : (_500 ? 2 : 1));
    const slidesPerGroup = slidesPerView;
    return (
        <HighlightedItemsContainer>
            <TitleRow>
                <CategoryTitleWrapper>
                    <SectionIconImage>
                        {sectionIcon({ fill: COLORS.ORANGE, fontSize: '2.3rem' })}
                    </SectionIconImage>
                    <CategoryTitle>
                        {title}
                        {
                            boldedTitle &&
                            <span style={{ fontWeight: '800' }}>
                                {' ' + boldedTitle}
                            </span>
                        }
                    </CategoryTitle>
                </CategoryTitleWrapper>
                <ButtonWrapper>
                    <AllProductsButton {...buttonArgs}>{buttonText}</AllProductsButton>
                </ButtonWrapper >
            </TitleRow >
            <HorizontalScrollDisplayWrapper>
                {
                    data && data.length > 0 && (
                        <Swipeable {...{
                            data,
                            render_item,
                            slide_key: 'product_id',
                            additional_swiper_props: { slidesPerView, slidesPerGroup, }
                        }} />
                    )
                }
            </HorizontalScrollDisplayWrapper>
        </HighlightedItemsContainer >
    );
};

export default HighlightedSection;
