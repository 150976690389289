import { EffectCallback, useEffect, useState, Dispatch, SetStateAction, useRef } from 'react';

import {
    getWindowDimensions,
    testPasswordForWeakness,
} from './helpers';

// IMPORT TYPES
import { FormSubmissionType } from './types';
import { PrivateCoordinates } from '@custom_components';

// IMPORT HELPERS
import { defaultLatLng } from './default';
import { matchPath, useLocation } from 'react-router-dom';
import { HTTP_REQUEST, RESPONSE_TYPE } from '@api/interactions';
import { useImmer } from 'use-immer';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { AuthenticationState } from '@redux/state/auth';



// ===========================================================================================
/**
 * Fetches data based on if hidden input is added in html
 */
export function useDataGetter<Type>(
    request: HTTP_REQUEST,
    useDocument: boolean = false,
    initialState?: Type
): [
        state: Type,
        loading: boolean,
        setRequestState: React.Dispatch<React.SetStateAction<HTTP_REQUEST>>
    ] {

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<any>(initialState);

    const [requestState, setRequestState] = useState(request);

    let dataFromDocument: string | null = (() => {
        if (!useDocument) return null;

        const dataDocumentElement = document?.getElementById('response_data');
        if (dataDocumentElement) {
            return (dataDocumentElement as HTMLInputElement).value;
        }
        return '';
    })();

    useEffect(() => {
        async function fetchItem() {
            if (!dataFromDocument) {
                try {
                    setLoading(true);
                    const Response = await requestState.callFetch();
                    const data = await Response.json();
                    setState(data);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            } else {
                const response_data = Object.values(
                    JSON.parse(dataFromDocument).api_data
                );
                setState(response_data[0]);
            }
        }
        fetchItem();
    }, [requestState]);
    return [state, loading, setRequestState];
}

// Local storage ========================================================================================================
function getLocalStorageValue(key: string, defaultValue: string) {
    const saved = localStorage.getItem(key);
    const initial = saved && JSON.parse(saved);
    return initial || defaultValue;
}

export const useLocalStorage = (key: string, defaultValue: any) => {
    const [value, setValue] = useState(() => {
        return getLocalStorageValue(key, defaultValue);
    });
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
};

// session storage ========================================================================================================
function getSessionStorageValue(key: string, defaultValue: string) {
    const saved = sessionStorage.getItem(key);
    const initial = saved && JSON.parse(saved);
    return initial || defaultValue;
}
export const useSessionStorage = (key: string, defaultValue: any) => {
    const [value, setValue] = useState(() => {
        return getSessionStorageValue(key, defaultValue);
    });
    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
};

//  =========================================================================================================================
export const useIsScreenBiggerThan = (max: number) => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const isBig = window.innerWidth > max;
    return isBig;
};

// validate email  ========================================================================================================

export const useEmailValidator = () => {
    const [email, setEmail] = useState('');
    const [emailerror, setEmailError] = useState<null | string>(null);
    useEffect(() => {
        const isValid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
        if (!isValid) {
            setEmailError('Please enter a valid Email');
        } else {
            setEmailError(null);
        }
    }, [email]);
    return { emailerror, email, setEmail };
};

// validate password  ========================================================================================================
export const useNewPasswordValidator = () => {
    const [{ password, confirmPassword }, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });
    const [confirmPasswordError, setConfirmPasswordError] = useState<
        null | string
    >(null);
    const [passwordError, setPasswordError] = useState<null | string>(null);
    useEffect(() => {
        const passwordWeakness = testPasswordForWeakness(password);
        setPasswordError(passwordWeakness);
        setConfirmPasswordError(
            password !== confirmPassword
                ? 'Confirm password is not matched'
                : ''
        );
    }, [password, confirmPassword]);
    return {
        passwordError,
        confirmPasswordError,
        password,
        confirmPassword,
        setPassword,
    };
};

/**
 * a hook that takes a requester, and returns an array that has both an object and a way to modify the form state.
 * To submit the form do: setFormState(state=> ({...state, submitted:true}))
 * @param requester Whatever async function you would like to call when a user submits eg: signIn(email,password)
 */


export const initialFormState = {
    loading: false,
    submitted: false,
    success: null,
    error: '',
    response: {
        status: false,
        response_body: null,
        error: { error_message: '' },
    },
}

export function useFormSubmission<T>(
    requester: () => Promise<RESPONSE_TYPE<T>>
): [
        FormSubmissionType<T>,
        Dispatch<SetStateAction<FormSubmissionType<T>>>
    ] {
    const [formState, setFormState] = useState<FormSubmissionType<T>>(initialFormState);
    useEffect(() => {
        if (formState.submitted) {
            setFormState((state) => ({
                ...state,
            }));
            onSubmit();
        }
    }, [formState.submitted]);

    const onSubmit = async () => {
        try {
            setFormState((state) => ({
                ...state,
                loading: true,
            }));
            const response = await requester();
            if (response.status) {
                setFormState((state) => ({
                    ...state,
                    loading: false,
                    success: true,
                    response,
                }));
            } else {
                setFormState((state) => ({
                    ...state,
                    loading: false,
                    success: false,
                    submitted: false,
                    error: response.error?.error_message || '',
                }));
            }
        } catch (error) {
            setFormState((state) => ({
                ...state,
                loading: false,
                success: false,
            }));
        }
    };
    return [formState, setFormState];
};

// useGeoLocation  ========================================================================================================

interface LocationState {
    loaded: boolean;
    coordinates?: PrivateCoordinates;
    error?: Partial<GeolocationPositionError>;
}

export const useGeoLocation = () => {
    const [location, setLocation] = useImmer<LocationState>({
        loaded: false,
    });

    const onSuccess = (location: GeolocationPosition) => {
        setLocation(draft => {
            draft.loaded = true;
            draft.coordinates = location.coords;
        });
    };

    const onError = (error: Partial<GeolocationPositionError>) => {
        setLocation(draft => {
            draft.loaded = true;
            draft.error = error;
        });
    };

    useEffect(() => {
        if (typeof navigator.geolocation === undefined) {
            setLocation((draft) => {
                draft.loaded = true;
                draft.error = {
                    code: 0,
                    message: 'Geolocation not supported',
                };
            });
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);
    return location;
};


// Route Matching Hook

export function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}


export const useAnimationTrigger = (control: boolean, delayMS: number = 750) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    useEffect(() => { setTimeout(() => setTrigger(control), delayMS) }, [control])
    return trigger;
}

export const useDidMount = (effect: EffectCallback) => useEffect(effect, [])

interface DATA_TYPE<T> {
    hostname: string;
    user: number | null;
    api_data: T | null;
}

export function useSessionProtected<T>() {
    let userInfo: number | null = (() => {
        const dataDocumentElement = document?.getElementById('response_data');
        if (dataDocumentElement) {
            const valueJSON: DATA_TYPE<T> = JSON.parse((dataDocumentElement as HTMLInputElement).value)
            return valueJSON.user;
        }
        return null;
    })();

    const [state, _] = useState<boolean>(userInfo !== null || userInfo !== undefined);
    return state;
}

// useFirstRender  ========================================================================================================

export function useFirstRender() {
    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = false;
    }, []);

    return firstRender.current;
}

export const useAuthProtected = () => {
    const { signed_in } = useSelector<RootState>(state => state.auth) as AuthenticationState
    const [state, _] = useState<boolean>(Boolean(signed_in));
    return state;
}

interface paymentRedirectStateType {
    order_id: string | null,
    hosted_checkout_identifier: string | null,
}

export const usePaymentRedirect = () => {
    const [state, setState] = useState<paymentRedirectStateType>({
        order_id: null,
        hosted_checkout_identifier: null,
    })

    let pageData = document?.getElementById('response_data_input') as HTMLInputElement | null;
    useEffect(() => {
        let paymentDetails: any = {
            order_id: null,
            hosted_checkout_identifier: null
        };
        if (pageData) {
            if (paymentDetails = {
                ...paymentDetails,
                ...(JSON.parse((pageData as any)['data-response-data']))
            }) {
                setState(paymentDetails)
            }
        }
    }, [pageData])

    return state
}
