import { RootState } from '@redux/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PhoneInput } from '../TabsElements';

const PhoneNumberInput = ({
    name,
    onBlur,
    isValid,
    isSubmitted,
    saveNumber,
    empty,
}: {
    name?: string;
    empty?: boolean;
    isValid: boolean;
    isSubmitted?: boolean;
    onBlur?: (phone_number: string) => void;
    saveNumber: (mobile_number: string) => void;
}) => {
    const { t } = useTranslation();
    const { otp_phone_number } = useSelector((state: RootState) => state.user.details)
    const [phone, setPhone] = useState<string>((otp_phone_number && !empty) ? otp_phone_number.replaceAll('+', '') : '');

    useEffect(() => {
        saveNumber('+' + phone);
    }, [phone]);

    const [focused, setFocused] = useState(false);
    return (
        <PhoneInput {...{
            country: 'qa',
            onChange: setPhone,
            value: phone || '',
            specialLabel: name,
            inputStyle: { width: '100%' },
            onFocus: () => setFocused(true),
            placeholder: t("userProfile.enterPhoneNumber"),
            onBlur: onBlur ? () => onBlur('+' + phone) : () => { },
            isValid: isSubmitted ? isValid : focused ? isValid : true,
        }} />
    );
};

export default PhoneNumberInput;
