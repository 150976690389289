import { memo } from 'react';

// IMPORT TYPES
import { RootState } from '@redux/store';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HOOKS
import { useIsScreenBiggerThan } from '@project/hooks';
import { useDispatch, useSelector } from 'react-redux';


// IMPORT STYLED COMPONENTS
import {
    ProfileImageAndButtonsContainer,
    ImageAndName,
    ProfileImageWrapper,
    UserNameWrapper,
    UserNameText,
    UserEmail,
    ButtonsContainer,
    LogOutButtonMobile,
    ProfileImage,
    CameraIcon,
    UserFacelessIconLarge,
    FacelessIconWrapper,
} from './ProfileImageAndButtonsElements';
import { CommonButton, CommonButtonText } from '@styled_components';

// IMPORT HELPERS
import { HOSTNAME } from 'config';
import signUserOut from '@api/auth/signout';
import { signOut } from '@redux/state/auth';
import { clearUser } from '@redux/state/user';
import { clearCart } from '@redux/state/cart';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileImageAndButtons = memo(
    ({ onClickChangeImage }: { onClickChangeImage: () => void }) => {
        const isBig = useIsScreenBiggerThan(800);
        const userData = useSelector(
            (store: RootState) => store.user.details
        );
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { t } = useTranslation();


        const handleSignOut = async () => {
            const response = await signUserOut();
            if (response.status) {
                dispatch(signOut());
                dispatch(clearUser());
                dispatch(clearCart());
                setTimeout(() => navigate('/'), 250);
            }
        };

        return (
            <ProfileImageAndButtonsContainer>
                <ImageAndName>
                    {
                        Boolean(userData.avatar) && (!(userData.avatar as string).includes(HOSTNAME))
                            ?
                            <ProfileImageWrapper {...{ onClick: onClickChangeImage }}>
                                <ProfileImage {...{ src: userData.avatar as string }} />
                                <CameraIcon />
                            </ProfileImageWrapper>
                            :
                            <FacelessIconWrapper {...{ onClick: onClickChangeImage }}>
                                <UserFacelessIconLarge />
                                <CameraIcon />
                            </FacelessIconWrapper>
                    }
                    <UserNameWrapper>
                        {userData.first_name ? (
                            <>
                                <UserNameText>
                                    {userData.first_name} {userData.last_name}
                                </UserNameText>
                                <UserEmail>{userData.email}</UserEmail>
                            </>
                        ) : userData.email ? (
                            <UserNameText>{userData.email}</UserNameText>
                        ) : (
                            <UserNameText>{t("userProfile.unamedUser")}</UserNameText>
                        )}
                    </UserNameWrapper>
                </ImageAndName>
                <ButtonsContainer>
                    {isBig ? (
                        <CommonButton {...{
                            onClick: handleSignOut,
                            color: COLORS.RED,
                            outlined: false,
                            style: {
                                width: 'auto',
                                height: 50,
                                padding: '0 20px',
                                margin: '0 5px',
                            }
                        }}>
                            <CommonButtonText {...{
                                outlined: false,
                                color: COLORS.RED,
                                style: {
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                }
                            }}>
                                {t("common.logout")}
                            </CommonButtonText>
                        </CommonButton>
                    ) : (
                        <LogOutButtonMobile {...{ onClick: handleSignOut }} />
                    )}
                </ButtonsContainer>
            </ProfileImageAndButtonsContainer>
        );
    }
);

export default ProfileImageAndButtons;
