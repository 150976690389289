import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';

// Import Reducers
import cartSliceReducer from '@redux/state/cart';
import userSliceReducer from '@redux/state/user';
import authSliceReducer from '@redux/state/auth';
import uploadSliceReducer from '@redux/state/upload';
import cookieSliceReducer from '@redux/state/cookie';
import checkoutSliceReducer from '@redux/state/checkout';
import settingsSliceReducer from '@redux/state/settings';
import categorySliceReducer from '@redux/state/categories';


const authConfig = {
    key: 'auth',
    version: 1,
    storage,
};

const persistedAuthReducer = persistReducer(authConfig, authSliceReducer);

export default combineReducers({
    cart: cartSliceReducer,
    user: userSliceReducer,
    auth: persistedAuthReducer,
    upload: uploadSliceReducer,
    cookie: cookieSliceReducer,
    checkout: checkoutSliceReducer,
    settings: settingsSliceReducer,
    categories: categorySliceReducer,
});
