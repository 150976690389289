// MODULE IMPORTS
import { makeStyles } from '@mui/styles';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';

// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HOOKS
import {
    useEmailValidator,
    useFormSubmission,
} from '@project/hooks';
import { useTranslation } from 'react-i18next';

// IMPORT API HELPERS
import resetPassword from '@api/auth/password/reset';

// IMPORT CUSTOM COMPONENTS
import { LoadingButton, ResponsiveText } from '@custom_components';
import { Slide, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getPathWithLang } from '@translation';


interface ForgotPasswordProps {
    nextPath: string | null;
}
const useHelperTextStyles = makeStyles(() => ({
    root: {
        color: COLORS.RED,
    },
}));

export const ForgotPasswordForm = ({ nextPath }: ForgotPasswordProps) => {
    const { t } = useTranslation();
    const helperTextStyles = useHelperTextStyles();
    const [showEmailError, setShowEmailError] = useState(false);
    const { emailerror, email, setEmail } = useEmailValidator();
    const [forgotPasswordState, setForgotPasswordState] = useFormSubmission<null>(
        () => resetPassword(email)
    );
    const { success, submitted } = forgotPasswordState;
    const delay = async (ms: number) =>
        new Promise((res) => setTimeout(res, ms));

    const navigate = useNavigate();
    const redirectUser = async () => {
        if (success && submitted) {
            await delay(1000);
            navigate(getPathWithLang(`/auth/login${nextPath ? `/?next=${nextPath}` : ''}`))
        }
    };

    useEffect(() => {
        redirectUser();
    }, [success, submitted]);

    const onSubmit = () => {
        setForgotPasswordState((state) => ({ ...state, submitted: true }));
    };

    const onPressEnter = (e: any) => {
        if (e.keyCode == 13 && Boolean(email)) {
            onSubmit();
        }
    }

    return (
        <Slide
            {...{
                in: true,
                exit: true,
                enter: false,
                direction: 'left',
                unmountOnExit: true,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    paddingBottom: 10,
                    height: 'fit-content',
                    flexDirection: 'column',
                }}
            >
                <ResponsiveText props={{
                    variant: 'h3',
                    marginLeft: '17%',
                    marginRight: '17%',
                    marginTop: '5%',
                    marginBottom: '1%',
                    fontWeight: '900',
                }}>
                    {t('auth.forgotPasswordTitle')}
                </ResponsiveText>
                <ResponsiveText props={{
                    variant: 'h5',
                    marginLeft: '17%',
                    marginRight: '17%',
                }}>
                    {t('auth.pleaseEnterEmail')}
                </ResponsiveText>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90%',
                    alignItems: 'center',
                }}
                >
                    <TextField
                        {...{
                            id: 'emailInput',
                            label: t('common.email'),
                            type: 'email',
                            onBlur: () => Boolean(email) && setShowEmailError(true),
                            style: {
                                width: '70%',
                                marginTop: '5%',
                                marginBottom: '5%',
                            },
                            variant: 'outlined',
                            helperText: showEmailError && emailerror,
                            FormHelperTextProps: {
                                classes: {
                                    root: helperTextStyles.root,
                                },
                            },
                            onChange: (e) => setEmail(e.target.value),
                            onKeyDown: onPressEnter,
                        }}
                    />

                    {!forgotPasswordState.success && (
                        <Typography
                            style={{ color: COLORS.RED, margin: 0 }}
                        >
                            {forgotPasswordState.error}
                        </Typography>
                    )}
                    {forgotPasswordState.success && (
                        <Typography
                            style={{ color: COLORS.AUTH.FORGOT_PASSWORD.PRIMARY, marginTop: '1%' }}
                        >
                            {t('auth.emailSentToYourAccount')}
                        </Typography>
                    )}
                    <LoadingButton
                        {...{
                            disabled: false,
                            onClick: onSubmit,
                            buttonState: forgotPasswordState,
                            buttonTitle: t('auth.resetPassword'),
                            colorScheme: COLORS.AUTH.FORGOT_PASSWORD,
                        }}
                    />
                </div>
            </div>
        </Slide >
    );
};
