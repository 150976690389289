//IMPORT STYLED COMPONENTS
import { COLORS } from '@project/styles';
import {
    MainWrapper,

    BannerHeading,
    BannerDescription,
    BannerTextWrapper,
    BannerInformationWrapper,

    GiftImg,
    FoundedInQatarImg,
    SameDayDeliveryImg,

    InformationBannerContainer,
    BannerImgContainer,

} from './InformationBannerElements';
import { ReactComponent as Gift } from '@pages/Home/assets/gift.svg';
import { ReactComponent as Truck } from '@pages/Home/assets/truck.svg';
import { ReactComponent as Qatar } from '@pages/Home/assets/qatar.svg';
import { useTranslation } from 'react-i18next';

interface InformationBannerProps {
    dark_mode?: boolean;
}

const InformationBanner = ({ dark_mode }: InformationBannerProps) => {
    const { t } = useTranslation();

    return (
        <MainWrapper>
            <InformationBannerContainer {...{ dark_mode }}>
                <BannerInformationWrapper>
                    <BannerImgContainer>
                        <Gift {...{ fill: dark_mode ? COLORS.DARK_GREEN : '#14b72d', width: '50%', height: '50%' }} />
                    </BannerImgContainer>
                    <BannerTextWrapper>
                        <BannerHeading>{t('home.infoBannerTitle1')}</BannerHeading>
                        <BannerDescription>
                            {t('home.infoBannerBody1')}
                        </BannerDescription>
                    </BannerTextWrapper>
                </BannerInformationWrapper>

                <BannerInformationWrapper>
                    <BannerImgContainer>
                        <Qatar {...{ fill: dark_mode ? COLORS.DARK_GREEN : '#14b72d', width: '60%', height: '60%' }} />
                    </BannerImgContainer>
                    <BannerTextWrapper>
                        <BannerHeading>{t('home.infoBannerTitle2')}</BannerHeading>
                        <BannerDescription>
                            {t('home.infoBannerBody2')}
                        </BannerDescription>
                    </BannerTextWrapper>
                </BannerInformationWrapper>

                <BannerInformationWrapper>
                    <BannerImgContainer>
                        <Truck {...{ fill: dark_mode ? COLORS.DARK_GREEN : '#14b72d', width: '60%', height: '60%' }} />
                    </BannerImgContainer>
                    <BannerTextWrapper>
                        <BannerHeading>{t('home.infoBannerTitle3')}</BannerHeading>
                        <BannerDescription>
                            {t('home.infoBannerBody3')}
                        </BannerDescription>
                    </BannerTextWrapper>
                </BannerInformationWrapper>
            </InformationBannerContainer>
        </MainWrapper>
    );
};

export default InformationBanner;