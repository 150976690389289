export interface API_BASE_VARIANT_TYPE {
  sku: string;
  price: number;
  product: string;
  quantity: number;
  updated_at?: string;
  is_in_sale: boolean;
  display_name: string;
  order_number: number;
  non_sale_price: number | null;
}

export interface API_PLANT_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "PlantSKU";

  // Unit Properties
  height_unit: string;
  volume_unit: string;
  weight_unit: string;
  diameter_unit: string;

  // Displayable Properties
  height: number;
  color: string | null;
  layers: number | null;
  volume: number | null;
  weight: number | null;
  diameter: number | null;

  is_hanging: boolean;
  pieces: number | null;
  stick_flowers: number | null;

  // Additional Properties
  color_code: string | null;
}


export type POT_FINISH = 'Glossy' | 'Matte';
export type POT_SHAPE = 'Round' | 'Rectangular' | 'Square';

export interface API_POT_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "PotSKU";

  // Unit Properties
  width_unit: string;
  height_unit: string;
  length_unit: string;
  diameter_unit: string;

  // Displayable Properties
  color: string;
  is_hanging: boolean;
  finish: POT_FINISH;
  pot_dimensions: string | null;

  // Additional Properties
  height: number;
  width: number | null;
  length: number | null;
  diameter: number | null;
  color_code: string | null;
  pot_shape: POT_SHAPE;
}

export interface API_BOUQUET_FLOWER_TYPE {
  quantity: number;
  display_name: string;
  flower: {
    icon: string | null;
    name: string;
    primary_color: string;
    tertiary_color: string | null;
    secondary_color: string | null;
    primary_color_code: string | null;
    tertiary_color_code: string | null;
    secondary_color_code: string | null;
  };
}

export interface API_FLOWER_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "FlowerBouquetSKU";
  flowers: API_BOUQUET_FLOWER_TYPE[];
  arrangement: string | null;
  arrangement_type: string | null;
}

export interface API_SOIL_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "SoilSKU";

  // Unit Properties
  volume_unit: string;
  weight_unit: string;

  // Displayable Properties
  volume: string | null;
  weight: string | null;
}

export interface API_TOOL_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "ToolSKU";
  model: string | null;
}

export interface API_EQUIPMENT_VARIANT_TYPE extends API_BASE_VARIANT_TYPE {
  product_sku_type: "EquipmentSKU";
}

export interface API_NORMAL_VARIANT_TYPE extends API_BASE_VARIANT_TYPE { }

export type API_PRODUCT_VARIANT_TYPE =
  | API_POT_VARIANT_TYPE
  | API_TOOL_VARIANT_TYPE
  | API_SOIL_VARIANT_TYPE
  | API_PLANT_VARIANT_TYPE
  | API_FLOWER_VARIANT_TYPE
  | API_NORMAL_VARIANT_TYPE
  | API_EQUIPMENT_VARIANT_TYPE;

export interface API_VARIANT_UNITS_TYPE {
  [k: string]: string;
}


export interface API_VARIANT_NON_HIERARCHY_KEYS extends API_BASE_VARIANT_TYPE {
  // Plant Non Hierarchy Properties
  height_unit: string;
  volume_unit: string;
  weight_unit: string;
  diameter_unit: string;

  // Pot Non Hierarchy Properties
  width_unit: string;
  length_unit: string;

  product_sku_type: string;

  // Flower Non Hierarchy Properties
  flowers: API_BOUQUET_FLOWER_TYPE[];
  arrangement: string | null;
  arrangement_type: string | null;

}


export const API_PLANT_INFORMATION_KEYS: (keyof API_PLANT_VARIANT_TYPE)[] = [
  "height",
  "color",
  "layers",
  "pieces",
  "volume",
  "weight",
  "diameter",
  "is_hanging",
  "stick_flowers",
];

export const API_POT_INFORMATION_KEYS: (keyof API_POT_VARIANT_TYPE)[] = [
  "width",
  "color",
  "finish",
  "height",
  "length",
  "diameter",
  "pot_shape",
  "is_hanging",
  "pot_dimensions",
];

export const API_FLOWER_BOUQUET_INFORMATION_KEYS: (keyof API_FLOWER_VARIANT_TYPE)[] = ["arrangement"];

export const API_TOOL_INFORAMTION_KEYS: (keyof API_TOOL_VARIANT_TYPE)[] = ["model"];

export const API_SOIL_INFORMATION_KEYS: (keyof API_SOIL_VARIANT_TYPE)[] = ["volume", "weight"];

export type API_VARIANT_HIERARCHY_KEY =
  | (keyof Omit<API_POT_VARIANT_TYPE, keyof API_VARIANT_NON_HIERARCHY_KEYS>)
  | (keyof Omit<API_TOOL_VARIANT_TYPE, keyof API_VARIANT_NON_HIERARCHY_KEYS>)
  | (keyof Omit<API_SOIL_VARIANT_TYPE, keyof API_VARIANT_NON_HIERARCHY_KEYS>)
  | (keyof Omit<API_PLANT_VARIANT_TYPE, keyof API_VARIANT_NON_HIERARCHY_KEYS>)
  | (keyof Omit<API_FLOWER_VARIANT_TYPE, keyof API_VARIANT_NON_HIERARCHY_KEYS>)