import {
    AuthTop,
    AuthLeft,
    AuthMain,
    AuthBottom,
    AuthNavLogo,
    AuthContainer,
    AuthFormsContainer,
    AuthMainCatchPhrase,
    GreenitNavContainer,
    GreenitNavLogoContainer,
    AuthNavLogoArabic,
} from '../AuthElements';
import TutorialCards from '../components/TutorialCards';
import { SignUpForm } from '@pages/Auth/SignUp/SignUpForm';
import { BaseButton, ResponsiveText } from '@custom_components';


// IMPORT STYLES
import { COLORS } from '@project/styles';

// IMPORT HELPERS
import { RouterPages } from '@project/routes';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';


const SignUp = () => {
    const direction = i18next.dir();
    const [params, _] = useSearchParams();
    const nextPath = params.get('next');
    const { t } = useTranslation();

    return (
        <AuthContainer>
            <Helmet><title>{t('auth.signupMetaTitle')}</title></Helmet>
            <AuthLeft {...{ color: COLORS.AUTH.SIGNUP.SECONDARY }}>
                <AuthTop>
                    <AuthMainCatchPhrase {...{ color: COLORS.AUTH.SIGNUP.TERTIARY }}>
                        {t("auth.tutorialCatchphrase")}
                    </AuthMainCatchPhrase>
                </AuthTop>
                <AuthBottom>
                    <TutorialCards {...{ colors: COLORS.AUTH.SIGNUP }} />
                </AuthBottom>
            </AuthLeft>
            <AuthMain>
                <GreenitNavContainer {...{
                    page: RouterPages.Login,
                    pageSearchParams: nextPath ? { next: nextPath } : undefined,
                }}>
                    <BaseButton
                        style={{
                            marginTop: '5%',
                            marginBottom: '5%',
                            cursor: 'pointer',
                        }}
                        onClick={() => { }}
                    >
                        <ResponsiveText>
                            {t('auth.alreadyHaveAccount')}{' '}
                            <span style={{ color: COLORS.AUTH.SIGNUP.PRIMARY, fontWeight: 'bold' }}>
                                {t('auth.logIn')}
                            </span>
                        </ResponsiveText>
                    </BaseButton>
                </GreenitNavContainer>
                <GreenitNavLogoContainer>
                    {
                        direction === 'ltr' ? < AuthNavLogo  {...{ page: RouterPages.Home }} />
                            : <AuthNavLogoArabic  {...{ page: RouterPages.Home }} />
                    }
                </GreenitNavLogoContainer>
                <AuthFormsContainer>
                    <SignUpForm {...{ nextPath }} />
                </AuthFormsContainer>
            </AuthMain>
        </AuthContainer>
    );
};

export default SignUp;
