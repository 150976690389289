import { RouterPages } from '@project/routes';
import { scrollToTop } from '@project/helpers';
import { Link as BaseLink, LinkProps as BaseLinkProps } from 'react-router-dom';
import { getLangPathFromPage, PagePathArgsType, SearchParamsType } from './helpers';

interface LinkProps extends Omit<BaseLinkProps, 'to'> {
    page: RouterPages;
    onClick?: () => void;
    pagePathArgs?: PagePathArgsType;
    pageSearchParams?: SearchParamsType;
}


export const Link = (props: LinkProps): JSX.Element => {
    const { page, pagePathArgs, pageSearchParams, onClick, children, ...restProps } = props;
    const pathWithLang = getLangPathFromPage(page, pagePathArgs, pageSearchParams);

    return (
        <BaseLink {...{
            to: pathWithLang,
            onClick: () => { (onClick || (() => { }))(); scrollToTop(); },
            ...restProps,
        }}>
            {children}
        </BaseLink>
    )
}
