import styled from 'styled-components';

//IMPORT STYLES
import { COLORS } from '@project/styles';

//IMPORT ICONS
import { Link } from '@project/routes/components';
import cross from '@assets/images/cart/cross.png';


// CARD CONTAINER
export const MainContainer = styled.div`
    display: flex;
    height: 150px;
    color: inherit;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        height: 175px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
`;


export const DiscountBadge = styled.div`
    display: flex;
    position: absolute;
    top: 22px;
    right: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.75;
    border-radius: 10px;
    background-color: red;

    align-items: center;
    flex-direction: column;

    justify-content: center;
    transform: rotate(0deg);
    
    @media screen and (max-width: 768px) {
        top: 40px;
        right: 20px;
        width: 35px;
        height: 35px;
    }
`

export const DiscountText = styled.div`
    color: white;
    margin: 0;
    padding: 0;
    
    font-size: .9rem;
    position: absolute;
    top: 4px;
    @media screen and (max-width: 768px) {
        top: 3px;
        font-size: .7rem;
    }
`

export const DiscountText2 = styled.div`
    display: flex;
    color: white;
    position: absolute;
    bottom: 4px;
    margin: 0;
    padding: 0px;
    font-size: .6rem;
    @media screen and (max-width: 768px) {
        font-size: .5rem;
        bottom: 3px;
    }
`


export const PreviousText = styled.h2`
    margin: 0;
    padding: 0;
    color: lightgray;
    font-size: .6rem;

    @media screen and (max-width: 768px) {
        font-size: .5rem;
    }
`

export const ImageAndDescription = styled(Link)`
    display: flex;
    height: 100%;
    color: inherit;
    position: relative;
    align-items: center;
    text-decoration: none;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
        padding-right: 10%;
    }
`;

export const ContentWrapper = styled.div`
    width: 95%;
    height: 90%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
`;


export const ItemImage = styled.img.attrs({})`
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @media screen and (max-width: 500px) {
        height: 75%;
        width: 75%;
    }
`;

export const ImageContainer = styled.div`
    width: 45%;
    max-height: 95%;
    min-height: 95%;
    border-radius: 5px;
    padding: 10px 10px;
    overflow: hidden;
    background-color: ${COLORS.WHITE};
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        width: 40%;
        padding: 5px;
    }
`

// DESCRIPTION
export const ItemDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 5%;

    @media screen and (max-width: 768px) {
        margin: 0%;
    }
`;
export const ItemName = styled.h1`
    font-weight: normal;
    font-size: 24px;
    margin: 0;
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
`;
export const ItemSubName = styled.p`
    margin: 0;
    opacity: 0.6;
    @media screen and (max-width: 800px) {
        font-size: 15px;
    }
`;
export const ItemPrice = styled.h4`
    margin: 0;
`;

// QUANTITY
export const ItemQuantityContainer = styled.div`
    display: flex;
    max-width: 100px;
    min-width: 100px;
    flex-direction: row;
    align-self: flex-end;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 768px) {
        position: absolute;
        height: 30px;
        right: 2%;
        bottom: calc(10% + 12px);
    }
`;

export const ItemQuantity = styled.div`
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    display: flex;
    margin: 0px 2px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: solid 1px ${COLORS.GREY};
    background-color: ${COLORS.WHITE};
`;

export const Plus = styled.div`
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: solid 1px ${COLORS.GREY};
    background-color: ${COLORS.WHITE};
`;

export const Minus = styled.div`
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: solid 1px ${COLORS.GREY};
    background-color: ${COLORS.WHITE};
`;

export const PlusMinusText = styled.h1`
    margin: 0;
    font-size: 24px;
    font-weight: normal;
`;

// DELETE ITEM
interface RemoveItem {
    isRemovedClicked: boolean;
}
export const Remove = styled.div<RemoveItem>`
    z-index: 9;
    background: #fff;
    background-image: ${(props) =>
        props.isRemovedClicked ? 'none%' : `url(${cross})`};
    background-size: contain;
    background-repeat: no-repeat;
    width: ${(props) => (props.isRemovedClicked ? '25%' : '30px')};
    height: ${(props) =>
        props.isRemovedClicked ? 'calc(100% - 20px)' : '30px'};

    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 20px;
    transition: all 0.5s, border-radius 0.5s, top 0.5s;
    overflow: hidden;
    @media screen and (max-width: 800px) {
        width: ${(props) => (props.isRemovedClicked ? '32%' : '30px')};
        height: ${(props) =>
        props.isRemovedClicked ? 'calc(100% - 20px)' : '30px'};
    }
`;
export const RemoveConfirm = styled.div<RemoveItem>`
    ${(props) =>
        props.isRemovedClicked
            ? `
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-between;
        height: 90%;
    `
            : `display:none`}
`;
export const RemoveConfirmTitle = styled.h3`
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    margin: 0;
    margin-top: 15px;
`;
