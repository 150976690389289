import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';

import {
    LatLng,
    SERVICE_AREA_TYPE,
    API_POLYGON_COORDINATES,
} from '@api/user/address/types';


const getServiceAreaFetch = async (providedAccessToken?: string) => {
    try {
        const HttpRequest = new HTTP_REQUEST('settings/get-order-map-polygon-coordinates/', {
            method: HTTP_METHODS.GET,
            options: {
                validateCookies: true,
                providedAccessToken,
            },
        });

        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('SERVICE AREA FETCH FAILED!')
    }
};

type CUSTOM_RESPONSE = SERVICE_AREA_TYPE

const getServiceArea = async (auth_token?: string): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await getServiceAreaFetch(auth_token);
        let body = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>(convertToLatLng(body))
            : FAILURE_RESPONSE('GET_SERVICE_AREA', body)
    } catch (error) {
        return FAILURE_RESPONSE('GET_SERVICE_AREA', error)
    }
}


const stringToCoords = (coord: string): LatLng => {
    var coordArray = coord.split(',')
    return {
        latitude: Number(coordArray[1]),
        longitude: Number(coordArray[0])
    }
}


const convertToLatLng = (response: API_POLYGON_COORDINATES): CUSTOM_RESPONSE => {
    const polygonList = response.polygon_coordinates

    return polygonList.map(stringToCoords)
}


export default getServiceArea;