import React, { useEffect, useState } from 'react';

// IMPORT MACROS AND STYLES
import { GetDataFromDOM } from '@custom_components';
import { BIG_CONTENT_CONTAINER_HEIGHT, COLORS } from '@project/styles';

import { getOrderStatus } from '@api/checkout/helpers';

// IMPORT TYPES
import { API_ORDER_DETAIL_TYPE, APP_STATE_ORDER_TYPE } from '@api/types';

// IMPORT HOOKS
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';


interface PaymentRedirectProps {
    api_data: InjectedPaymentRedirectProps | null
}
interface InjectedPaymentRedirectProps {
    order: API_ORDER_DETAIL_TYPE | null;
    hosted_checkout_identifier: string | null;
}

interface PaymentRedirectState {
    checkout_type: 'hosted';
    payment_identifier: string | null;
    order_information: APP_STATE_ORDER_TYPE | null;
}

const mapOrder = (order: API_ORDER_DETAIL_TYPE): APP_STATE_ORDER_TYPE => {
    return { ...order, status: getOrderStatus(order.status) }
}

const PaymentRedirecting = ({ api_data }: PaymentRedirectProps) => {
    const [state, setState] = useImmer<PaymentRedirectState>({
        checkout_type: 'hosted',
        order_information: null,
        payment_identifier: null,
    })

    const navigate = useNavigate();

    useEffect(() => {
        if (api_data) {
            const { order, hosted_checkout_identifier } = api_data;
            setState(draft => {
                draft.payment_identifier = hosted_checkout_identifier;
                draft.order_information = order === null ? null : mapOrder(order);
            })
        }
    }, [])

    useEffect(() => {
        if (state.payment_identifier && state.order_information) {
            navigate(`/payment/status/?order=${state.order_information.order_id}`, { state, replace: true })
        }
    }, [state])

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: COLORS.WHITE,
            height: `${BIG_CONTENT_CONTAINER_HEIGHT}vh`,
        }} />
    )
}

export default GetDataFromDOM<InjectedPaymentRedirectProps>(PaymentRedirecting)