import { Tabs } from '@mui/material';
import { COLORS } from '@project/styles';
import styled from 'styled-components';


export const FilterInfoContainer = styled.div`
    display: flex;
    max-width: 350px;
    min-width: 350px;
    height: 100%;
    position: relative;
    flex-direction: column;
`

export const TitleText = styled.div`
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
    color: #111111;
`

export const TitleRowInfo = styled.div`
    width: 100%;
    height: 80px;
    padding: 0% 5%;
    display: flex;
    overflow: visible;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const CategoryPickerContainer = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0px 2px;
    flex-direction: column;
    border-top: solid 1px ${COLORS.LIGHTER_GREY};
    
`

export const Categories = styled.div`
    display: flex;

`

export const StyledMaterialTabs = styled(Tabs)`
    width: 100%;
    height: 100%;
`

export const CategoryBubble = styled.div<{ is_selected: boolean }>`
    display: flex;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    padding: 2px;
    background-color: ${COLORS.WHITE};
    border-radius: 1000px;
    border: solid 2px ${props => props.is_selected ? COLORS.DARK_GREEN : COLORS.LIGHTER_GREY};
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
`

export const CategoryImg = styled.img`
    min-height: 40px;
    max-height: 40px;
    object-fit: contain;

`