import styled from 'styled-components';

interface AddNewAddressesContainerProps {
    isOpen: boolean;
}
export const AddNewAddressesContainer = styled.div<AddNewAddressesContainerProps>`
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.isOpen ? `auto` : `0px`)};
    overflow: hidden;
    margin: 20px 0px;
    transition: all 5s ease;
    @media screen and (max-width: 800px) {
        height: ${(props) => (props.isOpen ? `1700px` : `0px`)};
    }
`;
