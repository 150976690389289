import { Fragment, useEffect, useMemo, useState } from 'react';



// IMPORT HOOKS
import { useFirstRender } from '@project/hooks';
import { useParams, useLocation } from 'react-router-dom';

// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';
import { HTTP_METHODS, HTTP_REQUEST } from '@api/interactions';

import {
    NoProductDetails,
    ProductMainContainer,
    HighlightedItemsWrapper,
    ProductDetailsContainer,
} from './ProductDetailsElements';

import {
    Slash,
    TabTitle,
    TabContainer,
    TabContainerLink,
    NavigationBarContainer,
} from './Top/TopElements';
import Top from './Top/Top';
import { GiFlowerPot } from 'react-icons/gi';
import { RouterPages } from '@project/routes';
import { GetDataFromDOM, Loading } from '@custom_components';
import HighlightedSection from '@home_components/HighlightedSection/HighlightedSection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


async function getProductRequest(requestData: any, onSuccessCallback: any, onFailureCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('product/', {
        method: HTTP_METHODS.GET,
        options: { data: requestData, validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => onSuccessCallback(response))
        .catch(err => { onFailureCallback() })
}

async function getSimilarProductRequest(requestData: any, onSuccessCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('product/similar-recommendations/', {
        method: HTTP_METHODS.GET,
        options: { data: requestData, validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => onSuccessCallback(response))
        .catch(err => console.error(err))
}

async function getComplementaryProductRequest(requestData: any, onSuccessCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('product/complementary-recommendations/', {
        method: HTTP_METHODS.GET,
        options: { data: requestData, validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => onSuccessCallback(response))
        .catch(err => console.error(err))
}

interface ProductDetailProps {
    api_data: InjectedProductProps | null
}
interface InjectedProductProps {
    product: API_PRODUCT_TYPE | null
}

interface LocationState { productInfo: API_PRODUCT_TYPE | null }

const ProductsDetails = ({ api_data }: ProductDetailProps) => {
    const { t } = useTranslation();

    const { root_category_slug, slug } = useParams();

    const { state } = useLocation();
    const isFirstRender = useFirstRender();
    const [loading, setLoading] = useState(false);

    const [similarProducts, setSimilarProducts] = useState<API_PRODUCT_TYPE[]>([]);
    const [productDetails, setProductDetails] = useState<API_PRODUCT_TYPE | null>(null);
    const [complementaryProducts, setComplementaryProducts] = useState<API_PRODUCT_TYPE[]>([]);

    const productSuccessCallback = (response: any) => {
        setProductDetails(response);
        setLoading(false);
    }

    const productFailureCallback = () => {
        setLoading(false);
    }

    useEffect(() => {
        let productsSet = false;
        let productDetails: API_PRODUCT_TYPE | null | undefined = api_data?.product;

        // Either get the product info from the DOM or from the previous page state
        if (isFirstRender && productDetails) {
            setProductDetails(productDetails);
            productsSet = true;
        }
        if (state) {
            let productDetailsLinkState;
            if (productDetailsLinkState = (state as LocationState).productInfo) {
                setProductDetails(productDetailsLinkState);
                productsSet = true;
            }
        }

        if (!productsSet) {
            setLoading(true);
            getProductRequest(
                { slug: slug },
                productSuccessCallback,
                productFailureCallback
            )
        }

        getSimilarProductRequest({ slug }, setSimilarProducts);
        getComplementaryProductRequest({ slug, num: 4 }, setComplementaryProducts);
    }, [slug])

    interface NAVIGATION_TAB_TYPE {
        label: string;
        listings_parameters?: {
            page: RouterPages;
            pagePathArgs: any;
        };
    }

    const navigationTabs = useMemo(productDetails ? () => {
        const { slug: category, name: root_label } = productDetails?.root_category;
        let tabs: NAVIGATION_TAB_TYPE[] = [{
            label: root_label,
            listings_parameters: {
                pagePathArgs: { category },
                page: RouterPages.ListingCategoryPage,
            },
        }];

        if (productDetails?.sub_categories.length) {
            tabs = [...tabs, { label: productDetails?.sub_categories[0].name }]
        };

        return tabs.concat([{ label: productDetails?.shortened_display_name || productDetails?.name, }])
    } : () => [], [productDetails])

    return (
        <ProductMainContainer>
            {
                productDetails &&
                <Helmet>
                    <title>{t('product.metaTitle', { 'title': productDetails.web_meta_title ? productDetails.web_meta_title : productDetails.name })}</title>
                    <meta name="description" content={productDetails.web_meta_description ? productDetails.web_meta_description : productDetails.description} />
                </Helmet>
            }
            {
                loading ? <Loading />
                    :
                    <>
                        {
                            Boolean(navigationTabs.length) &&
                            <NavigationBarContainer>
                                {
                                    navigationTabs.map(({ label, listings_parameters }, index) => (
                                        <Fragment {...{ key: label }}>
                                            {
                                                !!(index) && <Slash>/</Slash>
                                            }
                                            {
                                                listings_parameters ?
                                                    <TabContainerLink {...listings_parameters}>
                                                        <TabTitle {...{ color: 'green' }}>{label}</TabTitle>
                                                    </TabContainerLink>
                                                    :
                                                    <TabContainer key={label}>
                                                        <TabTitle {...{ color: '#444' }}>{label}</TabTitle>
                                                    </TabContainer>
                                            }
                                        </Fragment>
                                    ))
                                }
                            </NavigationBarContainer>
                        }
                        {
                            productDetails !== null && !loading ? (
                                <ProductDetailsContainer>
                                    <Top {...{ product: productDetails, complementaryProducts }} />
                                    <HighlightedItemsWrapper>
                                        {similarProducts && Boolean(similarProducts.length) && <HighlightedSection
                                            {...{
                                                title: t("productDetail.youMight"),
                                                data: similarProducts,
                                                boldedTitle: t("productDetail.alsoLike"),
                                                buttonText: t("common.viewMore"),
                                                sectionIcon: GiFlowerPot,
                                                buttonCategorySlug: root_category_slug
                                            }}
                                        />}
                                    </HighlightedItemsWrapper>
                                </ProductDetailsContainer>
                            ) : <NoProductDetails><h1>{t("productDetails.productDetailsNotAvailable")}</h1></NoProductDetails>
                        }
                    </>
            }
        </ProductMainContainer>
    );
};

export default GetDataFromDOM<InjectedProductProps>(ProductsDetails);
