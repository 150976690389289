import { configureStore, ConfigureStoreOptions, EnhancedStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import {
    FLUSH,
    PURGE,
    PAUSE,
    PERSIST,
    REGISTER,
    REHYDRATE,
    persistStore,
    persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
};

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const storeConfigurationOptions: ConfigureStoreOptions = {
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
};

export const store: EnhancedStore<RootState> = configureStore(storeConfigurationOptions);
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;

export const storeDispatch = (action: any) =>
    (store.dispatch as unknown as Function)(action);
