import {
    HTTP_METHODS,
    RESPONSE_TYPE,
    ENCODE_JSON_DATA,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';
import { ENVIRONMENT, DIBSY_PUBLIC_KEY_LIVE } from '@project/config';
import { CARD_INFORMATION_TYPE, CARD_TOKEN_TYPE } from '@api/checkout/types';


const getDibsyKey = (): string => {
    switch (ENVIRONMENT) {
        case 'LOCAL':
        case 'STAGING':
        case 'DEVELOPMENT':
            return DIBSY_PUBLIC_KEY_LIVE;
        case 'PRODUCTION':
            return DIBSY_PUBLIC_KEY_LIVE;
        default:
            return DIBSY_PUBLIC_KEY_LIVE;
    }
}


const getCreditCardTokenFetch = async (card_info: CARD_INFORMATION_TYPE) => {
    const Response = await fetch('https://vault.dibsy.one/card-tokens', {
        method: HTTP_METHODS.POST,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getDibsyKey()}`
        },
        body: ENCODE_JSON_DATA(card_info)
    })

    return Response;
};


export const getCreditCardToken = async (card_info: CARD_INFORMATION_TYPE):
    Promise<RESPONSE_TYPE<CARD_TOKEN_TYPE>> => {
    try {
        const response = await getCreditCardTokenFetch(card_info);
        let body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<CARD_TOKEN_TYPE>(body)
            : FAILURE_RESPONSE('CREATE_CARD_TOKEN', body)
    } catch (error) {
        return FAILURE_RESPONSE('CREATE_CARD_TOKEN', error)
    }
};

export default getCreditCardToken;