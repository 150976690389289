import assert from 'assert';
import React, { Children, useEffect } from 'react';
import { useSpring } from '@react-spring/web';

// IMPORT HOOKS
import { useImmer } from 'use-immer';

// IMPORT COMPONENTS
import {
    AccordionIcon,
    AccordionText,
    AccordionWrapper,

    AccordionMainInfo,
    AccordionMainTitle,
    AccordionMainContent,
} from './AccordionElements';
import { FiChevronDown } from 'react-icons/fi';

// IMPORT STYLES
import { COLORS } from '@project/styles';


interface AccordionProps {
    rows: number;
    children: React.ReactNode;
}

interface InjectedAccordionItemProps {
    open: boolean;
    toggleOpen: () => void;
}

interface AccordionItemProps {
    title: string | React.ReactNode;
    contentHeight: number;
    children: React.ReactNode;

    // Injected Props
    open?: boolean;
    textColor?: string;
    exteriorOpen?: boolean;
    toggleOpen?: () => void;
}

interface AccordionContentProps {
    children: React.ReactNode;

    // Injected Props
    open?: boolean;
}

const AccordionItem = ({ title, exteriorOpen, textColor, contentHeight,
    children, ...restProps }: AccordionItemProps) => {
    const { open, toggleOpen } = {
        open: exteriorOpen !== undefined ? exteriorOpen : restProps.open,
        toggleOpen: restProps.toggleOpen,
    } as InjectedAccordionItemProps;

    const [itemStyles, _itemRef] = useSpring({
        from: {
            maxHeight: 80,
            color: open ? COLORS.DARK_GREEN : textColor
        },
        to: {
            maxHeight: open ? contentHeight + 80 : 80,
            color: open ? COLORS.DARK_GREEN : textColor,
        },
        config: { duration: 250 }
    }, [open, contentHeight]);

    const [iconStyles, _iconRef] = useSpring({
        from: {
            transform: "rotate(0deg)",
            color: "#ffff"
        },
        to: {
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            color: open ? COLORS.DARK_GREEN : textColor
        },
        config: { duration: 120 }
    }, [open]);


    return (
        <AccordionMainInfo style={itemStyles}>
            <AccordionMainTitle onClick={toggleOpen}>
                {
                    typeof title === 'string' ?
                        <>
                            <AccordionText>{title}</AccordionText>

                            <AccordionIcon style={iconStyles}>
                                <FiChevronDown {...{ size: '1rem' }} />
                            </AccordionIcon>
                        </>
                        : title
                }
            </AccordionMainTitle>
            {React.cloneElement(children as React.ReactElement, { open })}
        </AccordionMainInfo>
    )
}

const AccordionContent = ({ children, ...restProps }: AccordionContentProps) => {
    const { open } = restProps as Pick<InjectedAccordionItemProps, 'open'>;;

    const [contentStyles, _ref] = useSpring({
        from: { opacity: 0 },
        to: { opacity: Number(open) },
        config: { duration: 200 },
    }, [open]);

    return (
        <AccordionMainContent style={contentStyles}>
            {children}
        </AccordionMainContent>
    )
}

const Accordion = (props: AccordionProps) => {
    const { rows, children } = props;
    const [state, setState] = useImmer<{ open: boolean }[]>(
        Array.from(
            new Array(rows)
        ).map(_ => ({
            open: false,
        }))
    )

    const toggleAccordionIndex = (index: number) => {
        setState(draft => {
            draft[index].open = !draft[index].open
        })
    }

    return (
        <AccordionWrapper>
            {
                Children.map(children, (child: React.ReactNode, index) => {
                    const injectedProps: any = {
                        ...(state[index]),
                        toggleOpen: () => toggleAccordionIndex(index),
                    }

                    if (React.isValidElement<any>(child)) {
                        // Inject Children Props
                        return React.cloneElement(child, injectedProps)
                    } return child;
                })
            }
        </AccordionWrapper>
    )
}

export {
    Accordion,
    AccordionItem,
    AccordionContent,
}