import { COLORS } from '@project/styles';
import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
    
    width: 100%;
    height: 200px;
    margin: 20px 0;
    padding: 20px 5%;
    position: relative;
    flex-direction: row;
    border-radius: 10px;

    justify-content: space-between;

    @media screen and (max-width: 1400px) {
        width: 100%;
        margin: 15px 0;
        padding: 20px 3%;
    }
`;

export const ProductImageWrapper = styled.div`
    width: 25%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px) {
        width: 20%;
    }
`;

export const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    justify-content: center;
    /* align-items: center; */
    width: 50%;
    padding-left: 20px;

    @media screen and (max-width: 1000px) {
        margin-left: 20px;
        width: 40%;
        /* border: 1px solid black; */
    }
`;
export const ProductName = styled.h1`
    font-weight: normal;
    font-size: 25px;
    margin: 0;
    @media screen and (max-width: 1000px) {
        font-size: 20px;
    }
`;
export const ProductDetail = styled.h3`
    font-weight: normal;
    margin: 0;
    font-size: 22px;
    @media screen and (max-width: 1000px) {
        font-size: 18px;
    }
`;
export const QuantityWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;


export const QtyComponent = styled.h2`
    width: 30%;
    height: 45px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.DARKER_GREY};
    @media screen and (max-width: 1000px) {
        margin: 0;
    }
`;

export const QtyPlus = styled.h2`
    width: 30%;
    height: 45px;
    margin: 0;
    display: flex;
    cursor: pointer;
    text-align: center;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.DARKER_GREY};
    transition: all .2s ease;
    @media screen and (max-width: 1000px) {
        margin: 0;
    }

    &:hover {
        font-size: 1.8rem;
        border: 2px solid #444;
    }
`;

export const QtyMinus = styled.h2`
    width: 30%;
    height: 45px;
    margin: 0;
    display: flex;
    cursor: pointer;
    text-align: center;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.DARKER_GREY};
    transition: all .2s ease;
    @media screen and (max-width: 1000px) {
        margin: 0;
    }

    &:hover {
        font-size: 1.8rem;
        border: 2px solid #444;
    }
`;

export const TotalWrapper = styled.div`
    display: flex;
    width: 20%;
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
export const PriceText = styled.h1`
    font-weight: normal;
    font-size: 25px;
    @media screen and (max-width: 1000px) {
        width: 100%;
        font-weight: bold;
        font-size: 15px;
    }
`;
