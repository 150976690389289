export type API_CARD_BRANDS = 'Amex' | 'Visa' | 'Mastercard';

export interface API_SAVED_CARDS_TYPE {
    id: string;
    card_expiry: string;
    card_number: string;
    is_primary_card: boolean;
    card_country_code: string;
    card_holder: string | null;
    card_label: API_CARD_BRANDS;
}

export interface SAVED_CREDIT_CARD_TYPE {
    payment_type: 'credit';
    cvv: string;
    is_saved: boolean;
    cc_number: string;
    payment_id: number;
    expiry_year: string;
    expiry_month: string;
    brand: CREDIT_CARD_BRAND_TYPE;
}

export interface VERIFY_SAVED_CARD_PROPERTIES_TYPE {
    cvv: string;
    cc_number: string;
    expiry_year: string;
    expiry_month: string;
}

export interface CREDIT_CARD_TYPE {
    payment_type: 'credit';
    payment_id: string;
}

export interface DEBIT_CARD_TYPE {
    payment_type: 'debit';
    payment_id: string;
}

export interface APPLE_PAY_TYPE {
    payment_type: 'apple_pay';
    payment_id: string;
}

export interface INVALID_CARD_TYPE {
    payment_type: 'undetermined';
    payment_id: '';
}

export type CARD_TYPE = DEBIT_CARD_TYPE | CREDIT_CARD_TYPE | INVALID_CARD_TYPE;

interface CASH_TYPE {
    payment_type: 'cash';
    payment_id: string;
}

interface SAVED_TYPE extends API_SAVED_CARDS_TYPE {
    payment_type: 'saved';
    payment_id: string;
}

interface ADDED_TYPE {
    payment_type: 'added';
    card_cv2: string;
    cc_number: string;
    payment_id: string;
    expiry_year: string;
    expiry_month: string;
    card_holders_name?: string;
    save_card_for_user: boolean;
}

export type DIBSY_ADDED_PAYMENT_OPTION_TYPE = ADDED_TYPE;
export type DIBSY_SAVED_PAYMENT_OPTION_TYPE = SAVED_TYPE;
export type DIBSY_PRIORITY_PAYMENT_OPTION_TYPE = APPLE_PAY_TYPE;
export type DIBSY_DEFAULT_PAYMENT_OPTION_TYPE = CASH_TYPE | CARD_TYPE;

export type DIBSY_PAYMENT_OPTION =
    | DIBSY_ADDED_PAYMENT_OPTION_TYPE
    | DIBSY_SAVED_PAYMENT_OPTION_TYPE
    | DIBSY_DEFAULT_PAYMENT_OPTION_TYPE
    | DIBSY_PRIORITY_PAYMENT_OPTION_TYPE;

export enum CREDIT_CARD_BRAND_TYPE {
    VISA,
    MASTERCARD,
    AMERICAN_EXPRESS,
    INVALID,
}
