import React from 'react';

export interface BaseDOMDataType<T> {
    auth_token_cookie: string | null;
    web_device_id_cookie: string | null;
    api_data: T
}


function GetDataFromDOM<T>(Component: React.ElementType) {
    function GetDataFromDOM_(props: any) {
        const dataDocumentDiv = document?.getElementById('response_data');
        const dataDocumentInputElement = document?.getElementById('response_data_input');

        let responseDataDOM = dataDocumentInputElement?.getAttribute('data-response-data');
        let DOMInformationDict: BaseDOMDataType<T> | null = null;

        if (responseDataDOM) {
            DOMInformationDict = JSON.parse(responseDataDOM) as BaseDOMDataType<T>;
            // todo: sameer can you check here if webdeviceidcookie and the authtokencookie are valid before returning this?
        }

        dataDocumentDiv && dataDocumentDiv.remove()
        return <Component {...props} {...(DOMInformationDict || {})} />;
    }
    return GetDataFromDOM_;
}

export default GetDataFromDOM;
