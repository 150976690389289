// IMPORT MODULES
import styled from 'styled-components';
import { Link } from '@project/routes/components';

// IMPORT IMAGES
import greenitLogoSmall from '@assets/images/header/greenitLogoSmall.png';

// IMPORT STYLES
import { COLORS, BIG_HEADER_HEIGHT } from '@project/styles';

// IMPORT ICONS
import { BsSearch } from 'react-icons/bs';
import { GiRamProfile } from 'react-icons/gi';
import { FaShoppingCart } from 'react-icons/fa';
import { MdAccountCircle } from 'react-icons/md';
import { RiDeleteBin5Fill, RiMapPinUserFill } from 'react-icons/ri';

import { RiBarChartHorizontalLine } from 'react-icons/ri';


const RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE = 'images/header_greenit_logo.svg';
const RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE_ARABIC = 'images/header_greenit_logo_arabic.svg';
const RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_SMALL = 'images/header_greenit_logo_small.png';


import '@project/fonts';
import { IoReceiptSharp } from 'react-icons/io5';
import { Select } from '@mui/material';
import { FiLogOut } from 'react-icons/fi';


export const Nav = styled.nav<{ deactivate: boolean }>`
    top: 0;
    z-index: 70;
    display: ${({ deactivate }) => deactivate ? 'none' : 'flex'};
    font-size: 1rem;
    padding: 0% 6%;
    position: sticky;
    background: #fff;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: ${BIG_HEADER_HEIGHT}vh;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    @media screen and (max-width: 900px) {
        transition: all 0.8s ease;
        padding: 0% 3%;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    height: 100%;
    z-index: 1;
    width: 100%;
    position: relative;
    transition: all 0.5s ease;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        /* height: 10vh; */
    }
`;

export const NavLogoLink = styled(Link)`
    position: relative;
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 5px 0%;
    align-items: center;
    text-decoration: none;
    @media screen and (max-width: 768px) {
        width: auto;
        justify-content: center;
    }
`;

export const NavLogoLinkMain = styled(Link)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    @media screen and (max-width: 768px) {
        width: 20%;
        display: none;
        justify-content: center;
    }
`;

export const NavLogo = styled(Link)`
    display: flex;
    width: 190px;
    height: 40px;
    padding-left: 15%;
    /* border: solid 1px ${COLORS.RED}; */
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE}) no-repeat center;
    background-size: 100% auto;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavLogoArabic = styled(Link)`
    display: flex;
    width: 190px;
    height: 70px;
    padding-left: 15%;
    /* border: solid 1px ${COLORS.RED}; */
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_LARGE_ARABIC}) no-repeat center;
    background-size: 75% auto;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;


export const NavLogoMobile = styled(Link)`
    width: 40px;
    height: 40px;
    display: none;
    background: url(${process.env.PUBLIC_URL}/${RELATIVE_PATH_OF_IMAGE_GREENIT_LOGO_SMALL}) no-repeat center;
    background-size: 100% auto;
    @media screen and (max-width: 768px) {
        display: flex;
    }
`;

export const NavLogoLetter = styled.img.attrs({
    src: `${greenitLogoSmall}`,
})`
    display: flex;
    object-fit: contain;
    padding-left: 15%;
    height: 35px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const MobileLeftIcons = styled.div`
    display: none;
    height: 100%;
    @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
`;

export const UserIconLinkWrapper = styled(Link)`
    display: flex;
    width: auto;
    height: 100%;
    cursor: pointer;
    padding: 0 3%;
    margin-left: 10px;
    text-decoration: none;
    align-items: center;
    color: ${COLORS.DARK_GREEN};
    justify-content: center;
    @media screen and (max-width: 400px) {
        margin: 0px 0px;
    }

    @media screen and (max-width: 768px) {
        width: auto;
    }
`;

export const UserIconSelectWrapper = styled.div`
    display: flex;
    width: auto;
    height: 100%;
    cursor: pointer;
    padding: 0 5%;
    margin-left: 20px;
    text-decoration: none;
    align-items: center;
    color: ${COLORS.DARK_GREEN};
    justify-content: center;
    /* border: solid 2px green; */
    
    @media screen and (max-width: 400px) {
        margin: 0px 0px;
    }

    @media screen and (max-width: 768px) {
        width: auto;
    }
`;

export const ItemRowWrapper = styled.div`
    display: flex;
    width: 300px;
    height: 40px;
    cursor: pointer;
    padding: 0 5%;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    color: ${COLORS.DARK_GREEN};
    justify-content: flex-start;
`;

export const ItemRowWrapperA = styled.a`
    display: flex;
    width: 300px;
    height: 40px;
    cursor: pointer;
    padding: 0 5%;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    color: ${COLORS.DARK_GREEN};
    justify-content: flex-start;
`;

export const ItemRowWrapperLink = styled(Link)`
    display: flex;
    width: 300px;
    height: 40px;
    cursor: pointer;
    padding: 0 5%;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    color: ${COLORS.DARK_GREEN};
    justify-content: flex-start;
`;

export const ItemIconContainer = styled.div`
    display: flex;
    width: 20%;
    height: auto;
    cursor: pointer;
    padding: 0 3%;
    margin-right: 20px;
    text-decoration: none;
    align-items: center;
    flex-direction: row;
    color: ${COLORS.DARK_GREEN};
    justify-content: center;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0px 0px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const TextLinkContainer = styled(Link)`
    display: flex;
    width: auto;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0% 10px;
`

export const DeleteLinkContainer = styled.div`
    display: flex;
    width: auto;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0% 10px;
`

export const Text = styled.div`
    width: 70px;
    height: 35px;
    display: flex;
    margin-right: 10px;
    border-radius: 5px;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    color: ${COLORS.DARK_GREEN};
    justify-content: center;
    border: solid 1px ${COLORS.DARK_GREEN};
    text-decoration: none;
`

export const UserIcon = styled(MdAccountCircle)`
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
    @media screen and (max-width: 768px) {
        display: flex;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.8rem;
    };

    @media screen and (max-width: 400px) {
        font-size: 1.6rem;
    }
`;

export const ReceiptFill = styled(IoReceiptSharp)`
    position: relative;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
`;

export const LogoutFill = styled(FiLogOut)`
    position: relative;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
`;

export const DeleteFill = styled(RiDeleteBin5Fill)`
    position: relative;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
`;

export const PersonFill = styled(RiMapPinUserFill)`
    position: relative;
    font-size: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
`;



export const CartIconFill = styled(FaShoppingCart)`
    position: relative;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};
`;

export const CartIconOutline = styled(FaShoppingCart)`
    position: relative;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};

    @media screen and (max-width: 500px) {
        font-size: 1.6rem;
    };

    @media screen and (max-width: 400px) {
        font-size: 1.4rem;
    }
`;

export const SearchIcon = styled(BsSearch)`
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};

    @media screen and (max-width: 500px) {
        font-size: 1.4rem;
    };

    @media screen and (max-width: 400px) {
        font-size: 1.3rem;
    }
`

export const MenuIcon = styled(RiBarChartHorizontalLine)`
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLORS.DARK_GREEN};

    @media screen and (max-width: 500px) {
        font-size: 1.4rem;
    };

    @media screen and (max-width: 400px) {
        font-size: 1.3rem;
    }
`

export const HeaderIcon = styled.img`
    /* margin: 0px 30px; */
    width: 10%;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        display: block;
        width: 12%;
    }
    @media screen and (max-width: 500px) {
        display: block;
        width: 15%;
    }
    @media screen and (max-width: 400px) {
        margin: 0px 0px;
        width: 17%;
    }
`;

export const CartIconWrapper = styled.div`
    height: auto;
    cursor: pointer;
    position: relative;
    padding: 10px 10px 10px 10px;

    @media screen and (max-width: 768px) {
        padding: 10px 0px;
    }
`;


export const CartIconNumbers = styled.div`
    position: absolute;
    background-color: ${COLORS.ORANGE};
    border-radius: 60px;
    width: auto;
    overflow: hidden;
    height: 22px;
    min-width: 22px;
    bottom: 3px;
    right: 1px;
    padding: 1px;
    background-color: ${COLORS.WHITE};
    border: solid 1px ${COLORS.RED};
    display: flex;

    @media screen and (max-width: 768px) {
        right: -10px;
    }
`;

export const CartIconNumbers_Flipped = styled.div`
    position: absolute;
    background-color: ${COLORS.ORANGE};
    border-radius: 60px;
    width: auto;
    overflow: hidden;
    height: 22px;
    min-width: 22px;
    bottom: 3px;
    left: 1px;
    padding: 1px;
    background-color: ${COLORS.WHITE};
    border: solid 1px ${COLORS.RED};
    display: flex;

    @media screen and (max-width: 768px) {
        left: -10px;
    }
`;

export const CartIconNumbersInner = styled.div`
    position: relative;
    padding: 2px;
    width: 100%;
    border-radius: 60px;
    background-color: ${COLORS.RED};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const CartItemNumber = styled.p`
    color: white;
    font-size: bold;
    font-size: 0.7rem;
`;

export const RightIconsWrapper = styled.div`
    display: flex;
    width: 20%;
    justify-content: flex-end;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 768px) {
        max-width: 40%;
        min-width: 40%;
    }
`;

export const LeftIconsWrapper = styled.div`
    display: flex;
    width: 20%;
    justify-content: flex-start;
    transition: all 0.5s ease;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 768px) {
        max-width: 40%;
        min-width: 40%;
    }
`

export const LeftIcons = styled.div`
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
    margin: 0px 40px;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
        justify-content: space-evenly;
    }

`

export const MiddleTabs = styled.div`
    display: flex;
    width: auto;
    height: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        width: 20%;
    }
`

export const StyledTabs = styled.div`
    display: flex;
    height: 100%;
    @media screen and (max-width: 900px) {
        display: none;
    }
`

export const RightIcons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 40px;
    @media screen and (max-width: 768px) {
        margin-right: 0px;
        width: 100%;
        justify-content: space-evenly;
    }
`;


export const UserImageContainer = styled.div`
    display: flex;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 2000px;
    border: 2px solid green;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${COLORS.WHITE};

    @media screen and (max-width: 768px) {
        max-width: 35px;
        min-width: 35px;
        height: 35px;
    }
`

export const UserAvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

export const UserFacelessIcon = styled(GiRamProfile)`
    color: #01bf71;
    font-size: 1.25rem;
`