import styled from 'styled-components';

// IMPORT COMPONENTS
import Drawer from 'react-modern-drawer';
import { Link } from '@project/routes/components';

//IMPORT STYLES
import { COLORS } from '@project/styles';

import '@project/fonts';

import 'react-modern-drawer/dist/index.css'


export const DrawerContainer = styled(Drawer)`
    display: flex;
    z-index: 10001;
    position: relative;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: center;
`;
export const DrawerContentWrapper = styled.div`
    display: flex;
    width: 90%;
    height: 95%;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 95%;
    }
`;

export const CartItemsContainer = styled.div`
    height: 65%;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding: 10px 0px 40px 0px;

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar{
        width: 6px;
    } 

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        margin: 0px 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(0,0,0,.3);
    }
`;

export const CartTitleContainer = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
`;
export const CartMainTitle = styled.h2`
    font-weight: bold;
    font-size: 30px;
    margin: 0;
`;
export const CartSubTitle = styled.h3`
    font-weight: normal;
    margin: 0;
    opacity: 0.6;
`;
export const GoToCartPage = styled(Link)`
    color: inherit;
    cursor: pointer;
    display: flex;
    top: 2%;
    right: 10px;
    position: absolute;
    align-self: flex-end;
    text-decoration: none;
`;
interface GiftMessageContainerProps {
    isGift: boolean;
}


export const GiftMessageContainer = styled.div<GiftMessageContainerProps>`
    width: 100%;
    transition: height 0.3s;
    height: ${(props) => (props.isGift ? '130px' : '12px')};
    overflow: hidden;
    padding: 20px 0;
    margin-bottom: 20px;
`;


export const GiftMessageTitle = styled.p`
    margin-top: 0;
    font-weight: normal;
`;


export const GiftMessageTextBox = styled.textarea<GiftMessageContainerProps>`
    border: 1px solid ${COLORS.DARK_GREY};
    height: 100px;
`;

export const OverRequestContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    padding: 5px 10px;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
`

export const OverRequestContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    overflow-y: auto;
    margin: 0% 2px 0% 0%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    justify-content: space-between;
    -webkit-box-sizing: border-box;

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        margin: 0px 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(0,0,0,.3);
    }   
`

export const OverRequestImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 70px;
    height: 70px;
    padding: 2px;
`

export const OverRequestImage = styled.img`
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: contain;
`

export const OverRequestStockContainer = styled.div`
    display: flex;
    height: 100%;
    width: 150px;
    font-weight: bolder;
    align-items: center;
    justify-content: flex-end;
    padding: 0% 20px 0% 0%;
`

export const OverRequestNameAndInfoContainer = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
`

export const OverRequestName = styled.h4`
    color: #222;
    margin: 0;
`

export const OverRequestVariantName = styled.h4`
    margin: 0;
    display: flex;
    color: ${COLORS.DARKER_GREY};

    @media screen and (max-width: 500px) {
        display: none;
    }
`

export const OverRequestOutOfStock = styled.h4`
    margin: 0% 0% 0% 3%;
    width: 100%;
    height: auto;
    padding: 5% 5%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: black;
    align-items: center;
    justify-content: center;
    background-color: white;
`

export const OverRequestQuantityContainer = styled.div`
    margin: 0% 0% 0% 3%;
    width: 100%;
    height: auto;
    padding: 5% 5%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: black;
    align-items: center;
    justify-content: center;
    background-color: white;
`