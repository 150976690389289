import React, { useState } from 'react';

import {
    Slide,
    Slider,
    CarouselProvider,
} from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';


const VerticalCarousel = ({
    children,
    activeSlide,
}: {
    children: React.ReactChild[];
    activeSlide?: number;
}) => (
    <CarouselProvider {...{
        step: 1,
        interval: 3000,
        naturalSlideWidth: 200,
        naturalSlideHeight: 250,
        isIntrinsicHeight: true,
        orientation: 'horizontal',
        totalSlides: children.length,
        currentSlide: activeSlide || 0,
    }}>
        <Slider>
            {children.map(
                (child, index) => (
                    <Slide {...{ index, key: index }}>
                        {child}
                    </Slide>
                )
            )}
        </Slider>
    </CarouselProvider>
);

export { VerticalCarousel };
