import React, { useEffect } from 'react';

import {
    OverRequestName,
    OverRequestImage,
    OverRequestContainer,
    OverRequestOutOfStock,
    OverRequestVariantName,
    OverRequestImageContainer,
    OverRequestStockContainer,
    OverRequestContentContainer,
    OverRequestQuantityContainer,
    OverRequestNameAndInfoContainer,
} from '../CartElements';


import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton, StaticLoadingButton } from '@custom_components';

import {
    imageUrlCustomSize,
} from '@api/interactions';
import {
    getVariantImage,
    OVER_REQUESTED_ITEM_TYPE,
    removeOverRequestedItemsFromCart
} from '@api/user/cart/helpers';
import { COLORS } from '@project/styles';
import { getCartItems } from '@api/helpers';
import { getVariant } from '@project/helpers';
import { useFormSubmission } from '@project/hooks';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const OverRequestTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 4, fontWeight: 'bold', height: 80 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const OverRequestedItems = ({ openDialog, onCloseDialog, items }: { openDialog: boolean, onCloseDialog: () => void, items: OVER_REQUESTED_ITEM_TYPE[] }) => {
    const [fixCartState, setFixCartState] = useFormSubmission<boolean>(() => removeOverRequestedItemsFromCart())
    const { success } = fixCartState;

    const onFixCartPress = () => {
        setFixCartState(prev => ({ ...prev, submitted: true }))
    }

    useEffect(() => {
        if (success) {
            onCloseDialog();
            getCartItems();
        }
    }, [success]);

    return (
        <Dialog {...{
            open: openDialog, onClose: onCloseDialog,
        }}>
            <OverRequestTitle id="customized-dialog-title" onClose={onCloseDialog}>
                Some items are currently unavailable!
            </OverRequestTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    We regret to inform you that the following items are not available in their requested quantities.
                    Please review the following items and fix your cart before placing an order.
                </Typography>
                <OverRequestContentContainer>
                    {
                        items.map((req) => (
                            <OverRequestContainer>
                                <OverRequestImageContainer>
                                    <OverRequestImage {...{
                                        src: imageUrlCustomSize(getVariantImage(req.product.product_images,
                                            req.product_sku), 200, 200)
                                    }} />
                                </OverRequestImageContainer>
                                <OverRequestNameAndInfoContainer>
                                    <OverRequestName>
                                        {req.product.shortened_display_name || req.product.name}
                                    </OverRequestName>
                                    <OverRequestVariantName>
                                        {getVariant(req.product, req.product_sku)?.display_name}
                                    </OverRequestVariantName>
                                </OverRequestNameAndInfoContainer>
                                <OverRequestStockContainer>
                                    {
                                        req.out_of_stock ?
                                            <OverRequestOutOfStock>
                                                Out of Stock
                                            </OverRequestOutOfStock>
                                            :
                                            <OverRequestQuantityContainer>
                                                {req.max_quantity} in Stock
                                            </OverRequestQuantityContainer>
                                    }
                                </OverRequestStockContainer>
                            </OverRequestContainer>
                        ))
                    }
                </OverRequestContentContainer>
            </DialogContent>
            <DialogActions {...{ sx: { padding: '20px 20px' } }}>
                <StaticLoadingButton {...{
                    buttonTitle: 'Cancel',
                    onClick: onCloseDialog,
                    colorScheme: COLORS.AUTH.LOGIN,
                    textStyle: { fontSize: '1rem' },
                    style: { width: 120, height: 40, margin: '0px 5px' },
                }} />
                <LoadingButton {...{
                    disabled: false,
                    onClick: onFixCartPress,
                    buttonTitle: 'Fix Cart',
                    buttonState: fixCartState,
                    colorScheme: COLORS.AUTH.LOGIN,
                    style: { width: 120, height: 40, margin: '0px 5px' },
                    textStyle: { fontSize: '1rem' },
                }} />
            </DialogActions>
        </Dialog>
    )
}

export default OverRequestedItems