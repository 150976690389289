import { API_PRODUCT_IMAGE_TYPE, API_PRODUCT_TYPE } from '@api/types';

import {
    API_PROMO_CODE_INFO,
    API_CART_PRODUCT_TYPE,
    APP_STATE_PROMO_CODE_INFO,
    APP_STATE_CART_PRODUCT_TYPE,
} from './types';
import getCart from './get';
import fixCart from './fix';
import { imageUrlCustomSize, getPromocodeType, RESPONSE_TYPE, SUCCESS_RESPONSE, FAILURE_RESPONSE } from '@api/interactions';

export const getVariantImage = (
    images: API_PRODUCT_IMAGE_TYPE[],
    sku: string
) => {
    const variantImages = images.filter(
        (elem) =>
            elem.product_skus.indexOf(sku) >= 0
            || !elem.product_skus.length
    );

    const variantMainImage = variantImages.find(img => img.is_main)
    return imageUrlCustomSize(variantMainImage?.image || variantImages[0].image, 400, 400) as string;
};


export const mapCartProducts = (
    items: API_CART_PRODUCT_TYPE[]
): APP_STATE_CART_PRODUCT_TYPE[] => {
    return items.map((elem: API_CART_PRODUCT_TYPE) => {
        const product = elem.product;
        const variant_name = product.variants.filter(
            (variant) => variant.sku === elem.product_sku
        )[0].display_name;
        return {
            variant_name,
            variant_image: getVariantImage(
                product.product_images,
                elem.product_sku
            ),
            cart_item_id: elem.cart_item_id,
            product_sku: elem.product_sku,
            actual_price: elem.actual_price,
            requested_quantity: elem.requested_quantity,
            price_entered_at: elem.price_entered_at,
            available_quantity: elem.available_quantity,
            product: elem.product,
            custom_card: elem.custom_card
                ? {
                    to: elem.custom_card?.recipient,
                    from: elem.custom_card?.sender,
                    message: elem.custom_card?.message,
                }
                : undefined,
        };
    });
};

export const mapPromocode = (
    promo?: API_PROMO_CODE_INFO
): APP_STATE_PROMO_CODE_INFO | undefined => {
    if (promo) {
        return {
            ...promo,
            type: getPromocodeType(promo.type),
        };
    }
    return undefined;
};

export const addIndividualQuantities = (cart_items: any[]) => {
    const accumulator = (prev: number, curr: any) =>
        prev + curr.requested_quantity;
    return cart_items.reduce(accumulator, 0);
};


export const isCartValid = async (): Promise<boolean> => {
    const cart = await getCart();
    if (cart.status) {
        return cart.response_body.cart_items.reduceRight<boolean>(
            (acc, p) => acc && (p.requested_quantity <= p.available_quantity), true)
    }
    return false;
}

export interface OVER_REQUESTED_ITEM_TYPE {
    product_sku: string;
    max_quantity: number;
    out_of_stock: boolean;
    product: API_PRODUCT_TYPE;
    over_requested_amount: number;
}
export const getOverRequestedItems = async (): Promise<OVER_REQUESTED_ITEM_TYPE[]> => {
    const { status, response_body } = await getCart();
    if (status) {
        const mapped_cart_item: (false | OVER_REQUESTED_ITEM_TYPE)[] = response_body.cart_items.map(
            ({ product, product_sku, requested_quantity, available_quantity: max_quantity }) =>
                requested_quantity > max_quantity ? ({
                    product,
                    product_sku,
                    max_quantity,
                    out_of_stock: !max_quantity,
                    over_requested_amount: requested_quantity - max_quantity,
                }) : false)

        return mapped_cart_item.filter(Boolean) as OVER_REQUESTED_ITEM_TYPE[]
    }
    return [];
}

export const removeOverRequestedItemsFromCart = async (): Promise<RESPONSE_TYPE<boolean>> => {
    const { status } = await fixCart();
    return new Promise((rs, rj) => status ? rs(SUCCESS_RESPONSE(true)) : rj(FAILURE_RESPONSE('FIX_CART', false)))
}