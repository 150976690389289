import {
    HTTP_METHODS, HTTP_REQUEST,
    FAILURE_RESPONSE, RESPONSE_TYPE, SUCCESS_RESPONSE
} from '@api/interactions'

export const requestOTPFetch = async (otp_phone_number: string) => {
    const HttpRequest = new HTTP_REQUEST(`user/otp/request-otp/`, {
        method: HTTP_METHODS.POST,
        options: { data: { otp_phone_number } }
    })

    const Response = await HttpRequest.callFetch()
    return Response
}


type CUSTOM_RESPONSE = null
const api_type = 'REQUEST_OTP'

const requestOTP = async (phone: string): Promise<RESPONSE_TYPE<CUSTOM_RESPONSE>> => {
    try {
        const response = await requestOTPFetch(phone)
        const success = response.ok

        return success ? SUCCESS_RESPONSE<CUSTOM_RESPONSE>(null)
            : FAILURE_RESPONSE(api_type)

    } catch (err) {
        return FAILURE_RESPONSE(api_type)
    }
}

export default requestOTP;