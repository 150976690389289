import styled from 'styled-components';
import { COLORS } from '@project/styles';

export const ProductDetailsWrapper = styled.div`
    display: flex;
    overflow: hidden;
    padding: 3% 0% 0% 0%;
    flex-direction: column;
`;
export const ProductDetailsOptionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
`;


export const ProductDetailsOption = styled.div`
    width: 100%;
    display: flex;
    align-self: center;
    border-radius: 15px;
    flex-direction: row;
    align-items: center;
    transition: all 0.4s;
    padding-left: 10px;
    justify-content: flex-start;
    
    @media screen and (max-width: 1200px) {
        width: 100%;
        border-radius: 0;
        padding-left: 10px;
    }
    
`;
export const ProductDetailsOptionName = styled.h3`
    margin: 0px 10px;
    padding: 5px 2%;
    font-size: 1.5rem;
    font-weight: bolder;
    color: ${COLORS.DARK_GREEN};
`;

export const InformationSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const InformationItem = styled.div`
    width: 100%;
`;

export const InformationItemText = styled.h3`
    padding: 10px 10px;
    font-weight: normal;
    text-align: justify;
`;
