import {
    HTTP_METHODS,
    RequestError,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';

import { API_REPOTTING_CONFIGURATION_TYPE } from '@api/checkout/types';


export const getRepotConfigurationFetch = async (auth_token?: string) => {
    const HttpRequest = new HTTP_REQUEST('settings/get-repotting-settings/', {
        method: HTTP_METHODS.GET,
        options: {
            validateCookies: true,
            providedAccessToken: auth_token
        },
    });

    try {
        const Response = await HttpRequest.callFetch()
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Fetch Repotting Configuration');
    }
};



const getRepotConfiguration = async (auth_token?: string): Promise<RESPONSE_TYPE<API_REPOTTING_CONFIGURATION_TYPE>> => {
    try {

        const response = await getRepotConfigurationFetch(auth_token);
        if (response.ok) {
            let body = await response.json();
            return SUCCESS_RESPONSE<API_REPOTTING_CONFIGURATION_TYPE>(body)
        }
        return FAILURE_RESPONSE('GET_ORDER_SCHEDULE')
    }
    catch (error) {
        return FAILURE_RESPONSE('GET_ORDER_SCHEDULE')
    }
};

export default getRepotConfiguration;


export const defaultRepottingConfig: API_REPOTTING_CONFIGURATION_TYPE = {
    repotting_cost_strategy: 'flat_fee_repotting',
    each_repotting_cost: 5,
    first_repotting_cost: 5,
    second_repotting_cost: 10,
    flat_fee_repotting_cost: 10,
}