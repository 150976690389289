import HotItems from '@home_components/HotItems';
import NewArrivals from '@home_components/NewArrivals';
import PotSection from '@home_components/PotSection';
import FlowerBouquetSection from '@home_components/FlowerBouquetSection';
import DownloadApp from '@home_components/DownloadApp/DownloadApp';
import HeroSection from '@home_components/HeroSection/HeroSection';
import Testimonials from '@home_components/Testimonials/Testimonials';
import CategoryPicker from '@home_components/CategoryPicker/CategoryPicker';
import InstagramDisplay from '@home_components/InstagramDisplay/InstagramDisplay';
import InformationBanner from '@home_components/InformationBanner/InformationBanner';
import { GetDataFromDOM } from '@custom_components';

import { useEffect, useState } from 'react';
import { HTTP_METHODS, HTTP_REQUEST } from '@api/interactions';
import { API_CATEGORY_TYPE, API_PRODUCT_TYPE, API_FLOWER_TYPE, API_POT_TYPE } from '@api/types';
import { useFirstRender } from '@project/hooks';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


async function getHomePageSections(onSuccessCallback: any): Promise<any> {
    let HttpRequest = new HTTP_REQUEST('listing/home-page-sections/', {
        method: HTTP_METHODS.GET,
        options: { validateCookies: true },
    });

    await HttpRequest.callFetch()
        .then(response => response.json())
        .then(response => onSuccessCallback(response))
        .catch(err => console.error(err))
}

interface HomeProps {
    api_data: InjectedHomeProps | null
}
interface InjectedHomeProps {
    flower_bouquet_products: API_FLOWER_TYPE[];
    pot_products: API_POT_TYPE[];
    popular_products: API_PRODUCT_TYPE[];
    hot_new_releases: API_PRODUCT_TYPE[];
    root_categories: API_CATEGORY_TYPE[];
}

const Home = ({ api_data }: HomeProps) => {
    const { t } = useTranslation();

    const isFirstRender = useFirstRender();
    const [popularProducts, setPopularProducts] = useState<API_PRODUCT_TYPE[]>([]);
    const [hotNewReleases, setHotNewReleases] = useState<API_PRODUCT_TYPE[]>([]);
    const [flowerBouquetProducts, setFlowerBouquetProducts] = useState<API_FLOWER_TYPE[]>([]);
    const [potProducts, setPotProducts] = useState<API_POT_TYPE[]>([]);
    const [categories, setCategories] = useState<API_CATEGORY_TYPE[]>([]);

    function populateHomePage(response: InjectedHomeProps) {
        setPopularProducts(response.popular_products);
        setHotNewReleases(response.hot_new_releases);
        setFlowerBouquetProducts(response.flower_bouquet_products);
        setPotProducts(response.pot_products);
        setCategories(response.root_categories)
    }

    useEffect(() => {
        if (isFirstRender && api_data) {
            const [popularProductsDOM, hotNewReleasesDOM] = [api_data.popular_products, api_data.hot_new_releases]
            const [flowerBouquetProductsDOM, potProductsDOM] = [api_data.flower_bouquet_products, api_data.pot_products]
            const categoriesDOM = api_data.root_categories;
            if (popularProductsDOM && hotNewReleasesDOM && flowerBouquetProductsDOM && potProductsDOM && categoriesDOM) {
                populateHomePage({
                    popular_products: popularProductsDOM,
                    hot_new_releases: hotNewReleasesDOM,
                    flower_bouquet_products: flowerBouquetProductsDOM,
                    pot_products: potProductsDOM,
                    root_categories: categoriesDOM,
                })
                return;
            }
        }
        getHomePageSections(populateHomePage);
    }, [])

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Helmet><title>{t('home.metaTitle')}</title></Helmet>
            <HeroSection />
            <CategoryPicker categories={categories} />
            <HotItems data={popularProducts} />
            <DownloadApp />
            <NewArrivals data={hotNewReleases} />
            <Testimonials />
            <FlowerBouquetSection products={flowerBouquetProducts} />
            <div style={{ padding: '0% 5%' }}>
                <InformationBanner />
            </div>
            <PotSection products={potProducts} />
            <InstagramDisplay />
        </div >
    );
};

export default GetDataFromDOM<InjectedHomeProps>(Home);
