import { COLORS } from '@project/styles';
import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
export const AddAddressButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
export const MenuItemWrapper = styled.div`
    max-height: 200px;
`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;
`;