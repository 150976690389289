import { useState } from 'react';


// IMPORT COMPONENTS
import { Divider } from '@mui/material';
import SavedCards from './SavedCards/SavedCards';
import { AddressForm } from '@custom_components';
import SavedAddresses from './SavedAddresses/SavedAddresses';


// IMPORT TYPES
import { API_ADDRESS_TYPE } from '@api/user/address/types';
import { useTranslation } from 'react-i18next';

interface AddFormProps {
    type: 'Add';
    formTitle: string;
}
interface EditFormProps {
    type: 'Edit';
    formTitle: string;
    editAddress: API_ADDRESS_TYPE;
}
const AddressesAndCards = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState<boolean>(false);
    const [addressFormProps, setAddressProps] = useState<
        AddFormProps | EditFormProps
    >({
        type: 'Add',
        formTitle: t('userProfile.addANewAddress'),
    });

    const hideForm = () => {
        setOpen(false);
    }

    const showForm = (
        isOpen: boolean,
        editAddress?: API_ADDRESS_TYPE
    ) => {
        if (editAddress) {
            setAddressProps({
                type: 'Edit',
                editAddress,
                formTitle: t("userProfile.editThisAddress"),
            });
        } else {
            setAddressProps({
                type: 'Add',
                formTitle: t('userProfile.addANewAddress')
            })
        }

        if (isOpen) {
            goToAddresses();
        } else { goToTop() }

        setOpen(() => isOpen);
    };

    const toggleAddresses = () => {
        setExpand(state => !state)
    }

    const goToAddresses = () => {
        1
        window.scrollTo({
            top: expand ? 1000 : 800,
            behavior: 'smooth',
        });
    };
    const goToTop = () => {
        window.scrollTo({
            top: 200,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <SavedAddresses
                {...{
                    expand,
                    showForm,
                    toggleAddresses,
                }}
            />
            <AddressForm
                {...{
                    open,
                    hideForm,
                    ...addressFormProps,
                }}
            />
            <Divider />
            <SavedCards />
        </>
    );
};

export default AddressesAndCards;
