import {
    HTTP_METHODS,
    RequestError,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';


const getDeliveryAvailabilityFetch = async (auth_token?: string) => {
    const HttpRequest = new HTTP_REQUEST('user/cart/get-delivery-availability/', {
        method: HTTP_METHODS.GET,
        options: {
            validateCookies: true,
            providedAccessToken: auth_token,
        },
    });

    try {
        const Response = await HttpRequest.callFetch()
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Fetch Order Schedule');
    }
};
import { API_ORDER_SCHEDULE_TYPE } from '../types';

const mapOrderSchedule = (schedule: any): API_ORDER_SCHEDULE_TYPE => ({
    ...schedule,
    possible_timing_windows: schedule.possible_timing_windows.map((elem: [string, string]) => ({
        start_time: elem[0],
        end_time: elem[1],
    }))
})

const getDeliveryAvailability = async (auth_token?: string): Promise<RESPONSE_TYPE<API_ORDER_SCHEDULE_TYPE>> => {
    try {
        const response = await getDeliveryAvailabilityFetch(auth_token);
        let body = await response.json();
        return response.ok
            ? SUCCESS_RESPONSE<API_ORDER_SCHEDULE_TYPE>(mapOrderSchedule(body))
            : FAILURE_RESPONSE('GET_DELIVERY_AVAILABILITY')
    }
    catch (error) {
        return FAILURE_RESPONSE('GET_DELIVERY_AVAILABILITY')
    }
};


export default getDeliveryAvailability


