import styled from 'styled-components';
import { COLORS } from '@project/styles';
import visaLogo from '@assets/images/user/visaLogo.png';
import cardChip from '@assets/images/user/cardChip.png';

export const Card = styled.div`
    display: flex;
    transition: all 0.5s;
    margin: 20px 0 20px 0;
    overflow: hidden; 
    justify-content: center;
    align-items: center;
    width: 48%;
    aspect-ratio: 100%;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;

    @media screen and (max-width: 800px) {
        width: 90%;
    }
`;
;

interface CardSectionProps {
    isFront: boolean;
}
export const CardSection = styled.div<CardSectionProps>`
    box-shadow: 1px 1px #aaa3a3;
    width: 90%;
    height: 80%;
    background-image: linear-gradient(
        to bottom,
        ${COLORS.GREEN},
        ${COLORS.GREEN},
        ${COLORS.DARK_GREEN}
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    backface-visibility: hidden;

    ${(props) =>
        props.isFront
            ? `
        padding: 18px;
    `
            : `
        padding: 18px 0;
    `}
`;

export const BlackLine = styled.div`
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
`;
export const CardBackContent = styled.div`
    padding: 15px 15px 0;
    border: 1px solid white;
`;
export const CardFrontContent = styled.div`
    margin-top: 60px;
`;
export const CardSecret = styled.div`
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
`;
export const CardSecretLast = styled.p`
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
`;

interface CardLogos {
    isFront: boolean;
}
export const CardChip = styled.img.attrs({
    src: cardChip,
}) <CardLogos>`
    position: absolute;
    height: 30px;
    ${(props) =>
        props.isFront
            ? `
        top: 18px;
        left: 15px;
    `
            : `
        bottom: 18px;
        left: 15px;
    `}
`;
export const CardLogo = styled.img.attrs({
    src: visaLogo,
}) <CardLogos>`
    height: 22px;
    position: absolute;
    /* top: 18px;
    right: 18px; */
    ${(props) =>
        props.isFront
            ? `
        top: 18px;
        right: 18px;
    `
            : `
        bottom: 18px;
        right: 18px;
    `}
`;

export const CardNumber = styled.p`
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 1rem;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
`;
export const CardSpace75 = styled.div`
    width: 75%;
    float: left;
`;
export const CardSpace25 = styled.div`
    width: 25%;
    float: left;
`;
export const CardLabel = styled.span`
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
`;
export const CardInfo = styled.p`
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
`;
