import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationState {
    signed_in: boolean;
    auth_token: string | null;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        signed_in: false,
        auth_token: null,
    } as AuthenticationState,
    reducers: {
        signIn: (state, { payload }: PayloadAction<string>) => {
            state.signed_in = true;
            state.auth_token = payload;
        },
        signOut: (state) => {
            state.signed_in = false;
            state.auth_token = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
