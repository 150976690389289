//IMPORT STYLED COMPONENTS
import styled from 'styled-components';
import { BASE_TRANSITION_TIME, COLORS } from '@project/styles';
import { Link } from '@project/routes/components';


export const HighlightedItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 5% 20px 5%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all ${BASE_TRANSITION_TIME}s;
    @media screen and (max-width: 900px) {
        padding: 15px 2% 24px 2%;

    }
`;

// TITLE STYLING
export const TitleRow = styled.div`
    display: flex;
    padding: 2px 8%;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        justify-content: center;
    }
`;

export const CategoryTitleWrapper = styled.div`
    display: flex;
    width: 40%;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 10px 0px;
        justify-content: center;
    }
`;
export const CategoryTitle = styled.div`
    font-family: roboto, sans-serif;
    font-weight: 100;
    font-size: 2rem;

    @media screen and (max-width: 1000px) {
        font-size: 1.8rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const SectionIconImage = styled.div`
    width: 50px;
    aspect-ratio: 1;
    margin: 0% 2%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 1000px) {
        width: 30px;
    }
`;


// BUTTON WRAPPER
export const ButtonWrapper = styled.div`
    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

export const HorizontalScrollDisplayWrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`;


export const AllProductsButton = styled(Link)`
    height: auto;
    padding: 10px 20px;
    font-size: large;
    border-radius: 5px;
    border-width: 0.5px;
    font-weight: 800;
    color:${COLORS.GREEN_BRIGHT};
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    transition: all 0.3s ease;
    background-color: ${COLORS.WHITE};
    border: solid 1px ${COLORS.GREEN_BRIGHT};
    text-decoration: none;
    &:hover {
        opacity: .85;
        border: solid 1px ${COLORS.DARK_GREEN};
        color: ${COLORS.DARK_GREEN};
    }
`;
