import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uploadState } from './types';

const initialImageUploadState: uploadState = {
    user_avatar: undefined,
    product_reviews: undefined,
    recommendation: undefined,
    custom_bouquet: undefined,
};

export const imageUploadSlice = createSlice({
    name: 'ImageUpload',
    initialState: initialImageUploadState,
    reducers: {
        setUserAvatarImageKeys: (state, { payload }: PayloadAction<string>) => {
            state.user_avatar = {
                image_key: payload,
                number_of_images: 1,
            };
        },
        setProductReviewImageKeys: (
            state,
            { payload }: PayloadAction<string[]>
        ) => {
            state.product_reviews = {
                image_keys: payload,
                number_of_images: payload.length,
            };
        },
        setCustomBouquetImageKeys: (
            state,
            { payload }: PayloadAction<string[]>
        ) => {
            state.custom_bouquet = {
                image_keys: payload,
                number_of_images: payload.length,
            };
        },
        setProductRecommendationsImageKeys: (
            state,
            { payload }: PayloadAction<string[]>
        ) => {
            state.recommendation = {
                image_keys: payload,
                number_of_images: payload.length,
            };
        },
        //
        //ADD TO EXISTING KEYS
        //
        addProductReviewImageKeys: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.product_reviews = {
                image_keys: [
                    payload,
                    ...(state.product_reviews?.image_keys ?? []),
                ],
                number_of_images:
                    (state.product_reviews?.number_of_images ?? 0) + 1,
            };
        },
        addCustomBouquetImageKeys: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.custom_bouquet = {
                image_keys: [
                    payload,
                    ...(state.custom_bouquet?.image_keys ?? []),
                ],
                number_of_images:
                    (state.custom_bouquet?.number_of_images ?? 0) + 1,
            };
        },
        addProductRecommendationsImageKeys: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.recommendation = {
                image_keys: [
                    payload,
                    ...(state.recommendation?.image_keys ?? []),
                ],
                number_of_images:
                    (state.recommendation?.number_of_images ?? 0) + 1,
            };
        },
        //
        // CLEAR KEYS
        //
        clearUserAvatarImageKeys: (state) => {
            state.user_avatar = undefined;
        },
        clearProductReviewImageKeys: (state) => {
            state.product_reviews = undefined;
        },
        clearCustomBouquetImageKeys: (state) => {
            state.custom_bouquet = undefined;
        },
        clearProductRecommendationsImageKeys: (state) => {
            state.recommendation = undefined;
        },
    },
});

export const {
    setUserAvatarImageKeys,
    setProductReviewImageKeys,
    setCustomBouquetImageKeys,
    setProductRecommendationsImageKeys,
    addProductReviewImageKeys,
    addCustomBouquetImageKeys,
    addProductRecommendationsImageKeys,
    clearUserAvatarImageKeys,
    clearProductReviewImageKeys,
    clearCustomBouquetImageKeys,
    clearProductRecommendationsImageKeys,
} = imageUploadSlice.actions;

export default imageUploadSlice.reducer;
