
import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RESPONSE_TYPE,
    SUCCESS_RESPONSE,
    FAILURE_RESPONSE,
} from '@api/interactions';


const resolvePaymentPollAfterHostedCheckoutFetch = async (payment_process_identifier: string) => {
    const HttpRequest = new HTTP_REQUEST(`payment/hosted-checkout/check-payment-status/`, {
        method: HTTP_METHODS.POST,
        options: { data: { hosted_checkout_process: payment_process_identifier } },
    });

    const Response = await HttpRequest.callFetch();
    return Response;
};


interface PAYMENT_STATUS_TYPE {
    status: 'Pending' | 'Success' | 'Failed' | 'Timeout';
    failure_message?: string;
}

const pollPaymentAfterCheckout = async (payment_process_identifier: string):
    Promise<RESPONSE_TYPE<PAYMENT_STATUS_TYPE>> => {
    try {
        const response = await resolvePaymentPollAfterHostedCheckoutFetch(payment_process_identifier)

        const body = await response.json();
        if (response.ok) {
            return SUCCESS_RESPONSE<PAYMENT_STATUS_TYPE>(body)
        }
        return FAILURE_RESPONSE('GET_PAYMENT_STATUS', body)
    } catch (error) {
        return FAILURE_RESPONSE('GET_PAYMENT_STATUS', error)
    }
}

export default pollPaymentAfterCheckout;