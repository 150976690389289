// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

// IMPORT HOOKS
import { useTranslation } from 'react-i18next';

// IMPORT COMPONENTS
import { GiFire } from 'react-icons/gi';
import HighlightedSection from './HighlightedSection/HighlightedSection';


const HighlightedItems = ({ data }: { data: API_PRODUCT_TYPE[] }) => {
    const { t } = useTranslation();
    return (
        <HighlightedSection
            {...{
                data,
                sectionIcon: GiFire,
                title: t('home.whats'),
                boldedTitle: t('home.hotNow'),
                buttonText: t('common.viewMore'),
                buttonCategorySlug: 'indoor-plants'
            }}
        />
    );
};

export default HighlightedItems;
