export function PrivacyPolicyPage() {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center",  height: "1000px", marginBottom: '100px' }}>
                <iframe 
                    src="https://d1cpnn4md03zcv.cloudfront.net/general-documents/greenit-privacy-policy.pdf" 
                    width="75%" 
                    height="1000px"
                    style={{ border: "none" }}>
                    This browser does not support PDFs. Please download the PDF to view it: <a href="https://d1cpnn4md03zcv.cloudfront.net/general-documents/greenit-privacy-policy.pdf">Download PDF</a>.
                </iframe>
            </div>
        </>
    );
}
