import {
    HTTP_METHODS,
    HTTP_REQUEST,
    RequestError,
    RESPONSE_TYPE,
    FAILURE_RESPONSE,
    SUCCESS_RESPONSE,
} from '@api/interactions';
import {
    CART_TYPE,
    API_CART_TYPE,
    cartItemIdType,
} from '../types';


export const updateCartQuantityFetch = async (id: cartItemIdType, requested_quantity: number) => {
    const HttpRequest = new HTTP_REQUEST(`user/cart/${id}/`, {
        method: HTTP_METHODS.PATCH,
        options: {
            data: { requested_quantity }, validateCookies: true,
        },
    });

    try {
        const Response = await HttpRequest.callFetch();
        return Response;
    } catch (error) {
        throw new RequestError('Failed To Update Quantity');
    }
};


import { mapCartProducts, mapPromocode, addIndividualQuantities } from '../helpers';


const updateCartQuantity = async (id: cartItemIdType, quantity: number): Promise<RESPONSE_TYPE<CART_TYPE>> => {
    try {
        const response = await updateCartQuantityFetch(id, quantity);
        let body: API_CART_TYPE = await response.json();

        return response.ok
            ? SUCCESS_RESPONSE<CART_TYPE>(
                {
                    ...body,
                    cart_items: mapCartProducts(body.cart_items),
                    promo_code: mapPromocode(body.promo_code),
                    num_items: addIndividualQuantities(body.cart_items)
                }
            )
            : FAILURE_RESPONSE('UPDATE_CART', body)
    } catch (error) {
        return FAILURE_RESPONSE('UPDATE_CART', error)
    }
};

export default updateCartQuantity;