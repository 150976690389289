import { useRef, useState } from 'react';

// IMPORT COMPONENTS
import CreditCard from './CreditCard/CreditCard';
import { InputSectionTitle } from '@form_components';
import { TitleWrapper } from '../AddressesAndCardsElements';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Main, TitleButtonsWrapper, AllCards } from './SavedCardsElements';

// IMPORT STYLED COMPONENTS
import {
    CommonButton,
    LowOpacityH1,
} from '@styled_components';

// IMPORT STYLES
import { COLORS } from '@project/styles';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SavedCards = () => {
    const { t } = useTranslation();

    const [expandCards, setExpandCards] = useState<boolean>(false);

    const cardDivRef = useRef<any>(null);

    const handleCloseCards = () => {
        setExpandCards((state) => !state);
    };

    const savedCards = useSelector(
        (store: RootState) => store.user.cards
    );

    return (
        <Main>
            <TitleWrapper style={{ margin: '30px 0 0 0' }}>
                <InputSectionTitle>{t("userProfile.savedCards")}</InputSectionTitle>
                <TitleButtonsWrapper>
                    {(savedCards && savedCards.length > 2) ? (
                        <CommonButton
                            {...{
                                color: COLORS.GREEN,
                                onClick: handleCloseCards,
                                width: 30,
                            }}
                        >
                            {expandCards ? (
                                <FaChevronUp {...{ color: 'white' }} />
                            ) : (
                                <FaChevronDown {...{ color: 'white' }} />
                            )}
                        </CommonButton>
                    ) : null}
                </TitleButtonsWrapper>
            </TitleWrapper>
            <AllCards
                {...{
                    expand: expandCards,
                    ref: cardDivRef,
                }}
            >
                {savedCards && savedCards.length ? (
                    savedCards.map((card) => (
                        <CreditCard {...{ key: card.id, card }} />
                    ))
                ) : (
                    <LowOpacityH1>{t("userProfile.youHaveNoSavedCreditCards")}</LowOpacityH1>
                )}
            </AllCards>
        </Main>
    );
};

export default SavedCards;
