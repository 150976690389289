import styled from 'styled-components';

// IMPORT COMPONENTS
import { Tab, Tabs } from '@mui/material';
import ImportedPhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';


//EDIT USER DETAILS FORM
export const Main = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 30px 20%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.5s ease;

    @media screen and (max-width: 1200px) {
        margin: 30px 100px;
    }
    @media screen and (max-width: 800px) {
        margin: 30px 25px;
    }
`;
export const FormMarginsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 420px;
`;


//TABS
export const TabsContainer = styled(Tabs)`
    width: 100%;
`;
export const CategoryTab = styled(Tab)`
    width: calc(100% / 3);
`;
interface MainSectionContainer {
    selectedTab: number;
}
export const MainSectionContainer = styled.div<MainSectionContainer>`
    display: flex;
    flex-direction: row;
    width: 300%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transform: ${(props) =>
        props.selectedTab === 0
            ? `translateX(0%)`
            : props.selectedTab === 1
                ? `translateX(-${100 / 3}%)`
                : `translateX(-${200 / 3}%)`};
    transition: transform 0.5s;
`;

//PHONE INPUT
export const PhoneInput = styled(ImportedPhoneInput)`
    width: 48%;
    transition: all 0.5s ease;
    
    @media screen and (max-width: 800px) {
        margin: 2% 0 0 0;
        width: 100%;
    }
`;

export const ProfileDetailsWrapper = styled.div`
    display: flex;
    width: ${100 / 3}%;
    padding: 0% 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-direction: column;
`;
export const CardsAndAddressesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${100 / 3}%;
    padding: 0% 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
`;
export const ResetPasswordWrapper = styled.div`
    display: flex;
    width: ${100 / 3}%;
    padding: 0% 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
`;

export const AddNewAddressMap = styled.div`
    width: 100%;
    border-radius: 10px;
    height: 300px;
`;
