import React, { useCallback, useEffect, useState } from 'react';

// IMPORT COMPONENTS
import {
    SectionTitle,
    ScheduleInfo,
    ScheduleDate,
    ScheduleTimeSlot,
    ScheduleContainer,
    MUIDatePickerDesktop,
    ScheduleDateInformation,
} from './AdditionalElements';
import AddressSection from './AddressSection';
import { Loading } from '@custom_components';
import { MenuItem, TextField } from '@mui/material';

// IMPORT TYPES
import {
    API_SCHEDULE_TIME_TYPE,
    API_ORDER_SCHEDULE_TYPE,
    API_SCHEDULE_TIMING_TYPE,
    API_GIFT_ADDRESS_TYPE,
} from '@api/types';

// IMPORT HELPERS
import moment, { Moment } from 'moment';
import getDeliveryAvailability from '@api/checkout/date';

// IMPORT STYLES
import styled from 'styled-components';
import { COLORS } from '@project/styles';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { ComingSoon } from '@pages/Listing/ListingElements';
import { useDidMount } from '@project/hooks';
import { useTranslation } from 'react-i18next';


const SelectTime = styled(TextField)`
    position: relative;
    width: auto;
    max-height: 100%;
    min-height: 100%;
    color: ${COLORS.DARKER_GREY};
`

const NotAvailableTimeSlot = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    background-color: ${COLORS.WHITE_SMOKE};
`

interface ScheduleProps {
    setGiftAddress: (gift?: API_GIFT_ADDRESS_TYPE) => void;
    setDeliveryAddress: (address_id?: string) => void;
    setDeliverySchedule: (date: Moment, time: API_SCHEDULE_TIME_TYPE) => void;
}

const ScheduleInformation = ({ setGiftAddress, setDeliveryAddress, setDeliverySchedule }: ScheduleProps) => {
    const { t } = useTranslation();

    const [time, setTime] = useState<number | null>(null);
    const [date, setDate] = useState<Moment>(moment(new Date()));

    const [validDates, setValidDates] = useState<string[]>([]);
    const [validTimes, setValidTimes] = useState<API_SCHEDULE_TIME_TYPE[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const { delivery_availability } = useSelector((state: RootState) => state.checkout)
    const [schedule, setSchedule] = useState<API_ORDER_SCHEDULE_TYPE | null>(delivery_availability);

    const onChangeDate = (date: unknown, _?: string | undefined) => {
        date && setDate(date as Moment);
        date && setTime(null);
    };

    const isTimeSlotApplicable = (start: string, end: string) => {
        return validTimes.length && validTimes
            .map(({ start_time, end_time }) =>
                moment(start, 'kk:mm').format('hh:mm') === moment(start_time, 'kk:mm').format('hh:mm')
                && moment(end, 'kk:mm').format('hh:mm') === moment(end_time, 'kk:mm').format('hh:mm'))
            .reduceRight((acc, curr) => Boolean(acc) || Boolean(curr), false);
    }


    const onChangeTime = useCallback((e: any) => {
        if (schedule) {
            const { start_time, end_time } = schedule.possible_timing_windows[e.target.value as number];
            if (isTimeSlotApplicable(start_time, end_time)) {
                setTime(e.target.value as number)
            }
        }
    }, [schedule, isTimeSlotApplicable])

    const minDate = moment(new Date());
    const maxDate = moment(new Date()).add(7, 'd');

    const updateValidDates = useCallback((schedule: API_ORDER_SCHEDULE_TYPE): void => {
        const { delivery_options: { single_order: { timings } } } = schedule;
        const validDateSlots = timings.map(elem => Object.keys(elem)[0]);
        setValidDates(validDateSlots);
        validDateSlots.length && setDate(moment(validDateSlots[0], 'YYYY-MM-DD'));
    }, [setValidDates]);


    const updateValidTimes = useCallback((schedule: API_ORDER_SCHEDULE_TYPE): void => {
        const { delivery_options: { single_order: { timings } } } = schedule;
        if (!validDates.includes(date.format('YYYY-MM-DD'))) { setValidTimes([]); return; }

        let selectedDate: API_SCHEDULE_TIMING_TYPE | undefined;
        if (selectedDate = timings.find(time_set =>
            Object.keys(time_set)[0] === date.format('YYYY-MM-DD'))
        ) { setValidTimes((Object.values(selectedDate) as API_SCHEDULE_TIME_TYPE[][])[0] || []); }
    }, [date, validDates])


    const shouldDisableDate = useCallback((date: unknown) => {
        return Boolean(validDates.length)
            && !(validDates.includes((date as Moment).format('YYYY-MM-DD')))
    }, [validDates]);


    useDidMount(() => {
        let cancelled = false;
        const getDeliveryAvailabilitySchedule = async () => {
            setLoading(true);
            const response = await getDeliveryAvailability();
            if (response.status && !cancelled) {
                setSchedule(response.response_body);
                updateValidDates(response.response_body)
            }
            setLoading(false);
        };

        if (!schedule) { getDeliveryAvailabilitySchedule() }
        else { updateValidDates(schedule) }
        return () => { cancelled = true; }
    })

    useEffect(() => {
        let cancelled = false;
        schedule && !cancelled && updateValidTimes(schedule);
        return () => { cancelled = true; }
    }, [date])

    useEffect(() => {
        let cancelled = false;
        schedule && date && time !== null && !cancelled && setDeliverySchedule(date, schedule.possible_timing_windows[time])
        return () => { cancelled = true; }
    }, [date, time, schedule])

    return (
        <ScheduleContainer {...{ componentLoading: loading }}>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <SectionTitle>{t("checkout.scheduleAndAddress")}</SectionTitle>
                        {
                            (schedule === null || !(schedule.delivery_options.single_order.timings.length))
                                ?
                                <ComingSoon style={{ height: 200 }}><h5>{t("checkout.driversBusyMsg")}</h5></ComingSoon>
                                :
                                <ScheduleInfo>
                                    <ScheduleDateInformation>
                                        <ScheduleDate>
                                            <MUIDatePickerDesktop {...{
                                                value: date,
                                                minDate, maxDate,
                                                shouldDisableDate,
                                                onChange: onChangeDate,
                                                label: t("checkout.scheduledDeliveryDate"),
                                                renderInput: (params: any) => <TextField {...params} {...{
                                                    sx: {
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                    }
                                                }} />,
                                            }} />
                                        </ScheduleDate>
                                        <ScheduleTimeSlot>
                                            <SelectTime
                                                {...{
                                                    required: false,
                                                    label: time === null ? t("checkout.selectTimeSlot") : t('checkout.scheduledTimeSlot'),
                                                    value: time !== null ? time : '',
                                                    onChange: onChangeTime,
                                                    sx: { width: '100%' },
                                                }} select>
                                                {
                                                    schedule!.possible_timing_windows.map(
                                                        ({ start_time, end_time }, window_index) => {
                                                            const validSlot = isTimeSlotApplicable(start_time, end_time);
                                                            return (
                                                                <MenuItem {...{
                                                                    key: window_index + 1,
                                                                    value: window_index,
                                                                }}>
                                                                    {moment(start_time, 'kk:mm').format('hh:mm A')} {t('common.to')} {moment(end_time, 'kk:mm').format('hh:mm A')}
                                                                    {!validSlot && <NotAvailableTimeSlot />}
                                                                </MenuItem>
                                                            )
                                                        })
                                                }
                                            </SelectTime>
                                        </ScheduleTimeSlot>
                                    </ScheduleDateInformation>
                                    <AddressSection {...{ setGiftAddress, setDeliveryAddress }} />
                                </ScheduleInfo>
                        }

                    </>
            }
        </ScheduleContainer>
    )
}

export default ScheduleInformation