import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import {
    AppStoreDownloadButton,
    PlayStoreDownloadButton,

    PhoneAppImage,

    PictureContainer,
    DownloadAppContainer,
    DownloadAppButtonsWrapper,
    DownloadAppInformationContainer,

    SectionDescription,
    SectionDescriptionText,
    SectionDescriptionHeading,
    SectionDescriptionContainer,

    AppStoreDownloadButtonContainer,
    PlayStoreDownloadButtonContainer,
    SectionDescriptionRelativeContainer,
    BackgroundImage_1,
    BackgroundImage_2,
    PhoneAppImage_Flipped,
    BackgroundImage_2_Flipped,
    BackgroundImage_1_Flipped,
} from './DownloadAppElements';

const DownloadApp = () => {
    const { t } = useTranslation();
    const dir = i18next.dir();
    return (
        <DownloadAppContainer>
            {dir === 'ltr' ? <BackgroundImage_2 /> : <BackgroundImage_2_Flipped />}
            {dir === 'ltr' ? <BackgroundImage_1 /> : <BackgroundImage_1_Flipped />}
            <DownloadAppInformationContainer>
                {/* Generic Images For Aesthetics */}
                <PictureContainer />
                {dir === 'ltr' ? <PhoneAppImage /> : <PhoneAppImage_Flipped />}

                {/* App Information Container */}
                <SectionDescription>
                    <SectionDescriptionRelativeContainer>
                        <SectionDescriptionContainer>
                            <SectionDescriptionHeading>
                                {t('home.downloadAppTitle')}
                            </SectionDescriptionHeading>
                            <SectionDescriptionText>
                                {t('home.downloadAppDescriptionTitle')}
                            </SectionDescriptionText>

                            <DownloadAppButtonsWrapper>
                                <AppStoreDownloadButtonContainer>
                                    <AppStoreDownloadButton />
                                </AppStoreDownloadButtonContainer>
                                <PlayStoreDownloadButtonContainer>
                                    <PlayStoreDownloadButton />
                                </PlayStoreDownloadButtonContainer>
                            </DownloadAppButtonsWrapper>
                        </SectionDescriptionContainer>
                    </SectionDescriptionRelativeContainer>
                </SectionDescription>

            </DownloadAppInformationContainer>


            {/* <SectionDescriptionWrapper>
                <SectionDescription>
                    <SectionDescriptionContainer>
                        <SectionDescriptionHeading>
                            {t('home.downloadAppTitle')}
                        </SectionDescriptionHeading>
                        <SectionDescriptionText>
                            {t('home.downloadAppDescriptionTitle')}
                            <ul>
                                <li><p>{t('home.downloadAppPoint1')}</p></li>
                                <li><p>{t('home.downloadAppPoint2')}</p></li>
                                <li><p>{t('home.downloadAppPoint3')}</p></li>
                            </ul>
                        </SectionDescriptionText>
                        <DownloadAppButtonsWrapper>
                            <AppStoreDownloadButtonContainer>
                                <AppStoreDownloadButton />
                            </AppStoreDownloadButtonContainer>
                            <PlayStoreDownloadButtonContainer>
                                <PlayStoreDownloadButton />
                            </PlayStoreDownloadButtonContainer>
                        </DownloadAppButtonsWrapper>
                    </SectionDescriptionContainer>
                </SectionDescription>
            </SectionDescriptionWrapper> */}
        </DownloadAppContainer>
    );
};

export default DownloadApp;
