import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// IMPORT TYPES
import { RootState } from '@redux/store';
import { API_USER_TYPE } from '@api/user/types';


// IMPORT API INTERACTIONS
import getUserInformation, { updateUserFetch } from '@api/user';
import { syncUserDetailsWithDb } from '@redux/state/user';


// IMPORT STYLED COMPONENTS
import {
    TextInput,
    InputSectionTitle,
    TextInputTwoFields,
    TextInputOneField,
} from '@form_components';
import UploadAvatar from './UploadAvatar/UploadAvatar';
import { PrivateButtonState, LoadingButton } from '@custom_components';
import { AddAddressButtonWrapper } from '../AddressAndCardsTab/AddressesAndCardsElements';
import { COLORS } from '@project/styles';
import { useTranslation } from 'react-i18next';


const ProfileDetails = ({
    openImageSelector,
    setOpenImageSelector,
}: {
    openImageSelector: boolean;
    setOpenImageSelector: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const [userDetails, setUserDetails] = useState<API_USER_TYPE>();
    const getUserDetails = async () => {
        const response = await getUserInformation();
        if (response.status) {
            setUserDetails(response.response_body);
        }
    };
    useEffect(() => {
        getUserDetails();
    }, []);

    const dispatch = useDispatch();

    const [submitButtonState, setSubmitButtonState] = useState<PrivateButtonState & {
        isValid: boolean;
    }>({
        loading: false,
        submitted: false,
        success: null,
        error: '',
        isValid: false,
    });

    const user_details = useSelector(
        (store: RootState) => store.user.details
    );

    const [{ first_name, last_name, email, username }, setNewUserDetails] =
        useState<Partial<API_USER_TYPE>>({
            email: user_details.email,
            last_name: user_details.last_name,
            first_name: user_details.first_name,
            avatar: userDetails?.avatar || undefined,
            username: userDetails?.username || undefined,
        });

    const handleSubmit = async () => {
        setSubmitButtonState((state) => ({
            ...state,
            loading: true,
            submitted: true,
        }));
        const response = await updateUserFetch({
            first_name,
            last_name,
            username,
        });
        if (response.status) {
            dispatch(
                syncUserDetailsWithDb({
                    first_name,
                    last_name,
                })
            );
            setSubmitButtonState((state) => ({
                ...state,
                loading: false,
                success: true,
            }));
        } else {
            setSubmitButtonState((state) => ({
                ...state,
                loading: false,
                success: false,
            }));
        }
    };
    return (
        <div>
            <>
                <InputSectionTitle
                    {...{ style: { marginTop: 30 }, centerOnMobile: true }}
                >
                    {t("userProfile.profileDetails")}
                </InputSectionTitle>
                <TextInputTwoFields>
                    <TextInput
                        {...{
                            value: first_name || '',
                            label: t("common.firstName"),
                            variant: 'outlined',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    first_name: e.target.value,
                                })),
                        }}
                    />
                    <TextInput
                        {...{
                            value: last_name || '',
                            label: t("common.lastName"),
                            variant: 'outlined',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    last_name: e.target.value,
                                })),
                        }}
                    />
                </TextInputTwoFields>
                <TextInputTwoFields>
                    <TextInput
                        {...{
                            value: username || '',
                            label: t("userProfile.username"),
                            variant: 'outlined',
                            type: 'text',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    username: e.target.value,
                                })),
                        }}
                    />
                </TextInputTwoFields>

                <TextInputOneField>
                    <TextInput
                        {...{
                            value: first_name || '',
                            label: t("common.firstName"),
                            variant: 'outlined',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    first_name: e.target.value,
                                })),
                        }}
                    />
                </TextInputOneField>

                <TextInputOneField>
                    <TextInput
                        {...{
                            value: last_name || '',
                            label: t("common.lastName"),
                            variant: 'outlined',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    last_name: e.target.value,
                                })),
                        }}
                    />
                </TextInputOneField>

                <TextInputOneField>
                    <TextInput
                        {...{
                            value: username || '',
                            label: t("userProfile.username"),
                            variant: 'outlined',
                            type: 'text',
                            onChange: (e) =>
                                setNewUserDetails((state) => ({
                                    ...state,
                                    username: e.target.value,
                                })),
                        }}
                    />
                </TextInputOneField>
            </>
            <AddAddressButtonWrapper>
                <LoadingButton
                    {...{
                        style: {
                            height: 50,
                            width: '100%',
                            marginTop: 50,
                            borderRadius: 10,
                        },
                        onClick: handleSubmit,
                        buttonTitle: t("common.editDetails"),
                        buttonState: submitButtonState,
                        disabled: !first_name || !last_name,
                        colorScheme: COLORS.BASIC_LOADING_COLOR_SCHEME,
                    }}
                />
            </AddAddressButtonWrapper>
            <UploadAvatar {...{ openImageSelector, setOpenImageSelector }} />
        </div>
    );
};

export default ProfileDetails;
