import styled from 'styled-components';

//IMPORT ICONS
import plusIcon from '@assets/images/common/plus.png';
import cartIconWhite from '@assets/images/common/cartIconWhite.png';

// IMPORT STYLES
import Input from '@mui/material/Input';
import { COLORS } from '@project/styles';
import { Link } from 'routes/components/Link';


import '@project/fonts';

//CAROUSEL
export const ProductCarouselAndTitleSectionWrapper = styled.div`
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const ProductContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
`

export const NavigationBarContainer = styled.div`
    width: 100%;
    height: 50px;
    padding: 0% 5%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
`

export const Slash = styled.h3`
    color: #444;
    margin: 0% 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`

export const TabContainer = styled.div`
    width: auto;
    height: 100%;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;   

    @media screen and (max-width: 768px) {
        padding: 2% 5px;
    }
`

export const SaleText = styled.span`
    font-size: 1.4rem;
    color: lightgray;
    font-weight: bold;
    text-decoration: line-through;
`


export const TabContainerLink = styled(Link)`
    width: auto;
    height: 100%;
    padding: 0px 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;   
    @media screen and (max-width: 768px) {
        padding: 2% 5px;
    }
`

export const TabTitle = styled.h3<{ color: string }>`
    color: ${({ color }) => color};
    font-weight: bold;
    margin: 0;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`

export const DetailLeftContainer = styled.div`
    max-width: 55%;
    min-width: 55%;
    display: flex;
    overflow: hidden;
    max-height: 70vh;
    margin: 1.5% 0% 0% 2%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media screen and (max-width: 1200px) {
        max-width: 85%;
        min-width: 85%;
        max-height: 80vh;
        position: relative;
        margin: 2% 0%;
        align-self: center;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        max-width: 95%;
        min-width: 95%;
    }
`

export const DetailRightContainer = styled.div`
    display: flex;
    margin: 1.5%;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 1200px) {
        width: 100%;
        height: auto;
        margin: 0%;
        align-items: center;
        justify-content: center;
    }
`

// TITLE SECTION
export const TitleSection = styled.div`
    width: 100%;
    display: flex;
    margin-left: 2%;
    padding: 0% 2%;
    flex-direction: column;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    @media screen and (max-width: 1000px) {
        width: 100%;
        margin-left: 0%;
        padding: 0% 1%;
        /* padding-bottom: 4%; */
        align-self: center;
    }
`;

export const TitleTextWrapper = styled.div`
    display: flex;
    padding: 0% 3%;
    flex-direction: column;
`;

export const TitleText = styled.div`
    font-size: 35px;
    font-weight: bold;
`;

export const SubHeaderText = styled.div`
    font-size: 30px;
    font-weight: normal;
`;

export const PriceText = styled.div`
    margin: 4% 0%;
    font-size: 1.75rem;
    font-weight: bolder;
`;

// ORDER DETAILS PICKER
export const OrderDetailsPickerWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 2% 0%;
    flex-direction: column;
`;

export const OrderDetailsPicker = styled.div`
    display: flex;
    padding: 1% 3%;
    flex-direction: column;
    border: 1px solid ${COLORS.DARK_GREY};
`;

export const OrderDetailName = styled.h3`
    font-weight: normal;
    color: ${COLORS.DARK_GREY};
    margin: 14px;
`;

export const OrderDetailOptionName = styled.h3`
    font-weight: normal;
`;

//ADD TO CART BUTTON
export const OutOfStockInterface = styled.div`
    display: flex;
    width: 100%;
    padding: 2%;
    font-size: 1.6rem;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
`

export const OutOfStockButton = styled.a`
    width: 100%;
    height: 65px;
    display: flex;
    color: white;
    padding: 0px 3%;
    cursor: pointer;
    align-items: center;
    border-radius: 15px;
    flex-direction: row;
    justify-content: center;    
    background-color: ${COLORS.DARK_GREY};

    @media screen and (max-width: 1200px) {
        padding: 2%;
    }
`;

export const AddToCartInterface = styled.div<{ quick_add?: boolean }>`
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: space-between;
    bottom: ${({ quick_add }) => quick_add ? '10px' : '0px'};
    position: ${({ quick_add }) => quick_add ? 'absolute' : 'relative'};
`

export const AddToCartButton = styled.a`
    width: 70%;
    height: 65px;
    display: flex;
    padding: 0px 3%;
    cursor: pointer;
    align-items: center;
    border-radius: 15px;
    flex-direction: row;
    color: #FFF;
    background-color: green;
    justify-content: space-between;    
    transition: all .2s ease;

    &:hover {
        width: 100%;
        height: 70px;
        
    }

    @media screen and (max-width: 1200px) {
        padding: 2%;
    }
`;

export const Counter = styled.div`
    width: 25%;
    display: flex;
    height: 65px;
    margin: 0% 0% 0% 2%;
    border: solid 1px #333;
    align-items: center;
    flex-direction: row;
    border-radius: 15px;
    transition: all .5s ease;
    justify-content: space-evenly;

    @media screen and (max-width: 768px) {
        width: 100px;
    }
`

export const PlusMinusBox = styled.div`
    width: 50%;
    color: #555;
    display: flex;
    font-size: 2rem;
    font-weight: normal;
    align-items: center;
    justify-content: center;

    &:hover {
        display: flex;
        cursor: pointer;
    }

    ${AddToCartButton}:hover & {
        display: none;
    }
`

export const QuantityBox = styled.div`
    width: 50%;
    display: flex;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;

    ${AddToCartButton}:hover & {
        display: none;
    }
`

export const QuantityReadOnlyBox = styled.div`
    width: 100%;
    display: none;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
`

export const CartIcon = styled.img.attrs({
    src: cartIconWhite,
})`
    width: 35px;
    height: 35px;
`;

export const AddToCartText = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 15px 4px;
    
    transition: font-size .2s ease;
    ${AddToCartButton}:hover & {
        font-size: 1.6rem;
    }
`;

//BUNDLE SECTION
export const BundleSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 2% 2%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding: 5% 2%;
        margin-bottom: 20px;
    }
`;

export const BundleSectionTitleText = styled.h2`
    text-align: center;
    font-size: 1.8rem;
    margin: 1% 0% 5% 0%;
    text-decoration: underline;

    @media screen and (max-width: 500px) {
        font-size: 1.5rem;
    }
    
`;

export const BundleItem = styled.div`
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #888;

    @media screen and (max-width: 500px) {
        height: 70px;
    }
`;

export const BundleImageWrapper = styled.div`
    display: flex;
    padding: 5px 5px;
    margin: 8px 8px;
    border-radius: 5px;
    border: solid 1px #AAA;

    @media screen and (max-width: 500px) {
        /* display: none; */
    }
`;

export const BundleItemImage = styled.img.attrs({
})`
    max-height: 70px;
    min-height: 70px;
    max-width: 70px;
    min-width: 70px;
    object-fit: contain;

    @media screen and (max-width: 500px) {
        max-height: 40px;
        min-height: 40px;
        max-width: 40px;
        min-width: 40px;
    }
`;

export const BundleItemNameWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0px 20px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 500px) {
        padding: 0px 10px;
    }
`;

export const BundleItemName = styled.h3`
    margin: 0;

    @media screen and (max-width: 500px) {
        font-size: 1rem;
    }
`;

export const BundleItemSubName = styled.h4`
    color: ${COLORS.DARK_GREY};
    font-weight: normal;
    margin: 0;
`;

export const BundlePriceAndAddButton = styled.div`
    width: 400px;
    display: flex;
    border: 1px solid black;
    height: 100%;
`;

export const BundlePriceWrapper = styled.div`
    display: flex;
    width: 200px;
    height: 100%;
    flex-direction: column;
    display: flex;
    padding-left: 20px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        width: 40%;
        align-items: center;
    }
`;

export const BundlePrice = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 500px) {
        height: 70%;
        width: 50px;
        border-radius: 5px;
        color: black;
        align-self: center;
        flex-direction: column;
        background: ${COLORS.WHITE};
        border: solid 1px green;
    }
`;

export const BundlePriceText = styled.h4`
    margin: 0% 3%;

    @media screen and (max-width: 500px) {
        font-size: 0.9rem;
        font-weight: bold;
        text-align: center;
    }
`;


export const AddButtonImg = styled.img.attrs({
    src: plusIcon,
})`
    width: 35px;
    height: 35px;
    border-radius: 60px;
    cursor: pointer;
    border: 2px solid ${COLORS.GREEN_BRIGHT};
    margin: 0px 20px;
    transition: all .25s ease;

    &:hover {
        transform: scale(1.15);
    }

    @media screen and (max-width: 500px) {
        width: 25px;
        height: 25px;
        margin: 0px 10px 0px 5px;
    }
`;