export const COLORS = {
    GREEN: '#5cbb6c',
    GREEN_BRIGHT: '#5BCC6B',
    LIGHT_GREEN: '#E6FAD7',
    DARK_GREEN: '#28442c',
    RED: '#F15757',
    DARK_RED: '#782B2B',
    PINK: '#FFC0CB',
    NOTIFICATION_GREEN: '#3F8E4A',
    NOTIFICATION_LIGHT_GREEN: '#CEEAD2',

    NOTIFICATION_RED: '#D04851',
    NOTIFICATION_LIGHT_RED: '#FCDDDD',

    NOTIFICATION_ORANGE: '#FE5A1D',
    NOTIFICATION_LIGHT_ORANGE: '#FFDDAA',

    BASIC_LOADING_COLOR_SCHEME: {
        PRIMARY: '#3F8E4A',
        SECONDARY: 'white',
        TERTIARY: '#D04851',
    },
    AUTH: {
        LOGIN: {
            PRIMARY: 'green',
            SECONDARY: '#ACDF87',
            TERTIARY: '#28442c',
        },
        SIGNUP: {
            PRIMARY: '#FF8C00',
            SECONDARY: '#FFD580',
            TERTIARY: '#D2691E',
        },
        FORGOT_PASSWORD: {
            PRIMARY: '#0262ab',
            SECONDARY: '#9fcfe0',
            TERTIARY: '#124a5e',
        }
    },

    WHITE_SMOKE: '#F9F9F9',
    LIGHTER_GREY: '#E4E4E4',
    GREY: '#D4D4D4',
    DARKER_GREY: '#A4A4A4',
    DARK_GREY: '#C4C4C4',
    WHITE: '#FFF',
    CREAM: '#f8f1e3',
    ORANGE: '#faae25',
    ORDER_STATUS: {
        PENDING: '#FFB000',
        ACCEPTED: '#FAAE25',
        DELIVERING: '#61A042',
        DELIVERED: '#5BCC6B',
        CANCELLED: '#F4364C',
        COULD_NOT_FULFILL: '#CF492C',
        FAILED: '#F9593C',
        OTHER: '#504F4F',
    },
    LOADING_SVG: {
        SPRING: {
            0: '#1E5631',
            1: '#A4DE02',
            2: '#76BA1B',
            3: '#4C9A2A',
            4: '#ACDF87',
            5: '#68BB59'
        },
        FALL: {
            0: '#FB8806',
            1: '#FBAF0A',
            2: '#A92D04',
            3: '#FBA35A',
            4: '#E75304',
            5: '#3A1105',
        },
        WINTER: {

        }
    }
};

export const BIG_SCREEN_WIDTH = 1400;

export const MEDIUM_SCREEN_WIDTH = 1000;

export const DEBUG_CONTENT = { border: '1px solid yellow' };

export const HOME_HORIZONTAL_MARGINS = 5 // percentage of full screen 

export const BASE_TRANSITION_TIME = 0.75;

export const BIG_HEADER_HEIGHT = 10;

export const SMALL_HEADER_HEIGHT = 8;

export const BIG_CONTENT_CONTAINER_HEIGHT = 90;

export const SMALL_CONTENT_CONTAINER_HEIGHT = 92;

// IMPORT ALL LOCAL HOSTED FONTS
import ManropeBoldEot from '@assets/fonts/manrope/manrope-v13-latin-600.eot'
import ManropeBoldSvg from '@assets/fonts/manrope/manrope-v13-latin-600.svg'
import ManropeBoldTtf from '@assets/fonts/manrope/manrope-v13-latin-600.ttf'
import ManropeBoldWoff from '@assets/fonts/manrope/manrope-v13-latin-600.woff'
import ManropeBoldWoff2 from '@assets/fonts/manrope/manrope-v13-latin-600.woff2'

import ManropeBolderEot from '@assets/fonts/manrope/manrope-v13-latin-800.eot'
import ManropeBolderSvg from '@assets/fonts/manrope/manrope-v13-latin-800.svg'
import ManropeBolderTtf from '@assets/fonts/manrope/manrope-v13-latin-800.ttf'
import ManropeBolderWoff from '@assets/fonts/manrope/manrope-v13-latin-800.woff'
import ManropeBolderWoff2 from '@assets/fonts/manrope/manrope-v13-latin-800.woff2'

import ManropeRegularEot from '@assets/fonts/manrope/manrope-v13-latin-regular.eot'
import ManropeRegularSvg from '@assets/fonts/manrope/manrope-v13-latin-regular.svg'
import ManropeRegularTtf from '@assets/fonts/manrope/manrope-v13-latin-regular.ttf'
import ManropeRegularWoff from '@assets/fonts/manrope/manrope-v13-latin-regular.woff';
import ManropeRegularWoff2 from '@assets/fonts/manrope/manrope-v13-latin-regular.woff2';

// Material UI Theming
import i18next from 'i18next';

import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';

export const theme = responsiveFontSizes(
    createTheme({
        direction: i18next.dir(),
        typography: {
            fontFamily: [
                'Manrope',
                'ManropeBold',
                'ManropeBolder',
            ].join(','),
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
            @font-face {
              font-family: 'Manrope';
              font-style: normal;
              font-display: swap;
              font-weight: normal;
              src: local('Manrope'), local('Manrope Regular'), local('Manrope-Regular'), 
                   url(${ManropeRegularWoff2}) format('woff2'),
                   url(${ManropeRegularWoff}) format('woff'),
                   url(${ManropeRegularTtf}) format('ttf'),
                   url(${ManropeRegularEot}) format('eot'),
                   url(${ManropeRegularSvg}) format('svg');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }

            @font-face {
                font-family: 'ManropeBold';
                font-style: normal;
                font-display: swap;
                font-weight: bold;
                src: local('ManropeBold'), local('Manrope Bold'), local('Manrope-Bold'), 
                     url(${ManropeBoldWoff2}) format('woff2'),
                     url(${ManropeBoldWoff}) format('woff'),
                     url(${ManropeBoldTtf}) format('ttf'),
                     url(${ManropeBoldEot}) format('eot'),
                     url(${ManropeBoldSvg}) format('svg');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }

              @font-face {
                font-family: 'ManropeBolder';
                font-style: normal;
                font-display: swap;
                font-weight: bolder;
                src: local('ManropeBolder'), local('Manrope Bolder'), local('Manrope-Bolder'), 
                     url(${ManropeBolderWoff2}) format('woff2'),
                     url(${ManropeBolderWoff}) format('woff'),
                     url(${ManropeBolderTtf}) format('ttf'),
                     url(${ManropeBolderEot}) format('eot'),
                     url(${ManropeBolderSvg}) format('svg');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }

          `,
            },
        },
    }))
