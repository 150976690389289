// IMPORT STYLED COMPONENTS
import {
    VideoTitle,
    YoutubeVideo,
    VideoContainer,

    MainContainer,

    DetailsIcon,
    DetailsItem,
    DetailsText,
    DetailContainer,
    DetailsTextWrapper,

    Title,
    TitleAndDescriptionContainer,
} from '@detail_components/PlantCare/PlantCareElements';


// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

//IMPORT ICONS
import bagIcon from '@assets/images/details/bagIcon.png';
import { useTranslation } from 'react-i18next';

interface PlantCareProps {
    product: API_PRODUCT_TYPE
}

const getVideoId = (url: string) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

const PlantCare = ({ product }: PlantCareProps) => {
    const { t } = useTranslation();

    const videoID = product.instructions_video_url ?
        getVideoId(product.instructions_video_url) : false
    return (
        <>
            {
                Boolean(videoID) &&
                <MainContainer>
                    <VideoContainer>
                        <VideoTitle>{product.instructions_video_title || t("productDetail.plantCareInstructionalVideo")}</VideoTitle>
                        <YoutubeVideo {...{
                            frameBorder: '0',
                            title: 'Embedded Youtube Video',
                            src: `https://www.youtube.com/embed/${videoID}/`,
                            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                        }} allowFullScreen />
                    </VideoContainer>
                </MainContainer>
            }
        </>
    );
};

export default PlantCare;
