import styled from 'styled-components';
import { COLORS } from '@project/styles';
import profilePic from '@assets/images/user/placeholderProfilePic.jpg';
import uploadCloudIcon from '@assets/images/user/uploadCloudIcon.png';
import uploadCloudIconWhite from '@assets/images/user/uploadCloudIconWhite.png';


export const Main = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0% 0% 5% 0;
`;
interface UploadImageContainer {
    isOpen: boolean;
    isAvatarEditorOpen: boolean;
}
export const UploadImageContainer = styled.div<UploadImageContainer>`
    display: flex;
    flex-direction: column;
    transition: height 0.5s;
    justify-content: center;
    align-items: center;
    height: ${(props) =>
        props.isOpen ? (props.isAvatarEditorOpen ? '900px' : '400px') : '0'};
    margin-top: 30px;
    /* width: 100%; */
    overflow: hidden;
    @media screen and (max-width: 800px) {
        align-items: center;
    }
`;
export const CurrentProfileImage = styled.div`
    background-image: url(${profilePic});
    background-size: cover;
    border: 5px solid white;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    left: 320px;
    bottom: -95px;
    z-index: 1;
`;
interface ImagePreviewAndCrop {
    areImagesUploaded: boolean;
}

export const CropperWrapper = styled.div`
    /* position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    width: 200px;
    height: 200px;
    overflow: hidden;
    /* border: 1px solid black; */
`;
export const ImagePreviewAndCrop = styled.div<ImagePreviewAndCrop>`
    transition: height 0.5s;
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.areImagesUploaded ? `500px` : 0)};
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
export const ImageUploadBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 99%;
    border-radius: 10px;
    border: 1px solid black;

    flex-direction: column;
    opacity: 0.75;
`;
export const UploadIcon = styled.img.attrs({
    src: uploadCloudIcon,
})`
    width: 50px;
    height: 50px;
`;
export const UploadIconWhite = styled.img.attrs({
    src: uploadCloudIconWhite,
})`
    width: 35px;
    height: 35px;
`;
export const UploadNewImageText = styled.div`
    font-weight: bold;
    color: #000000;
    margin: 0;
    text-align: center;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;
    width: 100%;
`;

export const UploadOrText = styled.div`
    font-weight: normal;
    text-align: center;
`;

export const UploadMaxText = styled.div`
    margin-top: 5%;
    font-weight: normal;
    text-align: center;
`;
export const SliderAndSaveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
`;
