import { useCallback, useEffect, useMemo, useState } from 'react';

// IMPORT TYPES
import { API_PRODUCT_TYPE } from '@api/types';

// IMPORT HELPERS
import {
    getVariant,
    getFirstVariant,
    getProductSubheading,
    isProductAvailable
} from '@project/helpers';
import { useTranslation } from 'react-i18next';
import { AddToCart as addToCart } from '@api/helpers';
import { notifyMessage, promiseMessage } from '@overlays/notifications';

//IMPORT PRODUCT DETAIL COMPONENTS
import {
    TitleText,
    TitleSection,
    TitleTextWrapper,

    PriceText,
    SubHeaderText,

    AddToCartText,
    AddToCartButton,
    AddToCartInterface,

    Counter,
    SaleText,
    QuantityBox,
    PlusMinusBox,
    ProductContentContainer,

    DetailLeftContainer,
    DetailRightContainer,

    OutOfStockButton,
    OutOfStockInterface,


    ProductCarouselAndTitleSectionWrapper,
} from '@detail_components/Top/TopElements';
import PlantCare from '@detail_components/PlantCare/PlantCare';
import VariantPicker from '@detail_components/VariantSelection';
import BundleSection from '@detail_components/Top/BundleSection';
import ProductCarousel from '@detail_components/ProductCarousel';
import InformationBanner from '@home_components/InformationBanner/InformationBanner';
import ProductDetailsAndFAQ from '@detail_components/ProductDescription/ProductDescriptionAndFAQ';



const Top = ({ product, complementaryProducts }: { product: API_PRODUCT_TYPE, complementaryProducts: API_PRODUCT_TYPE[] }) => {
    const { t } = useTranslation();

    const subheader = getProductSubheading(product);
    const { price, name, is_in_sale, non_sale_price } = product;
    const [requestedQuantity, setRequestedQuantity] = useState<number>(1);
    const [active_variant, set_active_variant] = useState<string>(getFirstVariant(product));

    const is_available = useMemo(() => isProductAvailable(product), [product])

    const incrementQuantity = useCallback(() => {
        const maxQuantity = product.variants.find(variant => variant.sku === active_variant)?.quantity || 0;

        if (requestedQuantity >= maxQuantity) {
            notifyMessage({
                message: t('productDetail.maximumQuantityReached'),
                notification_type: 'ERROR',
            })
        } else {
            setRequestedQuantity(prev => prev + 1)
        }
    }, [product, requestedQuantity, active_variant])

    const decrementQuantity = useCallback(() => {
        if (requestedQuantity === 1) {
            notifyMessage({
                message: t('productDetail.minimumQuantityReached'),
                notification_type: 'ERROR',
            })
        } else {
            setRequestedQuantity(prev => prev - 1)
        }
    }, [product, requestedQuantity])

    const variantInfo = useMemo(
        () => getVariant(product, active_variant),
        [product, active_variant])


    const currentPrice = useMemo(() => variantInfo?.price, [variantInfo])
    const variantInSale = useMemo(() => variantInfo?.is_in_sale, [variantInfo])
    const nonSalePrice = useMemo(() => variantInfo?.non_sale_price, [variantInfo])

    const saleFactor = useMemo(() => {
        const previous_price = +(nonSalePrice || '0');
        const current_price = +(currentPrice || '0');
        const price_difference = Math.abs(previous_price - current_price);
        return !previous_price ? 0 : Math.round((price_difference * 100) / previous_price);
    }, [currentPrice, nonSalePrice])


    const displayableImages = useMemo(
        () => variantInfo?.product_images || product.product_images,
        [product, variantInfo])

    const addProductToCart = () => {
        if (variantInfo) {
            const { sku, price } = variantInfo;
            promiseMessage<string | null>({
                promise: addToCart(sku, requestedQuantity, price),
                success: `Added ${product.shortened_display_name || product.name} to Cart!`,
                loading: `Adding ${product.shortened_display_name || product.name} to Cart...`,
                error: (error: string | null) => `Something went wrong, ${error || 'Please try again!'}.`,
            })
        }
    }

    const handleAddProduct = useCallback(addProductToCart, [addProductToCart])

    useEffect(() => {
        let cancelled = false;
        if (!cancelled) {
            set_active_variant(getFirstVariant(product));
        }
        return () => { cancelled = true; }
    }, [product])

    return (
        <ProductCarouselAndTitleSectionWrapper>
            <ProductContentContainer>
                <DetailLeftContainer>
                    <ProductCarousel {...{ displayableImages }} />
                </DetailLeftContainer>

                <DetailRightContainer>
                    <TitleSection>
                        <TitleTextWrapper>
                            <TitleText>
                                {name}
                            </TitleText>

                            <SubHeaderText>
                                {subheader}
                            </SubHeaderText>

                            <PriceText>
                                {t("common.QAR")} {currentPrice}
                                {variantInSale &&
                                    <>
                                        <span>{'\xA0\n'}</ span>
                                        <SaleText>
                                            QAR {(+(nonSalePrice || 0)).toFixed(0)}
                                        </SaleText>
                                    </>}
                            </PriceText>
                        </TitleTextWrapper>
                        {
                            product.variants.length > 1 && is_available &&
                            <VariantPicker {...{
                                product,
                                active_variant,
                                set_active_variant,
                            }} />
                        }

                        {
                            is_available ?
                                <AddToCartInterface>
                                    <AddToCartButton {...{ onClick: handleAddProduct }}>
                                        <AddToCartText>
                                            {t("productDetail.addToCart")}
                                        </AddToCartText>
                                        <AddToCartText>
                                            {t("common.QAR")} {(variantInfo?.price || 0) * requestedQuantity}
                                        </AddToCartText>
                                    </AddToCartButton>

                                    <Counter>
                                        <PlusMinusBox {...{ onClick: decrementQuantity }}>
                                            -
                                        </PlusMinusBox>
                                        <QuantityBox {...{
                                            sx: {
                                                border: 'none',
                                                textAlign: 'center',
                                            }
                                        }}>{requestedQuantity}</QuantityBox>
                                        <PlusMinusBox {...{ onClick: incrementQuantity }}>
                                            +
                                        </PlusMinusBox>
                                    </Counter>
                                </AddToCartInterface>
                                :
                                <OutOfStockInterface>
                                    <OutOfStockButton>
                                        {t("productDetail.outOfStock")}
                                    </OutOfStockButton>
                                </OutOfStockInterface>
                        }

                        <ProductDetailsAndFAQ {...{ product }} />
                    </TitleSection>
                </DetailRightContainer>
            </ProductContentContainer>
            <ProductContentContainer>
                {
                    Boolean(complementaryProducts.length) &&
                    <BundleSection {...{ complementaryProducts }} />
                }
                <PlantCare {...{ product }} />
            </ProductContentContainer>
            <InformationBanner dark_mode />
        </ProductCarouselAndTitleSectionWrapper >
    );
};

export default Top;

