import styled from 'styled-components';


// CAROUSEL DOTS
export const Dots = styled.li`
    box-shadow: 1px 1px 2px rgba(#000, 0.9);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding-top: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 0px 8px;
`;
